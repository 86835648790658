import React, { useState, useEffect, useRef, useMemo } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ConfigurationReportTab from './configurationTab';
import Sorting from '../../components/company_panel/common/sorting';
import { capitalizeAllFirstLetters, capitalizeFirstLetter, formateDateWithTime, formatToTwoDecimals, getBrandId, getCompany, getOutletId } from '../../helpers/commonFunctions';
import CustomPagination from '../../components/company_panel/common/custompagination';
import Loader from '../../components/company_panel/common/loader';
import notFound from '../../../src/assets/company/img/no-record-found.png'
import CustomRangepicker from '../../components/company_panel/common/rangepicker';
import { ListInventoryReport, StockMovementReport } from '../../services/company_panel/report.services';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { LOADER_TIME } from '../../utils/Constants';
import $ from "jquery";
import { GetParentCategories } from '../../services/company_panel/categoryService';
import { GetAllSuppliers } from '../../services/company_panel/supplierService';


const StockMovementReportList = () => {

    const breadcrumbs = [{ title: "Dashboard", url: "/company/dashboard" }, { title: "Pages", url: "" },]
    const [list, setList] = useState([])
    const [dataLength, setDataLength] = useState()
    const [itemPerPage, setItemPerPage] = useState(10)
    const [sorting, setSorting] = useState({});
    const [defaultSorting, setDefaultSorting] = useState(true);
    const [page, setPage] = useState(1);
    const [loader, setLoader] = useState(true)
    const [search, setSearch] = useState({})
    const [globalSearch, setGlobalSearch] = useState("")
    const [resetdate, setResetDate] = useState(false);
    const selectRef = useRef(null);
    const [orderType, setOrderType] = useState();

    const params = useParams();
    const [categoryData, setCategoryData] = useState([]);
    const [supplier, setSupplier] = useState({});

    const [isBrandChanged, setIsBrandChanged] = useState(false);
    const [isOutletChanged, setIsOutletChanged] = useState(false);

    useMemo(() => {
        document.addEventListener('brand-changed', function () {
            setIsBrandChanged(!isBrandChanged);
        });
        document.addEventListener('outlet-changed', function () {
            setIsOutletChanged(!isOutletChanged);
        })
    });

    useEffect(() => {
        if (search) {
            setLoader(true)
            setTimeout(() => {
                const formData = new FormData()
                formData.append("page", page)
                formData.append("per_page", itemPerPage)
                formData.append("sort", JSON.stringify(sorting))
                formData.append("search", JSON.stringify(search))
                formData.append("global_search", globalSearch)
                formData.append('outlet_id', getOutletId());
                formData.append('company_id', getCompany().company_id);
                StockMovementReport(formData).then(data => {
                    setDataLength(data.data.response.count);
                    setList(data && data.data && data.data.response ? data.data.response.rows : [])
                    setLoader(false);
                }).catch(error => {
                    console.log("error ====> ", error);
                    setLoader(false);
                })
            }, 500);
        }
    }, [sorting, search, globalSearch, page])

    const handleSort = (e, column) => {
        setDefaultSorting(false);
        let sort = { order: 0, column: column };
        if (e.target.classList.contains("assc")) {
            sort.order = -1;
        } else {
            sort.order = 1;
        }
        setSorting(sort);
    }

    const prepareSearch = (key, value) => {
        let sr = { ...search };
        if (String(value).length > 0) {
            sr[key] = value;
        } else {
            delete sr[key];
        }
        setSearch(sr);
    }

    const resetFilter = (e) => {
        e.preventDefault()
        setGlobalSearch("")
        prepareSearch()
        setSearch({})
        setResetDate(!resetdate)
        // $("#defaultstatus")[0].selectedIndex = 0
        // $("#resetFilter")[0].selectedIndex = 0
        // $("#supplierFilter")[0].selectedIndex = 0
    }

    const headerMapping = {
        "item_name": "Inventory",
        "category_name": "Category Name",
        "quantity_new": "Opening Stock",
        "quantity_credited": "Inward Stock",
        "quantity_debited": "Outward Stock",
        "quantity_old": "Closing Stock",
        "outlet_name": "Outlet Name",
        "created_at": "Date and Time",
    };

    const handleExport = () => {
        const formData = new FormData()
        formData.append("page", 1)
        formData.append("per_page", dataLength)
        formData.append("sort", JSON.stringify(sorting))
        formData.append("search", JSON.stringify(search))
        formData.append("global_search", globalSearch)
        formData.append('outlet_id', getOutletId());
        StockMovementReport(formData).then(data => {
            setDataLength(data.data.response.count);
            const listData = data && data.data && data.data.response ? data.data.response.rows : [];
            const printData = listData.map((item) => {
                const transformedItem = {};
                // Extract fields from nested objects
                const itemName = item?.item_name || 'N/A';
                const category = item?.category_name || 'N/A';
                const openingStock = item?.quantity_new || 'N/A';
                const inwardStock = item?.quantity_credited || 'N/A';
                const outWardStock = item?.quantity_debited || 'N/A';
                const closingStock = item?.quantity_old || 'N/A';
                const OutletName = capitalizeFirstLetter(item?.outlet_name) || 'N/A'
                const createdAt = formateDateWithTime(item?.created_at) || 'N/A'

                // Map fields according to headerMapping
                for (const key in headerMapping) {
                    if (headerMapping.hasOwnProperty(key)) {
                        const newKey = headerMapping[key];
                        switch (key) {
                            case "item_name":
                                transformedItem[newKey] = capitalizeFirstLetter(itemName);
                                break;
                            case "category_name":
                                transformedItem[newKey] = capitalizeFirstLetter(category);
                                break;
                            case "quantity_new":
                                transformedItem[newKey] = formatToTwoDecimals(openingStock);
                                break;
                            case "quantity_credited":
                                transformedItem[newKey] = formatToTwoDecimals(inwardStock);
                                break;
                            case "quantity_debited":
                                transformedItem[newKey] = formatToTwoDecimals(outWardStock);
                                break;
                            case "quantity_old":
                                transformedItem[newKey] = formatToTwoDecimals(closingStock);
                                break;
                            case "outlet_name":
                                transformedItem[newKey] = (OutletName);
                                break;
                            case "created_at":
                                transformedItem[newKey] = formateDateWithTime(createdAt);
                                break;
                            default:
                                // Handle any unexpected keys
                                console.warn(`Unexpected key in headerMapping: ${key}`);
                                transformedItem[newKey] = 'N/A';
                                break;
                        }
                    }
                }
                return transformedItem;
            });

            const ws = XLSX.utils.json_to_sheet(printData, { header: Object.values(headerMapping) });
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
            saveAs(blob, 'Stock-Movement-Report.xlsx');
            setTimeout(() => {
                setLoader(false);
            }, LOADER_TIME);
        }).catch(error => {
            setTimeout(() => {
                setLoader(false);
            }, LOADER_TIME);
            console.log("error ====> ", error);
        })
    };

    const handleFilterChange = (e) => {
        if (e?.target.value) {
            prepareSearch("category_id", e.target.value);
        } else {
            prepareSearch("category_id", "");
        }
    };

    const handleFilterChangeSupplier = (e) => {
        if (e?.target.value) {
            prepareSearch("supplier_id", e.target.value);
        } else {
            prepareSearch("supplier_id", "");
        }
    };

    useEffect(() => {
        const formData = new FormData()
        formData.append('brand_id', getBrandId());
        GetParentCategories(formData).then(data => {
            setCategoryData(data && data.data && data.data.response ? data.data.response.rows : [])
        }).catch(error => {
            console.log("error ====> ", error);
        })
    }, [isBrandChanged])

    useEffect(() => {
        GetAllSuppliers({ brand_id: getBrandId() }).then(data => {
            setSupplier(data?.data?.response || []);
        }).catch(error => {
            console.log("error ====> ", error);
        });
    }, [isBrandChanged]);


    return (
        <>
            <div className="animation_fade">
                <div className='page-header d-flex align-items-center'>
                    <div className='page-header-left'>
                        <h4>Report</h4>
                    </div>
                    <div className='page-header-right ms-auto'>
                        <button onClick={handleExport} disabled={dataLength === 0} className="btn btn-primary ms-2">Export Report</button>
                    </div>
                </div>
                <div className="card custom-card overflow-hidden">
                    <div className='gap-20 border-bottom'>
                        <div className='col-12'>
                            <div className='d-flex justify-content-between align-items-center'>
                                <ConfigurationReportTab tab='stock' />
                            </div>
                        </div>
                    </div>
                    <div className="gap-20">
                        <div className='page-header d-flex align-items-center mb-0'>
                            {/* <div className='page-header-left'></div> */}
                            <div className='d-flex gap-3 align-items-center'>
                                <div className="form-group mb-0 rangepicker_container">
                                    <input type="search" className="form-control" value={globalSearch} placeholder="Search" onChange={(e) => { setGlobalSearch(e.target.value) }} />
                                </div>
                            </div>
                            <div className='page-header-right ms-auto'>
                                <div className='d-flex gap-3 align-items-center'>
                                    <div className="date-input">
                                        <CustomRangepicker GetDateRange={(e) => { prepareSearch("created", e) }} resetdate={resetdate} />
                                    </div>
                                    {/* <div className="me-3">
                                        <div className="form-group mb-0 filter_icon_container filter_design">
                                            <select
                                                ref={selectRef}
                                                className={`form-control cp ${categoryData && categoryData.length > 5 ? 'scrollable-select' : ''}`}
                                                onChange={handleFilterChange}
                                                id="resetFilter"
                                            >
                                                <option value="">Select Category</option>
                                                {categoryData &&
                                                    categoryData.length > 0 &&
                                                    categoryData.map((option, i) => {
                                                        return (
                                                            <option key={i} value={option?.id}>
                                                                {capitalizeFirstLetter(option?.name)}
                                                            </option>
                                                        );
                                                    })}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="me-3">
                                        <div className="form-group mb-0 filter_icon_container filter_design">
                                            <select
                                                ref={selectRef}
                                                className={`form-control cp ${supplier && supplier.length > 5 ? 'scrollable-select' : ''}`}
                                                onChange={handleFilterChangeSupplier}
                                                id="supplierFilter"
                                            >
                                                <option value="">Select Supplier</option>
                                                {supplier &&
                                                    supplier.length > 0 &&
                                                    supplier.map((option, i) => {
                                                        return (
                                                            <option key={i} value={option?.id}>
                                                                {capitalizeFirstLetter(option?.name)}
                                                            </option>
                                                        );
                                                    })}
                                            </select>
                                        </div>
                                    </div> */}
                                    <button type="reset" value='Reset' onClick={resetFilter} className="btn btn-primary mr-2">Reset Filter</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tabler-wrap">
                        <div className="table-responsive">
                            <table className="table table-custom">
                                <thead>
                                    <tr>
                                        {/* <th width="10%">
                                            <div className='sorting_column'>
                                                <span>Item ID</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="inventories.id" />
                                            </div>
                                        </th> */}
                                        <th width="10%">
                                            <div className='sorting_column'>
                                                <span>Inventory</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="inventories.item_name" />
                                            </div>
                                        </th>
                                        <th width="10%">
                                            <div className='sorting_column'>
                                                <span>Category Name</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="categories.name" />
                                            </div>
                                        </th>
                                        <th width="10%">
                                            <div className='sorting_column'>
                                                <span>Opening Stock</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="inventory_stock_histories.quantity_old" />
                                            </div>
                                        </th>
                                        <th width="10%">
                                            <div className='sorting_column'>
                                                <span>Inward Stock</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="inventory_stock_histories.quantity_credited" />
                                            </div>
                                        </th>
                                        <th width="10%">
                                            <div className='sorting_column'>
                                                <span>Outward Stock</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="inventory_stock_histories.quantity_debited" />
                                            </div>
                                        </th>
                                        <th width="10%">
                                            <div className='sorting_column'>
                                                <span>Closing Stock</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="inventory_stock_histories.quantity_new" />
                                            </div>
                                        </th>
                                        <th width="10%">
                                            <div className='sorting_column'>
                                                <span>Outlet Name</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="outlets.outlet_name" />
                                            </div>
                                        </th>
                                        <th width="10%">
                                            <div className='sorting_column'>
                                                <span>Date and Time</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="inventory_stock_histories.created_at" />
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {loader ?
                                        <tr>
                                            <td colSpan={11}><Loader /></td>
                                        </tr> : <>
                                            {list.length ? list?.map((row, i) => (
                                                <tr>
                                                    {/* <td>#{row?.inventory_id}</td> */}
                                                    <td>{capitalizeFirstLetter(row?.item_name ? row?.item_name : "-")}</td>
                                                    <td>{capitalizeFirstLetter(row?.category_name ? row?.category_name : "-")}</td>
                                                    <td>{row?.quantity_old ? `${formatToTwoDecimals(row?.quantity_old)} ${row?.primary_unit_of_measure}` : "-"}</td>
                                                    <td>{row?.quantity_credited ? `${formatToTwoDecimals(row?.quantity_credited)} ${row?.primary_unit_of_measure}` : "-"}</td>
                                                    <td>{row?.quantity_debited ? `${formatToTwoDecimals(row?.quantity_debited)} ${row?.primary_unit_of_measure}` : "-"}</td>
                                                    <td>{row?.quantity_new ? `${formatToTwoDecimals(row?.quantity_new)} ${row?.primary_unit_of_measure}` : "-"}</td>
                                                    <td>{row?.outlet_name ? capitalizeFirstLetter(row?.outlet_name) : "-"}</td>
                                                    <td>{row?.created_at ? formateDateWithTime(row?.created_at) : "-"}</td>
                                                </tr>
                                            )) : <tr>
                                                <td colSpan={11} className="text-center">
                                                    <img src={notFound} style={{ width: "5%" }} alt="Not Found" />
                                                    <p>No record found</p>
                                                </td>
                                            </tr>
                                            }</>}
                                </tbody>
                            </table>
                        </div>
                        <div className='tabler-footer'>
                            <div className='d-flex align-items-center'>
                                <div id="example1_info" role="status" className="table-result" aria-live="polite"><b>Total Records : {dataLength ? dataLength : "0"}</b></div>
                                {dataLength && dataLength > 0 ?
                                    <CustomPagination datalength={dataLength} itemperpage={itemPerPage} setPage={setPage} currentPage={page} pageRoute={[{ name: "report", path: "/company/report/stock_movement/list" }]} /> : ""}
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer
                    position="top-right"
                    hideProgressBar={false}
                    autoClose={true | 1000}
                    newestOnTop={true}
                    closeOnClick={false}
                    draggable={false}
                    rtl={false}
                />

            </div>
        </>
    );
}

export default StockMovementReportList;