import React,{useState,useEffect} from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Breadcrums from '../../components/company_panel/common/breadcrumbs'
import { LoyaltyPointDetails } from '../../services/company_panel/loyaltypoints.service'
import Loader from '../../components/company_panel/common/loader'
import { capitalizeFirstLetter, formateDate, formateDateWithMonth } from '../../helpers/commonFunctions'

const LoyaltyPointView = () => {

    const breadcrumbs = [{ title: "Loyalty Points", url: "/company/crm/loyalty-points/list/1" },{
        title: "Loyalty Point Details",
        url: ""
    },]

    const navigate = useNavigate()
    const params = useParams()
    const [showDefault, setShowDefault] = useState({})

    useEffect(() => {
        LoyaltyPointDetails({ id: params.id }).then(data => {
            setShowDefault(data && data.data && data.data.response ? data.data.response : [])
        }).catch(error => {
            console.log("error=====>", error)
        })
    }, [])

    return (
        <>
            <div className="page-header d-flex align-items-center">
                <div className="page-header-left">
                    <Breadcrums data={breadcrumbs} />
                    <h4>Loyalty Point</h4>
                </div>
            </div>
            <div className="card custom-card">
            {showDefault && Object.keys(showDefault).length > 0 ?
                    <div className="card-body">
                        <div className="col-lg-12 form-group">
                            <table id="simple-table" className="table  table-bordered table-hover">
                                <tbody>
                                    <tr>
                                        <th>Customer Name</th>
                                        <td>{showDefault && showDefault.customer.customer_name ? capitalizeFirstLetter(showDefault.customer.customer_name)  : "N/A"}</td>
                                    </tr>
                                    <tr>
                                        <th>Customer Number</th>
                                        <td>{showDefault && showDefault.customer.customer_number ? capitalizeFirstLetter(showDefault.customer.customer_number)   : "N/A"}</td>
                                    </tr>
                                    <tr>
                                        <th>Duration</th>
                                        <td>{showDefault && showDefault.duration ? showDefault.duration + " " + "Month"  : "N/A"}</td>
                                    </tr>
                                    <tr>
                                        <th>Loyalty Point</th>
                                        <td>{showDefault && showDefault.loyalty_points ? showDefault.loyalty_points : "N/A"}</td>
                                    </tr>
                                    <tr>
                                        <th>Expiry Date</th>
                                        <td>{showDefault &&  showDefault.created_at ? formateDateWithMonth(showDefault.created_at, showDefault?.duration)   : "N/A"}</td>
                                    </tr>
                                    <tr>
                                        <th>Created Date</th>
                                        <td>{showDefault &&  showDefault.created_at ? formateDate(showDefault.created_at)   : "N/A"}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                     : <Loader />}
            </div>
        </>
    )
}

export default LoyaltyPointView