import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { capitalizeAllFirstLetters, capitalizeFirstLetter, removeHyphens } from '../../helpers/commonFunctions';
import Breadcrums from '../../components/company_panel/common/breadcrumbs';
import Loader from '../../components/company_panel/common/loader'
import { WasteDetailsReason } from '../../services/company_panel/wasteManagement.services';


const WasteManagementReasonsView = () => {
    const navigate = useNavigate()
    const params = useParams()
    const [showDefault, setShowDefault] = useState({})
    const breadcrumbs = [{ title: "Waste Management", url: "/company/store_manager/waste_management/list/1" }, { title: "View Reason", url: "" }]

    useEffect(() => {
        WasteDetailsReason({ id: params.id }).then(data => {
            setShowDefault(data && data.data && data.data.response ? data.data.response : [])
        }).catch(error => {
            console.log("error=====>", error)
        })
    }, [])

    return (
        <>
            <div className="page-header d-flex align-items-center">
                <div className="page-header-left">
                    <Breadcrums data={breadcrumbs} />
                    <h4>View Reason</h4>
                </div>
            </div>
            <div className="card custom-card">
                {showDefault && Object.keys(showDefault).length > 0 ?
                    <div className="card-body p-0">
                        <div className="col-lg-12 form-group">
                            <table id="simple-table" className="table table-custom">
                                <tbody>
                                    <tr>
                                        <td>ID</td>
                                        <td className='text-end'>{showDefault && showDefault.id ? ((showDefault.id)) : "N/A"}</td>
                                    </tr>
                                    <tr>
                                        <td>Reason</td>
                                        <td className='text-end'>{showDefault && showDefault.reason ? (capitalizeFirstLetter(showDefault.reason)) : "N/A"}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div> : <Loader />}
            </div>
        </>
    )
}

export default WasteManagementReasonsView