import React, { useEffect, useState } from 'react'
import { capitalizeFirstLetter, formateDateWithTime, getCompany } from '../../helpers/commonFunctions';
import { getAllLoggedInUser, getTodaysOrders, getTodaysTransactions } from '../../services/company_panel/dashboardServices';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from '../../components/company_panel/common/loader';
import notFound from '../../assets/company/img/no-record-found.png';
import RsuiteCustomPagination from '../../components/company_panel/common/rsuitPagination';
import CustomModal from '../../components/company_panel/common/customModal';

const DashboardList = () => {

    const params = useParams();
    const navigate = useNavigate();
    const [search, setSearch] = useState({})
    const [globalSearch, setGlobalSearch] = useState("");
    const [sorting, setSorting] = useState({});
    const [page, setPage] = useState(1);
    const [orderPage, setOrderPage] = useState(1);
    const [transitionPage, setTransitionPage] = useState(1);
    const [defaultSorting, setDefaultSorting] = useState(true);
    const [dataLength, setDataLength] = useState()
    const [orderDataLength, setOrderDataLength] = useState()
    const [transitionDataLength, setTransitionDataLength] = useState()
    const [itemPerPage, setItemPerPage] = useState(8)
    const [loader, setLoader] = useState(true)
    const [statsUpdate, setStatusUpdate] = useState("false")
    const [loggedInUser, setLoggedInUser] = useState([]);
    const [todaysOrders, setTodaysOrders] = useState([]);
    const [todaysTransitions, setTodaysTransition] = useState([]);
    const [activeButton, setActiveButton] = useState("loggedInUsers");
    const [modalData, setModalData] = useState([]);

    useEffect(() => {
        if (search && activeButton === "loggedInUsers") {
            setLoader(true)
            setTimeout(() => {
                const formData = new FormData();
                formData.append("page", page)
                formData.append("per_page", itemPerPage)
                formData.append("sort", JSON.stringify(sorting))
                formData.append("search", JSON.stringify(search))
                formData.append("global_search", globalSearch)
                formData.append("company_id", getCompany().company_id);
                getAllLoggedInUser(formData).then(data => {
                    setDataLength(data.data.response.count);
                    setLoggedInUser(data && data.data && data?.data?.response ? data?.data?.response?.rows : [])
                    setLoader(false)
                }).catch(error => {
                    console.log("error ====> ", error);
                })
            }, 500);
        }
    }, [sorting, search, globalSearch, statsUpdate, page, activeButton]);

    useEffect(() => {
        if (search && activeButton === "todaysOrders") {
            setLoader(true)
            setTimeout(() => {
                const formData = new FormData();
                formData.append("page", orderPage)
                formData.append("per_page", itemPerPage)
                formData.append("sort", JSON.stringify(sorting))
                formData.append("search", JSON.stringify(search))
                formData.append("global_search", globalSearch)
                formData.append("company_id", getCompany().company_id);
                getTodaysOrders(formData).then(data => {
                    setOrderDataLength(data.data.response.count);
                    setTodaysOrders(data && data.data && data?.data?.response ? data?.data?.response?.rows : [])
                    setLoader(false)
                }).catch(error => {
                    console.log("error ====> ", error);
                })
            }, 500);
        }
    }, [sorting, search, globalSearch, statsUpdate, orderPage, activeButton]);

    useEffect(() => {
        if (search && activeButton === "todaysTransitions") {
            setLoader(true)
            setTimeout(() => {
                const formData = new FormData();
                formData.append("page", transitionPage)
                formData.append("per_page", itemPerPage)
                formData.append("sort", JSON.stringify(sorting))
                formData.append("search", JSON.stringify(search))
                formData.append("global_search", globalSearch)
                formData.append("company_id", getCompany().company_id);
                getTodaysTransactions(formData).then(data => {
                    setTransitionDataLength(data.data.response.count);
                    setTodaysTransition(data && data.data && data?.data?.response ? data?.data?.response?.rows : [])
                    setLoader(false)
                }).catch(error => {
                    console.log("error ====> ", error);
                })
            }, 500);
        }
    }, [sorting, search, globalSearch, statsUpdate, transitionPage, activeButton]);

    function renderCommaSeparatedList(items) {
        return (
            <div style={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: '5px',
                maxWidth: 'auto',
                padding: '0',
            }}>
                {items.map((item, idx) => (
                    <span key={idx} style={{ whiteSpace: 'nowrap', fontSize: '14px' }}>
                        {capitalizeFirstLetter(item)}{idx < items.length - 1 ? ', ' : ''}
                    </span>
                ))}
            </div>
        );
    }
    const getOrderItemsNames = (data) => {
        return data && (
            <div className="d-flex flex-wrap">
                {data?.slice(0,2).map((menuItems, index) => (
                    <span key={index} className="mx-1 text-capitalize">
                        {menuItems}
                        {index < data?.menu_items?.length - 1 && ', '}
                    </span>
                ))}
                {data?.length > 2 &&
                    <span
                        className="link-text cp"
                        data-bs-target="#exampleModalToggle"
                        data-bs-toggle="modal"
                        onClick={() => setModalData(data)} 
                    >
                        ...
                    </span>
                }
            </div>
        );
    };

    const viewOrderFunction = (id) => {
        navigate(`/company/transaction/order/view/${id}`);
    }

    const viewTransitionFunction = (row) => {
        navigate(`/company/transaction/transaction/view/${row}`)
    }

    const printTransition = (row, index) => {
        const printContent = `
            <div style="max-width: 600px; margin: auto; padding: 20px; border: 1px solid #ccc; border-radius: 5px;">
                <h1 style="text-align: left; color: #333;">${row?.order_id || 'N/A'}</h1>
                <h2 style="text-align: center; color: #333;">Invoice</h2>
                <hr style="border: 1px solid #ccc;">
                <div style="margin-bottom: 20px;">
                    <h3 style="color: #666;">Transaction Details</h3>
                    <p><strong>Transaction ID:</strong> ${row?.transaction_id || 'N/A'}</p>
                    <p><strong>Customer Name:</strong> ${row?.customer?.customer_name ? capitalizeFirstLetter(row?.customer?.customer_name) : 'N/A'}</p>
                    <p><strong>Customer Number:</strong> ${row.customer?.customer_number || 'N/A'}</p>
                    <p><strong>Order ID:</strong> ${row?.order_id || 'N/A'}</p>
                    <p><strong>Amount:</strong> ${row.amount ? row.amount + " SAR" : 'N/A'}</p>
                    <p><strong>Status:</strong> ${row.payment_status ? capitalizeFirstLetter(row.payment_status) : 'N/A'}</p>
                    <p><strong>Payment Method:</strong> ${row.payment_method ? capitalizeFirstLetter(row.payment_method) : 'N/A'}</p>
                </div>
                <hr style="border: 1px solid #ccc;">
                <p style="text-align: center; font-style: italic;">Thank you for your business!</p>
            </div>
        `;

        const iframe = document.createElement('iframe');
        iframe.style.position = 'absolute';
        iframe.style.width = '0';
        iframe.style.height = '0';
        iframe.style.border = 'none';
        document.body.appendChild(iframe);

        const doc = iframe.contentWindow.document;
        doc.open();
        doc.write(`
            <html>
                <head>
                    <title>Invoice Print</title>
                    <style>
                        body { font-family: Arial, sans-serif; }
                        h2 { margin-bottom: 10px; }
                        h3 { margin: 10px 0; }
                        p { line-height: 1.5; margin: 5px 0; }
                        hr { margin: 20px 0; }
                    </style>
                </head>
                <body>
                    ${printContent}
                </body>
            </html>
        `);
        doc.close();

        iframe.contentWindow.focus();
        iframe.contentWindow.print();
        document.body.removeChild(iframe);
    };

    return (
        <>
            <div className='card mt-4'>
                <div className='card-header d-flex align-items-center justify-content-between'>
                    <div className="card-header-left">
                        <h4>Recent Activities</h4>
                    </div>
                    <div className="card-header-right">
                        <ul className="nav nav-pills" id="pills-tab2" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button onClick={() => setActiveButton("loggedInUsers")} className="nav-link active" id="pills-Logged-Users-tab" data-bs-toggle="pill" data-bs-target="#pills-Logged-Users" type="button" role="tab" aria-controls="pills-Logged-Users" aria-selected="true">Logged Users</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button onClick={() => setActiveButton("todaysOrders")} className="nav-link" id="pills-Today-orders-tab" data-bs-toggle="pill" data-bs-target="#pills-Today-orders" type="button" role="tab" aria-controls="pills-Today-orders" aria-selected="false">Today's orders</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button onClick={() => setActiveButton("todaysTransitions")} className="nav-link" id="pills-Today-payment-tab" data-bs-toggle="pill" data-bs-target="#pills-Today-payment" type="button" role="tab" aria-controls="pills-Today-payment" aria-selected="false">Today's payment</button>
                            </li>
                        </ul>
                    </div>
                </div>

                {activeButton === "loggedInUsers" && <div className="tabler-wrap">
                    <div className="table-responsive">
                        <table className="table table-custom">
                            <thead>
                                <tr>
                                    <th style={{ width: '33%' }} className='action_head'>Name</th>
                                    <th style={{ width: '33%' }} className='action_head'>Role</th>
                                    <th style={{ width: '33%' }} className='action_head'>Date</th>
                                </tr>
                            </thead>

                            <tbody>
                                {loader ?
                                    <tr>
                                        <td colSpan={11}><Loader /></td>
                                    </tr> :
                                    <>
                                        {loggedInUser?.length > 0 ? (
                                            loggedInUser?.map((user, index) => (
                                                <tr key={index}>
                                                    <td>{`${user?.first_name} ${user?.last_name}`}</td>
                                                    <td>{user?.platform_user_role?.name ? user?.platform_user_role?.name : "-"}</td>
                                                    <td>{"21-23-2345"}</td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan={9} className="text-center p-5">
                                                    <img src={notFound} style={{ width: "5%" }} alt="Not Found" />
                                                    <p className='mt-3'>No Logged User found</p>
                                                </td>
                                            </tr>
                                        )}
                                    </>
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className='tabler-footer'>
                        <div className='d-flex align-items-center justify-content-between'>
                            <div id="example1_info" role="status" className="table-result" aria-live="polite"><b>Total Records : {dataLength ? dataLength : "0"}</b></div>
                            {dataLength && dataLength > 0 ?
                                // <CustomPagination datalength={dataLength} itemperpage={itemPerPage} setPage={setPage} currentPage={page} pageRoute={[{ name: "user", path: "/company/dashboard" }]} /> 

                                <RsuiteCustomPagination
                                    datalength={dataLength}
                                    itemperpage={itemPerPage}
                                    setPage={setPage}
                                    currentPage={page}
                                />

                                : ""}
                        </div>
                    </div>
                </div>}

                {activeButton === "todaysOrders" && <div className="tabler-wrap">
                    <div className="table-responsive">
                        <table className="table table-custom">
                            <thead>
                                <tr>
                                    <th style={{ width: '' }} className='action_head'>Sr. No.</th>
                                    <th style={{ width: '' }} className='action_head'>Food item Name</th>
                                    <th style={{ width: '' }} className='action_head'>Customer name</th>
                                    <th style={{ width: '' }} className='action_head'>Brand</th>
                                    <th style={{ width: '' }} className='action_head'>Outlet name</th>
                                    <th style={{ width: '' }} className='action_head'>Outlet location</th>
                                    <th style={{ width: '' }} className='action_head'>Date & Time</th>
                                    <th style={{ width: '' }} className='action_head'>Price</th>
                                    <th style={{ width: '' }} className='action_head'>Order Status</th>
                                    <th style={{ width: '' }} className='action_head text-end'>Action</th>
                                </tr>
                            </thead>

                            <tbody>
                                {loader ?
                                    <tr>
                                        <td colSpan={12}><Loader /></td>
                                    </tr> :
                                    <>
                                        {todaysOrders?.length > 0 ? (
                                            todaysOrders?.map((order, index) => (
                                                <tr key={index}>
                                                    <td>{((orderPage - 1) * itemPerPage) + index + 1}</td>
                                                    <td>{getOrderItemsNames(order?.menu_item_names)}</td>
                                                    <td>{order?.customer?.customer_name ? order?.customer?.customer_name : "-"}</td>
                                                    <td>{order?.brand_id ? order?.brand_id : "-"}</td>
                                                    <td>{order?.outlet?.outlet_name ? order?.outlet?.outlet_name : "-"}</td>
                                                    <td>{order?.outlet?.location ? order?.outlet?.location : order?.outlet?.city_id?.name ? order?.outlet?.city_id?.name : "-"}</td>
                                                    <td>{order?.created_at ? formateDateWithTime(order?.created_at) : "-"}</td>
                                                    <td>{order?.total_price ? order?.total_price : "-"}</td>
                                                    <td>{order?.order_status ? order?.order_status : "-"}</td>
                                                    <td className='text-end'>
                                                        <div className="dropdown d-inline-block">
                                                            <button className="btn btn-icon btn-secondary" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                <i className='ti ti-dots-vertical'></i>
                                                            </button>
                                                            <ul className="dropdown-menu">
                                                                <li>
                                                                    <div className='dropdown-item' onClick={() => { viewOrderFunction(order?.id) }}>
                                                                        <i className="ti ti-eye" aria-hidden="true"></i> View details
                                                                    </div>
                                                                </li>
                                                                {/* <li>
                                                                    <div className='dropdown-item' onClick={() => { printTransition(transition.id) }}>
                                                                        <i className="ti ti-printer" aria-hidden="true"></i> Print
                                                                    </div>
                                                                </li> */}
                                                            </ul>

                                                        </div>
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan={12} className="text-center p-5">
                                                    <img src={notFound} style={{ width: "5%" }} alt="Not Found" />
                                                    <p className='mt-3'>No Order found</p>
                                                </td>
                                            </tr>
                                        )}
                                    </>
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className='tabler-footer'>
                        <div className='d-flex align-items-center justify-content-between'>
                            <div id="example1_info" role="status" className="table-result" aria-live="polite"><b>Total Records : {orderDataLength ? orderDataLength : "0"}</b></div>
                            {orderDataLength && orderDataLength > 0 ?
                                // <CustomPagination datalength={dataLength} itemperpage={itemPerPage} setPage={setPage} currentPage={page} pageRoute={[{ name: "user", path: "/company/dashboard" }]} /> 

                                < RsuiteCustomPagination
                                    datalength={orderDataLength}
                                    itemperpage={itemPerPage}
                                    setPage={setOrderPage}
                                    currentPage={orderPage}
                                />
                                : ""}
                        </div>
                    </div>
                </div>}

                {activeButton === "todaysTransitions" && <div className="tabler-wrap">
                    <div className="table-responsive">
                        <table className="table table-custom">
                            <thead>
                                <tr>
                                    <th style={{ width: '' }} className='action_head'>Sr. No.</th>
                                    <th style={{ width: '' }} className='action_head'>Transition number</th>
                                    <th style={{ width: '' }} className='action_head'>Customer name</th>
                                    <th style={{ width: '' }} className='action_head'>Customer number</th>
                                    <th style={{ width: '' }} className='action_head'>Order ID</th>
                                    <th style={{ width: '' }} className='action_head'>Payment status</th>
                                    <th style={{ width: '' }} className='action_head'>Payment method</th>
                                    <th style={{ width: '20rem' }} className='action_head'>Date & Time</th>
                                    <th style={{ width: '' }} className='action_head'>Order Type</th>
                                    <th style={{ width: '' }} className='action_head text-end'>Action</th>
                                </tr>
                            </thead>

                            <tbody>
                                {loader ?
                                    <tr>
                                        <td colSpan={12}><Loader /></td>
                                    </tr> :
                                    <>
                                        {todaysTransitions?.length > 0 ? (
                                            todaysTransitions?.map((transition, index) => (
                                                <tr key={index}>
                                                    <td>{((transitionPage - 1) * itemPerPage) + index + 1}</td>
                                                    <td>{transition?.transaction_id ? transition?.transaction_id : "-"}</td>
                                                    <td>{transition?.customer?.customer_name ? transition?.customer?.customer_name : "-"}</td>
                                                    <td>{transition?.customer?.customer_number ? transition?.customer?.customer_number : "-"}</td>
                                                    <td>{transition?.order_id ? transition?.order_id : "-"}</td>
                                                    <td>{transition?.payment_status ? transition?.payment_status : "-"}</td>
                                                    <td>{transition?.payment_method ? transition?.payment_method : "-"}</td>
                                                    <td>{transition?.created_at ? formateDateWithTime(transition?.created_at) : "-"}</td>
                                                    <td>{transition?.customer_order?.order_mode_configuration?.name ? transition?.customer_order?.order_mode_configuration?.name : "-"}</td>
                                                    <td className='text-end'>
                                                        <div className="dropdown d-inline-block">
                                                            <button className="btn btn-icon btn-secondary" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                <i className='ti ti-dots-vertical'></i>
                                                            </button>
                                                            <ul className="dropdown-menu">
                                                                <li>
                                                                    <div className='dropdown-item' onClick={() => { viewTransitionFunction(transition?.id) }}>
                                                                        <i className="ti ti-eye" aria-hidden="true"></i> View details
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className='dropdown-item' onClick={() => { printTransition(transition.id) }}>
                                                                        <i className="ti ti-printer" aria-hidden="true"></i> Print
                                                                    </div>
                                                                </li>
                                                            </ul>

                                                        </div>
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan={12} className="text-center p-5">
                                                    <img src={notFound} style={{ width: "5%" }} alt="Not Found" />
                                                    <p className='mt-3'>No Transition found</p>
                                                </td>
                                            </tr>
                                        )}
                                    </>
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className='tabler-footer'>
                        <div className='d-flex align-items-center justify-content-between'>
                            <div id="example1_info" role="status" className="table-result" aria-live="polite"><b>Total Records : {transitionDataLength ? transitionDataLength : "0"}</b></div>
                            {transitionDataLength && transitionDataLength > 0 ?
                                // <CustomPagination datalength={dataLength} itemperpage={itemPerPage} setPage={setPage} currentPage={page} pageRoute={[{ name: "user", path: "/company/dashboard" }]} /> 

                                < RsuiteCustomPagination
                                    datalength={transitionDataLength}
                                    itemperpage={itemPerPage}
                                    setPage={setTransitionPage}
                                    currentPage={transitionPage}
                                />
                                : ""}
                        </div>
                    </div>
                </div>}

            </div>
            <CustomModal
                title="Food Items name"
                content={modalData} 
            />
        </>
    )
}

export default DashboardList;
