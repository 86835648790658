import React, { useState, useEffect, useMemo } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { GetChangeStatus, GetOutletList, GetDeleteOutlet, ResendKitchenCredential, GetZatcaDetails } from '../../services/company_panel/outletService';
import Swal from 'sweetalert2'
import CustomPagination from '../../components/company_panel/common/custompagination'
import { capitalizeFirstLetter, formateDate } from '../../helpers/commonFunctions';
import Sorting from '../../components/company_panel/common/sorting';
import Loader from '../../components/company_panel/common/loader'
import StatusFilter from '../../components/company_panel/common/statusFilter'
import { showFilterlist } from '../../utils/Constants'
import CustomRangepicker from '../../components/company_panel/common/rangepicker'
import $ from "jquery";
import { getCompany, checkPermission } from '../../helpers/commonFunctions';
import notFound from '../../assets/company/img/no-record-found.png'
import { jsPDF } from "jspdf";

const OutletList = () => {

    const breadcrumbs = [{ title: "Dashboard", url: "/company/dashboard" }, { title: "Pages", url: "" },]
    const navigate = useNavigate()
    const params = useParams()
    const location = useLocation()
    const [list, setList] = useState([])
    const [statsUpdate, setStatusUpdate] = useState("false")
    const [dataLength, setDataLength] = useState()
    const [itemPerPage, setItemPerPage] = useState(10)
    const [sorting, setSorting] = useState({});
    const [defaultSorting, setDefaultSorting] = useState(true);
    const [page, setPage] = useState(1);
    const [loader, setLoader] = useState(true)
    const [search, setSearch] = useState({})
    const [globalSearch, setGlobalSearch] = useState("")
    const [resetdate, setResetDate] = useState(false);
    const [isOutletListModified, setIsOutletListModified] = useState(false);

    function handleChangeOutlet() {
        const evt = new CustomEvent('outlet-list-modified', { detail: {} });
        document.dispatchEvent(evt);
    }

    useMemo(() => {
        document.addEventListener('outlet-list-modified', function () {
            setIsOutletListModified(!isOutletListModified);
        });
    });

    useEffect(() => {
        if (search) {
            setLoader(true)
            const formData = new FormData()
            formData.append("page", params.id)
            formData.append("per_page", itemPerPage)
            formData.append("sort", JSON.stringify(sorting))
            formData.append("search", JSON.stringify(search))
            formData.append("global_search", globalSearch)
            formData.append('company_id', getCompany().company_id);
            GetOutletList(formData).then(data => {
                setDataLength(data.data.response.count);
                setList(data && data.data && data.data.response ? data.data.response.rows : [])
                handleChangeOutlet();
                setLoader(false)
            }).catch(error => {
                console.log("error ====> ", error);
            })
        }
    }, [location, statsUpdate, sorting, search, globalSearch])

    const resetFilter = (e) => {
        e.preventDefault()
        setGlobalSearch("")
        prepareSearch()
        setSearch({})
        setResetDate(!resetdate)
        $("#defaultstatus")[0].selectedIndex = 0
    }


    function deleteFunction(row) {
        Swal.fire({
            customClass: 'swal-wide',
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#2f9bff',
            cancelButtonColor: '#dc3545',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                const formdata = new FormData()
                formdata.append("id", row)
                GetDeleteOutlet(formdata).then(data => {
                    if (data.status === 200) {
                        Swal.fire({
                            customClass: 'swal-wide',
                            position: 'absolute',
                            icon: 'success',
                            title: 'Outlet deleted successfully',
                            showConfirmButton: false,
                            timer: 2000,
                            toast: true,
                            position: 'top-right',
                        })
                        setStatusUpdate(!statsUpdate)
                    }
                }).catch(error => {
                    console.log("deleteError", error)
                })
            }
        })
    }

    const ChangeStatus = (row, Num) => {
        const formData = new FormData()
        formData.append("id", row)
        formData.append("status", Num)
        Swal.fire({
            customClass: 'swal-wide',
            title: 'Are you sure?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#2f9bff',
            cancelButtonColor: '#dc3545',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                GetChangeStatus(formData).then(data => {
                    if (data.status === 200) {
                        Swal.fire({
                            customClass: 'swal-wide',
                            position: 'absolute',
                            icon: 'success',
                            title: data.data.message,
                            showConfirmButton: false,
                            timer: 2000,
                            toast: true,
                            position: 'top-right',
                        })
                        setStatusUpdate(!statsUpdate)
                    }
                }).catch(error => {
                    console.log("error===>", error)
                })
            }
        })
    }

    // resend outlet credentials
    const resendCredential = (row) => {
        const formData = new FormData()
        formData.append("id", row?.id)
        Swal.fire({
            customClass: 'swal-wide',
            title: 'Do you want to resend Credential?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#2f9bff',
            cancelButtonColor: '#dc3545',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                ResendKitchenCredential(formData).then(data => {
                    if (data.status === 200) {
                        Swal.fire({
                            customClass: 'swal-wide',
                            position: 'absolute',
                            icon: 'success',
                            title: data.data.message,
                            showConfirmButton: false,
                            timer: 2000,
                            toast: true,
                            position: 'top-right',
                        })
                    }
                }).catch(error => {
                    console.log("error===>", error)
                })
            }
        })
    }

    const viewFunction = (row) => {
        navigate(`/company/business/outlet/view/${row}`)
    }

    const editFunction = (row) => {
        navigate(`/company/business/outlet/${params.id}/edit/${row.id}`, {
            state: {
                outletName: row?.outlet_name,
            },
        })
    }

    const copyOutletFunction = (row) => {
        navigate(`/company/business/outlet/${params.id}/edit/${row.id}?copy=true`);
    }

    const handleSort = (e, column) => {
        setDefaultSorting(false);
        let sort = { order: 0, column: column };
        if (e.target.classList.contains("assc")) {
            sort.order = -1;
        } else {
            sort.order = 1;
        }
        setSorting(sort);
    }

    // search or filter function
    const prepareSearch = (key, value) => {
        let sr = { ...search };
        if (String(value).length > 0) {
            sr[key] = value;
        } else {
            delete sr[key];
        }
        setSearch(sr);
    }
    // search or filer end

    const handleDownloadClick = (row) => {
        const formData = new FormData();
        formData.append("id", row?.id);
        GetZatcaDetails(formData).then((data) => {
            if (data.status === 200) {
                generatePdf(data?.data?.response);
            }
        })
        .catch((error) => {
        console.log("error===>", error);
        });
    };

    const generatePdf = (data) => {
        const doc = new jsPDF();
        let yPosition = 14; 
        const marginLeft = 8;
        const maxWidth = 180;
        
        // Increased heading font size, decreased value font size
        const headingFontSize = 14;  // Larger font size for headings
        const valueFontSize = 10;     // Smaller font size for values
    
        const pageHeight = doc.internal.pageSize.height;
        const lineHeight = 5;  // Line height to control spacing
        const sectionGap = 4;  // Additional gap between sections (heading and next value)
    
        // Function to add text with wrapping
        const addFileHeading = (value) => {
    
            doc.setFont("helvetica", "bold");
            doc.setFontSize(18);
            doc.text(value, 68, yPosition);
    
            // Add a gap before the next section (heading and value)
            yPosition += 2*sectionGap;  // Adjust this gap to your preference
        };
        const addTextWithWrap = (label, value) => {
            const labelText = `${label}: `;
            const wrappedLabelText = doc.splitTextToSize(labelText, maxWidth);
            const wrappedValueText = doc.splitTextToSize(value, maxWidth);
    
            const spaceRequired = (wrappedLabelText.length + wrappedValueText.length) * lineHeight;
    
            // Check if there’s enough space for the text, if not, add a page
            if (yPosition + spaceRequired > pageHeight - 10) {
                doc.addPage();
                yPosition = 8;
            }
    
            // Add the label (heading)
            doc.setFont("helvetica", "bold");
            doc.setFontSize(headingFontSize);
            wrappedLabelText.forEach(line => {
                doc.text(line, marginLeft, yPosition);
                yPosition += lineHeight;
            });
    
            // Add the value (body text)
            doc.setFont("helvetica", "normal");
            doc.setFontSize(valueFontSize);
            wrappedValueText.forEach(line => {
                doc.text(line, marginLeft + 14, yPosition);  // Indent the value a bit
                yPosition += lineHeight;
            });
    
            // Add a gap before the next section (heading and value)
            yPosition += sectionGap;  // Adjust this gap to your preference
        };
    
        // Data fields to add
        addFileHeading("Zatca Certificate Details");
        addTextWithWrap("Organization Name", data?.vat_name || "N/A");
        addTextWithWrap("VAT Number", data?.vat_number || "N/A");
        addTextWithWrap("Organization Unit Name", data?.organisation_unit_name || "N/A");
        addTextWithWrap("Postal Zone", data?.postal_zone || "N/A");
        addTextWithWrap("Private Key", data?.zatca_details?.private_key || "N/A");
        addTextWithWrap("CSR", data?.zatca_details?.csr || "N/A");
        addTextWithWrap("Compliance Request ID", data?.zatca_details?.compliance_request_id || "N/A");
        addTextWithWrap("Compliance Certificate", data?.zatca_details?.compliance_certificate || "N/A");
        addTextWithWrap("Compliance Secret", data?.zatca_details?.compliance_secret || "N/A");
        addTextWithWrap("Production Request ID", data?.zatca_details?.production_request_id || "N/A");
        addTextWithWrap("Production Certificate", data?.zatca_details?.production_certificate || "N/A");
        addTextWithWrap("Production Secret", data?.zatca_details?.production_secret || "N/A");
        addTextWithWrap("Previous Invoice Hash", data?.zatca_details?.previous_invoice_hash || "N/A");
        addTextWithWrap("Public Key", data?.zatca_details?.public_key || "N/A");
    
        // Save the document
        doc.save("zatca_details.pdf");
    };
    
    return (
        <>
            <div className="animation_fade">
                <div className='page-header d-flex align-items-center'>
                    <div className='page-header-left'>
                        <h4>Outlets List</h4>
                    </div>

                    <div className='page-header-right ms-auto'>
                        {/* <a className="btn btn-primary mr-2" href={ZatcaSampleFileXlsx} download>
                            Sample Zatca Certificate <i class="fa fa-download" aria-hidden="true"></i>
                        </a> */}
                        {checkPermission('store_outlets', 'add') ?
                            <button className="btn btn-primary ms-2" onClick={() => navigate(`/company/business/outlet/add`)}>Create Outlet</button>
                            : ''}

                    </div>
                </div>
                <div className="card custom-card overflow-hidden">
                    <div className='gap-20'>
                        <div className='filter-header d-flex justify-content-between align-items-center'>
                            <div className='d-flex gap-3 align-items-center'>
                                <div className="form-group mb-0 rangepicker_container">
                                    <input type="search" className="form-control" value={globalSearch} placeholder="Search" onChange={(e) => { setGlobalSearch(e.target.value) }} />
                                </div>
                            </div>
                            <div className='d-flex gap-3 align-items-center'>
                                <div className="date-input">
                                    <CustomRangepicker GetDateRange={(e) => { prepareSearch("created", e) }} resetdate={resetdate} />
                                </div>
                                <StatusFilter data={showFilterlist} prepareSearch={prepareSearch} />
                                <button type="reset" value='Reset' onClick={resetFilter} className="btn btn-primary mr-2">Reset Filter</button>
                            </div>
                        </div>
                    </div>
                    <div className="tabler-wrap">
                        <div className="table-responsive">
                            <table className="table table-custom">
                                <thead>
                                    <tr>
                                        <th width="12%">
                                            <div className='sorting_column'>
                                                <span>Name</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="outlet_name" />
                                            </div>
                                        </th>
                                        <th width="15%">
                                            <div className='sorting_column'>
                                                <span>Name (Arabic) </span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="outlet_name_ar" />
                                            </div>
                                        </th>
                                        <th width="11%">
                                            <div className='sorting_column'>
                                                <span>Shortcode </span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="short_code" />
                                            </div>
                                        </th>
                                        <th width="15%">
                                            <div className='sorting_column'>
                                                <span>VAT Number</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="vat_information" />
                                            </div>
                                        </th>
                                        <th width="12%">
                                            <div className='sorting_column'>
                                                <span>Brand</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="brand_name" />
                                            </div>
                                        </th>
                                        <th width="13%">
                                            <div className='sorting_column'>
                                                <span>Created Date</span>
                                                <Sorting sort={sorting} handleSort={handleSort} defaultSorting={defaultSorting} column="created_at" />
                                            </div>
                                        </th>
                                        <th width="7%">Status</th>
                                        <th className='action_head text-end' width="9%">Actions </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {loader ?

                                        <tr>
                                            <td colSpan={10}><Loader /></td>
                                        </tr> : <>
                                            {list.length ? list.map((row, i) => (
                                                <tr>
                                                    <td>{capitalizeFirstLetter(row?.outlet_name)}</td>
                                                    <td>{capitalizeFirstLetter(row?.outlet_name_ar)}</td>
                                                    <td >{row.short_code ? capitalizeFirstLetter(row?.short_code) : '-'}</td>
                                                    <td >{capitalizeFirstLetter(row?.vat_number)}</td>
                                                    <td>{capitalizeFirstLetter(row?.brand_name)}</td>
                                                    <td>{row.created_at ? formateDate(row.created_at) : "N/A"}</td>
                                                    <td>
                                                        <div className="form-check form-switch w-25">
                                                            <input className="form-check-input" type="checkbox" id="status-change" name="status" checked={row.status == 1 ? 'checked' : ''} onClick={() => { ChangeStatus(row.id, row.status == 1 ? 0 : 1) }} />
                                                            <label className="form-check-label" for="status-change"></label>
                                                        </div>
                                                    </td>
                                                    <td className='text-end'>
                                                        <div className="dropdown  d-inline-block">
                                                            <button className="btn btn-icon btn-secondary" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                <i className='ti ti-dots-vertical'></i>
                                                            </button>
                                                            <ul className="dropdown-menu">
                                                                {checkPermission('store_outlets', 'edit') ?
                                                                    <li><div className='dropdown-item' onClick={() => { viewFunction(row.id) }}><i className="ti ti-eye" aria-hidden="true"></i> View details</div></li>
                                                                    : ''}
                                                                {checkPermission('store_outlets', 'edit') ?
                                                                    <li><div className='dropdown-item' onClick={() => { editFunction(row) }}><i className="ti ti-edit" aria-hidden="true"></i> Edit</div></li>
                                                                    : ''}
                                                                {checkPermission('store_outlets', 'delete') && row.slug != 'default' ?
                                                                    <li> <div className='dropdown-item' onClick={() => { deleteFunction(row.id) }}><i className="ti ti-trash" aria-hidden="true"></i> Delete</div></li>
                                                                    : ''}
                                                                {checkPermission('store_outlets', 'add') ?
                                                                    <li> <div className='dropdown-item' onClick={() => { copyOutletFunction(row) }}><i className="ti ti-copy" aria-hidden="true"></i> Copy outlet</div></li>
                                                                    : ''}
                                                                <li><div className='dropdown-item' onClick={() => { resendCredential(row) }}><i className="ti ti-reload" aria-hidden="true"></i>Send Kitchen credential</div></li>
                                                                <li><div className='dropdown-item' onClick={() => handleDownloadClick(row)}><i className="ti ti-download" aria-hidden="true"></i>Download Zatca Details</div></li>
                                                            </ul>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )) : <tr>
                                                <td colSpan={10} className="text-center">
                                                    <img src={notFound} style={{ width: "5%" }} alt="Not Found" />
                                                    <p>No records</p>
                                                </td>
                                            </tr>}</>}
                                </tbody>
                            </table>
                        </div>
                        <div className='tabler-footer'>
                            <div className='d-flex align-items-center'>
                                <div id="example1_info" role="status" className="table-result" aria-live="polite"><b>Total Records : {dataLength ? dataLength : "0"}</b></div>
                                {dataLength && dataLength > 0 ?
                                    <CustomPagination datalength={dataLength} itemperpage={itemPerPage} setPage={setPage} currentPage={page} pageRoute={[{ name: "outlet-management", path: "/company/business/outlet/list" }]} /> : ""}
                            </div>
                        </div>
                    </div>
                    <div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default OutletList