
import React, { useState, useEffect, useMemo } from 'react'
import {  useFormik } from 'formik';
import { EditOutletUser, GetRoles, GetOutletUserDetail, CreateOutlet } from '../../../services/company_panel/platform_user/platformUserService';
import { GetOutletAllList } from '../../../services/company_panel/outletService';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Swal from "sweetalert2"
import Breadcrums from '../../../components/company_panel/common/breadcrumbs';
import { countries } from 'countries-list';
import { Dropdown } from 'semantic-ui-react';
import { blockInvalidChar, capitalizeAllFirstLetters, companyDetail, getBrandId, getCompany, getMobileLength } from '../../../helpers/commonFunctions';
import * as yup from 'yup';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactCountryFlag from "react-country-flag";
import { SelectPicker } from 'rsuite';
import { Form, InputGroup } from 'react-bootstrap';
import { getAllAreas } from '../../../services/company_panel/setting/settingAreaServices';
import CustomMultiSelect from '../../../components/common/CustomMultiSelect';
import OtpInput from 'react-otp-input';

const OutletUserAdd = () => {
  const navigate = useNavigate()
  const [previewImage, setPreviewImage] = useState("");
  const params = useParams();
  const [searchParams] = useSearchParams();
  const [submitted, setSubmitted] = useState(false)
  const [selectedCountryCode, setSelectedCountryCode] = useState([]);
  const [outletList, setOutletList] = useState([]);
  const [roles, setRoles] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [roleSlug, setRoleSlug] = useState(null);
  const [showDefault, setShowDefault] = useState({})
  const [storeTime, setStoreTime] = useState({});
  const [showOutletDropdown, setShowOutletDropdown] = useState(null);
  const [areaList, setAreaList] = useState([]);
  const [isBrandChanged, setIsBrandChanged] = useState(false);
  const [phoneNoLength, setPhoneNoLength] = useState();
  const isCopy = searchParams.get('copy') === 'true';
  const [selectedTime, setSelectedTime] = useState({
    sunday_opening_time: null,
    monday_opening_time: null,
    tuesday_opening_time: null,
    wednesday_opening_time: null,
    thursday_opening_time: null,
    friday_opening_time: null,
    saturday_opening_time: null,
    sunday_closing_time: null,
    monday_closing_time: null,
    tuesday_closing_time: null,
    wednesday_closing_time: null,
    thursday_closing_time: null,
    friday_closing_time: null,
    saturday_closing_time: null,
  });

  const breadcrumbs = [
    {
      title: "Platform User",
      url: "/company/business/platform-User/outlet-user/list/1"
    },
    {
      title: "Outlet Users",
      url: "/company/business/platform-User/outlet-user/list/1"
    },
    {
      title: searchParams.get("copy") ? "Create Platform User" : "Edit Platform User",
      url: ""
    },
  ]

  // handle Time Change
  const handleTimeChange = (flag, time) => {
    setSelectedTime(prevState => ({
      ...prevState,
      [flag]: time
    }));
  };
  const addMinutes = (time, minsToAdd) => {
    const [hours, minutes] = time.split(':').map(Number);
    const date = new Date();
    date.setHours(hours, minutes + minsToAdd);
    const newHours = date.getHours().toString().padStart(2, '0');
    const newMinutes = date.getMinutes().toString().padStart(2, '0');
    return `${newHours}:${newMinutes}`;
  };

  useMemo(() => {
    document.addEventListener('brand-changed', function () {
      setIsBrandChanged(!isBrandChanged);
    });
  });

  useEffect(() => {
    let dataOutlet;
    GetOutletUserDetail(params.id).then(data => {
      dataOutlet = data && data.data && data.data.response ? data.data.response : [];
      setShowDefault(dataOutlet);
      setPhoneNoLength(getMobileLength(dataOutlet?.country_code ? dataOutlet?.country_code : companyDetail().country_code));
      const timeCheckbox = [
        { value: 'monday', label: 'Monday', opening_time: dataOutlet.monday_opening_time, closing_time: dataOutlet.monday_closing_time, is_day: dataOutlet.is_monday },
        { value: 'tuesday', label: 'Tuesday', opening_time: dataOutlet.tuesday_opening_time, closing_time: dataOutlet.tuesday_closing_time, is_day: dataOutlet.is_tuesday },
        { value: 'wednesday', label: 'Wednesday', opening_time: dataOutlet.wednesday_opening_time, closing_time: dataOutlet.wednesday_closing_time, is_day: dataOutlet.is_wednesday },
        { value: 'thursday', label: 'Thursday', opening_time: dataOutlet.thursday_opening_time, closing_time: dataOutlet.thursday_closing_time, is_day: dataOutlet.is_thursday },
        { value: 'friday', label: 'Friday', opening_time: dataOutlet.friday_opening_time, closing_time: dataOutlet.friday_closing_time, is_day: dataOutlet.is_friday },
        { value: 'saturday', label: 'Saturday', opening_time: dataOutlet.saturday_opening_time, closing_time: dataOutlet.saturday_closing_time, is_day: dataOutlet.is_saturday },
        { value: 'sunday', label: 'Sunday', opening_time: dataOutlet.sunday_opening_time, closing_time: dataOutlet.sunday_closing_time, is_day: dataOutlet.is_sunday },
      ];
      setStoreTime(timeCheckbox);
      if (dataOutlet) {
        setSelectedTime({
          sunday_opening_time: dataOutlet.sunday_opening_time,
          monday_opening_time: dataOutlet.monday_opening_time,
          tuesday_opening_time: dataOutlet.tuesday_opening_time,
          wednesday_opening_time: dataOutlet.wednesday_opening_time,
          thursday_opening_time: dataOutlet.thursday_opening_time,
          friday_opening_time: dataOutlet.friday_opening_time,
          saturday_opening_time: dataOutlet.saturday_opening_time,
          sunday_closing_time: dataOutlet.sunday_closing_time,
          monday_closing_time: dataOutlet.monday_closing_time,
          tuesday_closing_time: dataOutlet.tuesday_closing_time,
          wednesday_closing_time: dataOutlet.wednesday_closing_time,
          thursday_closing_time: dataOutlet.thursday_closing_time,
          friday_closing_time: dataOutlet.friday_closing_time,
          saturday_closing_time: dataOutlet.saturday_closing_time,
        });
      }
    }).catch(error => {
      console.log("error=====>", error)
    })
    const options = Object.keys(countries).map(countryCode => ({
      label: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <ReactCountryFlag
            countryCode={countryCode}
            style={{ width: '1.5em', height: '1.5em', marginRight: '0.5em' }}
            aria-label={countryCode}
          />
          {` + ${countries[countryCode].phone}`}
        </div>
      ),
      value: countries[countryCode].phone
    }));
    setSelectedCountryCode(options);

    // Get Roles List
    GetRoles(getCompany().company_id).then(data => {
      const allRoles = data && data.data && data.data.response ? data.data.response : []
      setRoles(allRoles);

      const role = allRoles.find(role => role.id == dataOutlet?.role_id)
      setRoleSlug(role?.slug);

      const roleDropdown = allRoles.map((res) => ({
        key: res.id,
        text: capitalizeAllFirstLetters(res.name),
        value: res.id,
      }))
      setRoleList(roleDropdown)

    }).catch(error => {
      console.log("error=====>", error);
    })
  }, [])

  useEffect(() => {
    GetOutletAllList(getCompany().company_id).then(data => {
      setOutletList(data && data.data && data.data.response ? data.data.response : []);
    }).catch(error => {
      console.log("error=====>", error);
    })
  }, [])

  useEffect(() => {
    getAllAreas({ brand_id: getBrandId() }).then(data => {
      setAreaList(data && data.data && data.data.response ? data.data.response : []);
    }).catch(error => {
      console.log("error=====>", error);
    })
  }, [isBrandChanged])


  const handleTimeCheckbox = (value, key) => {

    setStoreTime((prevStoreTime) =>
      prevStoreTime.map((day) =>
        day.value === key ? { ...day, is_day: value } : day
      )
    );
  };

  const outListData =
    outletList &&
    outletList.map((item) => ({ label: capitalizeAllFirstLetters(item?.outlet_name), value: item?.id }));

  const areaListData =
    areaList &&
    areaList.map((item) => ({ label: capitalizeAllFirstLetters(item?.name), value: item?.id }));

  const validationSchema = yup.object().shape({
    first_name: yup.string().trim().required('First Name is required').min(3, "First Name must be at least 3 digits").max(30, 'First Name cannot exceed 30 digits'),
    first_name_ar: yup.string().trim().required('First Name (Arabic) is required').min(3, "First Name (Arabic) must be at least 3 digits").max(30, 'First Name (Arabic) cannot exceed 30 digits'),
    last_name: yup.string().trim().required('Last Name is required').min(3, "Last Name must be at least 3 digits").max(30, 'Last Name cannot exceed 30 digits'),
    last_name_ar: yup.string().trim().required('Last Name (Arabic) is required').min(3, "Last Name (Arabic) must be at least 3 digits").max(30, 'Last Name (Arabic) cannot exceed 30 digits'),
    country_code: yup.array().required('Country code is required'),
    mobile: yup.string().trim()
      .matches(/^[0-9]+$/, "Phone number must contain only digits")
      .length(phoneNoLength, `Phone number must be of length ${phoneNoLength}`)
      .required('Phone number is required'),
    role_id: yup.string().trim().required('Role is required'),
    employment_type: yup.string().trim().required('Employment type is required'),
    shift: yup.string().trim().required('Shift is required'),
    email: yup.string()
      .email('Invalid email address')
      .required('Email is required'),
    type_of_user: yup.string().trim().required('Type of user is required'),
    outlet_id: showOutletDropdown !== null && showOutletDropdown ? yup.array().min(1, 'At least one outlet must be selected').required('Outlet is required') : null,
    area_id: showOutletDropdown !== null && showOutletDropdown == false ? yup.array().min(1, 'At least one area must be selected').required('Area is required') : null,
    user_login_pin: yup
      .string()
      .min(4, 'PIN must be at least 4 digits')
      .max(4, 'PIN must be exactly 4 digits'),
    user_login_pin: isCopy
      ? yup.string().required('Login PIN is required').min(4, 'Login PIN must be at least 4 digits')
      : yup.string().min(4, 'Login PIN must be at least 4 digits'),

  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      logo: showDefault && showDefault.profile_image ? showDefault.profile_image : "",
      first_name: showDefault && showDefault.first_name ? showDefault.first_name : "",
      first_name_ar: showDefault && showDefault.first_name_ar ? showDefault.first_name_ar : "",
      last_name: showDefault && showDefault.last_name ? showDefault.last_name : "",
      last_name_ar: showDefault && showDefault.last_name_ar ? showDefault.last_name_ar : "",
      email: showDefault && showDefault.email ? showDefault.email : "",
      country_code: showDefault && showDefault.country_code ? [Number(showDefault.country_code)] : [],
      mobile: showDefault && showDefault.mobile ? showDefault.mobile : "",
      role_id: showDefault && showDefault.role_id ? showDefault.role_id : "",
      type_of_user: showDefault && showDefault.type_of_user ? showDefault.type_of_user : "",
      outlet_id: showDefault && showDefault?.outletidlist ? showDefault?.outletidlist : [],
      area_id: showDefault && showDefault?.areaidlist ? showDefault?.areaidlist : [],
      employment_type: showDefault && showDefault.employment_type ? showDefault.employment_type : "",
      shift: showDefault && showDefault.shift ? showDefault.shift : "",
      user_login_pin: "",
      is_sunday: showDefault && showDefault.is_sunday ? showDefault.is_sunday : "",
      is_monday: showDefault && showDefault.is_monday ? showDefault.is_monday : "",
      is_tuesday: showDefault && showDefault.is_tuesday ? showDefault.is_tuesday : "",
      is_wednesday: showDefault && showDefault.is_wednesday ? showDefault.is_wednesday : "",
      is_thursday: showDefault && showDefault.is_thursday ? showDefault.is_thursday : "",
      is_friday: showDefault && showDefault.is_friday ? showDefault.is_friday : "",
      is_saturday: showDefault && showDefault.is_saturday ? showDefault.is_saturday : "",
      sunday_opening_time: showDefault && showDefault.sunday_opening_time ? showDefault.sunday_opening_time : "",
      monday_opening_time: showDefault && showDefault.monday_opening_time ? showDefault.monday_opening_time : "",
      tuesday_opening_time: showDefault && showDefault.tuesday_opening_time ? showDefault.tuesday_opening_time : "",
      wednesday_opening_time: showDefault && showDefault.wednesday_opening_time ? showDefault.wednesday_opening_time : "",
      thursday_opening_time: showDefault && showDefault.thursday_opening_time ? showDefault.thursday_opening_time : "",
      friday_opening_time: showDefault && showDefault.friday_opening_time ? showDefault.friday_opening_time : "",
      saturday_opening_time: showDefault && showDefault.saturday_opening_time ? showDefault.saturday_opening_time : "",
      sunday_closing_time: showDefault && showDefault.sunday_closing_time ? showDefault.sunday_closing_time : "",
      monday_closing_time: showDefault && showDefault.monday_closing_time ? showDefault.monday_closing_time : "",
      tuesday_closing_time: showDefault && showDefault.tuesday_closing_time ? showDefault.tuesday_closing_time : "",
      wednesday_closing_time: showDefault && showDefault.wednesday_closing_time ? showDefault.wednesday_closing_time : "",
      thursday_closing_time: showDefault && showDefault.thursday_closing_time ? showDefault.thursday_closing_time : "",
      friday_closing_time: showDefault && showDefault.friday_closing_time ? showDefault.friday_closing_time : "",
      saturday_closing_time: showDefault && showDefault.saturday_closing_time ? showDefault.saturday_closing_time : "",
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      setSubmitted(true)
      let formData = new FormData();
      formData.append("id", params.id)
      formData.append('profile_image', values.logo);
      formData.append('first_name', values.first_name);
      formData.append('first_name_ar', values.first_name_ar);
      formData.append('last_name', values.last_name);
      formData.append('last_name_ar', values.last_name_ar);
      formData.append('email', values.email);
      formData.append('country_code', values.country_code);
      formData.append('mobile', values.mobile);
      formData.append('role_id', values.role_id);
      formData.append('outlet_id', JSON.stringify(values.outlet_id));
      formData.append('area_id', JSON.stringify(values.area_id));
      formData.append('employment_type', values.employment_type);
      formData.append('shift', values.shift);
      formData.append('is_sunday', values.is_sunday ? values.is_sunday : '');
      formData.append('is_monday', values.is_monday ? values.is_monday : '');
      formData.append('is_tuesday', values.is_tuesday ? values.is_tuesday : '');
      formData.append('is_wednesday', values.is_wednesday ? values.is_wednesday : '');
      formData.append('is_thursday', values.is_thursday ? values.is_thursday : '');
      formData.append('is_friday', values.is_friday ? values.is_friday : '');
      formData.append('is_saturday', values.is_saturday ? values.is_saturday : '');
      formData.append('sunday_opening_time', selectedTime.sunday_opening_time);
      formData.append('monday_opening_time', selectedTime.monday_opening_time);
      formData.append('tuesday_opening_time', selectedTime.tuesday_opening_time);
      formData.append('wednesday_opening_time', selectedTime.wednesday_opening_time);
      formData.append('thursday_opening_time', selectedTime.thursday_opening_time);
      formData.append('friday_opening_time', selectedTime.friday_opening_time);
      formData.append('saturday_opening_time', selectedTime.saturday_opening_time);
      formData.append('sunday_closing_time', selectedTime.sunday_closing_time);
      formData.append('monday_closing_time', selectedTime.monday_closing_time);
      formData.append('tuesday_closing_time', selectedTime.tuesday_closing_time);
      formData.append('wednesday_closing_time', selectedTime.wednesday_closing_time);
      formData.append('thursday_closing_time', selectedTime.thursday_closing_time);
      formData.append('friday_closing_time', selectedTime.friday_closing_time);
      formData.append('saturday_closing_time', selectedTime.saturday_closing_time);
      formData.append('user_login_pin', values.user_login_pin);
      formData.append('company_id', getCompany().company_id);

      let role;
      let type_of_user;
      if (roleSlug == 'above_store_manager') {
        role = 3;
      } else {
        role = 2;
      }
      
      if (roleSlug != 'other') {
        type_of_user = null;
      } else {
        type_of_user = values.type_of_user;
      }

      formData.append('role', role);
      formData.append('type_of_user', type_of_user);
      if (!searchParams.get('copy')) {
        EditOutletUser(formData).then(response => {
          if (response?.data?.status === 1 || response?.data?.status === "1") {
            Swal.fire({
              customClass: 'swal-wide',
              icon: 'success',
              title: response.data.message,
              showConfirmButton: false,
              timer: 1500,
              toast: true,
              position: 'top-right',
            });
            setTimeout(() => {
              navigate(`/company/business/platform-User/outlet-user/list/${1}`)
            }, 1000);
          } else {
            Swal.fire({
              customClass: 'swal-wide',
              icon: 'error',
              title: 'Login pin already exists',
              showConfirmButton: false,
              timer: 5000,
              toast: true,
              position: 'top-right',
            })
            setSubmitted(false)
          }
        }).catch(error => {
          console.log("error ====> ", error);


        })
      } else {
        CreateOutlet(formData).then(response => {
          if (response?.data?.status === 1 || response?.data?.status === "1") {
            Swal.fire({
              customClass: 'swal-wide',
              icon: 'success',
              title: response.data.message,
              showConfirmButton: false,
              timer: 1500,
              toast: true,
              position: 'top-right',
            });
            setTimeout(() => {
              navigate(`/company/business/platform-User/outlet-user/list/${1}`)
            }, 1000);
          } else {
            setSubmitted(false)
          }
        }).catch(error => {
          console.log("error ====> ", error);
        })
      }
    }

  });

  var otp_inputs = document.querySelectorAll(".otp__digit")
  var mykey = "0123456789".split("")
  otp_inputs.forEach((_) => {
    _.addEventListener("keyup", handle_next_input)
  })
  function handle_next_input(event) {
    let current = event.target
    let index = parseInt(current.classList[1].split("__")[2])
    current.value = event.key

    if (event.keyCode == 8 && index > 1) {
      current.previousElementSibling.focus()
    }
    if (index < 4 && mykey.indexOf("" + event.key + "") != -1) {
      var next = current.nextElementSibling;
      next.focus()
    }
    var _finalKey = ""
    for (let { value } of otp_inputs) {
      _finalKey += value
    }
    if (_finalKey.length == 4) {

      formik.setFieldValue("user_login_pin", _finalKey);
    } else {
    }
  }


  const onChangeRole = (roleId) => {
    const roleData = roles.find((role) => role.id === roleId);
    if (roleData?.slug) {
      setRoleSlug(roleData?.slug);
    } else {
      setRoleSlug('other');
    }
  };

  useEffect(() => {
    if (formik.values.role_id) {
      onChangeRole(formik.values.role_id);
    }
  }, [formik.values.role_id]);


  return (
    <>
      <div className="page-header d-flex align-items-center">
        <div className="page-header-left">
          <Breadcrums data={breadcrumbs} />
          <h4>Platform User</h4>
        </div>
        <div className="page-header-right ms-auto"></div>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="row row-sm">
          <div className="card custom-card">
            <div className="card-body">
              <div className="row row-sm">
                <div className='col-md-12'>
                  <div className='upload-logo d-flex align-items-center gap-3 mb-3'>
                    <div className="avatar avatar-xl">
                      {previewImage ?
                        <img alt="avatar" src={previewImage} className={previewImage ? "" : "d-none"} />
                        :
                        <img src={formik.values.logo} alt="profileImage" />
                      }
                    </div>
                    <div className='btn btn-black btn-file-type'>Upload logo <input id="logo" name="logo" accept="image/*" type="file" onChange={(event) => {
                      formik.setFieldValue("logo", event.currentTarget.files[0] || "");
                      { event.currentTarget.files.length == 1 ? (setPreviewImage(URL.createObjectURL(event.currentTarget.files[0]))) : (setPreviewImage("")) }
                    }}
                    /></div>
                  </div>
                  <span className='text-danger d-flex text-left' id='errortext'>
                    {formik.errors.logo && formik.touched.logo && formik.errors.logo}
                  </span>
                </div>
                <div className='col-lg-6 text-center form-group mt-3'>
                  <label htmlFor='first_name' className='text-left d-flex'>First Name <span className="requirestar">*</span></label>
                  <input name='first_name' id="first_name" type='text' placeholder='Enter first name' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.first_name} className='form-control' />
                  <span className='text-danger d-flex text-left'>{formik.errors.first_name && formik.touched.first_name && formik.errors.first_name}</span>
                </div>
                <div className='col-lg-6 text-center form-group mt-3'>
                  <label htmlFor='first_name_ar' className='text-left d-flex'>First Name (Arabic) <span className="requirestar">*</span></label>
                  <input name='first_name_ar' id="first_name_ar" type='text' placeholder='Enter first name arabic' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.first_name_ar} className='form-control' />
                  <span className='text-danger d-flex text-left'>{formik.errors.first_name_ar && formik.touched.first_name_ar && formik.errors.first_name_ar}</span>
                </div>
                <div className='col-lg-6 text-center form-group mt-3'>
                  <label htmlFor='last_name' className='text-left d-flex'>Last Name <span className="requirestar">*</span></label>
                  <input name='last_name' id="last_name" type='text' placeholder='Enter last name' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.last_name} className='form-control' />
                  <span className='text-danger d-flex text-left'>{formik.errors.last_name && formik.touched.last_name && formik.errors.last_name}</span>
                </div>
                <div className='col-lg-6 text-center form-group mt-3'>
                  <label htmlFor='last_name_ar' className='text-left d-flex'>Last Name (Arabic) <span className="requirestar">*</span></label>
                  <input name='last_name_ar' id="last_name_ar" type='text' placeholder='Enter last name arabic' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.last_name_ar} className='form-control' />
                  <span className='text-danger d-flex text-left'>{formik.errors.last_name_ar && formik.touched.last_name_ar && formik.errors.last_name_ar}</span>
                </div>
                <div className='col-lg-6 text-center form-group mt-3'>
                  <label htmlFor='email' className='text-left d-flex'>Email <span className="requirestar">*</span></label>
                  <input name='email' id="email" type='email' placeholder='Enter email' onChange={formik.handleChange} onBlur={formik.handleBlur} disabled={!isCopy} value={formik.values.email} className='form-control' />
                  <span className='text-danger d-flex text-left'>{formik.errors.email && formik.touched.email && formik.errors.email}</span>
                </div>
                <div className='col-lg-6 text-center form-group mt-3'>
                  <label htmlFor='name' className='text-left d-flex'>Phone number <span className="requirestar">*</span></label>
                  <div className="input-group">
                    <span className=''>
                      <SelectPicker
                        style={{ border: 'none', paddingLeft: '0', paddingRight: '0' }}
                        data={selectedCountryCode}
                        name='country_code'
                        className='form-control'
                        id='country_code'
                        onChange={(value) => {formik.setFieldValue("country_code", value); setPhoneNoLength(() => getMobileLength(value)); formik.validateField("mobile")}}
                        onBlur={formik.handleBlur}
                        value={formik.values.country_code}
                        placeholder="Code"
                      />
                    </span>
                    <input
                      type="text"
                      name='mobile'
                      id="mobile"
                      className="form-control"
                      placeholder="Enter phone number"
                      value={formik.values.mobile}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      onKeyDown={blockInvalidChar}
                    />
                  </div>
                  <span className='text-danger d-flex text-left'>{formik.errors.mobile && formik.touched.mobile && formik.errors.mobile}</span>
                  <span className='text-danger d-flex text-left'>{formik.errors.country_code && formik.touched.country_code && formik.errors.country_code}</span>
                </div>
                <div className='col-lg-6 text-center form-group mt-3'>
                  <label htmlFor='role_id' className='text-left d-flex'>Role <span className="requirestar">*</span></label>
                  <Dropdown
                    className='form-control'
                    id="role_id"
                    placeholder='Select role'
                    search
                    fluid
                    selection
                    options={roleList}
                    value={formik.values.role_id}
                    onChange={(event, data) => { formik.setFieldValue("role_id", data.value); onChangeRole(data.value) }}
                  />
                  <span className='text-danger d-flex text-left'>{formik.errors.role_id && formik.touched.role_id && formik.errors.role_id}</span>
                </div>
                {
                  roleSlug && roleSlug == 'other' ?
                    <div className='col-lg-6 text-center form-group mt-3'>
                      <label htmlFor='type_of_user' className='text-left d-flex'>Type of user  <span className="requirestar">*</span></label>
                      <select id="type_of_user" className='form-control' name='type_of_user' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.type_of_user}>
                        <option value="">Select type</option>
                        <option value="store_manager">Store manager</option>
                        <option value="pos">POS</option>
                        <option value="both">Both (Store manager and POS)</option>
                      </select>
                      <span className='text-danger d-flex text-left'>{formik.errors.type_of_user && formik.touched.type_of_user && formik.errors.type_of_user}</span>
                    </div> : null
                }

                {
                  roleSlug && roleSlug == 'area_manager' ?
                    <div className='col-lg-6 text-center form-group mt-3'>
                      <label htmlFor='area_id' className='text-left d-flex'>
                        Area <span className="requirestar">*</span>
                      </label>
                      <CustomMultiSelect data={areaListData} overFlowVisibleOptions = {1} form={formik} name='area_id' />
                      <span className='text-danger d-flex text-left'>
                        {formik.errors.area_id && formik.touched.area_id && formik.errors.area_id}
                      </span>
                    </div> : null
                }

                {
                  roleSlug && roleSlug == 'other' ?
                    <div className='col-lg-6 text-center form-group mt-3'>
                      <label htmlFor='outlet_id' className='text-left d-flex'>
                        Outlet <span className="requirestar">*</span>
                      </label>
                      <CustomMultiSelect data={outListData} overFlowVisibleOptions = {1} form={formik} name='outlet_id' />
                      <span className='text-danger d-flex text-left'>
                        {formik.errors.outlet_id && formik.touched.outlet_id && formik.errors.outlet_id}
                      </span>
                    </div> : null
                }
                <div className='col-lg-6 text-center form-group mt-3'>
                  <label for="employment_type" className="text-left d-flex">Employment Type<span className="requirestar">*</span></label>
                  <div className='row ps-3 mt-3'>
                    <div className="form-check w-25 text-start">
                      <input className="form-check-input" type="radio" style={{ cursor: 'pointer' }} name="employment_type" id="part-time" value="Part-time" autoComplete='off' onChange={formik.handleChange} onBlur={formik.handleBlur} checked={formik.values.employment_type === 'Part-time' ? true : ''} />
                      <label className="form-check-label" for="part-time" style={{ cursor: 'pointer' }} >Part-time</label>
                    </div>
                    <div className="form-check w-25 text-start">
                      <input className="form-check-input" type="radio" style={{ cursor: 'pointer' }} name="employment_type" id="full-time" value="Full-time" autoComplete='off' onChange={formik.handleChange} onBlur={formik.handleBlur} checked={formik.values.employment_type === 'Full-time' ? true : ''} />
                      <label className="form-check-label" for="full-time" style={{ cursor: 'pointer' }} >Full-time</label>
                    </div>
                  </div>
                  <span className='text-danger d-flex text-left'>{formik.errors.employment_type && formik.touched.employment_type && formik.errors.employment_type}</span>
                </div>
                <div className='col-lg-12 text-center form-group mt-3'>
                  <label for="order_mode" className="text-left d-flex">Shift<span className="requirestar">*</span></label>
                  <div className='row ps-3 mt-3'>
                    <div className="form-check w-25 text-start">
                      <input className="form-check-input" type="radio" style={{ cursor: 'pointer' }} name="shift" id="Morning" value="Morning" autoComplete='off' onChange={formik.handleChange} onBlur={formik.handleBlur} checked={formik.values.shift === 'Morning' ? true : ''} />
                      <label className="form-check-label" for="Morning" style={{ cursor: 'pointer' }} >Morning</label>
                    </div>
                    <div className="form-check w-25 text-start">
                      <input className="form-check-input" type="radio" style={{ cursor: 'pointer' }} name="shift" id="Evening" value="Evening" autoComplete='off' onChange={formik.handleChange} onBlur={formik.handleBlur} checked={formik.values.shift === 'Evening' ? true : ''} />
                      <label className="form-check-label" for="Evening" style={{ cursor: 'pointer' }} >Evening</label>
                    </div>
                    <div className="form-check w-25 text-start">
                      <input className="form-check-input" type="radio" style={{ cursor: 'pointer' }} name="shift" id="Afternoon" value="Afternoon" autoComplete='off' onChange={formik.handleChange} onBlur={formik.handleBlur} checked={formik.values.shift === 'Afternoon' ? true : ''} />
                      <label className="form-check-label" for="Afternoon" style={{ cursor: 'pointer' }} >Afternoon</label>
                    </div>
                  </div>
                  <span className='text-danger d-flex text-left'>{formik.errors.shift && formik.touched.shift && formik.errors.shift}</span>
                </div>
                <div className='col-lg-6 text-center form-group mt-3'>
                  <label htmlFor='name' className='text-left d-flex mb-2'>Working Days & Time</label>
                  <table width='100%'>
                    {storeTime.length ? storeTime.map((row, i) => (
                      <tr key={i}>
                        <td className='px-2 py-3'>
                          <div className="form-check w-25">
                            <input className="form-check-input"
                              style={{ cursor: 'pointer' }}
                              type="checkbox" name={"is_" + row.value}
                              id={"is_" + row.value} value="1"
                              onChange={(e) => {
                                formik.handleChange(e); handleTimeCheckbox(e.target.checked, row.value);
                                formik.setFieldValue(`is_${row.value}`, e.target.checked);
                              }}
                              onBlur={formik.handleBlur}
                              checked={row.is_day ? true : ''} />
                            <label className="form-check-label" for="inlineRadio2" >{row.label}</label>
                          </div>
                        </td>
                        <td className='p-2'>
                          {formik.values[`is_${row.value}`] && (
                            <InputGroup>
                              <Form.Control
                                type="time"
                                defaultValue={row.opening_time}
                                placeholder="Opening Time"
                                onChange={(event) => {
                                  const time = event.target.value;
                                  handleTimeChange(row.value + '_opening_time', time)
                                }}
                              />
                            </InputGroup>
                          )}
                        </td>
                        <td className='p-2 pe-0'>
                          {formik.values[`is_${row.value}`] && (
                            <InputGroup>
                              <Form.Control
                                type="time"
                                defaultValue={row.closing_time}
                                placeholder="Closing Time"
                                min={selectedTime[row.value + '_opening_time'] ? addMinutes(selectedTime[row.value + '_opening_time'], 5) : undefined}
                                onChange={(event) => {
                                  const time = event.target.value;
                                  handleTimeChange(row.value + '_closing_time', time)
                                }}
                              />
                            </InputGroup>
                          )}
                        </td>
                      </tr>
                    )) : ''}
                  </table>
                </div>
                <div className='col-lg-12 text-center form-group mt-3'></div>
                <div className='col-lg-6 text-center form-group mt-3'>
                  <label htmlFor='user_login_pin' className='text-left d-flex'>
                    Create Login PIN
                    {isCopy && <span className="requirestar">*</span>}
                  </label>
                  <div className='row mt-2'>
                    <OtpInput
                      inputStyle={{
                        width: "114px",
                        height: "1.5em",
                        padding: "22px",
                        fontSize: "20px",
                        borderRadius: 4,
                        marginRight: '20px',
                        border: "1px solid rgba(232, 232, 232, 1)"
                      }}
                      inputType='number'
                      renderInput={(props) => <input {...props} />}
                      numInputs={4}
                      separator={<span>-</span>}
                      value={formik.values.user_login_pin}
                      onChange={(value) => formik.setFieldValue('user_login_pin', value)}
                    />
                    <div className='col-lg-4'></div>
                  </div>
                  <span className='text-danger d-flex text-left'>
                    {formik.errors.user_login_pin && formik.touched.user_login_pin && formik.errors.user_login_pin}
                  </span>
                </div>
                <div className="mt-4">
                  <button className="btn btn-primary mr-2" type="submit">
                    {isCopy ? 'Add User' : 'Update'}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <ToastContainer
        position="top-right"
        hideProgressBar={false}
        autoClose={true | 1000}
        newestOnTop={true}
        closeOnClick={false}
        draggable={false}
        rtl={false}
      />
    </>
  );
}

export default OutletUserAdd