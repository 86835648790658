import React, { useEffect, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { CouponValidator, GetMealCombosOrder, GetMenuItemsOrder, GetSingleOrderDetails } from '../../services/company_panel/orderService';
import { blockInvalidChar, capitalizeAllFirstLetters, capitalizeFirstLetter, companyDetail, getBrandId, getOutletId, showPrice } from '../../helpers/commonFunctions';
import { GetActiveOrderModes } from '../../services/company_panel/setting/orderModeConfigurationService';
import { Modal, Button } from 'react-bootstrap';
import ItemDetailsModal from './model';
import { useNavigate, Link, useParams, useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import noRecordFoundImg from './../../assets/company/img/no-record-found.png'
import { allergyImageMap, LOADER_TIME } from '../../utils/Constants';
import Breadcrums from '../../components/company_panel/common/breadcrumbs';
import OtpInput from 'react-otp-input';
import { countries } from 'countries-list';
import ReactCountryFlag from 'react-country-flag';
import { Modal as RsModal, Button as RsButton, SelectPicker } from 'rsuite';
import GlobalLoader from '../../components/company_panel/common/globalLoader';


export const RefundOrderAdd = () => {
    const [selectedCountryCode, setSelectedCountryCode] = useState([]);
    const [countryCode, setCountryCode] = useState([Number(companyDetail().country_code)]);
    const [menuItemsOrders, setMenuItemsOrders] = useState([]);
    const [activeCategory, setActiveCategory] = useState(null);
    const [categoryData, setCategoryData] = useState([]);
    const [subCategoryData, setSubCategoryData] = useState([]);
    const [filteredMenuItems, setFilteredMenuItems] = useState([]);
    const [activeTab, setActiveTab] = useState(null);
    const [orderModeData, setOrderModesData] = useState([]);
    const [mealCombosData, setMealCombosData] = useState([]);
    const [viewCombos, setViewCombos] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredItems, setFilteredItems] = useState(filteredMenuItems);
    const [filteredCombos, setFilteredCombos] = useState(mealCombosData);
    const [showModal, setShowModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [couponId, setCouponId] = useState(null)
    const [discount, setDiscount] = useState(0);/** This will be replaced by real value from API */
    const navigate = useNavigate();
    const params = useParams();
    const [isBrandChanged, setIsBrandChanged] = useState(false);
    const [isOutletChanged, setIsOutletChanged] = useState(false);
    const [currentOutlet, setCurrentOutlet] = useState(getOutletId());
    const [orderModeId, setOrderModeId] = useState("");
    const [filteredOrderMode, setFilteredOrderMode] = useState({});
    const [dataLoading, setDataLoading] = useState(false);

    const [couponCalled, setCouponCalled] = useState(false);
    const [changes, setChanges] = useState(true);
    const [otp, setOtp] = useState('');
    const [editIndex, setEditIndex] = useState(-1);

    const { state } = useLocation();
    const [stateDetails, setStateDetails] = useState(null);

    const [showCouponModel, setShowCouponModel] = useState(false);

    //for recall case
    const [newCouponApplied, setNewCouponApplied] = useState(false);
    const [couponType, setCouponType] = useState('');
    const [couponCustomerNumberOld, setCouponCustomerNumberOld] = useState("");

    const [couponCustomerNumber, setCouponCustomerNumber] = useState("");
    const [orderedItems, setOrderedItems] = useState([]);
    const [couponApprovalRequestId, setCouponApprovalRequestId] = useState('');
    //states for selecting addons-validations

    const [addOnNextClicked, setAddOnNextClicked] = useState(false);
    const [addOnSkipClicked, setAddOnSkipClicked] = useState(false);
    const [addOnCategoryIndex, setAddOnCatgoryIndex] = useState(0);
    useMemo(() => {
        document.addEventListener('brand-changed', function () {
            setIsBrandChanged(!isBrandChanged);
        });
        document.addEventListener('outlet-changed', function () {
            setIsOutletChanged(!isOutletChanged);
        });
        if (currentOutlet != getOutletId()) {
            navigate(`/company/transaction/order/list/${1}`);
        }
    });

    useEffect(() => {
        const options = Object.keys(countries).map(countryCode => ({
            label: (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <ReactCountryFlag
                        countryCode={countryCode}
                        style={{ width: '1.5em', height: '1.5em', marginRight: '0.5em' }}
                        aria-label={countryCode}
                    />
                    {` + ${countries[countryCode].phone}`}
                </div>
            ),
            value: countries[countryCode].phone
        }));
        setSelectedCountryCode(options);

        // const options = Object.keys(countries).map(countryCode => ({
        //   value: `${countries[countryCode].phone}`,
        //   countryCode: countryCode
        // }));

        // const seen = new Set();
        // const filterOptions = options.filter(obj => {
        //   const duplicate = seen.has(obj.value);
        //   seen.add(obj.value);
        //   return !duplicate;
        // });

        // setSelectedCountryCode(filterOptions);
    }, []);

    useEffect(() => {
        if (state) {
            if (state.recallOrderId) {
                GetSingleOrderDetails({ id: state.recallOrderId }).then(data => {
                    const order = data && data?.data && data?.data?.response ? data?.data?.response : {};

                    const items = order.customer_order_menu_item_relations.map(item => ({
                        id: item?.menu_item?.id,
                        name: item?.menu_item?.item_name,
                        logo: item?.menu_item?.logo,
                        tax_type: item?.tax_type,
                        tax_amount: item?.tax_amount,
                        price: item?.price,
                        quantity: item?.quantity,
                        image: item?.menu_item?.logo,
                        menuAddOns : item?.menuAddOns,
                        menu_addons_price : item?.menu_addons_price,
                        type: 'menu_item',
                    }));

                    const combos = order.customer_order_meal_combo_relations.map(item => ({
                        id: item?.menu_meal_combo?.id,
                        name: item?.menu_meal_combo?.name,
                        logo: item?.menu_meal_combo?.logo,
                        tax_type: item?.tax_type,
                        tax_amount: item?.tax_amount,
                        price: item?.price,
                        quantity: item?.quantity,
                        image: item?.menu_meal_combo?.logo,
                        type: 'combos',
                    }));
                    let details = {
                        orderItems: [...items, ...combos],
                        subTotal: order?.sub_total_price,
                        taxes: order?.tax_total_price,
                        discount: order?.discount_price,
                        surcharge: order?.surcharge_price,
                        aggregator_commission: order?.aggregator_commission,
                        total_price: order?.total_price,
                        customer_name: order?.customer_name,
                        customer_number: order?.customer?.customer_number,
                        country_code: order?.customer?.country_code,
                    }
                    if (order?.aggregator_id) {
                        details = {
                            ...details,
                            aggregator: order?.aggregators_configuration
                        };
                    }
                    if (order?.service_type) {
                        details = { ...details, service_type: order?.service_type?.service_type }
                        if (order?.service_type.table_name) {
                            details = {
                                ...details,
                                table: {
                                    table_name: order?.service_type?.table_name,
                                    table_number: order?.service_type?.table_number
                                }
                            }
                        }
                    }
                
                    setStateDetails(details);
                    setCouponId(order?.coupon_id ? order?.coupon_id : order?.promo_id);
                    setCouponCustomerNumberOld(order?.coupon_applied_number);
                    setCouponType(order?.coupon_id ? 'coupon' : 'promo');
                    formik?.setFieldValue('couponCode', order?.coupon_id ? order?.coupon?.coupon_code : order?.promo?.promo_code);
                    setCouponCalled((order?.coupon_id || order.promo_id) ? true : false);
                    setChanges((order?.coupon_id || order.promo_id) ? false : true);
                }).catch(error => {
                    console.log("error=====>", error)
                })
            }
            if (state.service_type) {
                let details = {
                    service_type: state.service_type,
                }
                if (state.table) {
                    details = { ...details, table: state.table }
                }
                if (state.aggregator) {
                    details = { ...details, aggregator: state.aggregator };
                }
                setStateDetails(details);
            }
        }
    }, []);

    const handleCategoryClick = (categoryId) => {
        console.log("categoryId", categoryId)
        setActiveCategory(categoryId);
        // const filteredSubCategories = menuItemsOrders?.subCategories?.filter(
        //     (subCategory) => subCategory?.parent_id === categoryId
        // );
        const filteredSubCategories = menuItemsOrders?.subCategories?.filter((subCategory) => {
            const hasMatchingMenuItem = menuItemsOrders?.menuItems?.some(
                (menuItem) => menuItem?.sub_category_id == subCategory?.id
            );
            return subCategory?.parent_id == categoryId && hasMatchingMenuItem;
        });
        // console.log("filteredSubCategories--->",filteredSubCategories);

        setSubCategoryData(filteredSubCategories);
        if (filteredSubCategories.length === 0) {
            setFilteredMenuItems([]);
        }
    };

    useEffect(() => {
        if (activeCategory) {
            const filteredSubCategories = menuItemsOrders?.subCategories?.filter((subCategory) => {
                const hasMatchingMenuItem = menuItemsOrders?.menuItems?.some(
                    (menuItem) => menuItem?.sub_category_id == subCategory?.id
                );
                return subCategory?.parent_id == activeCategory && hasMatchingMenuItem;
            });

            setSubCategoryData(filteredSubCategories);
            if (filteredSubCategories.length === 0) {
                setFilteredMenuItems([]);
            }
        }
    }, [activeCategory]);

    // useEffect(() => {
    //     const filteredCategories = menuItemsOrders?.categories?.filter((category) => {
    //         const hasMatchingSubcategory = menuItemsOrders?.subCategories?.some(
    //             (subCategory) => subCategory?.parent_id == category?.id
    //         );
    //         return category?.id == hasMatchingSubcategory;
    //     });

    //     setCategoryData(filteredCategories);
    // }, []);

    // useEffect(() => {
    //     const filteredCategories = menuItemsOrders?.categories?.filter((category) => {
    //         const hasMatchingSubcategory = menuItemsOrders?.subCategories?.some(
    //             (subCategory) => subCategory?.parent_id === category?.id
    //         );
    //         return hasMatchingSubcategory;
    //     });

    //     setCategoryData(filteredCategories);
    // }, []);

    // console.log("categoryData",categoryData);



    const filterMenuItemsByCategory = (categoryId) => {
        setActiveTab(categoryId);
        const filteredItems = menuItemsOrders?.menuItems?.filter(
            (item) => item?.sub_category_id === categoryId
        );
        setFilteredMenuItems(filteredItems);
    };

    useEffect(() => {
        if (subCategoryData?.length > 0) {
            setActiveTab(subCategoryData[0].id);
            filterMenuItemsByCategory(subCategoryData[0].id);
        }
    }, [subCategoryData]);

    useEffect(() => {
        setDataLoading(true);
        setTimeout(() => {
        if (viewCombos) {
            let payload = {
                outlet_id: getOutletId(),
                brand_id : getBrandId(),
                order_mode_id: params?.id
            }
            if (state?.aggregator) {
                payload = { ...payload, aggregator_id: state?.aggregator?.id }
            } else if (state?.aggregator_id) {
                payload = { ...payload, aggregator_id: state?.aggregator_id }
            }
            GetMealCombosOrder(payload).then(data => {
                if (data && data.data && data.data.response) {
                    setMealCombosData(data.data.response);
                    setDataLoading(false);
                }
            }).catch(error => {
                console.log("error=====>", error);
            });
        } else {
            setDataLoading(true);
            let payload = {
                outlet_id: getOutletId(),
                brand_id : getBrandId(),
                order_mode_id: params?.id
            }
            if (state?.aggregator) {
                payload = { ...payload, aggregator_id: state?.aggregator?.id }
            } else if (state?.aggregator_id) {
                payload = { ...payload, aggregator_id: state?.aggregator_id }
            }
            GetMenuItemsOrder(payload).then(data => {
                if (data && data.data && data.data.response) {
                    setMenuItemsOrders(data.data.response);
                    setActiveCategory(data?.data?.response?.categories[0]?.id)
                    setDataLoading(false);
                }
            }).catch(error => {
                console.log("error=====>", error);
                setDataLoading(false);
            });
        }
        }, 500);
    }, [viewCombos, isOutletChanged, isBrandChanged]);

    useEffect(() => {
        GetActiveOrderModes(getBrandId()).then(data => {
            setOrderModesData(data && data.data && data.data.response ? data.data.response : []);
            const selectedOrderMode = data?.data?.response?.find((orderMode) => orderMode.id == params?.id);
            setFilteredOrderMode(selectedOrderMode);
        }).catch(error => {
            console.log("error=====>", error);
        });
    }, []);

    useEffect(() => {
        setOrderModeId(params?.id);
    }, [])

    const formik = useFormik({
        initialValues: {
            orderType: '',
            customerNote: '',
            couponCode: '',
            staffDiscountPercentage: '',
            staffDiscount: '',
            selectedItems: [],
            items: []
        },
        validationSchema: Yup.object({
            orderType: Yup.string().required('Order type is required'),
            customerNote: Yup.string().max(200, 'Note must be 200 characters or less'),
            couponCode: Yup.string(),
            items: Yup.array().of(
                Yup.object({
                    quantity: Yup.number().min(1, 'Minimum quantity is 1').required('Quantity is required'),
                    name: Yup.string().required('Item name is required'),
                    price: Yup.number().required('Price is required'),
                    image: Yup.string().url().required('Image URL is required')
                })
            ),
            staffDiscount: Yup.number()
                .test(
                    'is-valid-discount',
                    function (value) {
                        const { staffDiscountPercentage } = this.parent;
                        const maxDiscount = discount?.max_discount;

                        if (!value && !staffDiscountPercentage) {
                            return this.createError({ path: 'staffDiscount', message: "Enter Discount amount" });
                        }
                        if (value) {
                            if (value > maxDiscount) {
                                return this.createError({ path: 'staffDiscount', message: `Maximum discount amount of ${maxDiscount} applied` });
                            }
                        }

                        return true;
                    }
                ),
            staffDiscountPercentage: Yup.number()
                .test(
                    'is-valid-percentage-discount',
                    function (value) {
                        const { staffDiscount } = this.parent;
                        const total = showPrice(getSubTotal()?.subtotal) + showPrice(getSubTotal()?.taxes);
                        const maxDiscount = discount?.max_discount;

                        if (!value && !staffDiscount) {
                            return this.createError({ path: 'staffDiscountPercentage', message: "Enter Discount percentage" });
                        }

                        if (value) {
                            const calculatedDiscount = Math.floor((value * showPrice(getSubTotal()?.subtotal)) / 100);
                            if (calculatedDiscount > maxDiscount) {
                                return this.createError({ path: 'staffDiscountPercentage', message: `Maximum discount amount of ${maxDiscount} applied` });
                            }
                        }

                        return true;
                    }
                )

        }),
        onSubmit: values => {
            console.log('Form Values:', values);
        }
    });


    //changing menu-item quantity
    const handleQuantityChange = (index, value) => {
        if (couponCalled) {
            setChanges(true);
        }
        const existingItems = [...formik.values.items];
        if (value < existingItems[index].quantity) {
            existingItems[index].quantity = value;
        } else {
            if (existingItems[index].type == 'menu_item') {
                existingItems[index].quantity = value;
            } else {
                existingItems[index].quantity = value;
            }
        }
        existingItems[index].addons_total_price = Number(existingItems[index].addons_price) * Number(existingItems[index].quantity);
        formik.setFieldValue('items', existingItems);
    };

    //removing menu-item or meal-combo
    const handleRemoveItem = (index) => {
        if (couponCalled) {
            setChanges(true);
        }
        const updatedItems = formik.values.items.filter((_, i) => i !== index);
        formik.setFieldValue('items', updatedItems);
        return updatedItems;
    };


    //on selecting menu-item
    const handleItemSelection = (item) => {
        if (couponCalled) {
            setChanges(true);
        }

        let addons = [];
        item?.menu_item_add_ons?.forEach(category => {
            let categoryObj = {
                category_id: category?.menu_add_on_category?.id,
                category_name: category?.menu_add_on_category?.name,
                addOnValid: category?.min == 0 ? true : false,
                total_quantity: 0,
                free: category?.free,
                min: category?.min,
                max: category?.max,
                total_price: 0
            }
            let categoryAddOns = [];
            category?.menu_add_on_category?.menu_add_ons.forEach(addon => {
                categoryAddOns.push({
                    ...addon,
                    quantity: 0,
                    price: Number(addon?.finalPrice),
                    total_price: 0
                    // add_on_id: category?.id
                });
            });
            categoryObj = {
                ...categoryObj,
                addons: categoryAddOns
            }
            if (categoryAddOns.length) {
                addons.push(categoryObj);
            }
        });
        item = {
            ...item,
            quantity: 1,
            menu_addons: addons,
            selected_menu_addons: [],
            addons_price: 0,
            addons_total_price: 0,
            type: "menu_item"
        };

        setSelectedItem(item);
        // console.log('----------selected addons', item.menu_addons);
        if (item?.menu_addons?.length) {
            setShowModal(true);
        } else {
            addToCart(item);
        }
    };
    // on editing menu-item
    const handleEditItem = (index) => {
        setEditIndex(index);
        let existingItems = [...formik?.values?.items];
        setSelectedItem(existingItems[index]);
        setShowModal(true);
    }
    // on selecting meal-combo
    const handleComboSelection = (combo) => {
        if (couponCalled) {
            setChanges(true);
        }

        const existingItems = [...formik.values.items];
        const comboItems = {
            id: combo.id,
            item_name: combo?.name,
            name_ar: combo.name_ar,
            description: combo.description,
            description_ar: combo.description_ar,
            quantity: 1,
            image: combo?.logo,
            price: combo?.price,
            tax_type: combo?.tax_type,
            finalPrice: combo?.final_price,
            tax_amount: combo?.tax_amount,
            combo_items: combo.combo_items || [],
            combo_menu_items: combo.combo_menu_items || [],
            logo: combo?.logo,
            type: "combos"
        };
        // setSelectedComboDetails(comboItems);
        setSelectedItem(comboItems);
        setIsModalOpen(true);

        // const itemExists = updatedItems.findIndex(item => item.id === comboItems.id && item?.type === comboItems?.type);
        // if (itemExists === -1) {
        //     formik.setFieldValue('items', [...updatedItems, comboItems]);
        // }
    };

    const getSelectedAddOns = (item) => {
        let addOns = [];
        item?.menu_addons?.forEach(categoryAddOns => {
            categoryAddOns?.addons?.forEach(addOn => {
                if (addOn.quantity) {
                    addOns.push({
                        id: addOn.id,
                        name: addOn.name,
                        quantity: addOn.quantity,
                    });
                }
            })
        })
        return addOns;
    }
    //adding menu-item or meal-combo to cart
    const addToCart = (item) => {
        const cartItem = item ? item : selectedItem;
        let cartItemQuantity = 1;
        let existingItems = [...formik.values.items];

        if (editIndex != -1) {
            cartItemQuantity = cartItem.quantity;
            existingItems = handleRemoveItem(editIndex);
            setEditIndex(-1);
        }

        const addons_price = cartItem.addons_price ? cartItem.addons_price : 0;

        const index = existingItems.findIndex(existingItem => (existingItem.id === cartItem.id && existingItem.type === cartItem.type));
        if (index == -1) {
            existingItems.push({ ...cartItem, selected_menu_addons: getSelectedAddOns(cartItem), addons_price: addons_price, addons_total_price: addons_price * cartItemQuantity });
        } else {
            let matchedIndex = -1;
            existingItems.some((existingItem, index) => {
                if (existingItem.id == cartItem.id && existingItem.type === cartItem.type) {
                    let matched = 1;

                    existingItem?.menu_addons?.some((categoryAddOns, categoryIndex) => {
                        return categoryAddOns?.addons?.some((existingMenuAddOn, index) => {
                            if (existingMenuAddOn?.quantity != cartItem.menu_addons[categoryIndex]?.addons[index]?.quantity) {
                                matched = 0;
                                return true;  // Exit inner `some()` loop
                            }
                            return false;  // Continue if no difference found
                        });
                    });
                    // existingItem?.menu_addons?.some((existingMenuAddOn, index) => {
                    //     if (existingMenuAddOn?.quantity != cartItem.menu_addons[index]?.quantity) {
                    //         matched = 0;
                    //         return true;
                    //     }
                    // })
                    if (matched) {
                        matchedIndex = index;
                        return true;
                    }
                }
            })
            if (matchedIndex == -1) {
                existingItems.push({ ...cartItem, selected_menu_addons: getSelectedAddOns(cartItem), addons_price: addons_price, addons_total_price: addons_price * cartItemQuantity });
            } else {
                existingItems[matchedIndex] = {
                    ...existingItems[matchedIndex],
                    quantity: existingItems[matchedIndex].quantity + cartItemQuantity,
                    addons_total_price: existingItems[matchedIndex].addons_price * (existingItems[matchedIndex].quantity + cartItemQuantity)
                }
            }
        }
        formik.setFieldValue('items', existingItems);
        setSelectedItem(null);
    }


    const handleCouponValidation = () => {
        setNewCouponApplied(true);

        let country_code = countryCode.join(' ');
        console.log(countryCode, country_code);
        const enteredCode = formik.values.couponCode.trim();
        let formData = new FormData();
        formData.append('coupon_code', enteredCode);
        if (state && state.recallOrderId) {
            if(formik.values.items.length){
                formData.append('order_amount', Number(getSubTotal().subtotal) + Number(stateDetails.subTotal));
            }else{
                formData.append('order_amount', Number(getSubTotal().subtotal));
            }
        } else {
            formData.append('order_amount', getSubTotal().subtotal);
        }
        formData.append('order_mode', orderModeId);
        formData.append('outlet_id', getOutletId());
        formData.append('brand_id', getBrandId());
        formData.append('customer_number', `${country_code} ${couponCustomerNumber}`);
        formData.append('otp', otp);
        
        let cartUniqueItems = {};
        formik?.values?.items?.forEach((item)=>{
            if(item.type == 'combos'){
                return;
            }
            cartUniqueItems[item.id] = !cartUniqueItems[item.id] ? 
                { quantity : item.quantity, price : item.price } : 
                {  ...cartUniqueItems[item.id], quantity : cartUniqueItems[item.id].quantity + item.quantity };
        })

        stateDetails?.orderItems?.forEach((item)=>{
            if(item.type == 'combos'){
                return;
            }
            cartUniqueItems[item.id] = !cartUniqueItems[item.id] ? 
                { quantity : item.quantity, price : (item.tax_type == 'exclusive' ? item.price : item.price - item.tax_amount)/item.quantity } : 
                {  ...cartUniqueItems[item.id], quantity : cartUniqueItems[item.id].quantity + item.quantity };
        })

        let orderItems=[];
        Object.entries(cartUniqueItems).forEach(([key, value])=>{
            orderItems.push({
                id : key,
                quantity : value.quantity,
                price : value.price
            })
        })

        // if(stateDetails){
        //     cartUniqueItems = {};
        //     stateDetails?.orderItems?.forEach((item)=>{
        //         if(item.type == 'combos'){
        //             return;
        //         }
        //         cartUniqueItems[item.id] = !cartUniqueItems[item.id] ? 
        //             { quantity : item.quantity, price : (item.tax_type == 'exclusive' ? item.price : item.price - item.tax_amount)/item.quantity } : 
        //             {  ...cartUniqueItems[item.id], quantity : cartUniqueItems[item.id].quantity + item.quantity };
        //     })

        //     Object.entries(cartUniqueItems).forEach(([key, value])=>{
        //         orderItems.push({
        //             id : key,
        //             quantity : value.quantity,
        //             price : value.price
        //         })
        //     })
        // }

        setOrderedItems(orderItems);
        // const orderItems = formik?.values?.items?.map((item) => ({
        //     id: item.id,
        //     quantity: item.quantity,
        //     price: item.price,
        // })) || [];
        formData.append('ordered_items', JSON.stringify(orderItems));
        formData.append('coupon_approval_request_id', couponApprovalRequestId);

        CouponValidator(formData).then(response => {
            if (response.data.status === 1 || response.data.status === "1") {
                // setDiscount(response.data?.response?.discount_amount || validCoupons[enteredCode] || 0);
                const data = response.data?.response;
                setDiscount(response?.data?.response);
                setCouponCalled(true);
                
                if(data?.coupon_id){
                    setCouponId(data?.coupon_id);
                    if (data?.status == "applied" && data?.menu_items && data?.menu_items.length) {
                        const items = [...formik.values.items];
                        let menuItemObj = {};
                        items?.forEach((item, index) => {
                            if(item.type == 'combos'){
                                return;
                            }
                            if(!menuItemObj[item.id]){
                                menuItemObj[item.id] = { index, addons_price : item?.addons_total_price }
                            }else if(item?.addons_total_price < menuItemObj[item.id].addons_price ){
                                menuItemObj[item.id] = { ...menuItemObj[item.id], addons_price : item?.addons_total_price }
                            }
                        })
                        console.log('-------menuitemobj', menuItemObj);
                        data?.menu_items.forEach((itemId)=>{
                            if(items[menuItemObj[itemId]?.index]){
                                items[menuItemObj[itemId]?.index].free_quantity = 1;    
                            }
                        })
                        formik?.setFieldValue('items', items);
                    }
                }else if(data?.promo_id){  
                    setCouponId(data?.promo_id);
                    if(data?.items){
                        const items = [...formik.values.items];
                    
                        let menuItems = data?.items;
                        let freeItemsObj= {};
                        menuItems.forEach(item=>{
                            if(!freeItemsObj[item.id]){
                                freeItemsObj[item.id] = {
                                    quantity : item.quantity,
                                    price : item.price
                                }
                            }else{
                                freeItemsObj[item.id] = {
                                    ...freeItemsObj[item.id],
                                    quantity : freeItemsObj[item.id].quantity + item.quantity,
                                }
                            }
                        })
                        items.forEach((item, index)=>{
                            if(freeItemsObj[item.id] && freeItemsObj[item.id].quantity && freeItemsObj[item.id].price == 0){
                                if(freeItemsObj[item.id].quantity >= item.quantity){
                                    items[index].free_quantity = item.quantity;
                                    freeItemsObj[item.id].quantity = freeItemsObj[item.id].quantity - item.quantity;
                                }else{
                                    items[index].free_quantity = freeItemsObj[item.id].quantity;
                                    freeItemsObj[item.id].quantity = 0;
                                }
                                
                            }
                        })
                        formik?.setFieldValue('items', items);
                    }
                }

                if(data?.status == "approval_request_created" && data?.request_id){
                    setCouponApprovalRequestId(data?.request_id);
                }
                // setCouponId(response.data?.response?.coupon_id)
                // setDiscount(response?.data?.response);
                // setCouponCalled(true);

                setChanges(false);
                Swal.fire({
                    customClass: 'swal-wide',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 2500,
                    toast: true,
                    position: 'top-right',
                });

                setTimeout(() => {
                    // navigate(`/company/transaction/order/list/${1}`);
                }, 1000);
            } else {
                formik.setFieldValue('discount', 0);

                Swal.fire({
                    customClass: 'swal-wide',
                    icon: 'error',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000,
                    toast: true,
                    position: 'top-right',
                });
            }
        }).catch(error => {
            console.log("error ====> ", error);

            Swal.fire({
                customClass: 'swal-wide',
                icon: 'error',
                title: 'Error validating coupon.',
                showConfirmButton: false,
                timer: 5000,
                toast: true,
                position: 'top-right',
            });
        });
    };

    const removeCoupon=()=>{
        formik.setFieldValue('couponCode', '');
        // if(discount){
            setOtp("");
            setDiscount(0);
            setCouponCalled(false);
            setCouponId(null);
            setCouponApprovalRequestId('');
    
            let items = [...formik.values.items];
            items?.forEach((item, index)=> item?.free_quantity ? items[index].free_quantity = 0 : null);
            formik.setFieldValue('items', items);

            
        // }
    }

    const handleSearch = (event) => {
        const query = event.target.value.toLowerCase();
        setSearchQuery(query);
    };
    useEffect(() => {
        if (viewCombos) {
            setFilteredCombos(
                mealCombosData.filter(combo => combo.name.toLowerCase().includes(searchQuery))
            );
        } else {
            setFilteredItems(
                filteredMenuItems.filter(item => item.item_name.toLowerCase().includes(searchQuery))
            );
        }
    }, [searchQuery, viewCombos, mealCombosData, filteredMenuItems]);

    const handleTabChange = (isCombo) => {
        setViewCombos(isCombo);
        setSearchQuery('');
        setFilteredItems(filteredMenuItems);
        setFilteredCombos(mealCombosData);
    };

    const isSelected = (item, type) => {
        return formik?.values?.items?.findIndex(record => record?.id === item?.id && record?.type === type) != -1;
    };

    const isComboSelected = (item, type) => {
        return formik?.values?.items?.findIndex(record => record?.id === item?.id && record?.type === type) != -1;
    };

    const handleMinAddOn = (index, type) => {
        //quantity calculation
        let item = { ...selectedItem };
        let menu_addons = [...item?.menu_addons];
        let addOns = [...menu_addons[addOnCategoryIndex].addons];

        if (type === 'increment') {
            addOns[index].quantity += 1;
            menu_addons[addOnCategoryIndex].total_quantity += 1;
        } else if (type === 'decrement') {
            addOns[index].quantity -= 1;
            menu_addons[addOnCategoryIndex].total_quantity -= 1;
        }

        if (menu_addons[addOnCategoryIndex].total_quantity < menu_addons[addOnCategoryIndex].min) {
            menu_addons[addOnCategoryIndex].addOnValid = false;
        } else {
            menu_addons[addOnCategoryIndex].addOnValid = true;
        }

        let addOnsPrices = [];
        let categoryAddOnsPrice = 0;

        addOns.forEach((addon, index) => {
            categoryAddOnsPrice += Number(addon.quantity) * Number(addon.price);
            addOns[index].total_price = Number(addOns[index].quantity) * Number(addOns[index].price);
            let quantity = addon.quantity;
            while (quantity) {
                addOnsPrices.push({ price: addon.price, addon_index: index });
                quantity--;
            }
        });

        if (menu_addons[addOnCategoryIndex].total_quantity >= menu_addons[addOnCategoryIndex].min && menu_addons[addOnCategoryIndex].free) {
            if (menu_addons[addOnCategoryIndex].free >= menu_addons[addOnCategoryIndex].total_quantity) {
                categoryAddOnsPrice = 0;
                for (let i = 0; i < addOns?.length; i++) {
                    addOns[i].total_price = 0;
                }
            } else {
                let free = menu_addons[addOnCategoryIndex].free;
                let addOnsObj = {}
                const cmp = (a, b) => {
                    return Number(a.price) - Number(b.price);
                }
                addOnsPrices.sort(cmp);
                for (let i = 0; i < addOnsPrices.length && free--; i++) {
                    categoryAddOnsPrice -= addOnsPrices[i].price;
                    addOnsObj[addOnsPrices[i].addon_index] = Number(addOnsPrices[i].price) + (addOnsObj[addOnsPrices[i].addon_index] ? Number(addOnsObj[addOnsPrices[i].addon_index]) : 0);
                }
                for (let key of Object.keys(addOnsObj)) {
                    addOns[key].total_price = (Number(addOns[key]?.quantity) * Number(addOns[key]?.price)) - Number(addOnsObj[key]);
                }
            }
        }
        item.addons_price = item.addons_price - menu_addons[addOnCategoryIndex].total_price + categoryAddOnsPrice;
        menu_addons[addOnCategoryIndex] = {
            ...menu_addons[addOnCategoryIndex],
            addons: [...addOns],
            total_price: categoryAddOnsPrice
        };
    
        setSelectedItem({
            ...item,
            menu_addons
        });

    };

    const handleMaxQuantityReached = (index)=>{
        let item = { ...selectedItem };
        let menu_addons = [...item?.menu_addons];
        let addOns = [...menu_addons[addOnCategoryIndex].addons];
        const addonIndex = addOns.findIndex((addOn, addOnIndex) => addOn.quantity > 0 && addOnIndex != index);
        handleMinAddOn(addonIndex, 'decrement');
        handleMinAddOn(index, 'increment');
    }

    const getSubTotal = () => {
        let result = {
            subtotal: 0,
            taxes: 0,
            surcharge: 0,
            total: 0,
            discount: 0,
            exclusiveTax: 0
        };
        let TotalTax = 0;/** In percentage */
        let items = [...formik?.values?.items];
        let menuItems = {};

        items.forEach((item, index) => {
            if(item?.type == 'combos'){
                return;
            }
            let addonPrices = Number(item?.addons_total_price ? item?.addons_total_price : 0)/item?.quantity;
            if (!menuItems[item.id]) {
                // menuItems[item.id] = item.finalPrice + (addonPrices/item?.quantity);
                menuItems[item.id] = {
                    price : item.price,
                    tax_amount : item.tax_amount,
                    addons_price : addonPrices
                }
            }else if(addonPrices < menuItems[item.id].addons_price ){
                menuItems[item.id] = {
                    ...menuItems[item.id],
                    addons_price : addonPrices
                }
            }
            let itemPrice = Number(item?.price * item?.quantity);
            let subtotal = itemPrice + addonPrices;
            result.subtotal += Number(subtotal);
            TotalTax += item?.tax_amount * item?.quantity
            if (item?.tax_type === "exclusive") {
                result.exclusiveTax += item?.tax_amount
            }
        });

        if (items.length) {
            result.taxes = TotalTax;
            result.surcharge = Number(filteredOrderMode?.surcharge);
            if (changes) {
                result.discount = 0;
            } else {
                //also including previous items from stateDetails
                if(state && state.recallOrderId){
                    stateDetails?.orderItems?.forEach(item =>{
                        //including here in menu items
                        if(item?.type == 'combos'){
                            return;
                        }
                        let addonPrices = Number(item?.menu_addons_price ? item?.menu_addons_price : 0)/item?.quantity;
                        if (!menuItems[item.id]) {
                            menuItems[item.id] = {
                                price : (item?.tax_type == 'exclusive' ? Number(item?.price) : Number(item?.price - item?.tax_amount))/item.quantity,
                                tax_amount : Number(item.tax_amount)/item.quantity,
                                addons_price : addonPrices
                            }
                        }else if(addonPrices < menuItems[item.id].addons_price ){
                            menuItems[item.id] = {
                                ...menuItems[item.id],
                                addons_price : addonPrices
                            }
                        }

                        //including here in items
                        items.push({ 
                            id : item.id,
                            price : (item?.tax_type == 'exclusive' ? Number(item?.price) : Number(item?.price - item?.tax_amount))/item.quantity,
                            tax_type : item.tax_type,
                            tax_amount : Number(item.tax_amount)/item.quantity,
                            quantity : item.quantity
                        })
                    })
                }

               if(discount?.coupon_id){
                    if (discount?.status == "applied" && discount?.menu_items && discount?.menu_items.length) {
                        console.log('--------menuItems obj', menuItems);
                        discount?.menu_items.forEach(menuItemId => {
                            if(menuItems[menuItemId]){
                                result.discount += menuItems[menuItemId].price + menuItems[menuItemId].tax_amount + menuItems[menuItemId].addons_price;
                            }else{
                                result.discount += 0;
                            }
                        })
                    } else if (discount?.status == "applied" && !discount?.discount_by_staff) {
                        result.discount = discount?.discount_amount || 0;
                    } else if (discount?.status == "applied" && discount?.discount_by_staff && discount.discount_type) {
                        console.log("---ask for discount percentage ----");
                        const discountPercent = formik?.values?.staffDiscountPercentage;
                        const discountAmount = (discountPercent / 100) * (result.subtotal);
                        // setDiscountAmount(discountAmount);
                        if (discountAmount < discount?.max_discount) {
                            result.discount = discountAmount || 0;
                        } else {
                            result.discount = discount?.max_discount || 0;
                        }
                    } else if (discount?.status == "applied" && discount?.discount_by_staff && !discount?.discount_type) {
                        console.log("---ask for discount flat ----");
                        if (formik?.values?.staffDiscount < discount?.max_discount) {
                            result.discount = formik?.values?.staffDiscount || 0;
                        } else {
                            result.discount = discount?.max_discount || 0;
                        }
                    } else {
                        result.discount = 0;
                    }
               }else if(discount?.promo_id){
                    //if promo is applied 
                    let menuItems = discount?.items;
                    let freeItemsObj= {};
                    menuItems?.forEach(item=>{
                        if(!freeItemsObj[item.id]){
                            freeItemsObj[item.id] = {
                                quantity : item.quantity,
                                price : item.price
                            }
                        }else{
                            freeItemsObj[item.id] = {
                                ...freeItemsObj[item.id],
                                quantity : freeItemsObj[item.id].quantity + item.quantity,
                            }
                        }
                    })
                    items.forEach((item, index)=>{
                        if(freeItemsObj[item.id] && freeItemsObj[item.id].quantity){
                            const discountedPrice = Number(item.price) - Number(freeItemsObj[item.id].price);
                            const discountedTax = ((Number(item.tax_amount) * discountedPrice) / Number(item.price));
                            if(freeItemsObj[item.id].quantity >= item.quantity){
                                result.discount += ((discountedPrice + discountedTax) * item.quantity)
                                freeItemsObj[item.id].quantity = freeItemsObj[item.id].quantity - item.quantity;
                            }else{
                                result.discount += ((discountedPrice + discountedTax) * freeItemsObj[item.id].quantity)
                                freeItemsObj[item.id].quantity = 0;
                            }                                
                        }
                    })
               }
            }

            if (state && state.service_type) {
                result.total = (result?.subtotal + result?.taxes) - (result?.discount || 0);
                if (state.aggregator) {
                    const commission = (stateDetails.aggregator.commission * result?.subtotal) / 100;
                    result.commission = commission;
                    // result.total = result.total + commission;
                }
                result.total += result?.surcharge;
            }
            if (state && state.recallOrderId) {
                result.total = Number(stateDetails.total_price) + Number(stateDetails.discount)
                    // - Number(stateDetails.aggregator_commission) 
                    + Number(result?.subtotal) + Number(result?.taxes) - (Number(result?.discount) || 0);
                if (stateDetails.aggregator) {
                    result.commission = Number((stateDetails.aggregator.commission * (Number(result?.subtotal) + Number(stateDetails?.subTotal))) / 100);
                    // result.total += result.commission;
                }
            }
            console.log("result.total---??", result);
        } else if (state && state.recallOrderId) {
            result.subtotal = Number(stateDetails?.subTotal);
            result.taxes = Number(stateDetails?.taxes);
            result.surcharge = Number(filteredOrderMode?.surcharge);
            result.commission = Number(stateDetails?.aggregator_commission);
            result.total = Number(stateDetails?.total_price);

            if (changes) {
                result.discount = 0;
            } else {
                if(discount?.coupon_id){
                    if (discount?.status == "applied" && discount?.menu_items && discount?.menu_items.length) {
                        
                        menuItems = {};
                        stateDetails?.orderItems?.forEach((item, index) => {
                            if(item?.type == 'combos'){
                                return;
                            }
                            let addonPrices = Number(item?.menu_addons_price ? item?.menu_addons_price : 0)/item?.quantity;
                            if (!menuItems[item.id]) {
                                // menuItems[item.id] = item.finalPrice + (addonPrices/item?.quantity);
                                menuItems[item.id] = {
                                    price : (item.tax_type == 'exclusive' ? item.price : item.price - item.tax_amount)/item.quantity,
                                    tax_amount : item.tax_amount/item.quantity,
                                    addons_price : addonPrices
                                }
                            }else if(addonPrices < menuItems[item.id].addons_price ){
                                menuItems[item.id] = {
                                    ...menuItems[item.id],
                                    addons_price : addonPrices
                                }
                            }
                           
                        });
                        
                        discount?.menu_items.forEach(menuItemId => {
                            if(menuItems[menuItemId]){
                                console.log('recall menu item', menuItems[menuItemId].price, menuItems[menuItemId].tax_amount, menuItems[menuItemId].addons_price)
                                result.discount += menuItems[menuItemId].price + menuItems[menuItemId].tax_amount + menuItems[menuItemId].addons_price;
                            }else{
                                result.discount += 0;
                            }
                        })
                        
                    } else if (discount?.status == "applied" && !discount?.discount_by_staff) {
                        result.discount = discount?.discount_amount || 0;
                    } else if (discount?.status == "applied" && discount?.discount_by_staff && discount.discount_type) {
                        const discountPercent = formik?.values?.staffDiscountPercentage;
                        const discountAmount = (discountPercent / 100) * (result.subtotal);
                        // setDiscountAmount(discountAmount);
                        if (discountAmount < discount?.max_discount) {
                            result.discount = discountAmount || 0;
                        } else {
                            result.discount = discount?.max_discount || 0;
                        }
                    } else if (discount?.status == "applied" && discount?.discount_by_staff && !discount?.discount_type) {
                        if (formik?.values?.staffDiscount < discount?.max_discount) {
                            result.discount = formik?.values?.staffDiscount || 0;
                        } else {
                            result.discount = discount?.max_discount || 0;
                        }
                    }else {
                        result.discount = 0;
                    }
               }else if(discount?.promo_id){
                    //if promo is applied 
                    let menuItems = discount?.items;
                    let freeItemsObj= {};
                    menuItems?.forEach(item=>{
                        if(!freeItemsObj[item.id]){
                            freeItemsObj[item.id] = {
                                quantity : item.quantity,
                                price : item.price
                            }
                        }else{
                            freeItemsObj[item.id] = {
                                ...freeItemsObj[item.id],
                                quantity : freeItemsObj[item.id].quantity + item.quantity,
                            }
                        }
                    })
                    stateDetails && stateDetails.orderItems.forEach((item, index)=>{
                        if(item?.type == 'combos'){
                            return;
                        }
                        if(freeItemsObj[item.id] && freeItemsObj[item.id].quantity){
                            const itemPrice = item.tax_type == 'exclusive' ? item.price : Number(item.price) - Number(item.tax_amount);
                            console.log('----------statedetails item', item);
                            const discountedPrice = (Number(itemPrice) - Number(freeItemsObj[item.id].price))/item?.quantity;
                            const discountedTax = ((Number(item.tax_amount) * discountedPrice) / Number(itemPrice));
                            if(freeItemsObj[item.id].quantity >= item.quantity){
                                result.discount += ((discountedPrice + discountedTax) * item.quantity)
                                freeItemsObj[item.id].quantity = freeItemsObj[item.id].quantity - item.quantity;
                            }else{
                                result.discount += ((discountedPrice + discountedTax) * freeItemsObj[item.id].quantity)
                                freeItemsObj[item.id].quantity = 0;
                            }                                
                        }
                    })
               }else{
                    result.discount = Number(stateDetails?.discount);
               }
            }
            result.total = result.total + Number(stateDetails?.discount) - result.discount;    

    
            //------------------------------create
            // coupon_code: promoonetime
            // order_amount: 212.71186440677968
            // order_mode: 2
            // outlet_id: 1
            // brand_id: 1
            // customer_number: 31 9999
            // otp: 
            // ordered_items: [{"id":"43","quantity":1,"price":27.966101694915253},{"id":"48","quantity":3,"price":44.067796610169495},{"id":"49","quantity":1,"price":52.54237288135593}]
            // coupon_approval_request_id: 

            
            // if (discount?.status == "applied" && !discount?.discount_by_staff) {
            //     result.discount = discount?.discount_amount || 0;
            //     result.total += Number(stateDetails?.discount) - (result.discount);
            // } else if (discount?.status == "applied" && discount?.discount_by_staff && discount.discount_type) {
            //     console.log("---ask for discount percentage ----");
            //     const discountPercent = formik?.values?.staffDiscountPercentage;
            //     const discountAmount = (discountPercent / 100) * (result.subtotal);
            //     // setDiscountAmount(discountAmount);
            //     if (discountAmount < discount?.max_discount) {
            //         result.discount = discountAmount || 0;
            //     } else {
            //         result.discount = discount?.max_discount || 0;
            //     }
            //     result.total += Number(stateDetails?.discount) - (result.discount);
            // } else if (discount?.status == "applied" && discount?.discount_by_staff && !discount?.discount_type) {
            //     console.log("---ask for discount flat ----");
            //     if (formik?.values?.staffDiscount < discount?.max_discount) {
            //         result.discount = formik?.values?.staffDiscount || 0;
            //     } else {
            //         result.discount = discount?.max_discount || 0;
            //     }
            //     result.total += Number(stateDetails?.discount) - (result.discount);
            // } else {
            //     result.discount = Number(stateDetails?.discount);
            // }
        }
        
        // formik.setFieldValue('items', items);
        console.log('------> result', result);
        return result;
    };

    const handleContinue = () => {
        let data = {
            items: formik?.values?.items,
            order_id: orderModeId,
            customer_note: formik.values?.customerNote,
            coupon_id: couponId,
            order_mode: filteredOrderMode,
            service_type: stateDetails?.service_type
        }
        if(couponId){
            data={
                ...data,
                coupon_code : formik.values.couponCode.trim(),
                coupon_applied_number : couponCustomerNumberOld ? 
                    couponCustomerNumberOld : 
                    ( couponCustomerNumber ? `${countryCode.join('')} ${couponCustomerNumber}` : ''),
                new_coupon_applied : newCouponApplied,
                coupon_type : couponType,
                ordered_items : orderedItems,
                coupon_approval_request_id : couponApprovalRequestId,
                otp : otp
            }
        }
        
        let pricing = getSubTotal();
        data = { ...data, pricing };

        if (state && state.recallOrderId) {
            const previousPrice = Number(stateDetails?.total_price) + Number(stateDetails?.discount)
                // - Number(stateDetails?.aggregator_commission) 
                - Number(stateDetails?.surcharge)
            if (formik.values.items.length) {
                pricing = { ...pricing, previousPrice };
                data = { ...data, pricing };
            }
            data = {
                ...data,
                recallOrderId: state.recallOrderId,
                previousItems: stateDetails.orderItems,
                previousPricing: {
                    subtotal: stateDetails.subTotal,
                    taxes: stateDetails.taxes,
                    total: previousPrice
                },
                customer_name: stateDetails.customer_name,
                customer_number: stateDetails.customer_number,
                country_code: stateDetails.country_code
            }
        }

        if (stateDetails.table) {
            data = { ...data, table: stateDetails.table }
        }
        if (stateDetails.aggregator) {
            data = { ...data, aggregator: stateDetails.aggregator }
        }

        navigate(`/company/transaction/order/continueOrder`, { state: data });
    }

    const breadcrumbs = [
        {
            title: "Orders ",
            url: "/company/transaction/order/list/1"
        },
        {
            title: "Create Order",
            url: ""
        },
    ]

    //   console.log("items-->",formik?.values?.items);

    // function handelTax(item) {
    //     if (!item?.tax_group_id) {
    //         return 0;
    //     };
    //     const filteredTaxGroup = filteredOrderMode;
    //     if (filteredTaxGroup?.tax == 'overwrite') {
    //         const price = item?.default_price;
    //         const taxPercent = filteredTaxGroup.tax_configuration?.percentage;
    //         const finalTax = (taxPercent / 100) * price;
    //         const taxType = filteredTaxGroup.tax_configuration?.tax_type
    //         return { finalTax, taxType };
    //     } else if (filteredTaxGroup?.tax == 'primary') {
    //         const price = item?.default_price;
    //         const taxPercent = item?.tax_configuration?.primary?.percentage;
    //         const finalTax = (taxPercent / 100) * price;
    //         const taxType = item?.tax_configuration?.primary?.tax_type
    //         return { finalTax, taxType };
    //     } else if (filteredTaxGroup?.tax == 'secondary') {
    //         const price = item?.default_price;
    //         const taxPercent = item?.tax_configuration?.secondary?.percentage;
    //         const finalTax = (taxPercent / 100) * price;
    //         const taxType = item?.tax_configuration?.secondary?.tax_type
    //         return { finalTax, taxType };
    //     }
    // }

    useEffect(() => {
        if (changes === true) {
            formik.setFieldValue('couponCode', '');
            formik.setFieldValue('staffDiscountPercentage', '');
            formik.setFieldValue('staffDiscount', '');
            removeCoupon();
        }
    }, [changes]);


    // const makeInvalidQuantityZero=(categoryIndex)=>{
    //     let categoryAddons = [...selectedItem?.menu_addons[categoryIndex]];
    //     categoryAddons?.addons = categoryAddons?.addons?.map(addon =>{
    //         if(addon?.quantity < addon?.min){
    //             addon.quantity = 0;
    //             addon.total_price = 0;
    //         }
    //         return addon;
    //     });
    //     selectedItem?.menu_addons[categoryIndex] = categoryAddons;
    //     setSelectedItem(selectedItem);
    // }

    const makeInvalidQuantityZero = (categoryIndex) => {
        let categoryAddons = selectedItem.menu_addons[categoryIndex];  // Removed optional chaining here
        categoryAddons.addons = categoryAddons.addons && categoryAddons.addons.map(addon => {
            if (addon?.quantity < addon?.min) {
                addon.quantity = 0;
                addon.total_price = 0;
            }
            return addon;
        });
        selectedItem.menu_addons[categoryIndex] = categoryAddons;  // Removed optional chaining here as well
        setSelectedItem(selectedItem);
    };
    // const makeInvalidQuantityZero = (categoryIndex) => {
    //     // Ensure menu_addons and the category at the given index exist and are valid
    //     if (!selectedItem?.menu_addons || !selectedItem.menu_addons[categoryIndex]) {
    //         console.error('Invalid data structure: menu_addons or the category at the given index is missing.');
    //         return;
    //     }

    //     // Access the categoryAddons from the specified categoryIndex
    //     let categoryAddons = selectedItem.menu_addons[categoryIndex];

    //     // Ensure that categoryAddons.addons is an array
    //     if (!Array.isArray(categoryAddons.addons)) {
    //         console.error('Invalid data structure: addons is not an array.');
    //         return;
    //     }

    //     // Map through the addons to update quantity and total_price if necessary
    //     categoryAddons.addons = categoryAddons.addons.map(addon => {
    //         if (addon?.quantity < addon?.min) {
    //             addon.quantity = 0;
    //             addon.total_price = 0;
    //         }
    //         return addon;
    //     });

    //     // Update the selectedItem state with the modified menu_addons
    //     selectedItem.menu_addons[categoryIndex] = categoryAddons;
    //     setSelectedItem({ ...selectedItem });  // Ensure state update triggers re-render
    // };

    function showAllergies(allergies) {
        return (
            <div style={{ 
                display: 'flex', 
                flexWrap: 'wrap', 
                gap: '10px', 
                maxWidth: '200px', 
                justifyContent: 'center', 
                alignItems: 'center',
                padding: '0' 
            }}>
                {allergies.map((allergy, idx) => (
                    // <div key={idx} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    //     {allergyImageMap[allergy] ? (
                    //         <img src={allergyImageMap[allergy]} alt={allergy} style={{ width: '20px', height: '20px', objectFit: 'contain' }} />
                    //     ) : null}
                    //     <span style={{ whiteSpace: 'nowrap', fontSize: '12px' }}>{allergy}</span>
                    // </div>
                    <div>
                         <img src={allergy.image_url} style={{ width: '20px', height: '20px', objectFit: 'contain' }} />
                         <span style={{ whiteSpace: 'nowrap', fontSize: '12px',marginLeft : '4px' }}>{capitalizeFirstLetter(allergy.name)}</span>
                    </div>
                ))}
            </div>
        );
    }
    


    return (
        <>
        {dataLoading && <GlobalLoader /> }
        <form onSubmit={formik.handleSubmit}>
            <div className="page-header d-flex align-items-center">
                <div className="page-header-left">
                    <Breadcrums data={breadcrumbs} />
                    <h4>Orders</h4>
                    {/* <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <a href="/company/transaction/order/list/1">Loyalty Points</a>
                            </li>
                            <li className="breadcrumb-item">
                                <a className="active disableclick" href="/company/transaction/order/add">Create Order</a>
                            </li>
                        </ol>
                    </nav> */}
                </div>
                <div className="page-header-right ms-auto"></div>
            </div>
            <div className='card'>
                <div className='card-body'>
                    <div className='row'>
                        <div className='col-md-5'>

                            <div className='card'>
                                <div className='card-body'>
                                    {stateDetails && stateDetails.aggregator ? <h4 style={{ color: '#2f9bff' }}>Aggregator : {capitalizeAllFirstLetters(stateDetails?.aggregator?.name)}</h4> : null}
                                    <div className='d-flex justify-content-between mb-2'>
                                        {
                                            stateDetails && stateDetails?.table ?
                                                <div>
                                                    <h4>Table name : {stateDetails?.table?.table_name}</h4>
                                                    <h4>Table number : {stateDetails?.table?.table_number}</h4>
                                                </div> :
                                                null
                                        }
                                        {
                                            state && state.recallOrderId ?
                                                <div>
                                                    <h4>Customer name : {stateDetails?.customer_name}</h4>
                                                    {/* <h4>Customer number :{stateDetails?.country_code ? ` +${countries[stateDetails?.country_code].phone[0]} ` : null} {stateDetails?.customer_number}</h4>  */}
                                                    <h4>Customer number : {stateDetails?.customer_number}</h4>

                                                </div> :
                                                null
                                        }
                                    </div>
                                    {/* displaying old order items */}
                                    {
                                        state && state.recallOrderId ?
                                            <>
                                                <h5>Previous Items</h5>
                                                <div className='order-item-wraper d-flex flex-column gap-3'>
                                                    {stateDetails?.orderItems?.map((item, index) => (
                                                        <>
                                                            <div className='order-item-list d-flex gap-3' key={index}>
                                                                <div className='order-item-info d-flex align-items-center gap-3 w-100'>
                                                                    <figure className='avatar avatar-sm m-0'>
                                                                        <img src={item?.logo} alt='Item' />
                                                                    </figure>
                                                                    <figcaption>
                                                                        <h3 className='m-0'>{capitalizeFirstLetter(item?.name)}</h3>
                                                                        <span>{item.price} SAR</span>
                                                                        <div style={{ fontSize: '0.8rem' }}> Tax: {item?.tax_amount} ({item?.tax_type}) SAR</div>
                                                                    </figcaption>
                                                                </div>
                                                                <span className="order-item-quantity" style={{ fontWeight: '500', width: '100px' }}>Qty : {item.quantity}</span>
                                                            </div>
                                                            <div className='d-flex'>
                                                                <div>
                                                                    {
                                                                        item?.menuAddOns?.length > 0 ?
                                                                        item?.menuAddOns?.slice(0, 2)?.map((addon, index) => {
                                                                            return <span>{`${capitalizeAllFirstLetters(addon.menu_add_on.name)} (${addon.quantity} pcs) 
                                                                            ${index == item?.menuAddOns?.length - 1 ? '' : (index == 1 ? '...' : ',')}`} </span>
                                                                        }) : null
                                                                    }
                                                                </div>
                                                                <div className='text-end ms-auto'>
                                                                    {
                                                                        item?.menuAddOns?.length > 0 ?
                                                                            `${showPrice(Number(item.menu_addons_price))} SAR`
                                                                            : null
                                                                    }
                                                                </div>
                                                            </div>
                                                        </>
                                                    ))}
                                                </div>

                                                {
                                                    formik.values.items.length ?
                                                        <div className='subtotal-list mt-3 d-flex flex-column gap-2'>
                                                            <div className='d-flex align-items-center justify-content-between'>
                                                                <h4>Sub-Total</h4>
                                                                <h4>{stateDetails?.subTotal} SAR</h4>
                                                            </div>
                                                            <div className='d-flex align-items-center justify-content-between'>
                                                                <h4>Taxes</h4>
                                                                <h4>{stateDetails?.taxes} SAR</h4>
                                                            </div>
                                                            {/* <div className='d-flex align-items-center justify-content-between'>
                                                        <h4>Discount</h4>
                                                        <h4>{stateDetails?.discount} SAR</h4>
                                                    </div> */}
                                                            {/* <div className='d-flex align-items-center justify-content-between'>
                                                        <h4>Surcharge</h4>
                                                        <h4>{stateDetails?.surcharge} SAR</h4>
                                                    </div> */}
                                                            {/* {
                                                        stateDetails && stateDetails.aggregator ?
                                                        <div className='d-flex align-items-center justify-content-between'>
                                                            <h4>Agreegator commission</h4>
                                                            <h4>{ stateDetails?.aggregator_commission } SAR</h4>
                                                        </div> : 
                                                        null
                                                    } */}
                                                           
                                                            <hr className='my-2'></hr>
                                                            <div className='d-flex align-items-center justify-content-between'>
                                                                <h3>Total</h3>
                                                                <h3>{showPrice(Number(stateDetails?.total_price) + Number(stateDetails?.discount) /* - Number(stateDetails?.aggregator_commission) */ - Number(stateDetails?.surcharge))} SAR</h3>
                                                            </div>
                                                        </div> : null
                                                }
                                            </>
                                            : null
                                    }
                                    {/* displaying current order items */}
                                    <h3 className='mt-4 mb-2'>Order Summary</h3>
                                    <div className='order-item-wraper d-flex flex-column gap-3'>
                                        {formik?.values?.items?.map((item, index) => (
                                            <>
                                                <div className='order-item-list d-flex gap-3' key={index}>
                                                    {console.log("index-item--", item)}
                                                    <div className='order-item-info d-flex align-items-center gap-3 w-100'>
                                                        <figure className='avatar avatar-sm m-0'>
                                                            <img src={item?.logo} alt='Item' />
                                                        </figure>
                                                        <figcaption>
                                                            <h3 className='m-0'>{capitalizeFirstLetter(item?.item_name)} { item?.free_quantity ? `(Free ${item?.free_quantity} QTY.)` : null }</h3>
                                                            <span>{item?.tax_type === "exclusive" ? (item.price * item?.quantity) : (item?.finalPrice * item?.quantity)} SAR</span>
                                                            {item?.tax_type && <div style={{ fontSize: '0.8rem' }}> Tax: {item?.tax_percentage ? item?.tax_percentage + "%" : showPrice(item?.tax_amount) + " SAR "}<span>({item?.tax_type})</span></div>}
                                                        </figcaption>
                                                    </div>
                                                    {/* <div style={{ width: "3rem" }}>
                                                        {item?.type === 'menu_item' && item?.menu_addons.length > 0 ?
                                                            <button type='button' className="btn btn-icon" onClick={() => handleEditItem(index)} >
                                                                <i className="ti ti-pencil"></i>
                                                            </button> : null
                                                        }
                                                    </div> */}
                                                    <div className='order-item-list-control d-flex gap-2'>
                                                        <div className='plus-minus-input d-flex'>
                                                            <button
                                                                type='button'
                                                                className='btn btn-icon me-1'
                                                                onClick={() => item?.quantity <= 1 ? handleRemoveItem(index) : handleQuantityChange(index, Math.max(1, item?.quantity - 1))}
                                                            >
                                                                <i className='ti ti-minus'></i>
                                                            </button>
                                                            <input
                                                                type='number'
                                                                className='form-control border-0'
                                                                value={item?.quantity}
                                                                onChange={(e) => handleQuantityChange(index, Math.max(1, Number(e.target.value)))}
                                                            />
                                                            <button
                                                                type='button'
                                                                className='btn btn-icon'
                                                                onClick={() => handleQuantityChange(index, item?.quantity + 1)}
                                                            >
                                                                <i className='ti ti-plus'></i>
                                                            </button>
                                                        </div>
                                                        <button
                                                            type='button'
                                                            className='btn btn-icon bg-danger text-white'
                                                            onClick={() => handleRemoveItem(index)}
                                                        >
                                                            <i className='ti ti-x'></i>
                                                        </button>
                                                    </div>
                                                </div>
                                                {/* addons */}
                                                <div className='d-flex mb-3'>
                                                    <div>
                                                        {item?.type === 'menu_item' && item?.menu_addons.length > 0 ?
                                                            <button className='btn-link' style={{ color: 'blue' }} type='button' onClick={() => handleEditItem(index)} >
                                                                Edit
                                                            </button> : null
                                                        }
                                                    </div>
                                                    {
                                                        item?.type == 'menu_item' && item?.selected_menu_addons.length > 0 ?
                                                            <div className='mx-2'>
                                                                <i className='ti ti-point-filled'></i>
                                                            </div> : null
                                                    }
                                                    <div className=''>
                                                        {
                                                            item?.type == 'menu_item' && item?.selected_menu_addons.length > 0 ?
                                                                item?.selected_menu_addons?.slice(0, 2)?.map((addon, index) => {
                                                                    return <span>{`${capitalizeAllFirstLetters(addon.name)} (${addon.quantity} pcs) 
                                                                    ${index == item?.selected_menu_addons?.length - 1 ? '' : (index == 1 ? '...' : ',')}`} </span>
                                                                }) : null
                                                        }
                                                    </div>
                                                    <div className='text-end ms-auto'>
                                                        {
                                                            item?.type == 'menu_item' && item?.selected_menu_addons.length > 0 ?
                                                                `${showPrice(Number(item.addons_total_price))} SAR`
                                                                : null
                                                        }
                                                    </div>
                                                </div>
                                            </>
                                        ))}
                                    </div>
                                    <div className='form-group mb-3'>
                                        <label>Order Mode</label><span className="requirestar">*</span>
                                        <select
                                            className='form-control'
                                            name='orderType'
                                            value={orderModeId}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            disabled
                                        >
                                            <option value=''>Select Order type</option>
                                            {orderModeData.map((mode, index) => (
                                                <option key={index} value={mode.id}>
                                                    {mode.name}
                                                </option>
                                            ))}
                                        </select>
                                        {formik.touched.orderType && formik.errors.orderType ? (
                                            <div className="text-danger">{formik.errors.orderType}</div>
                                        ) : null}
                                    </div>
                                    <div className='form-group mb-3'>
                                        <label>Customer note</label>
                                        <textarea
                                            className='form-control'
                                            name='customerNote'
                                            placeholder='Enter customer note'
                                            value={formik.values.customerNote}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        ></textarea>
                                        {formik.touched.customerNote && formik.errors.customerNote ? (
                                            <div className="text-danger">{formik.errors.customerNote}</div>
                                        ) : null}
                                    </div>
                                    <div className='coupon-code-box'>
                                        <div className='form-group mb-2'>
                                            <label className="me-2">Coupon Code</label>
                                            <input
                                                type="text"
                                                name="couponCode"
                                                placeholder="Enter Coupon Code"
                                                className="form-control me-2"
                                                value={formik.values.couponCode}
                                                onChange={(event) => { formik.handleChange(event); }}
                                                disabled={ ( !state && !formik?.values?.items.length ) || couponCalled }
                                            />
                                            {
                                                // !couponCalled ?
                                                // <button
                                                //     type="button"
                                                //     className='btn'
                                                //     onClick={() => { otp ? handleCouponValidation() : setShowCouponModel(true) }}
                                                //     disabled={getSubTotal().subtotal === 0 || !formik.values.couponCode}
                                                // >
                                                //     {/* <i className='ti ti-circle-check'></i> */}
                                                //     <i className={`ti ti-circle-check ${formik.values.couponCode && getSubTotal().subtotal !== 0 ? 'text-success' : ''}`}></i>
                                                // </button> : null
                                                
                                                formik?.values?.couponCode ?
                                                <button
                                                    type="button"
                                                    className='btn me-5'
                                                    onClick={() => { otp ? handleCouponValidation() : setShowCouponModel(true) }}
                                                    disabled={getSubTotal().subtotal === 0 || !formik.values.couponCode}
                                                >
                                                    {/* <i className='ti ti-circle-check'></i> */}
                                                    <i className={`ti ti-circle-check ${ discount?.status == 'applied' ? 'text-success' : ''}`}></i>
                                                </button> : null
                                                
                                            }
                                            {
                                                formik?.values?.couponCode ?
                                                    <button
                                                    type="button"
                                                    className='btn'
                                                    // onClick={() => { removeCoupon() }}
                                                    onClick={() => { setChanges(true); formik.setFieldValue('couponCode', ''); }}
                                                    >
                                                        <i className="ti ti-x"></i>
                                                    </button> : null
                                            }
                                        </div>
                                        {!changes && discount?.status == "applied" && discount?.discount_by_staff && !discount.discount_type
                                            && <div>
                                                <div className='form-group'>
                                                    <label className="me-2">Discount amount by staff
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="staffDiscount"
                                                        placeholder="Enter Discount"
                                                        className="form-control me-2"
                                                        value={formik.values.staffDiscount}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                    />
                                                </div>
                                                {formik.errors.staffDiscount ? (
                                                    <div className="text-danger">{formik.errors.staffDiscount}</div>
                                                ) : null}
                                            </div>}
                                        {!changes && discount?.status == "applied" && discount?.discount_by_staff && discount.discount_type
                                            && <div>
                                                <div className='form-group'>
                                                    <label className="me-2">Discount percentage by staff
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="staffDiscountPercentage"
                                                        placeholder="Enter Discount percentage"
                                                        className="form-control me-2"
                                                        value={formik.values.staffDiscountPercentage}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                    />
                                                </div>
                                                {formik.errors.staffDiscountPercentage ? (
                                                    <div className="text-danger">{formik.errors.staffDiscountPercentage}</div>
                                                ) : null}
                                            </div>}

                                        {!changes && discount?.status == "validate_otp"
                                            && <div>
                                                <div className='form-group'>
                                                    <label htmlFor='otp' className='text-left d-flex'>
                                                        Enter OTP<span className="requirestar">*</span>
                                                    </label>
                                                    <div className='mt-2'>
                                                        <OtpInput
                                                            inputStyle={{
                                                                width: "9rem",
                                                                height: "1.5em",
                                                                padding: "1.7rem",
                                                                fontSize: "1.5rem",
                                                                borderRadius: 4,
                                                                marginRight: '1.5rem',
                                                                border: "1px solid grey"
                                                            }}
                                                            renderInput={(props) => <input {...props} />}
                                                            inputType='number'
                                                            numInputs={4}
                                                            separator={<span>-</span>}
                                                            value={otp}
                                                            onChange={(value) => setOtp(value)}
                                                        />
                                                    </div>
                                                    <span className='text-danger d-flex text-left'>
                                                        {formik.errors.user_login_pin && formik.touched.user_login_pin && formik.errors.user_login_pin}
                                                    </span>
                                                </div>
                                            </div>
                                        }

                                    </div>
                                    <div className='subtotal-list mt-3 d-flex flex-column gap-2'>
                                        {
                                            state && state.recallOrderId && formik.values.items.length > 0 ?
                                                <div className='d-flex align-items-center justify-content-between'>
                                                    <h4>Previous price</h4>
                                                    <h4>{Number(stateDetails?.total_price) + Number(stateDetails?.discount) /* - Number(stateDetails?.aggregator_commission) */ - Number(stateDetails?.surcharge)} SAR</h4>
                                                </div> : null
                                        }
                                        <div className='d-flex align-items-center justify-content-between'>
                                            <h4>Sub-Total</h4>
                                            <h4>{showPrice(getSubTotal()?.subtotal)} SAR</h4>
                                        </div>
                                        <div className='d-flex align-items-center justify-content-between'>
                                            <h4>Taxes</h4>
                                            <h4>{showPrice(getSubTotal()?.taxes)} SAR</h4>
                                        </div>
                                        <div className='d-flex align-items-center justify-content-between'>
                                            <h4>Discount</h4>
                                            <h4>{showPrice(getSubTotal()?.discount)} SAR</h4>
                                        </div>
                                        <div className='d-flex align-items-center justify-content-between'>
                                            <h4>Surcharge</h4>
                                            <h4>{showPrice(getSubTotal()?.surcharge)} SAR</h4>
                                        </div>
                                        {/* {
                                            stateDetails && stateDetails.aggregator ?
                                            <div className='d-flex align-items-center justify-content-between'>
                                                <h4>Agreegator commission</h4>
                                                <h4>{ showPrice(getSubTotal()?.commission) } SAR</h4>
                                            </div> : 
                                            null
                                        } */}
                                        <hr className='my-2'></hr>
                                        <div className='d-flex align-items-center justify-content-between'>
                                            <h3>Total</h3>
                                            <h3>{showPrice(getSubTotal()?.total)} SAR</h3>
                                        </div>
                                    </div>
                                    {console.log('***********', formik.values.items)}
                                    <div className='form-group mt-4'>
                                        <button type='button' className='btn btn-primary' disabled={!(formik?.values?.items.length || (state && state.recallOrderId))} onClick={handleContinue}>
                                            Continue
                                        </button>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-7'>
                            <div className='card'>
                                <div className='card-body'>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <ul className="nav nav-pills" id="pills-tab" role="tablist">
                                            <li className="nav-item" role="presentation">
                                                <button className={`nav-link ${!viewCombos ? 'active' : ''}`} id="pills-Daily-tab" data-bs-toggle="pill" data-bs-target="#pills-Daily" type="button" role="tab" aria-controls="pills-Daily" aria-selected={!viewCombos} onClick={() => handleTabChange(false)} >
                                                    Food Items
                                                </button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button className={`nav-link ${viewCombos ? 'active' : ''}`} id="pills-Monthly-tab" data-bs-toggle="pill" data-bs-target="#pills-Monthly" type="button" role="tab" aria-controls="pills-Monthly" aria-selected={viewCombos} tabindex="-1" onClick={() => handleTabChange(true)}>
                                                    Combo
                                                </button>
                                            </li>
                                        </ul>
                                        <div className=''>
                                            <input type='text' placeholder='Search' className='form-control' value={searchQuery}
                                                onChange={handleSearch} />
                                        </div>
                                    </div>
                                    {/* {!viewCombos && menuItemsOrders?.categories?.length <= 0 &&
                                        <div className="alert alert-warning mt-2">
                                            There is no Primary Category created yet. Please{' '}
                                            <Link to="/company/menu/menu/categories/list/1">
                                                create a Primary Category
                                            </Link>{' '}
                                            first
                                        </div>
                                    } */}
                                    {!viewCombos && menuItemsOrders?.categories?.length > 0 && !activeCategory &&
                                        <div className="alert alert-warning mt-2">
                                            Please Select Category
                                        </div>
                                    }
                                    <div className='tags-wrap'>
                                        {console.log("subCategoryData------------->", subCategoryData, !viewCombos)}
                                        {/* {(subCategoryData?.length > 0 || !viewCombos) && menuItemsOrders?.categories?.map((category, index) => ( */}
                                        {!viewCombos && menuItemsOrders?.categories && menuItemsOrders?.categories?.map((category, index) => (
                                            <button type='button' className={activeCategory === category.id ? 'active' : ''} key={index} onClick={() => handleCategoryClick(category.id)}
                                            >
                                                {capitalizeFirstLetter(category?.name)}
                                            </button>
                                        ))}
                                    </div>
                                    {!viewCombos ? (
                                        <>
                                            {/* {activeCategory && subCategoryData?.length <= 0 &&
                                                <div className="alert alert-warning">
                                                    There is no Secondary Category of Selected Category. Please{' '}
                                                    <Link to="/company/menu/menu/categories/list/1">
                                                        create a Secondary Category
                                                    </Link>{' '}
                                                    first
                                                </div>
                                            } */}
                                            <ul className="d-flex setting submenu-list border-bottom mb-0 p-0">
                                                {subCategoryData?.map((subCategory, index) => (
                                                    <li
                                                        className={activeTab === subCategory.id ? 'active' : ''}
                                                        key={index}
                                                        onClick={() => filterMenuItemsByCategory(subCategory.id)}
                                                    >
                                                        {capitalizeFirstLetter(subCategory?.name)}
                                                    </li>
                                                ))}
                                            </ul>
                                            {/* {activeCategory && subCategoryData?.length > 0 && filteredItems?.length <= 0 && */}
                                            {filteredItems?.length <= 0 && searchQuery === "" &&
                                                <div className="alert alert-warning mt-2">
                                                    There is no menu item created yet. Please{' '}
                                                    <Link to="/company/menu/menu/menu_items/add">
                                                        create a Menu Item
                                                    </Link>{' '}
                                                    first
                                                </div>
                                            }
                                            {filteredItems.length > 0 ? (
                                                <div className='menu-item-list mt-3'>
                                                    {filteredItems.map((item, index) => {
                                                        return (
                                                            <div key={index}>
                                                                <div
                                                                    className={`menu-item-box cp ${isSelected(item, 'menu_item') ? ' item-selected' : ''}`}
                                                                    onClick={() => handleItemSelection(item, "menu_item")}
                                                                >
                                                                    <figure>
                                                                        <img src={item.logo} alt={item.item_name} />
                                                                    </figure>
                                                                    <figcaption className="text-center">
                                                                        <h4 className="m-0">{capitalizeFirstLetter(item.item_name)}</h4>
                                                                        <span>{item?.tax_type === "exclusive" ? item.price : item?.finalPrice} SAR</span>
                                                                        <div>Calories: {item?.calories ? item?.calories : " -"}</div>
                                                                        <div>Allergies:</div>
                                                                        <div>{item?.menu_item_allergies?.length ? showAllergies(item?.menu_item_allergies) : " -"}</div>
                                                                    </figcaption>
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            ) : (
                                                <div className='text-center mt-3'>
                                                    <img src={noRecordFoundImg} alt='No record found' />
                                                </div>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            {filteredCombos.length > 0 ? (
                                                <div className='menu-item-list mt-3'>
                                                    {filteredCombos.map((combo, index) => {
                                                        // const isSelected = formik.values.items.map(item => item.name).includes(combo.name);
                                                        return (
                                                            <div
                                                                key={index}

                                                                className={`menu-item-box cp ${isComboSelected(combo, 'combos') ? ' item-selected' : ''}`}
                                                                // className={`menu-item-box ${isSelected ? 'item-selected' : ''}`}
                                                                onClick={() => handleComboSelection(combo)}
                                                            >
                                                                <figure>
                                                                    <img src={combo?.logo} alt={combo?.name} />
                                                                </figure>
                                                                <figcaption className="text-center">
                                                                    <h4 className='m-0'>{capitalizeFirstLetter(combo?.name)}</h4>
                                                                    <span>{combo?.price} SAR</span>
                                                                    <div>Calories: {combo?.combo_total_calories ? combo?.combo_total_calories : " -"}</div>
                                                                    <div>Allergies:</div>
                                                                        <div>{combo?.combo_allergies?.length ? showAllergies(combo?.combo_allergies) : " -"}</div>
                                                                        {console.log("menu_item_allergies--->", combo?.combo_allergies)}
                                                                </figcaption>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            ) : (
                                                <div className='text-center mt-3'>
                                                    <img src={noRecordFoundImg} alt='No record found' />
                                                </div>
                                            )}

                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* {showModal && (
                <ItemDetailsModal
                    show={showModal}
                    onHide={() => setShowModal(false)}
                    item={selectedItem}
                    onNext={handleNext}
                />
            )} */}

            <Modal show={isModalOpen} onHide={() => { setIsModalOpen(false); setSelectedItem(null); }} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title style={{ fontSize: '22px' }}>Meal Combo</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedItem ? (
                        <div className="container mt-4">
                            <div className="card mb-4">
                                <div className="card-body">
                                    <div className="row mb-3">
                                        <div className="col-md-8">
                                            {console.log("selectedItem--->", selectedItem)}

                                            <h4 className="card-title"><strong>Name:</strong> {capitalizeFirstLetter(selectedItem?.item_name)}</h4>
                                            <p><strong>Description:</strong> {capitalizeFirstLetter(selectedItem?.description)}</p>
                                            <p className="font-weight-bolder"><strong>Price:</strong> {selectedItem?.price} SAR</p>
                                        </div>
                                        <div className="col-md-4">
                                            <div className={`menu-item-box`} style={{ border: "none", display: 'inline-block', maxHeight: '170px', width: '100%' }}>
                                                <figure className='m-0 p-0' style={{ width: '100%', height: 'auto', maxHeight: '170px' }}>
                                                    <img src={selectedItem.logo}
                                                        alt={selectedItem.item_name}
                                                        style={{
                                                            width: '100%',
                                                            height: 'auto',
                                                            maxHeight: '170px',
                                                            objectFit: 'fill',
                                                        }} />
                                                </figure>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>

                            <div className="card">
                                <div className="card-body">
                                    <h5 className="mt-0 mb-4 font-weight-bold" style={{ fontSize: '20px' }}><i className="bi bi-box"></i> Combo Items </h5>
                                    <ul className="list-unstyled">
                                        {selectedItem?.combo_items?.map((item, idx) => (
                                            item?.combo_menu_items?.length ?
                                                <li key={idx} className="mb-3 p-3 border rounded">

                                                    <div className="row mb-2" style={{ fontSize: "1.3rem" }}>
                                                        <div className="col-md-6">
                                                            <p className="font-weight-bold mb-1" ><strong>Item Type:</strong> {capitalizeFirstLetter(item?.item_type)}</p>
                                                        </div>
                                                        <div className="col-md-5">
                                                            <p className="font-weight-bold mb-1"><strong>Quantity:</strong> {item?.quantity}</p>
                                                        </div>
                                                        <div className="col-md-4">
                                                        </div>
                                                    </div>
                                                    <ul className="list-unstyled mt-2">
                                                        {item?.combo_menu_items?.map((menuItem, idy) => (
                                                            <li key={idy} className="mb-2">
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <p className="mb-1"><strong>Item Name:</strong> {capitalizeFirstLetter(menuItem?.menu_item?.item_name)}</p>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <p className="mb-1"><strong>Price:</strong> {menuItem?.price} SAR</p>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </li> : null
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="container mt-4">
                            <p className="text-center text-muted">No details available.</p>
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    {/* <button className="btn btn-outline-primary" onClick={() => setIsModalOpen(false)}>Cancel</button> */}
                    <Button variant="outline-primary" onClick={() => { setIsModalOpen(false); setSelectedItem(null); }}>Cancel</Button>
                    <Button variant="primary" onClick={() => { setIsModalOpen(false); addToCart(); }}>Next</Button>
                </Modal.Footer>
            </Modal>

            {/* todo from here -- changes in addons prices acc. to category  */}
            <Modal show={showModal == true} onHide={() => { setAddOnCatgoryIndex(0); setAddOnNextClicked(false); setAddOnSkipClicked(false); setShowModal(false); setSelectedItem(null); setEditIndex(-1); }} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title style={{ fontSize: '22px' }}>Select Modifier</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ maxHeight: '25rem', overflowY: 'auto' }}>
                    <div className="container p-0">
                        <table className="table table-custom p-0 m-0">
                            <thead className="thead-light">
                                <tr className="text-center">
                                    <th>Item</th>
                                    <th>Quantity</th>
                                    <th>Price</th>
                                    <th>Total Price</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td colSpan={4} style={{ backgroundColor: '#f1f1f1' }}>
                                        <div className="addon-category-group-name">
                                            <span>
                                                <b>{capitalizeAllFirstLetters(selectedItem?.menu_addons && selectedItem?.menu_addons[addOnCategoryIndex]?.category_name)}</b>
                                            </span>
                                            <span>
                                                <b>Min: {selectedItem?.menu_addons && selectedItem?.menu_addons[addOnCategoryIndex]?.min} &nbsp;
                                                    Max: {selectedItem?.menu_addons && selectedItem?.menu_addons[addOnCategoryIndex]?.max} &nbsp;
                                                    Free: {selectedItem?.menu_addons && selectedItem?.menu_addons[addOnCategoryIndex]?.free}
                                                </b>
                                            </span>
                                        </div>
                                        <div>
                                        </div>
                                    </td>
                                </tr>
                                {
                                    selectedItem?.menu_addons &&
                                    selectedItem?.menu_addons[addOnCategoryIndex]?.addons?.map((addOn, index) => {
                                        return <>
                                            <tr key={index} className="text-center align-middle">
                                                <td>{capitalizeFirstLetter(addOn?.name)}</td>
                                                <td>
                                                    <div className="d-flex align-items-center justify-content-center">
                                                        <button
                                                            className="btn btn-outline-secondary mx-1 d-flex justify-content-center align-items-center"
                                                            style={{ width: '30px', height: '30px', padding: '0' }}
                                                            onClick={() => handleMinAddOn(index, 'decrement')}
                                                            disabled={addOn?.quantity === 0}
                                                        >
                                                            <i className='ti ti-minus' style={{ margin: 0, padding: 0 }}></i>
                                                        </button>
                                                        <span className="mx-2">{addOn?.quantity}</span>
                                                        <button
                                                            className="btn btn-outline-secondary mx-1 d-flex justify-content-center align-items-center"
                                                            style={{ width: '30px', height: '30px', padding: '0' }}
                                                            onClick={() => {
                                                                selectedItem?.menu_addons[addOnCategoryIndex]?.total_quantity === selectedItem?.menu_addons[addOnCategoryIndex]?.max ?
                                                                handleMaxQuantityReached(index) : 
                                                                handleMinAddOn(index, 'increment')
                                                            }}
                                                            disabled={addOn?.quantity === selectedItem?.menu_addons[addOnCategoryIndex]?.max}
                                                            // disabled={selectedItem?.menu_addons[addOnCategoryIndex]?.total_quantity === selectedItem?.menu_addons[addOnCategoryIndex]?.max}
                                                        >
                                                            <i className='ti ti-plus'></i>
                                                        </button>
                                                    </div>
                                                </td>
                                                <td><span className="mx-2">{showPrice(addOn?.price)}</span> SAR</td>
                                                <td>{showPrice(addOn?.total_price)} SAR</td>
                                            </tr>
                                            {/* {
                                            addOn?.quantity && addOn?.quantity < addOn?.min && addOn?.addOnValid==false && (addOnNextClicked==true || addOnSkipClicked == true) ?
                                            <tr>
                                                <td colSpan={4} style={{color : 'red'}} >{ `Please select minimum quantity ${addOn.min}` }</td>
                                            </tr> : null   
                                        } */}
                                        </>
                                    })
                                }
                            </tbody>
                        </table>

                        {/* Display final price below the table */}
                        {
                            selectedItem?.menu_addons && selectedItem?.menu_addons[addOnCategoryIndex]?.addOnValid == false && (addOnNextClicked == true || addOnSkipClicked == true) ?
                                <span colSpan={4} style={{ color: 'red' }} >{`Please select minimum quantity ${selectedItem?.menu_addons[addOnCategoryIndex]?.min}`}</span>
                                : null
                        }
                        <div className="text-end mt-3">
                            <h5 style={{ fontSize: '15px' }}>Final Price: <span className="font-weight-bold">{showPrice(selectedItem?.addons_price)} SAR</span></h5>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {/* <Button variant="outline-primary" onClick={() => {setAddOnCatgoryIndex(0); setAddOnInvalidCount(0); setAddOnNextClicked(false); setAddOnSkipClicked(false); setShowModal(false); setSelectedItem(null); setEditIndex(-1); }}>Cancel</Button> */}

                    <Button variant="outline-primary" onClick={() => {
                        setAddOnSkipClicked(true);
                        if (selectedItem?.menu_addons && selectedItem?.menu_addons[addOnCategoryIndex]?.addOnValid == true) {
                            setAddOnSkipClicked(false);
                            setAddOnCatgoryIndex(0);
                            setShowModal(false);
                            addToCart();
                        }
                    }}
                    >
                        Skip
                    </Button>

                    {
                        addOnCategoryIndex != 0 ?
                            <Button variant="outline-primary" onClick={() => {
                                // makeInvalidQuantityZero(addOnCategoryIndex);
                                setAddOnNextClicked(true);
                                setAddOnCatgoryIndex(addOnCategoryIndex - 1);
                            }}
                            >
                                Back
                            </Button> : null
                    }

                    <Button variant="primary" onClick={() => {
                        setAddOnNextClicked(true);
                        if (selectedItem?.menu_addons && selectedItem?.menu_addons[addOnCategoryIndex]?.addOnValid == true) {
                            setAddOnNextClicked(false);
                            if (addOnCategoryIndex == selectedItem.menu_addons.length - 1) {
                                setAddOnCatgoryIndex(0);
                                setShowModal(false);
                                addToCart();
                            } else {
                                setAddOnCatgoryIndex(addOnCategoryIndex + 1);
                            }
                        }
                    }}>
                        Next
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* <Modal show={showCouponModel} onHide={() => { setShowCouponModel(false); setCouponCustomerNumber(""); }} size="md">
                <Modal.Header closeButton>
                    <Modal.Title style={{ fontSize: '22px' }}>Customer number</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ maxHeight: '25rem', overflowY: 'auto' }}>
                <div className='form-group'>
                    <label className="me-2">Enter customer number
                    </label>
                     <div className="input-group">
                        <span >
                            <SelectPicker
                                style={{ border: 'none', paddingLeft: '0', paddingRight: '0', zIndex: 1 }}
                                data={selectedCountryCode}
                                // name='country_code'
                                className='form-control'
                                // id='country_code'
                                onChange={(value) => setCountryCode(value)}
                                onBlur={formik.handleBlur}
                                value={countryCode}
                                placeholder="Code"
                            />
                            {console.log(countryCode)}
                        </span>
                        <input
                            type="text"
                            placeholder="Customer number"
                            // className="form-control me-2"
                            value={couponCustomerNumber}
                            onChange={(e)=> setCouponCustomerNumber(e.target.value)}
                            onBlur={formik.handleBlur}
                            // name='customerPhone'
                            // id="customerPhone"
                            className="form-control bordered"
                            style={{ border: '1px solid #e8e8e8' }}
                            // onKeyDown={blockInvalidChar}
                        />
                     </div>
                </div>                
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={() => { setShowCouponModel(false); setCouponCustomerNumber("");}}>Cancel</Button>
                    <Button variant="primary" onClick={() => { setShowCouponModel(false); handleCouponValidation(); }}>Next</Button>
                </Modal.Footer>
            </Modal> */}

            <RsModal open={showCouponModel} onClose={() => { setShowCouponModel(false); setCouponCustomerNumber(""); }} size="sm" backdrop="static">
                <RsModal.Header>
                    <RsModal.Title style={{ fontSize: '22px' }}>Customer number</RsModal.Title>
                </RsModal.Header>
                <RsModal.Body style={{ maxHeight: '25rem', overflowY: 'auto' }}>
                    <div className='form-group'>
                        <label className="me-2">Enter customer number</label>
                        <div className="input-group">
                            <span>
                                <SelectPicker
                                    style={{ border: 'none', paddingLeft: '0', paddingRight: '0', zIndex: 1 }}
                                    data={selectedCountryCode}
                                    className='form-control'
                                    onChange={(value) => setCountryCode(value)}
                                    onBlur={formik.handleBlur}
                                    value={countryCode}
                                    placeholder="Code"
                                />
                                {console.log(countryCode)}
                            </span>
                            <input
                                type="text"
                                placeholder="Customer number"
                                value={couponCustomerNumber}
                                onChange={(e) => setCouponCustomerNumber(e.target.value)}
                                onBlur={formik.handleBlur}
                                className="form-control bordered"
                                style={{ border: '1px solid #e8e8e8' }}
                                onKeyDown={blockInvalidChar}
                            />
                        </div>
                    </div>
                </RsModal.Body>
                <RsModal.Footer>
                    <RsButton className='btn btn-outline-primary' onClick={() => { setShowCouponModel(false); setCouponCustomerNumber(""); }}>Cancel</RsButton>
                    <RsButton className='btn btn-primary' onClick={() => { setShowCouponModel(false); handleCouponValidation(); }}>Next</RsButton>
                </RsModal.Footer>
            </RsModal>

        </form>
        </>
    );
};
