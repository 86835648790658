import React, { useEffect, useMemo, useState } from "react";
import ConfigurationTab from "../configurationTab";
import { ToastContainer } from "react-toastify";
import { FONT_SIZE, FONT_STYLE } from "../../../utils/Constants";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import { CreateKitchenInvoice, GetKitchenInvoice } from "../../../services/company_panel/setting/invoiceServices";
import * as Yup from "yup";
import { checkPermission, getBrandId } from "../../../helpers/commonFunctions";


const KitchenInvoiceSetting = () => {

    const [previewImage, setPreviewImage] = useState("");
    const [showDefault, setShowDefault] = useState({});
    const [isBrandChanged, setIsBrandChanged] = useState(false);

    useMemo(() => {
        document.addEventListener('brand-changed', function () {
            setIsBrandChanged(!isBrandChanged);
        });
    });

    useEffect(() => {
        GetKitchenInvoice({ brand_id: getBrandId() }).then(data => {
            setShowDefault(data && data?.data && data?.data?.response ? data?.data?.response : [])
            setPreviewImage(data?.data?.response?.logo)
        }).catch(error => {
            console.log("error=====>", error)
        })
    }, [isBrandChanged]);


    const validateSchema = Yup.object().shape({
        heading_text: Yup.string().trim().max(50, "Heading cannot exceed 50 characters"),
        footer_text: Yup.string().trim().max(150, "Footer cannot exceed 150 characters"),
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            logo: showDefault && showDefault?.logo ? showDefault?.logo : '',
            // line_separator: showDefault && showDefault?.line_separator ? showDefault?.line_separator : '',
            heading_text: showDefault && showDefault?.heading_text ? showDefault?.heading_text : '',
            heading_font_size: showDefault && showDefault?.heading_font_size ? showDefault?.heading_font_size : '',
            heading_font_style: showDefault && showDefault?.heading_font_style ? showDefault?.heading_font_style : '',

            deliver_in_font_size: showDefault && showDefault?.deliver_in_font_size ? showDefault?.deliver_in_font_size : '',
            deliver_in_font_style: showDefault && showDefault?.deliver_in_font_style ? showDefault?.deliver_in_font_style : '',
            delivery_font_size: showDefault && showDefault?.delivery_font_size ? showDefault?.delivery_font_size : '',
            delivery_font_style: showDefault && showDefault?.delivery_font_style ? showDefault?.delivery_font_style : '',
            delivery_date_time_font_size: showDefault && showDefault?.delivery_date_time_font_size ? showDefault?.delivery_date_time_font_size : '',
            delivery_date_time_font_style: showDefault && showDefault?.delivery_date_time_font_style ? showDefault?.delivery_date_time_font_style : '',

            number_font_size: showDefault && showDefault?.number_font_size ? showDefault?.number_font_size : '',
            number_font_style: showDefault && showDefault?.number_font_style ? showDefault?.number_font_style : '',
            accepted_at_font_size: showDefault && showDefault?.accepted_at_font_size ? showDefault?.accepted_at_font_size : '',
            accepted_at_font_style: showDefault && showDefault?.accepted_at_font_style ? showDefault?.accepted_at_font_size : '',
            first_name_font_size: showDefault && showDefault?.first_name_font_size ? showDefault?.first_name_font_size : '',
            first_name_font_style: showDefault && showDefault?.first_name_font_style ? showDefault?.first_name_font_style : '',
            last_name_font_size: showDefault && showDefault?.last_name_font_size ? showDefault?.last_name_font_size : '',
            last_name_font_style: showDefault && showDefault?.last_name_font_style ? showDefault?.last_name_font_style : '',
            order_instruction_font_size: showDefault && showDefault?.order_instruction_font_size ? showDefault?.order_instruction_font_size : '',
            order_instruction_font_style: showDefault && showDefault?.order_instruction_font_style ? showDefault?.order_instruction_font_style : '',

            item_name_font_size: showDefault && showDefault?.item_name_font_size ? showDefault?.item_name_font_size : '',
            item_name_font_style: showDefault && showDefault?.item_name_font_style ? showDefault?.item_name_font_style : '',
            qty_font_size: showDefault && showDefault?.qty_font_size ? showDefault?.qty_font_size : '',
            qty_font_style: showDefault && showDefault?.qty_font_style ? showDefault?.qty_font_style : '',
            item_extra_details_font_size: showDefault && showDefault?.item_extra_details_font_size ? showDefault?.item_extra_details_font_size : '',
            item_extra_details_font_style: showDefault && showDefault?.item_extra_details_font_style ? showDefault?.item_extra_details_font_style : '',
            item_instruction_font_size: showDefault && showDefault?.item_instruction_font_size ? showDefault?.item_instruction_font_size : '',
            item_instruction_font_style: showDefault && showDefault?.item_instruction_font_style ? showDefault?.item_instruction_font_style : '',

            footer_text: showDefault && showDefault?.footer_text ? showDefault?.footer_text : '',
            footer_font_size: showDefault && showDefault?.footer_font_size ? showDefault?.footer_font_size : '',
            footer_font_style: showDefault && showDefault?.footer_font_style ? showDefault?.footer_font_style : '',
        },
        validationSchema: validateSchema,
        onSubmit: (values, { resetForm, setSubmitting }) => {
            const formData = new FormData();
            formData.append("invoice_type", 'kitchen_invoice');
            formData.append("logo", values.logo);
            // formData.append("line_separator", values.line_separator);
            formData.append("heading_text", values.heading_text);
            formData.append("heading_font_size", values.heading_font_size);
            formData.append("heading_font_style", values.heading_font_style);

            formData.append("deliver_in_font_size", values.deliver_in_font_size);
            formData.append("deliver_in_font_style", values.deliver_in_font_style);
            formData.append("delivery_font_size", values.delivery_font_size);
            formData.append("delivery_font_style", values.delivery_font_style);
            formData.append("delivery_date_time_font_size", values.delivery_date_time_font_size);
            formData.append("delivery_date_time_font_style", values.delivery_date_time_font_style);

            formData.append("number_font_size", values.number_font_size);
            formData.append("number_font_style", values.number_font_style);
            formData.append("accepted_at_font_size", values.accepted_at_font_size);
            formData.append("accepted_at_font_style", values.accepted_at_font_style);
            formData.append("first_name_font_size", values.first_name_font_size);
            formData.append("first_name_font_style", values.first_name_font_style);
            formData.append("last_name_font_size", values.last_name_font_size);
            formData.append("last_name_font_style", values.last_name_font_style);
            formData.append("order_instruction_font_size", values.order_instruction_font_size);
            formData.append("order_instruction_font_style", values.order_instruction_font_style);

            formData.append("item_name_font_size", values.item_name_font_size);
            formData.append("item_name_font_style", values.item_name_font_style);
            formData.append("qty_font_size", values.qty_font_size);
            formData.append("qty_font_style", values.qty_font_style);
            formData.append("item_extra_details_font_size", values.item_extra_details_font_size);
            formData.append("item_extra_details_font_style", values.item_extra_details_font_style);
            formData.append("item_instruction_font_size", values.item_instruction_font_size);
            formData.append("item_instruction_font_style", values.item_instruction_font_style);

            formData.append("footer_text", values.footer_text);
            formData.append("footer_font_size", values.footer_font_size);
            formData.append("footer_font_style", values.footer_font_style);
            formData.append('brand_id', getBrandId());
            CreateKitchenInvoice(formData)
                .then((response) => {
                    if (response.data.status === 1 || response.data.status === "1") {
                        Swal.fire({
                            customClass: 'swal-wide',
                            icon: 'success',
                            title: response.data.message,
                            showConfirmButton: false,
                            timer: 1500,
                            toast: true,
                            position: 'top-right',
                        });
                    } else {
                        Swal.fire({
                            customClass: 'swal-wide',
                            icon: 'error',
                            title: response.data.message,
                            showConfirmButton: false,
                            timer: 5000,
                            toast: true,
                            position: 'top-right',
                        })
                    }
                })
                .catch((error) => {
                    console.log("Error==>", error)
                })
        }
    })

    return (
        <>
            <div className="animation_fade">
                <div className="page-header d-flex align-items-center">
                    <div className="page-header-left">
                        <h4>Settings</h4>
                    </div>
                    <div className="page-header-right ms-auto"></div>
                </div>
                <div className="card custom-card overflow-hidden">
                    <div className="gap-20 border-bottom">
                        <div className="col-12">
                            <div className="d-flex justify-content-between align-items-center">
                                <ConfigurationTab tab="kitchen" />
                            </div>
                        </div>
                    </div>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="w-100 p-4">
                            <div className="row">
                                <div className="col-8">
                                    <div className="card p-3">
                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <h3>Settings</h3>
                                            </div>
                                            {/* <div className="col-lg-12 mb-3">
                                                <h4>Separator</h4>
                                            </div> */}
                                            {/* <label className="">Line Separator</label>
                                            <div className="col-lg-12 text-center form-group mb-3">
                                                <select name="line_separator" id="line_separator" className="form-control" value={formik.values.line_separator} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                                                    <option value="">Select Line Separator</option>
                                                    <option value="solid">Solid</option>
                                                    <option value="dashed">Dashed</option>
                                                    <option value="dotted">Dotted</option>
                                                </select>
                                            </div> */}

                                            <div className="col-lg-12 mb-3">
                                                <h4>Header</h4>
                                            </div>
                                            <label className="">Heading</label>
                                            <div className="col-lg-4 text-center form-group mb-3">
                                                <input
                                                    name="heading_text"
                                                    id="heading_text"
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Enter Heading Text"
                                                    autocomplete="off"
                                                    value={formik.values.heading_text}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                />
                                                <span className='text-danger d-flex text-left'>{formik.errors.heading_text && formik.touched.heading_text && formik.errors.heading_text}</span>
                                            </div>
                                            <div className="col-lg-4 text-center form-group mb-3">
                                                <select name="heading_font_size" className="form-control" value={formik.values.heading_font_size} onChange={formik.handleChange} onBlur={formik.handleBlur} >
                                                    <option value="">Select Font Size</option>
                                                    {FONT_SIZE.map((size, i) => (
                                                        <option key={i} value={size}>{size}</option>
                                                    ))}

                                                </select>
                                            </div>
                                            <div className="col-lg-4 text-center form-group mb-3">
                                                <select name="heading_font_style" className="form-control" value={formik.values.heading_font_style} onChange={formik.handleChange} onBlur={formik.handleBlur} >
                                                    <option value="">Select Font Style</option>
                                                    {FONT_STYLE.map((style, i) => (
                                                        <option key={i} value={style}>{style}</option>
                                                    ))}
                                                </select>
                                            </div>

                                            <h4>Delivery Details</h4>

                                            <label className="">Delivery</label>
                                            <div className="col-lg-6 text-center form-group mb-3">
                                                <select name="delivery_font_size" className="form-control" value={formik.values.delivery_font_size} onChange={formik.handleChange} onBlur={formik.handleBlur} >
                                                    <option value="">Select Font Size</option>
                                                    {FONT_SIZE.map((size, i) => (
                                                        <option key={i} value={size}>{size}</option>
                                                    ))}

                                                </select>
                                            </div>
                                            <div className="col-lg-6 text-center form-group mb-3">
                                                <select name="delivery_font_style" className="form-control" value={formik.values.delivery_font_style} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                                                    <option value="">Select Font Style</option>
                                                    {FONT_STYLE.map((style, i) => (
                                                        <option key={i} value={style}>{style}</option>
                                                    ))}
                                                </select>
                                            </div>

                                            <label className="">Deliver In</label>
                                            <div className="col-lg-6 text-center form-group mb-3">
                                                <select name="deliver_in_font_size" className="form-control" value={formik.values.deliver_in_font_size} onChange={formik.handleChange} onBlur={formik.handleBlur} >
                                                    <option value="">Select Font Size</option>
                                                    {FONT_SIZE.map((size, i) => (
                                                        <option key={i} value={size}>{size}</option>
                                                    ))}

                                                </select>
                                            </div>
                                            <div className="col-lg-6 text-center form-group mb-3">
                                                <select name="deliver_in_font_style" className="form-control" value={formik.values.deliver_in_font_style} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                                                    <option value="">Select Font Style</option>
                                                    {FONT_STYLE.map((style, i) => (
                                                        <option key={i} value={style}>{style}</option>
                                                    ))}
                                                </select>
                                            </div>

                                            <label>Date and time</label>
                                            <div className="col-lg-6 text-center form-group mb-3">
                                                <select name="delivery_date_time_font_size" value={formik.values.delivery_date_time_font_size} onChange={formik.handleChange} onBlur={formik.handleBlur} className="form-control">
                                                    <option value="">Select Font Size</option>
                                                    {FONT_SIZE.map((size, i) => (
                                                        <option key={i} value={size}>{size}</option>
                                                    ))}

                                                </select>
                                            </div>
                                            <div className="col-lg-6 text-center form-group mb-3">
                                                <select name="delivery_date_time_font_style" value={formik.values.delivery_date_time_font_style} onChange={formik.handleChange} onBlur={formik.handleBlur} className="form-control">
                                                    <option value="">Select Font Style</option>
                                                    {FONT_STYLE.map((style, i) => (
                                                        <option key={i} value={style}>{style}</option>
                                                    ))}
                                                </select>
                                            </div>
                                            <label className="">Logo</label>
                                            <div className="upload-logo d-flex align-items-center gap-3 mb-3">
                                                <div className="avatar avatar-xl">
                                                    <img alt="avatar" src={previewImage ? previewImage : "https://pointofsalesapi.devtechnosys.tech/dummy_images/dummy_company_image.png"} />
                                                </div>
                                                <div className="btn btn-black btn-file-type">Upload logo
                                                    <input
                                                        id="logo"
                                                        type="file"
                                                        name="logo"
                                                        accept="image/*"
                                                        onChange={(event) => {
                                                            formik.setFieldValue("logo", event.target.files[0] || "");
                                                            { event.target.files.length == 1 ? setPreviewImage(URL.createObjectURL(event.target.files[0])) : setPreviewImage("") }
                                                        }}
                                                    />
                                                </div>
                                                <div
                                                    className="btn btn-danger"
                                                    onClick={() => {
                                                        formik.setFieldValue("logo", "");
                                                        setPreviewImage("");
                                                    }}
                                                >
                                                    Remove
                                                </div>
                                            </div>

                                            <h4>Order details</h4>

                                            <label className="">Number</label>
                                            <div className="col-lg-6 text-center form-group mb-3">
                                                <select name="number_font_size" className="form-control" value={formik.values.number_font_size} onChange={formik.handleChange} onBlur={formik.handleBlur} >
                                                    <option value="">Select Font Size</option>
                                                    {FONT_SIZE.map((size, i) => (
                                                        <option key={i} value={size}>{size}</option>
                                                    ))}

                                                </select>
                                            </div>
                                            <div className="col-lg-6 text-center form-group mb-3">
                                                <select name="number_font_style" className="form-control" value={formik.values.number_font_style} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                                                    <option value="">Select Font Style</option>
                                                    {FONT_STYLE.map((style, i) => (
                                                        <option key={i} value={style}>{style}</option>
                                                    ))}
                                                </select>
                                            </div>

                                            <label className="">Accepted at</label>
                                            <div className="col-lg-6 text-center form-group mb-3">
                                                <select name="accepted_at_font_size" className="form-control" value={formik.values.accepted_at_font_size} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                                                    <option value="">Select Font Size</option>
                                                    {FONT_SIZE.map((size, i) => (
                                                        <option key={i} value={size}>{size}</option>
                                                    ))}

                                                </select>
                                            </div>
                                            <div className="col-lg-6 text-center form-group mb-3">
                                                <select name="accepted_at_font_style" className="form-control" value={formik.values.accepted_at_font_style} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                                                    <option value="">Select Font Style</option>
                                                    {FONT_STYLE.map((style, i) => (
                                                        <option key={i} value={style}>{style}</option>
                                                    ))}
                                                </select>
                                            </div>

                                            <label className="">First Name</label>
                                            <div className="col-lg-6 text-center form-group mb-3">
                                                <select name="first_name_font_size" className="form-control" value={formik.values.first_name_font_size} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                                                    <option value="">Select Font Size</option>
                                                    {FONT_SIZE.map((size, i) => (
                                                        <option key={i} value={size}>{size}</option>
                                                    ))}

                                                </select>
                                            </div>
                                            <div className="col-lg-6 text-center form-group mb-3">
                                                <select name="first_name_font_style" className="form-control" value={formik.values.first_name_font_style} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                                                    <option value="">Select Font Style</option>
                                                    {FONT_STYLE.map((style, i) => (
                                                        <option key={i} value={style}>{style}</option>
                                                    ))}
                                                </select>
                                            </div>

                                            <label className="">Last Name</label>
                                            <div className="col-lg-6 text-center form-group mb-3">
                                                <select name="last_name_font_size" className="form-control" value={formik.values.last_name_font_size} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                                                    <option value="">Select Font Size</option>
                                                    {FONT_SIZE.map((size, i) => (
                                                        <option key={i} value={size}>{size}</option>
                                                    ))}

                                                </select>
                                            </div>
                                            <div className="col-lg-6 text-center form-group mb-3">
                                                <select name="last_name_font_style" className="form-control" value={formik.values.last_name_font_style} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                                                    <option value="">Select Font Style</option>
                                                    {FONT_STYLE.map((style, i) => (
                                                        <option key={i} value={style}>{style}</option>
                                                    ))}
                                                </select>
                                            </div>

                                            <label className="">Order Instruction</label>
                                            <div className="col-lg-6 text-center form-group mb-3">
                                                <select name="order_instruction_font_size" className="form-control" value={formik.values.order_instruction_font_size} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                                                    <option value="">Select Font Size</option>
                                                    {FONT_SIZE.map((size, i) => (
                                                        <option key={i} value={size}>{size}</option>
                                                    ))}

                                                </select>
                                            </div>
                                            <div className="col-lg-6 text-center form-group mb-3">
                                                <select name="order_instruction_font_style" className="form-control" value={formik.values.order_instruction_font_style} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                                                    <option value="">Select Font Style</option>
                                                    {FONT_STYLE.map((style, i) => (
                                                        <option key={i} value={style}>{style}</option>
                                                    ))}
                                                </select>
                                            </div>

                                            <h4>Item Details</h4>

                                            <label className="">Item Name</label>
                                            <div className="col-lg-6 text-center form-group mb-3">
                                                <select name="item_name_font_size" className="form-control" value={formik.values.item_name_font_size} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                                                    <option value="">Select Font Size</option>
                                                    {FONT_SIZE.map((size, i) => (
                                                        <option key={i} value={size}>{size}</option>
                                                    ))}

                                                </select>
                                            </div>
                                            <div className="col-lg-6 text-center form-group mb-3">
                                                <select name="item_name_font_style" className="form-control" value={formik.values.item_name_font_style} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                                                    <option value="">Select Font Style</option>
                                                    {FONT_STYLE.map((style, i) => (
                                                        <option key={i} value={style}>{style}</option>
                                                    ))}
                                                </select>
                                            </div>

                                            <label className="">Qty</label>
                                            <div className="col-lg-6 text-center form-group mb-3">
                                                <select name="qty_font_size" className="form-control" value={formik.values.qty_font_size} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                                                    <option value="">Select Font Size</option>
                                                    {FONT_SIZE.map((size, i) => (
                                                        <option key={i} value={size}>{size}</option>
                                                    ))}

                                                </select>
                                            </div>
                                            <div className="col-lg-6 text-center form-group mb-3">
                                                <select name="qty_font_style" className="form-control" value={formik.values.qty_font_style} onChange={formik.handleChange} onBlur={formik.handleBlur} >
                                                    <option value="">Select Font Style</option>
                                                    {FONT_STYLE.map((style, i) => (
                                                        <option key={i} value={style}>{style}</option>
                                                    ))}
                                                </select>
                                            </div>

                                            <label className="">Item Extra Details</label>
                                            <div className="col-lg-6 text-center form-group mb-3">
                                                <select name="item_extra_details_font_size" className="form-control" value={formik.values.item_extra_details_font_size} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                                                    <option value="">Select Font Size</option>
                                                    {FONT_SIZE.map((size, i) => (
                                                        <option key={i} value={size}>{size}</option>
                                                    ))}

                                                </select>
                                            </div>
                                            <div className="col-lg-6 text-center form-group mb-3">
                                                <select name="item_extra_details_font_style" className="form-control" value={formik.values.item_extra_details_font_style} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                                                    <option value="">Select Font Style</option>
                                                    {FONT_STYLE.map((style, i) => (
                                                        <option key={i} value={style}>{style}</option>
                                                    ))}
                                                </select>
                                            </div>

                                            <label className="">Item Instruction</label>
                                            <div className="col-lg-6 text-center form-group mb-3">
                                                <select name="item_instruction_font_size" className="form-control" value={formik.values.item_instruction_font_size} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                                                    <option value="">Select Font Size</option>
                                                    {FONT_SIZE.map((size, i) => (
                                                        <option key={i} value={size}>{size}</option>
                                                    ))}

                                                </select>
                                            </div>
                                            <div className="col-lg-6 text-center form-group mb-3">
                                                <select name="item_instruction_font_style" className="form-control" value={formik.values.item_instruction_font_style} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                                                    <option value="">Select Font Style</option>
                                                    {FONT_STYLE.map((style, i) => (
                                                        <option key={i} value={style}>{style}</option>
                                                    ))}
                                                </select>
                                            </div>

                                            <h4>Footer</h4>


                                            <label className="">Footer</label>
                                            <div className="col-lg-4 text-center form-group mb-3">
                                                <input
                                                    name="footer_text"
                                                    id="footer_text"
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Enter Footer Text"
                                                    autocomplete="off"
                                                    value={formik.values.footer_text}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                />
                                                <span className='text-danger d-flex text-left'>{formik.errors.footer_text && formik.touched.footer_text && formik.errors.footer_text}</span>
                                            </div>
                                            <div className="col-lg-4 text-center form-group mb-3">
                                                <select name="footer_font_size" className="form-control" value={formik.values.footer_font_size} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                                                    <option value="">Select Font Size</option>
                                                    {FONT_SIZE.map((size, i) => (
                                                        <option key={i} value={size}>{size}</option>
                                                    ))}

                                                </select>
                                            </div>
                                            <div className="col-lg-4 text-center form-group mb-3">
                                                <select name="footer_font_style" className="form-control" value={formik.values.footer_font_style} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                                                    <option value="">Select Font Style</option>
                                                    {FONT_STYLE.map((style, i) => (
                                                        <option key={i} value={style}>{style}</option>
                                                    ))}
                                                </select>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="card p-3 fw-bold">
                                        <h2>Preview</h2>
                                        <div className=" m-4 p-4">
                                            <div className="text-center">
                                                {previewImage &&
                                                    <div className="avatar avatar-xl">
                                                        <img alt="LOGO" src={previewImage} />
                                                    </div>
                                                }
                                                <h3 className="text-muted" style={{ fontSize: formik.values.heading_font_size, fontFamily: formik.values.heading_font_style }}>{formik?.values?.heading_text}</h3>
                                            </div>
                                            <div>
                                                <div className="d-flex flex-column text-white p-2 my-2">
                                                    <div className="section-title p-2 mb-2 bg-black" style={{ fontSize: formik.values.delivery_font_size, fontFamily: formik.values.delivery_font_style }}>Delivery</div>
                                                    <div className="section-title p-2 mb-2 bg-black" style={{ fontSize: formik.values.deliver_in_font_size, fontFamily: formik.values.deliver_in_font_style }}>ASAP (90 min)</div>
                                                    <div className="section-title p-2 mb-2 bg-black" style={{ fontSize: formik.values.delivery_date_time_font_size, fontFamily: formik.values.delivery_date_time_font_style }}>Aug 15th 7:45PM</div>
                                                </div>

                                                <div>
                                                    <h5>Order details:</h5>
                                                    <div className="row" style={{ fontSize: formik.values.number_font_size, fontFamily: formik.values.number_font_style }}>
                                                        <div className="col-6">Number:</div>
                                                        <div className="col-6 text-right">982734792</div>
                                                    </div>
                                                    <div className="row" style={{ fontSize: formik.values.accepted_at_font_size, fontFamily: formik.values.accepted_at_font_style }}>
                                                        <div className="col-6">Accepted at:</div>
                                                        <div className="col-6 text-right">November 19, 8:03 AM</div>
                                                    </div>
                                                    <div className="row" style={{ fontSize: formik.values.first_name_font_size, fontFamily: formik.values.first_name_font_style }}>
                                                        <div className="col-6">First Name:</div>
                                                        <div className="col-6 text-right">Jonathan</div>
                                                    </div>
                                                    <div className="row" style={{ fontSize: formik.values.last_name_font_size, fontFamily: formik.values.last_name_font_style }}>
                                                        <div className="col-6">Last Name:</div>
                                                        <div className="col-6 text-right">Doe</div>
                                                    </div>
                                                </div>
                                                <div className="alert alert-secondary d-flex align-items-center" role="alert" style={{ fontSize: formik.values.order_instruction_font_size, fontFamily: formik.values.order_instruction_font_style }}>
                                                    <div className="alert-icon me-3">⚠️</div>
                                                    <div>I want to have it delivered hot, not like last time when things were cold, please pay attention.</div>
                                                </div>
                                                {/* <div style={{ border: `1px ${formik.values.line_separator} black` }}></div> */}
                                                <div>
                                                    <h5>Item:</h5>
                                                    <div className="row">
                                                        <div className="col-3" style={{ fontSize: formik.values.qty_font_size, fontFamily: formik.values.qty_font_style }}>1X</div>
                                                        <div className="col-9" style={{ fontSize: formik.values.item_name_font_size, fontFamily: formik.values.item_name_font_style }}>Pizza Prosciuto</div>
                                                        {/* <div className="col-2 text-right"><input type="checkbox" /></div> */}
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-3" style={{ fontSize: formik.values.qty_font_size, fontFamily: formik.values.qty_font_style }}>2X</div>
                                                        <div className="col-9" style={{ fontSize: formik.values.item_name_font_size, fontFamily: formik.values.item_name_font_style }}>Pizza Prosciuto</div>
                                                        {/* <div className="col-2 text-right"><input type="checkbox" /></div> */}
                                                    </div>
                                                    <div style={{ marginLeft: "70px", fontWeight: "lighter", fontSize: formik.values.item_extra_details_font_size, fontFamily: formik.values.item_extra_details_font_style }}>
                                                        <p className="m-0">Size: Medium</p>
                                                        <p className="m-0">Sauces: Hot</p>
                                                        <p className="m-0">Toppings: Mozzarella</p>
                                                    </div>
                                                </div>
                                                <div className="alert alert-secondary d-flex align-items-center my-2" role="alert" style={{ fontSize: formik.values.item_instruction_font_size, fontFamily: formik.values.item_instruction_font_style }}>
                                                    <div className="alert-icon me-3">⚠️</div>
                                                    <div>No mushrooms on this pizza. I am allergic. Please be careful this time ok?</div>
                                                </div>
                                            </div>

                                            {/* <div style={{ border: `1px ${formik.values.line_separator} black` }}></div> */}

                                            <div className="text-center mt-5" style={{ fontSize: formik.values.footer_font_size, fontFamily: formik.values.footer_font_style }}>
                                                <p>Special package</p>
                                                <p>{formik.values.footer_text}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {checkPermission('store_settings', 'add') ?
                                <div className="mt-4"><button className="btn btn-primary px-5 mr-2" type="submit">Save</button></div>
                                : ''}
                        </div>
                    </form>

                    <ToastContainer
                        position="top-right"
                        hideProgressBar={false}
                        autoClose={true | 1000}
                        newestOnTop={true}
                        closeOnClick={false}
                        draggable={false}
                        rtl={false}
                    />
                </div>
            </div >
        </>
    )
}

export default KitchenInvoiceSetting;