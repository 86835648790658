import React, { useEffect, useState } from 'react'
import { Formik, Field } from 'formik'
import { useNavigate, useParams } from 'react-router-dom'
import Swal from "sweetalert2"
import Layout from '../../layout'
import Breadcrums from '../../components/common/breadcrumbs'
import Loader from '../../components/common/loader'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GetSingleSubscription, editSubscription } from '../../services/subscriptionService'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import toHtml from 'html-react-parser';
import { Dropdown } from 'semantic-ui-react'
import { companyDetail, getCompany } from '../../helpers/commonFunctions'
import { param } from 'jquery'

const SubscriptionEdit = () => {
    const navigate = useNavigate()
    const params = useParams()
    const [showDefault, setShowDefault] = useState({})
    const [submitted, setSubmitted] = useState(false)
    const breadcrumbs = [
        {title: "Dashboard",url: "/admin/dashboard"},
        {title: "Subscription",url: "/admin/subscription"},
        {title: "edit",url: ""}
    ]

    
    const statusOptions=[
        {key: 1, text: "Active", value: 1},
        {key: 0, text: "Inactive", value: 0}
    ]
    const monthOptions=[
        {key: "1", text: "1", value: "1"},
        {key: "3", text: "3", value: "3"},
        {key: "6", text: "6", value: "6"},
    ]

    useEffect(() => {
        GetSingleSubscription({id:params?.id}).then(data => {
            setShowDefault(data && data.data && data.data.response ? data.data.response : [])
        }).catch(error => {
            console.log("error=====>", error)
        })
    }, [])

    return (
        <>
            <Layout>
                <Breadcrums data={breadcrumbs} />
                <Formik
                    enableReinitialize
                    initialValues={{
                        name: showDefault && showDefault?.name ? showDefault.name : "",
                        amount: showDefault && showDefault?.amount ? showDefault.amount : "",
                        month : showDefault && showDefault?.month ? showDefault.month : "",
                        access_and_benefits: showDefault && showDefault?.access_and_benefits ? showDefault.access_and_benefits : "" ,
                        status: showDefault && showDefault?.status ? 1 : 0,
                    }}

                    validate={values => {
                        const error = {};
                        if (!values.name) error.name = "Name is required";
                        else if (values.name.trim().length > 20) {
                            error.name = "Name must be 20 characters or less";
                        }
                        if (!values.amount) error.amount = "Amount is required";
                        if (!values.month) error.month = "Month is required";
                        if (!values.access_and_benefits) error.access_and_benefits = "Access and benefits is required"
                        else if (values.access_and_benefits.trim().length > 500) {
                            error.access_and_benefits = "Access and benefits must be 500 characters or less";
                        };
                        return error;
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                        setSubmitted(true)
                        let formData = new FormData();
                        formData.append("id", params.id)
                        formData.append('name', values.name);
                        formData.append('amount', values.amount);
                        formData.append('month', values.month);
                        formData.append('access_and_benefits', values.access_and_benefits);
                        formData.append('status', values.status==1 ? true : false);
                        editSubscription(formData).then(response => {
                            setSubmitting(false);
                            setSubmitted(false);
                            if (response.data.status === 1 || response.data.status === "1") {
                                Swal.fire({
                                    customClass: 'swal-wide',
                                    icon: 'success',
                                    title: response.data.message,
                                    showConfirmButton: false,
                                    timer: 1500,
                                    toast: true,
                                    position: 'top-right',
                                });
                                setTimeout(() => {
                                    navigate('/admin/subscription')
                                }, 1000);
                            } else {
                                Swal.fire({
                                    customClass: 'swal-wide',
                                    icon: 'error',
                                    title: response.data.message,
                                    showConfirmButton: false,
                                    timer: 5000,
                                    toast: true,
                                    position: 'top-right',
                                })
                                setSubmitted(false)
                            }
                        }).catch(error => {
                            console.log("error ====> ", error);
                        })
                    }}
                >{({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,

                }) => (
                    <form onSubmit={handleSubmit}>
                        <div className="row row-sm">
                            <div className="col-lg-12 col-md-12 animation_fade">
                                <div className="page-header d-flex align-items-center">
                                    <div className="page-header-left">
                                        <h4>Edit Subscription</h4>
                                    </div>
                                    <div className="page-header-right ms-auto">
                                        
                                    </div>
                                </div>
                                <div className="card custom-card">
                                    <div className="card-body">
                                        {showDefault && Object.keys(showDefault).length > 0 ?
                                        <div className="row row-sm">
                                            
                                            <div className='col-lg-6 text-center form-group mb-3'>
                                                <label htmlFor='name' className='text-left d-flex'>Name :<span className="requirestar">*</span></label>
                                                <input name='name' id="name" type='text' onChange={handleChange} onBlur={handleBlur} value={values.name} className='form-control' />
                                                <span className='text-danger d-flex text-left'>{errors.name && touched.name && errors.name}</span>
                                            </div>
                                            <div className='col-lg-6 text-center form-group mb-3'>
                                                <label htmlFor='amount' className='text-left d-flex'>Amount :<span className="requirestar">*</span></label>
                                                <input name='amount' id="amount" type='number' onChange={handleChange} onBlur={handleBlur} value={values.amount} className='form-control' />
                                                <span className='text-danger d-flex text-left'>{errors.amount && touched.amount && errors.amount}</span>
                                            </div>
                                            <div className='col-lg-6 text-center form-group required mb-3'>
                                                <label htmlFor='month' className='text-left d-flex'>Duration (Months)  :<span className="requirestar">*</span> </label>
                                                <Dropdown
                                                    className='form-control'
                                                    name= "month"
                                                    id="month"
                                                    placeholder={values.month}
                                                    search
                                                    fluid
                                                    selection
                                                    options={monthOptions}
                                                    value={values.month}
                                                    onChange={(event, data) => { setFieldValue("month", data.value) }}
                                                />
                                                <span className='text-danger d-flex text-left'>{errors.month && touched.month && errors.month}</span>
                                            </div>

                                            <div className='col-lg-6 text-center form-group required mb-3'>
                                                <label htmlFor='status' className='text-left d-flex'>Status  :</label>
                                                <Dropdown
                                                    className='form-control'
                                                    name= "status"
                                                    id="status"
                                                    placeholder='status'
                                                    search
                                                    fluid
                                                    selection
                                                    options={statusOptions}
                                                    value={values.status}
                                                    onChange={(event, data) => { setFieldValue("status", data.value) }}
                                                />
                                                <span className='text-danger d-flex text-left'>{errors.status && touched.status && errors.status}</span>
                                            </div>
                                            <div className='col-lg-12 text-center form-group'>
                                                <label htmlFor='access_and_benefits' className='text-left d-flex'>Access and benefits  :<span className="requirestar">*</span></label>
                                                {
                                                    Boolean(showDefault) ?
                                                        <>
                                                            {/* <CKEditor
                                                                initData={showDefault.access_and_benefits ? toHtml(showDefault.access_and_benefits) : ""}
                                                                data={showDefault.access_and_benefits ? toHtml(showDefault.access_and_benefits): ""}
                                                                onChange={({ editor }) => {
                                                                    setFieldValue("access_and_benefits", editor.getData());
                                                                }}
                                                                onInstanceReady={({ editor }) => {
                                                                    editor.setData(toHtml(values.access_and_benefits));
                                                                }}
                                                            /> */}
                                                             <CKEditor
                                                                editor={ ClassicEditor }
                                                                // config={ editorConfiguration }
                                                                data={showDefault.access_and_benefits}
                                                                onReady={ editor => {
                                                                    console.log( 'Editor is ready to use!', editor );
                                                                } }
                                                                onChange={ ( event, editor ) => {
                                                                    setFieldValue("access_and_benefits", editor.getData());
                                                                } }
                                                                onBlur={ ( event, editor ) => {
                                                                    console.log( 'Blur.', editor );
                                                                } }
                                                                onFocus={ ( event, editor ) => {
                                                                    console.log( 'Focus.', editor );
                                                                } }
                                                            />
                                                        </>
                                                        : ""
                                                }
                                                <span className='text-danger d-flex text-left'>{errors.access_and_benefits && touched.access_and_benefits && errors.access_and_benefits}</span>
                                            </div>
        
                                            <div className="mt-2">
                                                <button className="btn ripple btn-secondary" type="submit" disabled={submitted ? true : null}>
                                                    Save
                                                </button>
                                            </div>
                                            </div> : <Loader />}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                )}
                </Formik>
                <ToastContainer
                position="top-right"
                hideProgressBar={false}
                autoClose={true | 1000}
                newestOnTop={true}
                closeOnClick={false}
                draggable={false}
                rtl={false}
                />
            </Layout>
        </>

    )
}

export default SubscriptionEdit


