import React,{ useEffect } from 'react'
import { globalLoader } from './helpers/global'

const LoginLayout = ({ children }) => {


  useEffect(()=>{
    globalLoader(true);
    Promise.all([
      import("./assets/admin/css/skins.css"),
      import("./assets/admin/css/icon-list.css"),
      import("./assets/admin/css/style.css"),
      import("./assets/admin/css/dark-style.css"),
      import("./assets/admin/css/colors/default.css"),
      import("./assets/admin/css/sidemenu/sidemenu.css"),
      import("./assets/admin/bootstrap-daterangepicker/daterangepicker.css"),
      
    ]).then(()=>{
      globalLoader(false);
    }).catch((error)=>{
      console.log("Error in CSS",error);
    })
  },[]);



  return (
    <div className='page main-signin-wrapper'>
      {/* <div className="main-content side-content pt-0"> */}
        {/* <div className="container-fluid"> */}
          <div className="inner-body">
           {children}
          </div>
        {/* </div> */}
      {/* </div> */}
    </div>
  )
}

export default LoginLayout