import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { GetBrandDetail } from '../../services/company_panel/brandService'
import toHtml from 'html-react-parser'
import {capitalizeFirstLetter } from '../../helpers/commonFunctions';
import Breadcrums from '../../components/company_panel/common/breadcrumbs';
import Loader from '../../components/company_panel/common/loader'
import { LOADER_TIME } from '../../utils/Constants';


const BrandView = () => {
    const navigate = useNavigate()
    const params = useParams()
    const [showDefault, setShowDefault] = useState({})
    const [loader, setLoader] = useState(true);
    const breadcrumbs = [{ title: "Brand", url: "/company/business/brand/list/1" }, { title: "View", url: "" }]

    useEffect(() => {
        GetBrandDetail(params.id).then(data => {
            setShowDefault(data && data.data && data.data.response ? data.data.response : [])
            setTimeout(() => {
                setLoader(false);
            }, LOADER_TIME);
        }).catch(error => {
            setTimeout(() => {
                setLoader(false);
            }, LOADER_TIME);
            console.log("error=====>", error)
        })
    }, [])

    const editFunction = () => {
        navigate(`/company/business/brand/1/edit/${params.id}`)
    }


    return (
        <>
            <div className="page-header d-flex align-items-center">
                <div className="page-header-left">
                    <Breadcrums data={breadcrumbs} />
                    <h4>Brands</h4>
                </div>
                <div className="page-header-right ms-auto">
                    <button className='btn btn-primary' onClick={() => { editFunction() }}>Edit Brand</button>
                </div>
            </div>
            <div className="card custom-card">
                {showDefault && Object.keys(showDefault)?.length > 0 ?
                    <div className="card-body">
                        <div className='brand-banner'>
                            <div className='avatar avatar-xl mx-auto'>
                                {showDefault && showDefault?.logo ? <img src={showDefault?.logo} /> : "N/A"}
                            </div>
                            <div className='brand-id'>
                                Brand ID: {showDefault?.brand_id}
                            </div>
                            <h4>{showDefault && showDefault?.name ? capitalizeFirstLetter(showDefault?.name) : "N/A"} </h4>
                        </div>
                        <div className='profile-info mt-5'>
                            <div className='profile-info-in'>
                                <h5>Name</h5>
                                <p>{showDefault && showDefault?.name ? capitalizeFirstLetter(showDefault?.name) : "N/A"}</p>
                            </div>
                            <div className='profile-info-in'>
                                <h5>Name (Arabic)</h5>
                                <p>{showDefault && showDefault?.name_ar ? capitalizeFirstLetter(showDefault?.name_ar) : "N/A"}</p>
                            </div>
                            <div className='profile-info-in'>
                                <h5>Short description</h5>
                                <p>{showDefault && showDefault?.short_description ? capitalizeFirstLetter(showDefault?.short_description) : "N/A"}</p>
                            </div>
                            <div className='profile-info-in'>
                                <h5>Short description (Arabic)</h5>
                                <p>{showDefault && showDefault?.short_description_ar ? capitalizeFirstLetter(showDefault?.short_description_ar) : "N/A"}</p>
                            </div>
                            <div className='profile-info-in'>
                                <h5>Long description </h5>
                                <p>{toHtml(showDefault && showDefault?.long_description ? capitalizeFirstLetter(showDefault?.long_description) : "N/A")}</p>
                            </div>
                            <div className='profile-info-in'>
                                <h5>Long description (Arabic) </h5>
                                <p>{toHtml(showDefault && showDefault?.long_description_ar ? capitalizeFirstLetter(showDefault?.long_description_ar) : "N/A")}</p>
                            </div>
                        </div>

                    </div>
                    : <Loader />}
            </div>
        </>
    )
}

export default BrandView