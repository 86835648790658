import React, { useState, useEffect, useMemo } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import CustomPagination from '../../components/company_panel/common/custompagination'
import { capitalizeFirstLetter, checkPermission, formateDate, getOutletId, removeHyphens } from '../../helpers/commonFunctions';
import Sorting from '../../components/company_panel/common/sorting';
import Loader from '../../components/company_panel/common/loader'
import StatusFilter from '../../components/company_panel/common/statusFilter'
import { LOADER_TIME, showFilterlist } from '../../utils/Constants'
import CustomRangepicker from '../../components/company_panel/common/rangepicker'
import $ from "jquery";
import { getCompany } from '../../helpers/commonFunctions';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import notFound from '../../assets/company/img/no-record-found.png'
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { ListTransaction } from '../../services/company_panel/transcation.services';


const TransactionList = () => {

    const navigate = useNavigate()
    const params = useParams()
    const location = useLocation()
    const [list, setList] = useState([])
    const [statsUpdate, setStatusUpdate] = useState("false")
    const [dataLength, setDataLength] = useState()
    const [itemPerPage, setItemPerPage] = useState(10)
    const [sorting, setSorting] = useState({});
    const [defaultSorting, setDefaultSorting] = useState(true);
    const [page, setPage] = useState(1);
    const [loader, setLoader] = useState(true)
    const [search, setSearch] = useState({})
    const [globalSearch, setGlobalSearch] = useState("")
    const [resetdate, setResetDate] = useState(false);
    const [isBrandChanged, setIsBrandChanged] = useState(false);
    const [isOutletChanged, setIsOutletChanged] = useState(false);

    useMemo(() => {
        document.addEventListener('brand-changed', function () {
            setIsBrandChanged(!isBrandChanged);
        });
        document.addEventListener('outlet-changed', function () {
            setIsOutletChanged(!isOutletChanged);
        });
    });

    useEffect(() => {
        if (search) {
            setLoader(true)
            setTimeout(() => {
                const formData = new FormData()
                formData.append("page", params.id)
                formData.append("per_page", itemPerPage)
                formData.append("sort", JSON.stringify(sorting))
                formData.append("search", JSON.stringify(search))
                formData.append("global_search", globalSearch)
                formData.append('outlet_id', getOutletId());
                ListTransaction(formData).then(data => {
                    setDataLength(data.data.response.count);
                    setList(data && data.data && data.data.response ? data.data.response.rows : [])
                    setLoader(false);
                }).catch(error => {
                    console.log("error ====> ", error);
                    setLoader(false);
                })
            }, 500);
        }
    }, [location, statsUpdate, sorting, search, globalSearch, isBrandChanged, isOutletChanged])


    const resetFilter = (e) => {
        e.preventDefault()
        setGlobalSearch("")
        prepareSearch()
        setSearch({})
        setResetDate(!resetdate)
    }

    const viewFunction = (row) => {
        navigate(`/company/transaction/transaction/view/${row}`)
    }

    const handleSort = (e, column) => {
        setDefaultSorting(false);
        let sort = { order: 0, column: column };
        if (e.target.classList.contains("assc")) {
            sort.order = -1;
        } else {
            sort.order = 1;
        }
        setSorting(sort);
    }

    const prepareSearch = (key, value) => {
        let sr = { ...search };
        if (String(value).length > 0) {
            sr[key] = value;
        } else {
            delete sr[key];
        }
        setSearch(sr);
    }

    const headerMapping = {
        "transaction_id": "Transaction ID",
        "customer_name": "Customer Name",
        "customer_number": "Customer Number",
        "order_id": "Order ID",
        "order_mode" : "Order Mode",
        "aggregator_name" : "Aggregator Name",
        "order_by" : "Order By",
        "amount": "Amount",
        "payment_status": "Status",
        "payment_method": "Payment Method",
        "created_at" : "Created Date"
    };

    const handleExport = () => {
        const formData = new FormData()
        formData.append("page", 1)
        formData.append("per_page", dataLength)
        formData.append("sort", JSON.stringify(sorting))
        formData.append("search", JSON.stringify(search))
        formData.append("global_search", globalSearch)
        formData.append('outlet_id', getOutletId());
        ListTransaction(formData).then(data => {
            setDataLength(data.data.response.count);
            const listData = data && data.data && data.data.response ? data.data.response.rows : [];
            const printData = listData.map((item) => {
                const transformedItem = {};
                // Extract fields from nested objects
                const transactionID = item?.transaction_id || 'N/A';
                const customerName = item?.customer?.customer_name || 'N/A';
                const customerNumber = item?.customer?.customer_number || 'N/A';
                const orderID = item?.customer_order?.order_id || 'N/A';
                const orderMode = item?.customer_order?.order_mode_configuration?.name || 'N/A';
                const aggregatorName = item?.customer_order?.aggregators_configuration?.name || 'N/A';
                const orderBy = item?.customer_order?.user?.first_name + " " + item?.customer_order?.user?.last_name  || 'N/A';
                const amount = item?.amount + " " + "SAR" || 'N/A';
                const paymentStatus = item?.payment_status || 'N/A';
                const paymentMethod = item?.payment_method || 'N/A';
                const createdAt = formateDate(item?.created_at )

                // Map fields according to headerMapping
                for (const key in headerMapping) {
                    if (headerMapping.hasOwnProperty(key)) {
                        const newKey = headerMapping[key];
                        switch (key) {
                            case "transaction_id":
                                transformedItem[newKey] = transactionID;
                                break;
                            case "customer_name":
                                transformedItem[newKey] = capitalizeFirstLetter(customerName);
                                break;
                            case "customer_number":
                                transformedItem[newKey] = capitalizeFirstLetter(customerNumber);
                                break;
                            case "order_id":
                                transformedItem[newKey] = (orderID);
                                break;
                            case "order_mode":
                                transformedItem[newKey] = capitalizeFirstLetter(orderMode);
                                break;
                            case "aggregator_name":
                                transformedItem[newKey] = capitalizeFirstLetter(aggregatorName);
                                break;
                            case "order_by":
                                transformedItem[newKey] = capitalizeFirstLetter(orderBy);
                                break;
                            case "amount":
                                transformedItem[newKey] = (amount);
                                break;
                            case "payment_status":
                                transformedItem[newKey] = capitalizeFirstLetter(paymentStatus);
                                break;
                            case "payment_method":
                                transformedItem[newKey] = capitalizeFirstLetter(paymentMethod);
                                break;
                            case "created_at":
                                    transformedItem[newKey] = (createdAt);
                                    break;
                            default:
                                // Handle any unexpected keys
                                console.warn(`Unexpected key in headerMapping: ${key}`);
                                transformedItem[newKey] = 'N/A';
                                break;
                        }
                    }
                }
                return transformedItem;
            });

            const ws = XLSX.utils.json_to_sheet(printData, { header: Object.values(headerMapping) });
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
            saveAs(blob, 'Transaction.xlsx');
            setTimeout(() => {
                setLoader(false);
            }, LOADER_TIME);
        }).catch(error => {
            setTimeout(() => {
                setLoader(false);
            }, LOADER_TIME);
            console.log("error ====> ", error);
        })
    };


    // const printData = (row, index) => {
    //     const printContent = `
    //         <div style="max-width: 600px; margin: auto; padding: 20px; border: 1px solid #ccc; border-radius: 5px;">
    //             <h1 style="text-align: left; color: #333;">${row.customer_order.order_id || 'N/A'}</h1>
    //             <h2 style="text-align: center; color: #333;">Invoice</h2>
    //             <hr style="border: 1px solid #ccc;">
    //             <div style="margin-bottom: 20px;">
    //                 <h3 style="color: #666;">Transaction Details</h3>
    //                 <p><strong>Transaction ID:</strong> ${row.transaction_id || 'N/A'}</p>
    //                 <p><strong>Customer Name:</strong> ${row.customer?.customer_name ? capitalizeFirstLetter(row.customer.customer_name) : 'N/A'}</p>
    //                 <p><strong>Customer Number:</strong> ${row.customer?.customer_number || 'N/A'}</p>
    //                 <p><strong>Order ID:</strong> ${row.customer_order.order_id || 'N/A'}</p>
    //                 <p><strong>Amount:</strong> ${row.amount ? row.amount + " SAR" : 'N/A'}</p>
    //                 <p><strong>Status:</strong> ${row.payment_status ? capitalizeFirstLetter(row.payment_status) : 'N/A'}</p>
    //                 <p><strong>Payment Method:</strong> ${row.payment_method ? capitalizeFirstLetter(row.payment_method) : 'N/A'}</p>
    //             </div>
    //             <hr style="border: 1px solid #ccc;">
    //             <p style="text-align: center; font-style: italic;">Thank you for your business!</p>
    //         </div>
    //     `;
    
    //     const printWindow = window.open('', '_blank');
    //     printWindow.document.write(`
    //         <html>
    //             <head>
    //                 <title>Invoice Print</title>
    //                 <style>
    //                     body { font-family: Arial, sans-serif; }
    //                     h2 { margin-bottom: 10px; }
    //                     h3 { margin: 10px 0; }
    //                     p { line-height: 1.5; margin: 5px 0; }
    //                     hr { margin: 20px 0; }
    //                 </style>
    //             </head>
    //             <body>
    //                 ${printContent}
    //             </body>
    //         </html>
    //     `);
    //     printWindow.document.close();
    //     printWindow.print();
    //     printWindow.close();
    // };

    const printData = (row, index) => {
        const printContent = `
            <div style="max-width: 600px; margin: auto; padding: 20px; border: 1px solid #ccc; border-radius: 5px;">
                <h1 style="text-align: left; color: #333;">${row.customer_order.order_id || 'N/A'}</h1>
                <h2 style="text-align: center; color: #333;">Invoice</h2>
                <hr style="border: 1px solid #ccc;">
                <div style="margin-bottom: 20px;">
                    <h3 style="color: #666;">Transaction Details</h3>
                    <p><strong>Transaction ID:</strong> ${row.transaction_id || 'N/A'}</p>
                    <p><strong>Customer Name:</strong> ${row.customer?.customer_name ? capitalizeFirstLetter(row.customer.customer_name) : 'N/A'}</p>
                    <p><strong>Customer Number:</strong> ${row.customer?.customer_number || 'N/A'}</p>
                    <p><strong>Order ID:</strong> ${row.customer_order.order_id || 'N/A'}</p>
                    <p><strong>Amount:</strong> ${row.amount ? row.amount + " SAR" : 'N/A'}</p>
                    <p><strong>Status:</strong> ${row.payment_status ? capitalizeFirstLetter(row.payment_status) : 'N/A'}</p>
                    <p><strong>Payment Method:</strong> ${row.payment_method ? capitalizeFirstLetter(row.payment_method) : 'N/A'}</p>
                </div>
                <hr style="border: 1px solid #ccc;">
                <p style="text-align: center; font-style: italic;">Thank you for your business!</p>
            </div>
        `;
    
        const iframe = document.createElement('iframe');
        iframe.style.position = 'absolute';
        iframe.style.width = '0';
        iframe.style.height = '0';
        iframe.style.border = 'none';
        document.body.appendChild(iframe);
    
        const doc = iframe.contentWindow.document;
        doc.open();
        doc.write(`
            <html>
                <head>
                    <title>Invoice Print</title>
                    <style>
                        body { font-family: Arial, sans-serif; }
                        h2 { margin-bottom: 10px; }
                        h3 { margin: 10px 0; }
                        p { line-height: 1.5; margin: 5px 0; }
                        hr { margin: 20px 0; }
                    </style>
                </head>
                <body>
                    ${printContent}
                </body>
            </html>
        `);
        doc.close();
    
        iframe.contentWindow.focus();
        iframe.contentWindow.print();
        document.body.removeChild(iframe);
    };
    
    

    return (
        <>
            <div className="animation_fade">
                <div className='page-header d-flex align-items-center'>
                    <div className='page-header-left'>
                        <h4>Transaction</h4>
                    </div>
                    <div className='page-header-right ms-auto'>
                        <button onClick={handleExport} disabled={dataLength === 0} className="btn btn-primary ms-2">Export Transaction</button>
                    </div>
                </div>
                <div className="card custom-card overflow-hidden">
                    <div className='gap-20'>
                        <div className='filter-header d-flex justify-content-between align-items-center'>
                            <div className='d-flex gap-3 align-items-center'>
                                <div className="form-group mb-0 rangepicker_container">
                                    <input type="search" className="form-control" value={globalSearch} placeholder="Search" onChange={(e) => { setGlobalSearch(e.target.value) }} />
                                </div>
                            </div>
                            <div className='d-flex gap-3 align-items-center'>
                                <div className="date-input">
                                    <CustomRangepicker GetDateRange={(e) => { prepareSearch("created", e) }} resetdate={resetdate} />
                                </div>
                                <button type="reset" value='Reset' onClick={resetFilter} className="btn btn-primary mr-2">Reset Filter</button>
                            </div>
                        </div>
                    </div>
                    <div className="tabler-wrap">
                        <div className="table-responsive">
                            <table className="table table-custom">
                                <thead>
                                    <tr>
                                        <th width="8%">
                                            <div className='sorting_column'>
                                                <span>Transaction ID</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="id" />
                                            </div>
                                        </th>
                                        <th width="10%">
                                            <div className='sorting_column'>
                                                <span>Customer Name</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="customer_name" />
                                            </div>
                                        </th>
                                        <th width="10%">
                                            <div className='sorting_column'>
                                                <span>Customer Number</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="customer_number" />
                                            </div>
                                        </th>
                                        <th width="10%">
                                            <div className='sorting_column'>
                                                <span>Order ID</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="order_id" />
                                            </div>
                                        </th>
                                        <th width="10%">
                                            <div className='sorting_column'>
                                                <span>Order Mode</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="order_mode" />
                                            </div>
                                        </th>
                                        <th width="10%">
                                            <div className='sorting_column'>
                                                <span>Aggregator Name</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="aggregator_name" />
                                            </div>
                                        </th>
                                        <th width="10%">
                                            <div className='sorting_column'>
                                                <span>Ordered By</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="order_by" />
                                            </div>
                                        </th>
                                        <th width="10%">
                                            <div className='sorting_column'>
                                                <span>Amount</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="amount" />
                                            </div>
                                        </th>
                                        <th width="10%">
                                            <div className='sorting_column'>
                                                <span>Status</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="payment_status" />
                                            </div>
                                        </th>
                                        <th width="10%">
                                            <div className='sorting_column'>
                                                <span>Payment Method</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="payment_method" />
                                            </div>
                                        </th>
                                        <th className="created_head" width="15%">
                                            <div className='sorting_column'>
                                                <span>Created Date</span>
                                                <Sorting sort={sorting} handleSort={handleSort} defaultSorting={defaultSorting} column="created_at" />
                                            </div>
                                        </th>
                                        <th className='action_head text-end' width="8%" style={{ paddingLeft: "3.5rem" }}>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {loader ?
                                        <tr>
                                            <td colSpan={12}>
                                                <Loader />
                                            </td>
                                        </tr> : <>
                                            {list.length ? list.map((row, index) => (
                                                <tr>
                                                    <td>{row?.transaction_id ? row?.transaction_id : 'N/A'}</td>
                                                    <td>{row?.customer?.customer_name ? capitalizeFirstLetter((row?.customer_order?.customer_name)) : 'N/A'}</td>
                                                    <td>{row?.customer?.customer_number ? row?.customer?.customer_number : '-'}</td>
                                                    <td>{row?.customer_order?.order_id ? row?.customer_order?.order_id : 'N/A'}</td>
                                                    <td>{row?.customer_order?.order_mode_configuration?.name ? capitalizeFirstLetter(row?.customer_order?.order_mode_configuration?.name) : 'N/A'}</td>
                                                    <td>{row?.customer_order?.aggregators_configuration?.name ? capitalizeFirstLetter(row?.customer_order?.aggregators_configuration?.name) : 'N/A'}</td>
                                                    <td>{row?.customer_order?.user ? `${capitalizeFirstLetter(row?.customer_order?.user?.first_name)} ${capitalizeFirstLetter(row?.customer_order?.user?.last_name)}` : 'N/A'}</td>
                                                    <td>{row?.amount ? row?.amount + " " + "SAR" : 'N/A'}</td>
                                                    <td>{row?.payment_status ? capitalizeFirstLetter(row?.payment_status) : 'N/A'}</td>
                                                    <td>{row?.payment_method ? capitalizeFirstLetter(row?.payment_method) : 'N/A'}</td>
                                                    {/* <td>{row?.order_type ? capitalizeFirstLetter(row?.order_type) : 'N/A'}</td> */}
                                                    <td>{row?.created_at ? formateDate(row?.created_at) : 'N/A' }</td>
                                                    <td className='text-end'>
                                                        <div className="dropdown  d-inline-block">
                                                            <button className="btn btn-icon btn-secondary" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                <i className='ti ti-dots-vertical'></i>
                                                            </button>
                                                            <ul className="dropdown-menu">
                                                            {checkPermission('store_transaction', 'view') ?
                                                                <li><div className='dropdown-item' onClick={() => { viewFunction(row.id) }}><i className="ti ti-eye" aria-hidden="true"></i> View details</div></li>
                                                                :''}
                                                                <li>
                                                                    <div className='dropdown-item' onClick={() => printData(row, index)}>
                                                                        <i className="ti ti-printer" aria-hidden="true"></i> Print
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )) : <tr>
                                                <td colSpan={12} className="text-center">
                                                    <img src={notFound} style={{ width: "5%" }} alt="Not Found" />
                                                    <p>No records</p>
                                                </td>
                                            </tr>}
                                        </>}
                                </tbody>
                            </table>
                        </div>
                        <div className='tabler-footer'>
                            <div className='d-flex align-items-center'>
                                <div id="example1_info" role="status" className="table-result" aria-live="polite"><b>Total Records : {dataLength ? dataLength : "0"}</b></div>
                                {dataLength && dataLength > 0 ?
                                    <CustomPagination datalength={dataLength} itemperpage={itemPerPage} setPage={setPage} currentPage={page} pageRoute={[{ name: "customer", path: "/company/transaction/transaction/list" }]} /> : ""}
                            </div>
                        </div>
                    </div>
                    <div>
                    </div>
                </div>
            </div>
            <ToastContainer
                position="top-right"
                hideProgressBar={false}
                autoClose={true | 1000}
                newestOnTop={true}
                closeOnClick={false}
                draggable={false}
                rtl={false}
            />
        </>
    );
}

export default TransactionList