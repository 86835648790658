import React from 'react';

const CustomModal = ({ title, content }) => {
    // Check if the content is an array (for rendering list)
    const isArrayContent = Array.isArray(content);

    return (
        <div className="modal fade" id="exampleModalToggle" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabIndex="-1">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="exampleModalToggleLabel">{title}</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        {isArrayContent ? (
                            <ul>
                                {content.map((item, index) => (
                                    <li key={index} className="text-capitalize">
                                        {item}
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <p>{content}</p>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CustomModal;
