import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Breadcrums from '../../components/company_panel/common/breadcrumbs';
import Swal from 'sweetalert2';
import { Button, Modal } from 'react-bootstrap';
import { EmailSend, GetPurchaseOrderDetail, changePurchaseOrderStatus, confirmPurchaseOrderInventories } from '../../services/company_panel/purchaseOrderService';
import { capitalizeFirstLetter, getCompany, getOutletId, TrimText } from '../../helpers/commonFunctions';
import notFound from '../../assets/company/img/no-record-found.png';

const OrderView = () => {
    const navigate = useNavigate();
    const params = useParams();
    const [showDefault, setShowDefault] = useState({});
    const breadcrumbs = [{ title: "Purchase order", url: "/company/store_manager/purchase_order/list/1" }, { title: "View", url: "" }];
    const [statsUpdate, setStatusUpdate] = useState("false");
    const [showModal, setShowModal] = useState(false);
    const [quantities, setQuantities] = useState({});
    // let totalPrice = 0;
    const [totalPrice, setTotalPrice] = useState(0);
    // const [finalPrice, setFinalPrice] = useState(0);
    const [isBrandChanged, setIsBrandChanged] = useState(false);
    const [currentOutlet] = useState(getOutletId());
    const [isOutletChanged, setIsOutletChanged] = useState(false);
    const [previewImage, setPreviewImage] = useState("");
    const [previewFileType, setPreviewFileType] = useState(null);
    const [pdfFileName, setPdfFileName] = useState('');
    const [invoiceError, setInvoiceError] = useState(false);


    useMemo(() => {
        document.addEventListener('brand-changed', function () {
            setIsBrandChanged(!isBrandChanged);
        });
        document.addEventListener('outlet-changed', function () {
            setIsOutletChanged(!isOutletChanged);
        });
        if (currentOutlet != getOutletId()) {
            navigate(`/company/store_manager/purchase_order/list/${1}`);
        }
    });

    useEffect(() => {
        GetPurchaseOrderDetail({ id: params.id }).then(data => {
            const responseData = data && data.data && data.data.response ? data.data.response : [];
            setShowDefault(responseData);
            const initialQuantities = responseData.purchase_orders_inventories.reduce((acc, item) => {
                acc[item.inventory_id] = item.ordered_quantity;
                return acc;
            }, {});
            setQuantities(initialQuantities);
        }).catch(error => {
            console.log("error=====>", error);
        });
    }, [statsUpdate]);

    useEffect(() => {
        if (showDefault?.purchase_orders_inventories) {
            const newTotalPrice = showDefault.purchase_orders_inventories.reduce((acc, item) => {
                const quantity = showDefault.order_status ? item?.recieved_quantity : quantities[item.inventory_id];
                return acc + (quantity * item?.price);
            }, 0);
            setTotalPrice(newTotalPrice);
        }
    }, [showDefault]);

    const handleQuantityChange = (inventory_id, newQuantity) => {
        console.log(newQuantity);
        if (newQuantity != Math.floor(newQuantity)) {
            return;
        }
        setQuantities(prevQuantities => ({
            ...prevQuantities,
            [inventory_id]: newQuantity
        }));
    };

    // const handleConfirm = () => {
    //     const updatedItems = showDefault.purchase_orders_inventories.map(item => ({
    //         inventory_id: item.inventory_id,
    //         item_name: item.inventory.item_name,
    //         quantity: Number(quantities[item.inventory_id]),
    //         ordered_quantity: item.ordered_quantity,
    //         price: item.price
    //     }));
    //     const newTotalPrice = updatedItems.reduce((acc, item) => {
    //         const quantity = item?.quantity ? item?.quantity : 0
    //         return acc + (quantity * item?.price);
    //     }, 0);
    //     setTotalPrice(newTotalPrice);
    //     confirmPurchaseOrderInventories({
    //         purchase_order_id: params.id,
    //         final_price: newTotalPrice ? newTotalPrice : totalPrice,
    //         inventories: updatedItems,
    //         company_id: getCompany().company_id,
    //         outlet_id: showDefault.outlet_id
    //     }).then(response => {

    //         setShowModal(false);
    //         Swal.fire({
    //             customClass: 'swal-wide',
    //             position: 'absolute',
    //             icon: 'success',
    //             title: 'Order marked as received',
    //             showConfirmButton: false,
    //             timer: 2000,
    //             toast: true,
    //             position: 'top-right',
    //         });
    //         setStatusUpdate(!statsUpdate);
    //     }).catch(error => {
    //         console.log("updateError", error);
    //     });
    // };

    const handleConfirm = () => {
        const invoiceNumber = document.getElementById("invoice_number").value;
        const logoInput = document.getElementById("logo");
        const logoFile = logoInput.files[0];
        console.log('----logoFile', logoFile, logoInput)
        const updatedItems = showDefault.purchase_orders_inventories.map(item => ({
            inventory_id: item.inventory_id,
            item_name: item.inventory.item_name,
            quantity: Number(quantities[item.inventory_id]),
            ordered_quantity: item.ordered_quantity,
            price: item.price
        }));
        const newTotalPrice = updatedItems.reduce((acc, item) => {
            const quantity = item?.quantity ? item?.quantity : 0
            return acc + (quantity * item?.price);
        }, 0);
        setTotalPrice(newTotalPrice);
        const formData = new FormData();
        formData.append("purchase_order_id", params.id)
        formData.append("final_price", newTotalPrice ? newTotalPrice : totalPrice)
        formData.append("inventories", JSON.stringify(updatedItems))
        formData.append("company_id", getCompany().company_id)
        formData.append("outlet_id", showDefault.outlet_id)
        formData.append("invoice_number", invoiceNumber)
        formData.append("invoice_logo", logoFile)
        confirmPurchaseOrderInventories(formData)
            .then(response => {
                setShowModal(false);
                Swal.fire({
                    customClass: 'swal-wide',
                    position: 'absolute',
                    icon: 'success',
                    title: 'Order marked as received',
                    showConfirmButton: false,
                    timer: 2000,
                    toast: true,
                    position: 'top-right',
                });
                setStatusUpdate(!statsUpdate);
            }).catch(error => {
                console.log("updateError", error);
            });
    };



    function sendEmail(data) {
        const formData = new FormData();
        formData.append("supplier_id", data?.supplier_id);
        formData.append("outlet_id", getOutletId());
        formData.append("order_id", data?.id);
        if (showDefault?.purchase_orders_inventories && showDefault?.purchase_orders_inventories.length) {
            formData.append("tableData", JSON.stringify(showDefault?.purchase_orders_inventories));
        }
        EmailSend(formData).then(data => {
            if (data.status === 200) {
                Swal.fire({
                    customClass: 'swal-wide',
                    position: 'absolute',
                    icon: 'success',
                    title: 'Email send successfully',
                    showConfirmButton: false,
                    timer: 2000,
                    toast: true,
                    position: 'top-right',
                });
            }
        }).catch(error => {
            console.log("deleteError");
        });
    }

    const [isFormValid, setIsFormValid] = useState(false);

    // const validateForm = () => {
    //     const invoiceNumber = document.getElementById('invoice_number').value;
    //     const isInvoiceNumberValid = /^[1-9][0-9]*$/.test(invoiceNumber) && invoiceNumber.length <= 15;

    //     const logoFile = document.getElementById('logo').files[0];
    //     const isLogoUploaded = logoFile && logoFile.size > 0;

    //     let isFileValid = true;
    //     if (logoFile) {
    //         const fileSizeInMB = logoFile.size / (1024 * 1024);
    //         if (isImage(logoFile.name) && fileSizeInMB > 10) {
    //             isFileValid = false;
    //         }
    //         if (isPDF(logoFile.name) && fileSizeInMB > 10) {
    //             isFileValid = false;
    //         }
    //     }

    //     setIsFormValid(isInvoiceNumberValid && isLogoUploaded && isFileValid);
    // };

    const validateForm = () => {
        const invoiceNumber = document.getElementById('invoice_number').value;
        const isInvoiceNumberValid = /^[1-9][0-9]*$/.test(invoiceNumber) && invoiceNumber.length <= 15;

        const logoFileInput = document.getElementById('logo');
        const logoFile = logoFileInput.files[0];
        const isLogoUploaded = logoFile && logoFile.size > 0;

        let isFileValid = true;
        if (isInvoiceNumberValid) {
            setInvoiceError(false)
        } else {
            setInvoiceError(true);
        }
        if (logoFile) {
            const fileSizeInMB = logoFile.size / (1024 * 1024);
            if ((isImage(logoFile.name) || isPDF(logoFile.name)) && fileSizeInMB > 10) {
                isFileValid = false;
                Swal.fire({
                    icon: 'error',
                    title: 'File size exceeds limit',
                    text: 'Image or PDF file size should not exceed 10 MB.',
                });
            }
        }
        setIsFormValid(isInvoiceNumberValid && isLogoUploaded && isFileValid);
    };


    const resetForm = () => {
        setIsFormValid(false);
        setPreviewImage(null);
        setPdfFileName(null);
    };

    const handleCloseModal = () => {
        resetForm();
        setShowModal(false);
    };

    const getFileSize = (url) => {
        const file = new File([url], url);
        return file.size / (1024 * 1024); // file size in MB
    };

    const isImage = (url) => /\.(jpeg|jpg|gif|png|webp)$/i.test(url);
    const isPDF = (url) => /\.pdf$/i.test(url);

    const renderFileContent = (url) => {
        const fileSize = getFileSize(url);

        if (isImage(url) && fileSize > 10) {
            return <div className="text-danger">Image size exceeds 10 MB</div>;
        }

        if (isPDF(url) && fileSize > 10) {
            return <div className="text-danger">PDF size exceeds 10 MB</div>;
        }

        if (isImage(url)) {
            return <img src={url} alt="Invoice Logo" style={{ maxWidth: '100px', maxHeight: '100px' }} />;
        }

        if (isPDF(url)) {
            const fileName = url.split('/').pop();
            return (
                <div className="d-flex align-items-center">
                    <a href={url} download={fileName} target="_blank" className="text-primary">
                        {TrimText(fileName, 20)}
                    </a>
                </div>
            );
        }

        return "N/A";
    };

    const allowedImageTypes = ['image/jpeg', 'image/jpg', 'image/gif', 'image/png', 'image/webp'];

    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            const fileExtension = file.name.split('.').pop().toLowerCase();
            const allowedExtensions = ['jpeg', 'jpg', 'gif', 'png', 'webp'];

            if (allowedImageTypes.includes(file.type) && allowedExtensions.includes(fileExtension)) {
                setPreviewImage(URL.createObjectURL(file));
                setPreviewFileType('image');
                setPdfFileName('');
            } else if (file.type === 'application/pdf') {
                setPreviewImage(null);
                setPreviewFileType('pdf');
                setPdfFileName(file.name);
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Invalid Image Type',
                    text: 'Please upload a valid image file (JPEG, JPG, GIF, PNG, WEBP).',
                });
                e.target.value = '';  // Clear the invalid file
                return;
            }
        }
        validateForm();
    };

    return (
        <>
            <div className="page-header d-flex align-items-center justify-content-between">
                <div className="page-header-left">
                    <Breadcrums data={breadcrumbs} />
                    <h4>Purchase order</h4>
                </div>
                {/* {
                    showDefault && showDefault.order_status == false ?
                        <Button variant="primary" onClick={() => setShowModal(true)}>
                            Mark as Received
                        </Button> :
                        <Button variant="secondary">
                            Order Received
                        </Button>
                } */}
                {
                    showDefault && showDefault.order_status === false && showDefault?.status === false ?
                        null :
                        showDefault && showDefault.order_status === false ?
                            <Button variant="primary" onClick={() => setShowModal(true)}>
                                Mark as Received
                            </Button> :
                            <Button variant="secondary">
                                Order Received
                            </Button>
                }

            </div>

            <div className="row row-sm">
                <div className="col-lg-12 col-md-12 animation_fade">
                    <div className="card custom-card">
                        <div className="card-body p-0   ">
                            <div className="d-flex justify-content-between p-3" style={{ background: '#F2F5F9' }}>
                                <div>
                                    <div style={{ display: "flex" }}>
                                        <h5 style={{ fontSize: '1.5em', width: '200px', }}>{capitalizeFirstLetter(showDefault?.supplier?.name)}</h5>
                                        {showDefault?.status === true && showDefault?.order_status == false ?
                                            <a variant="secondary" className="ms-3 cp" onClick={() => sendEmail(showDefault)}>
                                                Send Email to Supplier
                                            </a>
                                            : null}
                                    </div>
                                    <p>Email: <a href={`mailto:${showDefault?.supplier?.email}`}>{showDefault?.supplier?.email}</a></p>
                                    <p>Phone: {showDefault?.supplier?.phone_number}</p>
                                    <p>Address: {showDefault?.supplier?.street_address}, {showDefault?.supplier?.city}</p>
                                </div>
                                {/* <div className="d-flex align-items-start"></div> */}
                                {/* <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '20px' }}>   */}
                                <div className='d-flex flex-column gap-2 w-25'>
                                    <div className='d-flex justify-content-between'>
                                        <div>Outlet</div>
                                        <div>{showDefault?.outlet?.outlet_name}</div>
                                    </div>
                                    <div className='d-flex justify-content-between'>
                                        <div>Delivery Date</div>
                                        <div>{showDefault?.delivery_date}</div>
                                    </div>
                                    <div className='d-flex justify-content-between'>
                                        <div>Order ID</div>
                                        <div>#Purchase id {showDefault?.id}</div>
                                    </div>
                                    {showDefault?.invoice_number ?
                                        <div className='d-flex justify-content-between'>
                                            <div>Invoice Number</div>
                                            <div>{showDefault?.invoice_number}</div>
                                        </div>
                                        : null}
                                    {showDefault?.invoice_logo ?
                                        <div className='d-flex justify-content-between'>
                                            <div>Purchase Invoice</div>
                                            <div className='ml-auto'>
                                                {showDefault && showDefault?.invoice_logo ? (
                                                    renderFileContent(showDefault?.invoice_logo)
                                                ) : null}
                                            </div>
                                        </div>
                                        : null}
                                </div>
                            </div>
                            {showDefault && showDefault?.purchase_orders_inventories && Object.keys(showDefault?.purchase_orders_inventories).length > 0 ? (
                                <div className='row'>
                                    <div className="col-lg-12 form-group">
                                        <table id="simple-table" className="table table-custom">
                                            <thead>
                                                <tr>
                                                    <th>Item Name</th>
                                                    <th>Ordered quantity</th>
                                                    <th>Recieved quantity</th>
                                                    <th>Unit of measure</th>
                                                    <th>Price</th>
                                                    <th>Total price</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {showDefault?.purchase_orders_inventories?.map((item, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>{item?.inventory?.item_name}</td>
                                                            <td>{item?.ordered_quantity}</td>
                                                            <td>{item?.recieved_quantity}</td>
                                                            <td>{item?.inventory?.uom?.uom} ({item?.inventory?.uom?.uom_code})</td>
                                                            <td>{item?.price} SAR</td>
                                                            <td>{showDefault ?
                                                                showDefault.order_status == true ?
                                                                    `${item?.recieved_quantity * item?.price} SAR` :
                                                                    `${item?.ordered_quantity * item?.price} SAR`
                                                                : "N/A"}</td>
                                                        </tr>
                                                    );
                                                })}
                                                <tr>
                                                    <td colSpan="5" className="text-end" style={{ borderBottom: 'none', textDecoration: 'none' }}><strong>Sub-Total:</strong></td>
                                                    <td colSpan="1" style={{ borderBottom: 'none', textDecoration: 'none' }}>{totalPrice} SAR</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div className="p-3">
                                            <button className="btn ripple btn-secondary " type='button' onClick={() => navigate(-1)}>
                                                <i className="ace-icon-solid ion-arrow-return-left bigger-110 mx-1"></i>
                                                Back
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                // <p style={{ textAlign: 'center' }}>No data found</p>
                                <div className='text-center m-3'>
                                    <img src={notFound} style={{ width: "5%" }} alt="Not Found" />
                                    <p className='mt-3'>No record found</p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={showModal} onHide={handleCloseModal} backdrop={'static'} >
                <Modal.Header closeButton>
                    <Modal.Title style={{ fontSize: '1.5em' }}>Mark as Received</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ padding: '0' }}>
                    <table className="table table-custom">
                        <thead>
                            <tr>
                                <th>Item</th>
                                <th>Unit Of Measure</th>
                                <th>Inventory Stock</th>
                            </tr>
                        </thead>
                        <tbody>
                            {showDefault?.purchase_orders_inventories?.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{capitalizeFirstLetter(item?.inventory?.item_name) || "N/A"}</td>
                                        <td>{capitalizeFirstLetter(item?.inventory?.uom?.uom) || "N/A"}</td>
                                        <td>
                                            <input
                                                className='form-control'
                                                type="number"
                                                value={quantities[item.inventory_id]}
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    if (value === '' || (parseInt(value, 10) > 0 && parseInt(value, 10) <= 100000)) {
                                                        handleQuantityChange(item.inventory_id, value);
                                                    }
                                                }}
                                                onInvalid={(e) => {
                                                    if (!e.target.value) {
                                                        e.target.setCustomValidity('Please enter a value.');
                                                    }
                                                }}
                                                onInput={(e) => {
                                                    e.target.setCustomValidity('');
                                                }}
                                                required
                                            />
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                    <div className='p-4'>
                        <div className="row">
                            <div className="col-lg-12 col-md-12">
                                {/* <div className="col-lg-12 col-md-12"> */}
                                <div className="form-group">
                                    <label htmlFor="invoice_number" className="">
                                        {/* <label htmlFor="invoice_number" className="text-center d-flex ms-4"> */}
                                        Invoice Number <span className="requirestar">*</span>
                                    </label>
                                    <input
                                        name="invoice_number"
                                        id="invoice_number"
                                        // placeholder="Enter invoice number"
                                        type="text"
                                        // className="form-control mb-2"
                                        className='form-control'
                                        required
                                        pattern="^[1-9][0-9]*$"
                                        onInput={(e) => {
                                            const value = e.target.value;
                                            const isValid = /^[1-9][0-9]*$/.test(value);
                                            if (!isValid) {
                                                e.target.setCustomValidity('Invoice number must be a positive integer and cannot contain decimals or be negative.');
                                            }
                                            else if (value.length > 15) {
                                                e.target.setCustomValidity('Invoice number cannot exceed 15 digits.');
                                            } else {
                                                e.target.setCustomValidity('');
                                            }
                                            validateForm();
                                        }}
                                        onInvalid={(e) => {
                                            if (!e.target.value) {
                                                e.target.setCustomValidity('Please enter a valid invoice number.');
                                            }
                                        }}
                                        style={{
                                            // padding: '10px 12px',
                                            // borderRadius: '4px',
                                            // borderColor: '#ccc',

                                        }}
                                    />
                                </div>
                                <span className='text-danger d-flex text-left'>{invoiceError && "Invoice number should positive whole number and cannot exceed 15 digits."}</span>
                            </div>
                            <div className="col-lg-12 col-md-12 mt-3">
                                <div className="form-group">
                                    <label htmlFor="logo" className="text-center d-flex">
                                        Upload Invoice <span className="requirestar">*</span>
                                    </label>
                                    <div className="d-flex align-items-center gap-3">
                                        <div className="btn btn-black btn-file-type mb-2">
                                            Upload Invoice
                                            <input
                                                id="logo"
                                                name="logo"
                                                accept="image/*, application/pdf"
                                                type="file"
                                                onChange={handleFileUpload}
                                                style={{
                                                    padding: '5px',
                                                    borderRadius: '4px',
                                                    borderColor: '#ccc',
                                                }}
                                            />
                                        </div>
                                        {previewImage ? (
                                            <div className="avatar avatar-xl mb-2 ms-2">
                                                <img alt="image preview" src={previewImage} className="img-fluid" />
                                            </div>
                                        ) : (previewFileType === 'pdf' && pdfFileName) ? (
                                            <div className="d-flex align-items-center gap-3 ms-2">
                                                <span>{TrimText(pdfFileName, 30)}</span>
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className='btn btn-outline-primary' onClick={handleCloseModal}>
                        Close
                    </button>
                    <Button
                        variant="primary"
                        onClick={handleConfirm}
                        disabled={!isFormValid}
                    >
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default OrderView;
