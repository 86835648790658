import React, { useState } from 'react'
import LoginLayout from '../../companyloginlayout'
import { useNavigate, useParams, Link } from 'react-router-dom'
import { Formik } from 'formik';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Getforgetpassword } from '../../services/company_panel/LoginCompanyServices';
import Logo from '../../assets/company/img/logo.png';
import loginBg from '../../assets/company/img/login3.jpeg';


const CompanyPasswordForgot = () => {
    const navigate = useNavigate()
    const [loader, setLoader] = useState('false')
    const { tab } = useParams()

    return (
        <LoginLayout>
            <Formik
                initialValues={{ email: '', company_id: '' }}
                validate={values => {
                    const errors = {};
                    if (!values.email) {
                        errors.email = 'Email address is required';
                    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                        errors.email = 'Invalid email address';
                    }
                    if (!values.company_id) {
                        errors.company_id = 'Company ID is required';
                    } else if (!/^\d{6}$/.test(values.company_id)) {
                        errors.company_id = 'Company ID must be  6 digits';
                    } else if (!/^\d+$/.test(values.company_id)) {
                        errors.company_id = 'Please enter a valid company ID';
                    }
                    return errors;
                }}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                    setLoader(true)
                    let formData = new FormData();
                    formData.append("email", values.email);
                    formData.append("company_id", values.company_id);
                    Getforgetpassword(formData).then(data => {
                        if (data) {
                            if (data && data.data && data.data.statuscode === 200) {
                                toast(data.data.message);
                                resetForm()
                                setTimeout(() => {
                                    setLoader(false)
                                    navigate(`/company/forgot/otpverification/${values.email}?type=forget_password&company_id=${values.company_id}`)
                                }, 2000);
                            } else if (data && data.data && data.data.statuscode !== 200) {
                                toast(data.data.message);
                                setTimeout(() => {
                                    setLoader(false)
                                }, 2000);
                            }
                        } else {
                            setLoader(false);
                        }
                    }).catch(error => {
                        console.log("error: ", error);
                    })

                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                }) => (
                    <div className="page">
                        <div className="login-wraper">
                            <div className='login-wraper-in'>
                                <div className='login-left'>
                                    <figure>
                                        <img src={loginBg} />
                                    </figure>
                                    <div className='login-text'>
                                        <h4>Efficiency at your fingertips!  </h4>
                                        <p>With POS Restaurant Admin, managing your  restaurant has never been easier. From orders to inventory, we've got  you covered.</p>
                                    </div>
                                </div>
                                <div className='login-right'>
                                    <div className='login-right-in'>
                                        <div className="login-head">
                                            <Link to="/"> <img src={Logo} /> </Link>
                                            <div className="login-head-text">
                                                <h2>Forgot Password</h2>
                                                <p>Enter the registered email</p>
                                            </div>
                                        </div>
                                        <div className='login-body'>
                                            <div className='form-box'>
                                                <form onSubmit={handleSubmit}>
                                                    <div className="form-group text-left">
                                                        <label>Company ID</label>
                                                        <input className={"form-control"}
                                                            placeholder="Enter your company ID"
                                                            type="text"
                                                            name="company_id"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.company_id}
                                                            autoComplete="off" />
                                                        <span className='text-danger text-left d-blockerr-spn'>{errors.company_id && touched.company_id && errors.company_id}</span>
                                                    </div>
                                                    <div className="form-group text-left">
                                                        <label>Email Address</label>
                                                        <input className={"form-control"}
                                                            placeholder="Enter your email"
                                                            type="email"
                                                            name="email"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.email}
                                                            autoComplete="off" />
                                                        <span className='text-danger text-left d-blockerr-spn'>{errors.email && touched.email && errors.email}</span>
                                                    </div>
                                                    <button className={"btn ripple btn-primary signbtn w-100 position-relative mt-4" + (loader === true ? " disabled" : "")} type="submit">Submit
                                                        {loader === true ?
                                                            <div className="spinner-border text-white mt-2" role="status">
                                                                <span className="sr-only">Loading...</span>
                                                            </div> : ""}
                                                    </button>
                                                    <div className="col-6 mt-2">
                                                        <Link to="/company/login">Back to login</Link>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </Formik>
            <ToastContainer
                position="top-right"
                hideProgressBar={false}
                autoClose={true | 1000}
                newestOnTop={true}
                closeOnClick={false}
                draggable={false}
                rtl={false}
            />
        </LoginLayout>
    )
}

export default CompanyPasswordForgot