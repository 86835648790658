import axiosInstance from "../../utils/axiosCompany";

export const ListSalesReport = async (values) => {
    return await axiosInstance().post("company/report/sales-report-list", values);
}
export const ListCustomerReport = async (values) => {
    return await axiosInstance().post("company/report/customer-report-list", values);
}
export const ListPrimaryCategoryReport = async (values) => {
    return await axiosInstance().post("company/report/primary-category-report-list", values);
}
export const ListInventoryReport = async (values) => {
    return await axiosInstance().post("company/report/inventory-report-list", values);
}
export const StockMovementReport = async (values) => {
    return await axiosInstance().post("company/report/stock-movement-report-list", values);
}
export const ListItemCostCalculationReport = async (values) => {
    return await axiosInstance().post("company/report/item-cost-calculation-report-list", values);
}

