import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { json, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
// import Modal from 'react-bootstrap/Modal';
// import Button from 'react-bootstrap/Button';
import { globalLoader } from '../../helpers/global';
import Swal from 'sweetalert2';
import { blockInvalidChar, capitalizeAllFirstLetters, companyDetail, getBrandId, getCompany, getOutletId, showPrice } from '../../helpers/commonFunctions';
import { AddCoupon } from '../../services/company_panel/coupons.service';
import { CreateOrders, GetSingleOrderDetails, RecallOrder, checkoutOrder } from '../../services/company_panel/orderService';
import ReactCountryFlag from 'react-country-flag';
import { countries } from 'countries-list';
import * as yup from 'yup';
import Breadcrums from '../../components/company_panel/common/breadcrumbs';
import { Modal, Button, Input, SelectPicker, InputGroup } from 'rsuite';

export const ContinueOrderAdd = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [orderData, setOrderData] = useState(location.state);
    const [showModal, setShowModal] = useState(false);
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);
    const [selectedCountryCode, setSelectedCountryCode] = useState([]);
    const [searchParams] = useSearchParams();
    const [orderDetails, setOrderDetails] = useState({});
    const [skippedClicked, setSkippedClicked] = useState(false);

    const validationSchema = yup.object().shape({
        customerName: yup
            .string()
            .trim()
            .required('Name is required')
            .max(30, 'Name cannot be longer than 30 characters'),

        customerPhone: yup
            .string()
            .trim()
            .required('Phone number is required')
            .matches(/^\d{10}$/, 'Phone number must be exactly 10 digits')
    });

    const formik = useFormik({
        initialValues: {
            orderItems: [],
            subTotal: 0,
            taxes: 0,
            discount: 0,
            surcharge: 0,
            total: 0,
            paymentMethod: '',
            customerName: '',
            customerPhone: '',
            countryCode: [Number(companyDetail().country_code)],
            orderModeId: '',
            customerInstructions: '',
            meal_combos: [],
            country_code: '',
            coupon_id: null,
            new_coupon_applied : null,
            coupon_type : '',
            coupon_code : '',
            coupon_customer_number : '',
            ordered_items : [],
            coupon_approval_request_id : '',
            coupon_authentication_otp : '',
            customer_name: '',
            customer_number: ''
        },
        // validationSchema: validationSchema,
        validate: (values) => {
            const errors = {};
            // if (!(searchParams.get('orderId') || formik.values.recallOrderId)) {
            //     if (!values.customerName.trim()) {
            //         errors.customerName = "Customer name is required";
            //     } else if (values.customerName.length > 30) {
            //         errors.customerName = "Customer name cannot be longer than 30 characters";
            //     }
            //     if (!values.customerPhone.trim()) {
            //         errors.customerPhone = "Customer number is required";
            //     } else if (!/^\d{10}$/.test(values.customerPhone)) {
            //         errors.customerPhone = "Customer number must be exactly 10 digits";
            //     }
            // }
            return errors;
        },
        onSubmit: (values) => {
            console.log("values---???", values)
            let menuItemsId = values.orderItems.map((value) => {
                let addOns = [];
                value?.menu_addons?.forEach(categoryAddOns => {
                    categoryAddOns?.addons?.forEach((item) => {
                        if(item?.quantity){
                            let taxAmount;
                            if (item.total_price == 0) {
                                taxAmount = 0;
                            } else {
                                taxAmount = Number(item.tax_amount) * (item.quantity * value.quantity);
                            }
                            
                            addOns.push({
                                id: item.id,
                                quantity: item.quantity * value.quantity,
                                menu_item_id: value.id,
                                price: item.total_price * value.quantity,
                                tax_amount: taxAmount
                            });
                        }
                    })
                })
                return {
                    id: value.id,
                    quantity: value.quantity,
                    price: value.price * value.quantity,
                    finalPrice : value.finalPrice * value.quantity,
                    tax_amount: value.tax_amount * value.quantity,
                    tax_type: value.tax_type,
                    type: value.type,
                    menu_addons_price : value.addons_total_price,
                    menu_addons_list: addOns
                }
            });

            let formData = new FormData();
            let subTotal;
            let taxes;

            if (formik.values.previousPrice) {
                subTotal = Number(values.subTotal) + Number(formik.values?.previousPricing?.subtotal);
                taxes = Number(values.taxes) + Number(formik.values?.previousPricing?.taxes);
            } else {
                subTotal = Number(values.subTotal);
                taxes = Number(values.taxes);
            }
            formData.append('sub_total_price', subTotal);
            formData.append('tax_total_price', taxes);
            formData.append('discount_price', values.discount);
            formData.append('surcharge_price', values.surcharge);
            formData.append('total_price', values.total);
            
            //filtering countryCode form the list and getting numeric value and appending it before countryCode( IN => 91)
            if (!(searchParams.get('orderId') || formik.values.recallOrderId)) {
                if(values.customerPhone){
                    formData.append('customer_name', values.customerName);
                    // let countryCode = selectedCountryCode.filter(code => code.value == values.countryCode);
                    // countryCode = countryCode[0].label.props.children[1].slice(3).replace(/,/g, ' ');
                    formData.append('customer_number', `+${values.countryCode} ${values.customerPhone}`);
                    formData.append('country_code', values.countryCode);
                }else{
                    formData.append('customer_name', 'Guest user');
                    formData.append('customer_number', '');
                    formData.append('country_code', '');
                }
            }
            formData.append('menu_items_id', JSON.stringify(menuItemsId));
            formData.append('order_mode_id', values.orderModeId);
            formData.append('coupon_id', values.coupon_id);
            formData.append('new_coupon_applied', values.new_coupon_applied);
            formData.append('coupon_type', values.coupon_type);
            formData.append('coupon_code', values.coupon_code);
            formData.append('coupon_applied_number', values.coupon_applied_number);
            formData.append('ordered_items', JSON.stringify(values.ordered_items));
            formData.append('coupon_approval_request_id', values.coupon_approval_request_id);
            formData.append('coupon_authentication_otp', values.coupon_authentication_otp);
            formData.append('customer_address', 'jaipur');
            formData.append('customer_instruction', values?.customerInstructions);
            formData.append('transaction_id', '12346');
            if (values.paymentMethod) {
                formData.append('payment_method', values.paymentMethod?.toLowerCase());
            }
            formData.append('company_id', getCompany().company_id);
            formData.append('brand_id', getBrandId());
            formData.append('outlet_id', getOutletId());

            const serviceDetails = {
                service_type: formik?.values.service_type,
                table_name: formik?.values.table_details ? formik?.values.table_details?.table_name : null,
                table_number: formik?.values.table_details ? formik?.values.table_details?.table_number : null,
            }
            formData.append('service_type', JSON.stringify(serviceDetails));

            if (formik?.values?.aggregator) {
                formData.append('aggregator_id', formik?.values.aggregator?.id);
                formData.append('aggregator_commission', formik?.values.commission);
            }

            if (!formik.values.recallOrderId) {
                CreateOrders(formData).then(response => {
                    if (response.data.status === 1 || response.data.status === "1") {
                        Swal.fire({
                            customClass: 'swal-wide',
                            icon: 'success',
                            title: response.data.message,
                            showConfirmButton: false,
                            timer: 1500,
                            toast: true,
                            position: 'top-right',
                        });
                        globalLoader(false);
                        setTimeout(() => {
                            navigate(`/company/transaction/order/list/${1}`)
                        }, 1000);
                    } else {
                        Swal.fire({
                            customClass: 'swal-wide',
                            icon: 'error',
                            title: response.data.message,
                            showConfirmButton: false,
                            timer: 5000,
                            toast: true,
                            position: 'top-right',
                        })
                        globalLoader(false);
                    }
                }).catch(error => {
                    console.log("error ====> ", error);
                })
            } else {
                formData.append('id', formik.values.recallOrderId)
                RecallOrder(formData).then(response => {
                    if (response.data.status === 1 || response.data.status === "1") {
                        Swal.fire({
                            customClass: 'swal-wide',
                            icon: 'success',
                            title: response.data.message,
                            showConfirmButton: false,
                            timer: 1500,
                            toast: true,
                            position: 'top-right',
                        });
                        globalLoader(false);
                        setTimeout(() => {
                            navigate(`/company/transaction/order/list/${1}`)
                        }, 1000);
                    } else {
                        Swal.fire({
                            customClass: 'swal-wide',
                            icon: 'error',
                            title: response.data.message,
                            showConfirmButton: false,
                            timer: 5000,
                            toast: true,
                            position: 'top-right',
                        })
                        globalLoader(false);
                    }
                }).catch(error => {
                    console.log("error ====> ", error);
                })
            }

        }
    });

    useEffect(() => {
        // Transform countries into the format required by SelectPicker
        const options = Object.keys(countries).map(countryCode => ({
            label: (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <ReactCountryFlag
                        countryCode={countryCode}
                        style={{ width: '1.5em', height: '1.5em', marginRight: '0.5em' }}
                        aria-label={countryCode}
                    />
                    {` + ${countries[countryCode].phone}`}
                </div>
            ),
            // value: countryCode
            value: countries[countryCode].phone
        }));
        setSelectedCountryCode(options);
    }, [countries]);

    //if we are creating order normally or recall-order case
    useEffect(() => {
        if (orderData) {
            console.log('-----------orderData', orderData);
            const items = orderData.items.map(item => ({
                id: item?.id,
                name: item?.item_name,
                tax_type: item?.tax_type,
                tax_amount: item?.tax_amount,
                // price: item?.tax_type === "inclusive" ? item?.finalPrice : item?.price,
                price: item?.price,
                finalPrice : item?.finalPrice,
                quantity: item?.quantity,
                image: item?.logo,
                type: item?.type,
                menu_addons: item?.menu_addons,
                selected_menu_addons: item?.selected_menu_addons,
                addons_total_price : item?.addons_total_price
            }));

            formik.setFieldValue('orderItems', items);
            formik.setFieldValue('subTotal', orderData?.pricing?.subtotal);
            formik.setFieldValue('taxes', orderData?.pricing?.taxes || 0);
            formik.setFieldValue('discount', orderData?.pricing?.discount || 0);
            formik.setFieldValue('surcharge', orderData?.pricing?.surcharge || 0);
            if (orderData?.aggregator) {
                formik.setFieldValue('aggregator', orderData?.aggregator);
                formik.setFieldValue('commission', orderData.pricing.commission);
            }
            formik.setFieldValue('service_type', orderData.service_type);
            if (orderData?.table) {
                formik.setFieldValue('table_details', { ...orderData.table });
            }
            if (orderData?.pricing?.previousPrice) {
                formik.setFieldValue('previousPrice', orderData?.pricing?.previousPrice);
            }
            if (orderData?.recallOrderId) {
                formik.setFieldValue('recallOrderId', orderData?.recallOrderId);
                formik.setFieldValue('previousItems', orderData?.previousItems);
                formik.setFieldValue('previousPricing', orderData?.previousPricing);
                formik.setFieldValue('customer_name', orderData?.customer_name);
                formik.setFieldValue('customer_number', orderData?.customer_number);
                formik.setFieldValue('country_code', orderData?.country_code);
            }
            formik.setFieldValue('total', orderData.pricing?.total);
            formik.setFieldValue('orderModeId', orderData.order_id);
            formik.setFieldValue('coupon_id', orderData.coupon_id);
            formik.setFieldValue('new_coupon_applied', orderData.new_coupon_applied);
            formik.setFieldValue('coupon_type', orderData.coupon_type);
            formik.setFieldValue('coupon_code', orderData?.coupon_code);
            formik.setFieldValue('coupon_applied_number', orderData?.coupon_applied_number);
            formik.setFieldValue('ordered_items', orderData?.ordered_items);
            formik.setFieldValue('coupon_approval_request_id', orderData?.coupon_approval_request_id);
            formik.setFieldValue('coupon_authentication_otp', orderData?.otp);
            formik.setFieldValue('customerInstructions', orderData.customer_note);
        }
    }, [orderData, navigate]);

    //if we are checkoutOrder order
    useEffect(() => {
        if (searchParams.get('orderId')) {
            GetSingleOrderDetails({ id: searchParams.get('orderId') }).then(data => {
                setOrderDetails(data && data?.data && data?.data?.response ? data?.data?.response : [])
                const order = data && data?.data && data?.data?.response ? data?.data?.response : {};

                const items = order.customer_order_menu_item_relations.map(item => ({
                    id: item?.id,
                    name: item?.menu_item?.item_name,
                    tax_type: item?.tax_type,
                    tax_amount: item?.tax_amount,
                    price: item?.price,
                    quantity: item?.quantity,
                    image: item?.menu_item?.logo,
                    // type: item?.type,
                }));

                const combos = order.customer_order_meal_combo_relations.map(item => ({
                    id: item?.id,
                    name: item?.menu_meal_combo?.name,
                    tax_type: item?.tax_type,
                    tax_amount: item?.tax_amount,
                    price: item?.price,
                    quantity: item?.quantity,
                    image: item?.menu_meal_combo?.logo,
                    // type: item?.type,
                }));
                formik.setFieldValue('orderItems', [...items, ...combos]);
                formik.setFieldValue('subTotal', Number(order?.sub_total_price));
                formik.setFieldValue('taxes', Number(order?.tax_total_price));
                formik.setFieldValue('discount', Number(order?.discount_price));
                formik.setFieldValue('surcharge', Number(order?.surcharge_price));
                formik.setFieldValue('customer_name', order?.customer_name);
                formik.setFieldValue('customer_number', order?.customer.customer_number);
                formik.setFieldValue('country_code', order?.customer?.country_code);
                if (order?.aggregator_commission) {
                    formik.setFieldValue('aggregator', order?.aggregators_configuration);
                    formik.setFieldValue('commission', Number(order?.aggregator_commission));
                }
                formik.setFieldValue('service_type', orderData?.service_type?.service_type);
                if (order?.service_type?.service_type == 'table_service') {
                    formik.setFieldValue(
                        'table_details',
                        { table_name: order?.service_type?.table_name, table_number: order?.service_type?.table_number }
                    );
                }

                formik.setFieldValue('total', Number(order?.total_price));
                formik.setFieldValue('coupon_id', order?.coupon_id);
            }).catch(error => {
                console.log("error=====>", error)
            })
        }
    }, []);

    const handleCheckoutOrder = (paymentMethod) => {
        checkoutOrder({ payment_method: paymentMethod.toLowerCase(), id: searchParams.get('orderId') }).then(response => {
            if (response.data.status === 1 || response.data.status === "1") {
                Swal.fire({
                    customClass: 'swal-wide',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 1500,
                    toast: true,
                    position: 'top-right',
                });
                globalLoader(false);
                setTimeout(() => {
                    navigate(`/company/transaction/order/list/${1}`)
                }, 1000);
            } else {
                Swal.fire({
                    customClass: 'swal-wide',
                    icon: 'error',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000,
                    toast: true,
                    position: 'top-right',
                })
                globalLoader(false);
            }
        }).catch(error => {
            console.log("error=====>", error)
        })
    }

    const handlePaymentMethodClick = (method) => {
        formik.setFieldValue('paymentMethod', method);
        if (!(searchParams.get('orderId') || formik.values.recallOrderId)) {
            if(!formik?.values?.aggregator){
                setShowModal(true);
            }else{
                formik.handleSubmit();         
            }
        } else {
            if (formik.values.recallOrderId) {
                formik.handleSubmit();
            } else {
                handleCheckoutOrder(method);
            }
        }
    };

    const skipPayment = () => {
        formik.setFieldValue('paymentMethod', null);
        if (!formik.values.recallOrderId) {
            if(!formik?.values?.aggregator){
                setShowModal(true);
            }else{
                formik.handleSubmit();         
            }
        } else {
            formik.handleSubmit();
        }
    }

    const handleNextClick = () => {
        setShowModal(false);
        formik.handleSubmit(); 
    };

    const breadcrumbs = [
        {
            title: "Orders ",
            url: "/company/transaction/order/list/1"
        },
        {
            title: "Create Order",
            url: ""
        },
    ]

    console.log("orderData--->", orderData);
    console.log("formik values--->", formik.values);


    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="page-header d-flex align-items-center">
                <div className="page-header-left">
                    <Breadcrums data={breadcrumbs} />
                    <h4>Orders</h4>
                    {/* <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <a href="/company/transaction/order/add">Loyalty Points</a>
                            </li>
                            <li className="breadcrumb-item">
                                <a className="active disableclick" href="/company/transaction/order/add">Create Order</a>
                            </li>
                        </ol>
                    </nav> */}
                </div>
                <div className="page-header-right ms-auto"></div>
            </div>

            <div className='card'>
                <div className='card-body'>
                    <div className='row'>
                        <div className='col-md-5'>
                            <div className='card'>
                                <div className='card-body'>
                                    {formik?.values?.aggregator ? <h4 style={{ color: '#2f9bff' }}>Aggregator : {capitalizeAllFirstLetters(formik?.values?.aggregator?.name)}</h4> : null}
                                    <div className='d-flex justify-content-between mb-2'>
                                        {

                                            formik?.values?.table_details ?
                                                <div>
                                                    <h4>Table name : {formik?.values?.table_details?.table_name}</h4>
                                                    <h4>Table number : {formik?.values?.table_details?.table_number}</h4>
                                                </div> :
                                                null
                                        }
                                        {
                                            formik?.values?.customer_name ?
                                                <div>
                                                    <h4>Customer name : {formik?.values?.customer_name}</h4>
                                                    {/* <h4>Customer number :{formik?.values?.country_code ? ` +${countries[formik?.values?.country_code].phone[0]} ` : null} {formik?.values?.customer_number}</h4> */}
                                                    <h4>Customer number : {formik?.values?.customer_number}</h4>
                                                </div> :
                                                null
                                        }
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <span>Order Mode</span>
                                        <span>
                                            {orderData && orderData.order_mode ? orderData?.order_mode?.name : orderDetails?.order_mode_configuration?.name}
                                        </span>
                                    </div>

                                    {/* Previous Items List */}
                                    {
                                        formik?.values?.recallOrderId ?
                                            <>
                                                <h4 className='mt-3'>Previous items</h4>
                                                {
                                                    formik.values.previousItems.map((item, index) => (
                                                        <>
                                                            <div key={item.id} className="order-item mb-3 d-flex align-items-center justify-content-between">
                                                                <div className="d-flex align-items-center">
                                                                    <img src={item.logo} alt={item.name} className="order-item-img me-3" />
                                                                    <div>
                                                                        <div className="order-item-name" style={{ fontWeight: '500' }}>{item.name}</div>
                                                                        <div className="order-item-price">{item.price} SAR</div>
                                                                        <span className="order-item-price" style={{ fontSize: '0.8rem' }}>
                                                                            {`Tax: ${showPrice(item.tax_amount)} ${item.tax_type ? `SAR (${item.tax_type})` : ''}`}
                                                                        </span>
                                                                        <span>
                                                                        {
                                                                            item?.menuAddOns.length > 0 ?
                                                                            <div>
                                                                                <span style={{ color: '#0d6efd' }}>Modifiers</span> : &nbsp;
                                                                                {
                                                                                    item?.menuAddOns?.map((addon, index) => {
                                                                                        return <span>{`${capitalizeAllFirstLetters(addon.menu_add_on.name)} (${addon.quantity} pcs) 
                                                                                        ${index == item?.menuAddOns?.length - 1 ? '' : ','}`} </span>
                                                                                    })
                                                                                } : <b>{showPrice(Number(item.menu_addons_price))} SAR</b>
                                                                            </div>  : null
                                                                        }
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <span className="order-item-quantity" style={{ fontWeight: '500' }}>x{item.quantity}</span>
                                                            </div>
                                                            {/* <div className='d-flex'>
                                                               
                                                            </div> */}
                                                        </>
                                                    ))
                                                }

                                                {/* Previous Order Summary Details */}
                                                {
                                                    formik.values.previousPrice ?
                                                        <div className="order-summary-details mt-4">
                                                            <div className="d-flex justify-content-between">
                                                                <span>Sub-Total</span>
                                                                <span>{showPrice(formik.values?.previousPricing?.subtotal)} SAR</span>
                                                            </div>
                                                            <div className="d-flex justify-content-between">
                                                                <span>Taxes</span>
                                                                <span>{showPrice(formik.values?.previousPricing?.taxes)} SAR</span>
                                                            </div>


                                                            <div className="d-flex justify-content-between fw-bold mt-1">
                                                                <span>Total</span>
                                                                <span>{showPrice(formik.values?.previousPricing?.total)} SAR</span>
                                                            </div>
                                                        </div> : null
                                                }
                                            </> : null
                                    }

                                    <h4 className='mb-4 mt-4'>Order Summary</h4>
                                    {/* Order Items List */}
                                    {formik.values.orderItems.map((item, index) => {
                                        return <>
                                            <div key={item.id} className="order-item mb-3 d-flex align-items-center justify-content-between">
                                                <div className="d-flex align-items-center">
                                                    <img src={item.image} alt={item.name} className="order-item-img me-3" />
                                                    <div>
                                                        <div className="order-item-name" style={{ fontWeight: '500' }}>{item.name}</div>
                                                        <div>{showPrice(item.price * item?.quantity)} SAR</div>
                                                        <span className="order-item-price" style={{ fontSize: '0.8rem' }}>
                                                            {item?.tax_amount ? `Tax: ${showPrice(item.tax_amount)} ${item.tax_type ? `SAR (${item.tax_type})` : ''}` : ''}
                                                        </span>
                                                        {
                                                            item?.type=='menu_item' && item?.selected_menu_addons?.length ?
                                                                <div><span style={{ color: '#0d6efd' }}>Modifiers</span> : &nbsp;
                                                                    {
                                                                        item?.selected_menu_addons?.map((addon, index) => {
                                                                            return <span>{`${capitalizeAllFirstLetters(addon.name)} (${addon.quantity} pcs) 
                                                                        ${index == item?.selected_menu_addons?.length - 1 ? '' : ','}`} </span>
                                                                        })
                                                                    }
                                                                    : 
                                                                    <b>{` ${showPrice(Number(item.addons_total_price))} SAR` }</b>
                                                                    
                                                                </div> : null
                                                        }
                                                    </div>
                                                </div>
                                                <span className="order-item-quantity" style={{ fontWeight: '500' }}>x{item.quantity}</span>
                                            </div>

                                        </>
                                    })}

                                    {/* Order Summary Details */}
                                    <div className="order-summary-details mt-4">
                                        {
                                            formik.values.previousPrice ?
                                                <div className="d-flex justify-content-between">
                                                    <span>Previous price</span>
                                                    <span>{showPrice(formik.values.previousPrice)} SAR</span>
                                                </div> : null
                                        }
                                        <div className="d-flex justify-content-between">
                                            <span>Sub-Total</span>
                                            <span>{showPrice(formik.values.subTotal)} SAR</span>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <span>Taxes</span>
                                            <span>{showPrice(formik.values.taxes)} SAR</span>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <span>Discount</span>
                                            <span>{showPrice(formik.values.discount)} SAR</span>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <span>Surcharge</span>
                                            <span>{showPrice(formik.values.surcharge)} SAR</span>
                                        </div>

                                        {/* {
                                            formik.values.commission ?
                                            <div className='d-flex align-items-center justify-content-between'>
                                                <span>Agreegator commission</span>
                                                <span>{ showPrice(formik.values.commission) } SAR</span>
                                            </div> : 
                                            null
                                        } */}

                                        <div className="d-flex justify-content-between fw-bold mt-3">
                                            <span>Total</span>
                                            <span>{showPrice(formik?.values?.total)} SAR</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-md-7'>
                            <div className='card'>
                                <div className='card-body'>
                                    {
                                        // orderData && orderData.order_mode.slug == 'Online' ?
                                        // <>
                                        //      <button type="button" className={`btn btn-outline-primary`} onClick={() => handlePaymentMethodClick(null)}>
                                        //         Create order
                                        //     </button>
                                        // </> :
                                        <>
                                            <h4>Payment Method</h4>
                                            <div className='d-flex justify-content-between'>
                                                <div className="payment-method d-flex gap-3">
                                                    {['Cash', 'Card', 'Credit', 'Online'].map((method, index) => (
                                                        <button
                                                            key={index}
                                                            type="button"
                                                            className={`btn btn-outline-primary ${formik.values.paymentMethod === method ? 'active' : ''}`}
                                                            onClick={() => handlePaymentMethodClick(method)}
                                                        >
                                                            {method}
                                                        </button>
                                                    ))}
                                                </div>
                                                <div>
                                                    {
                                                        !searchParams.get('orderId') ?
                                                            <button
                                                                type="button"
                                                                className={`btn btn-primary`}
                                                                onClick={() => skipPayment()}
                                                            >
                                                                Skip and continue
                                                            </button> : null
                                                    }
                                                </div>
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                        {/* <div className="d-flex justify-content-start mt-3">
                            <button type="submit" className="btn btn-primary">Place Order</button>
                        </div> */}
                    </div>
                </div>
            </div>
            {/* <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Customer Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group">
                        <label>Name<span className="requirestar">*</span></label>
                        <input
                            type="text"
                            className="form-control"
                            name="customerName"
                            placeholder="Enter customer number"
                            value={formik.values.customerName}
                            onChange={formik.handleChange}
                        />
                           <span className='text-danger d-flex text-left'>{formik.errors.customerName && formik.touched.customerName && formik.errors.customerName}</span>
                    </div>
                    <div className="form-group mt-3">
                        <label htmlFor='name' className='text-left d-flex'>Phone number<span className="requirestar">*</span></label>
                        <div className="input-group">
                            <span >
                                {console.log('-----selectedcountryCOde', selectedCountryCode)}
                                <SelectPicker
                                    style={{ border: 'none', paddingLeft: '0', paddingRight: '0', zIndex: 1 }}
                                    data={selectedCountryCode}
                                    name='country_code'
                                    className='form-control'
                                    id='country_code'
                                    onChange={(value) => {formik.setFieldValue("countryCode", value); console.log(value)}}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.countryCode}
                                    placeholder="Code"
                                />
                            </span>
                            <input
                                type="text"
                                name='customerPhone'
                                id="customerPhone"
                                className="form-control bordered"
                                style={{ border: '1px solid #e8e8e8' }}
                                placeholder="Enter phone number"
                                value={formik.values.customerPhone}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                onKeyDown={blockInvalidChar}
                            />
                        </div>
                        <span className='text-danger d-flex text-left'>{formik.errors.customerPhone && formik.touched.customerPhone && formik.errors.customerPhone}</span>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    // <button
                    //     className="btn btn-outline-primary"
                    //     onClick={() => {
                    //         setShowModal(false);
                    //         formik.setFieldValue('customerName', '');
                    //         formik.setFieldValue('customerPhone', '');
                    //         formik.setFieldValue('country_code', '');
                    //     }}
                    // >
                    //     Skip
                    // </button> 

                    <Button variant="primary" onClick={handleNextClick}  disabled={!(formik.isValid && formik.dirty)}>
                        Next
                    </Button>
                </Modal.Footer>
            </Modal> */}

            <Modal open={showModal} onClose={() => { setShowModal(false); formik.setFieldValue('customerName', ""); formik.setFieldValue('customerPhone', ""); }} size="sm" backdrop="static">
                <Modal.Header>
                    <Modal.Title>Add Customer Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group">
                        <label>Name<span className="requirestar">*</span></label>
                        {/* <Input
                            type="text"
                            name="customerName"
                            value={formik.values.customerName}
                            onChange={value => formik.setFieldValue('customerName', value)}
                            placeholder="Enter customer name"
                            style={{ height: '38px' }}
                        /> */}
                        <input
                            type="text"
                            name="customerName"
                            placeholder="Enter customer name"
                            value={formik.values.customerName}
                            onChange={event => formik.setFieldValue('customerName', event.target.value)}
                            onBlur={formik.handleBlur}
                            className="form-control bordered"
                            style={{ border: '1px solid #e8e8e8', height: '38px' }}
                        />
                        <span className="text-danger d-flex text-left">
                            {formik.errors.customerName && formik.touched.customerName && formik.errors.customerName}
                        </span>
                    </div>

                    {/* <div className="form-group mt-3">
                        <label htmlFor="name" className="text-left d-flex">
                            Phone number<span className="requirestar">*</span>
                        </label>
                        <InputGroup style={{ height: '38px' }}>
                            <InputGroup.Addon style={{ padding: '0', height: '100%' }}>
                                <SelectPicker
                                    data={selectedCountryCode}
                                    name="countryCode"
                                    value={formik.values.countryCode}
                                    onChange={value => formik.setFieldValue("countryCode", value)}
                                    onBlur={formik.handleBlur}
                                    placeholder="Code"
                                    style={{
                                        borderTopRightRadius: '0',
                                        borderBottomRightRadius: '0',
                                        height: '100%',
                                        zIndex: 1,
                                    }}
                                />
                            </InputGroup.Addon>

                            <Input
                                type="text"
                                name="customerPhone"
                                value={formik.values.customerPhone}
                                onChange={value => formik.setFieldValue('customerPhone', value)}
                                onBlur={formik.handleBlur}
                                onKeyDown={blockInvalidChar}
                                placeholder="Enter phone number"
                                style={{
                                    borderTopLeftRadius: '0',
                                    borderBottomLeftRadius: '0',
                                    border: '1px solid #e8e8e8',
                                    height: '100%',
                                }}
                            />
                        </InputGroup>
                        <span className="text-danger d-flex text-left">
                            {formik.errors.customerPhone && formik.touched.customerPhone && formik.errors.customerPhone}
                        </span>
                    </div> */}

                    <div className='form-group mt-3'>
                        <label htmlFor="name" className="text-left d-flex">
                            Phone number<span className="requirestar">*</span>
                        </label>
                        <div className="input-group">
                            <span>
                                <SelectPicker
                                    style={{ border: 'none', paddingLeft: '0', paddingRight: '0', zIndex: 1 }}
                                    data={selectedCountryCode}
                                    className='form-control'
                                    name="countryCode"
                                    value={formik.values.countryCode}
                                    onChange={value => formik.setFieldValue("countryCode", value)}
                                    onBlur={formik.handleBlur}
                                    placeholder="Code"
                                />
                            </span>
                            <input
                                type="text"
                                name="customerPhone"
                                placeholder="Enter phone number"
                                value={formik.values.customerPhone}
                                onChange={e => formik.setFieldValue('customerPhone', e.target.value)}
                                onBlur={formik.handleBlur}
                                className="form-control bordered"
                                style={{ border: '1px solid #e8e8e8' }}
                                onKeyDown={blockInvalidChar}
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className='btn btn-outline-primary' onClick={() => {formik.setFieldValue('customerName', ''); formik.setFieldValue('customerPhone', ''); formik.setFieldValue('countryCode', ''); handleNextClick(true) }}>Skip</Button>
                    <Button className="btn btn-primary" onClick={() => {handleNextClick(false); }} disabled={formik.values.customerName.length===0 || formik.values.customerPhone.length===0} > Next </Button>
                </Modal.Footer>
            </Modal>



            {/* Success Popup */}
            {
                showSuccessPopup && (
                    <Modal show={showSuccessPopup} onHide={() => setShowSuccessPopup(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Order Created Successfully</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>Order has been created successfully. You can view it in Order History</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" onClick={() => setShowSuccessPopup(false)}>
                                Ok
                            </Button>
                        </Modal.Footer>
                    </Modal>
                )
            }

        </form >
    );
};
