import React, { useState, useEffect, useMemo } from 'react'
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import Swal from "sweetalert2"
import { Dropdown } from 'semantic-ui-react';
import * as yup from 'yup';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Breadcrums from '../../components/company_panel/common/breadcrumbs';
import { DURATION } from '../../utils/Constants';
import { DetailsLoyaltyPoint, LoyaltyPointDetails, LoyaltyPointsAdd } from '../../services/company_panel/loyaltypoints.service';
import { getBrandId } from '../../helpers/commonFunctions';
import { Tooltip, Whisper } from "rsuite";

const AddLoyaltyPoints = () => {

    const breadcrumbs = [
        {
            title: "Loyalty Points ",
            url: "/company/crm/loyalty-points/list/1"
        },
        {
            title: "Create Loyalty Point",
            url: ''
        },
    ]
    const navigate = useNavigate();
    const [showDefault, setShowDefault] = useState({});
    const [isBrandChanged, setIsBrandChanged] = useState(false);

    useMemo(() => {
        document.addEventListener('brand-changed', function () {
            setIsBrandChanged(!isBrandChanged);
        });
    });

    useEffect(() => {
        DetailsLoyaltyPoint({ brand_id: getBrandId() }).then(data => {
            setShowDefault(data && data.data && data.data.response[0] ? data.data.response[0] : [])
        }).catch(error => {
            console.log("error=====>", error)
        })
    }, [isBrandChanged])

    // const validationSchema = yup.object().shape({
    //     loyalty_point: yup.string().trim().required('Loyalty point is required'),
    //     currency_value: yup.string().trim().required('Currency value  is required'),
    //     bill_value: yup.string().trim().required('This field is required'),
    //     duration: yup.string().trim().required('This field is required'),
    // });

    const validationSchema = yup.object().shape({
        loyalty_point: yup
            .string()
            .trim()
            .required('Loyalty point is required')
            .matches(/^\d{1,8}$/, 'Loyalty point must be a whole number and no more than 8 digits')
            .test('non-negative', 'Loyalty point cannot be negative', value => parseInt(value) >= 0),

        currency_value: yup
            .string()
            .trim()
            .required('Currency value is required')
            .matches(/^\d{1,8}$/, 'Currency value must be a whole number and no more than 8 digits')
            .test('non-negative', 'Currency value cannot be negative', value => parseInt(value) >= 0),

        bill_value: yup
            .string()
            .trim()
            .required('This field is required')
            .matches(/^\d{1,8}$/, 'Bill value must be a whole number and no more than 8 digits')
            .test('non-negative', 'Bill value cannot be negative', value => parseInt(value) >= 0),

        duration: yup.string().trim().required('This field is required'),
    });


    const formik = useFormik({
        initialValues: {
            loyalty_point: showDefault && showDefault.loyalty_point ? showDefault.loyalty_point : '',
            currency_value: showDefault && showDefault.currency_value ? showDefault.currency_value : '',
            bill_value: showDefault && showDefault.bill_value ? showDefault.bill_value : '',
            duration: showDefault && showDefault.duration ? showDefault.duration : '',
            calculate_for_offer_or_not: showDefault && showDefault.calculate_for_offer_or_not ? showDefault.calculate_for_offer_or_not : false,
        },
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: values => {
            let formData = new FormData();
            formData.append('loyalty_point', values.loyalty_point);
            formData.append('currency_value', values.currency_value);
            formData.append('bill_value', values.bill_value);
            formData.append('duration', values.duration);
            formData.append('calculate_for_offer_or_not', values.calculate_for_offer_or_not);
            formData.append('brand_id', getBrandId());
            LoyaltyPointsAdd(formData).then(response => {
                if (response.data.status === 1 || response.data.status === "1") {
                    Swal.fire({
                        customClass: 'swal-wide',
                        icon: 'success',
                        title: response.data.message,
                        showConfirmButton: false,
                        timer: 1500,
                        toast: true,
                        position: 'top-right',
                    });
                    //   setTimeout(() => {
                    //     navigate(`/company/loyalty-points/list/${1}`)
                    //   }, 1000);
                } else {
                    Swal.fire({
                        customClass: 'swal-wide',
                        icon: 'error',
                        title: response.data.message,
                        showConfirmButton: false,
                        timer: 5000,
                        toast: true,
                        position: 'top-right',
                    })
                }
            }).catch(error => {
                console.log("error ====> ", error);
            })
        }

    });

    return (
        <>

            <div className="page-header d-flex align-items-center">
                <div className="page-header-left">
                    <Breadcrums data={breadcrumbs} />
                    <h4>Loyalty Points</h4>
                </div>
                <div className="page-header-right ms-auto">

                </div>
            </div>
            <form onSubmit={formik.handleSubmit}>
                <div className="row row-sm">
                    <div className="card custom-card">
                        <div className="card-body">
                            <div className="row row-sm">
                                <div className='col-lg-6 text-center form-group mt-3'>
                                    <label htmlFor='loyalty_point' className='text-left d-flex'>Loyalty Point<span className="requirestar">*</span>
                                        <Whisper
                                            placement="top"
                                            controlId="control-id-hover"
                                            trigger="hover"
                                            speaker={
                                                <Tooltip>
                                                    Enter the number of loyalty points awarded for purchases. Loyalty points encourage repeat customers by offering rewards.
                                                </Tooltip>
                                            }
                                        >
                                            <span className="field-more-info mt-1 ms-1 cp">
                                                ?
                                            </span>
                                        </Whisper>
                                    </label>
                                    <input name='loyalty_point' id="loyalty_point" type='text' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.loyalty_point} placeholder='Enter loyalty point' className='form-control' />
                                    <span className='text-danger d-flex text-left'>{formik.errors.loyalty_point && formik.touched.loyalty_point && formik.errors.loyalty_point}</span>
                                </div>
                                <div className='col-lg-6 text-center form-group mt-3'>
                                    <label htmlFor='currency_value' className='text-left d-flex'>Currency value Per Point<span className="requirestar">*</span>
                                        <Whisper
                                            placement="top"
                                            controlId="control-id-hover"
                                            trigger="hover"
                                            speaker={
                                                <Tooltip>
                                                    Specify the monetary value of each loyalty point. This value determines the discount a customer can redeem based on their points.
                                                </Tooltip>
                                            }
                                        >
                                            <span className="field-more-info mt-1 ms-1 cp">
                                                ?
                                            </span>
                                        </Whisper>
                                    </label>
                                    <input name='currency_value' id="currency_value" type='number' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.currency_value} placeholder='Enter currency value per point' className='form-control' />
                                    <span className='text-danger d-flex text-left'>{formik.errors.currency_value && formik.touched.currency_value && formik.errors.currency_value}</span>
                                </div>
                                <div className='col-lg-6 text-center form-group mt-3'>
                                    <label htmlFor='bill_value' className='text-left d-flex'>Bill Value greater Than<span className="requirestar">*</span>
                                        <Whisper
                                            placement="top"
                                            controlId="control-id-hover"
                                            trigger="hover"
                                            speaker={
                                                <Tooltip>
                                                    Enter the minimum bill amount required to qualify for loyalty points. Only purchases above this value will earn points.
                                                </Tooltip>
                                            }
                                        >
                                            <span className="field-more-info mt-1 ms-1 cp">
                                                ?
                                            </span>
                                        </Whisper>
                                    </label>
                                    <input name='bill_value' id="bill_value" type='number' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.bill_value} placeholder='Enter bill value greater than' className='form-control' />
                                    <span className='text-danger d-flex text-left'>{formik.errors.bill_value && formik.touched.bill_value && formik.errors.bill_value}</span>
                                </div>
                                <div className='col-lg-6 text-center form-group mt-3'>
                                    <label htmlFor='role_id' className='text-left d-flex'>Duration Of The Expiry<span className="requirestar">*</span>
                                        <Whisper
                                            placement="top"
                                            controlId="control-id-hover"
                                            trigger="hover"
                                            speaker={
                                                <Tooltip>
                                                    Select how long loyalty points will remain valid. After this period, unused points will expire.
                                                </Tooltip>
                                            }
                                        >
                                            <span className="field-more-info mt-1 ms-1 cp">
                                                ?
                                            </span>
                                        </Whisper>
                                    </label>
                                    <select
                                        className="form-control"
                                        onChange={(e) => { formik.setFieldValue("duration", e?.target?.value) }}
                                        id="duration"
                                        value={formik.values.duration}
                                    >
                                        <option value="">Select</option>
                                        {DURATION.length > 0 &&
                                            DURATION.map((option, i) => {
                                                return (
                                                    <option key={i} value={option.key}>
                                                        {option.label}
                                                    </option>
                                                );
                                            })}
                                    </select>
                                    <span className='text-danger d-flex text-left'>{formik.errors.duration && formik.touched.duration && formik.errors.duration}</span>
                                </div>
                                <div className='col-lg-12 form-group'>
                                    <div className='d-flex mt-3'>
                                        <input name='calculate_for_offer_or_not' id="calculate_for_offer_or_not" type='checkbox' checked={formik.values.calculate_for_offer_or_not} onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.calculate_for_offer_or_not} />
                                        <label htmlFor='calculate_for_offer_or_not' className='text-left d-flex mb-0 ms-2 no-before no-after'>Calculate for offer or not
                                            <Whisper
                                                placement="top"
                                                controlId="control-id-hover"
                                                trigger="hover"
                                                speaker={
                                                    <Tooltip>
                                                        Enable this option if you want loyalty points to be calculated for promotional offers as well.
                                                    </Tooltip>
                                                }
                                            >
                                                <span className="field-more-info mt-1 ms-1 cp">
                                                    ?
                                                </span>
                                            </Whisper>
                                        </label>
                                    </div>
                                </div>
                                <div className="mt-2">
                                    <button className="btn btn-primary mr-2" type="submit" >
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <ToastContainer
                position="top-right"
                hideProgressBar={false}
                autoClose={true | 1000}
                newestOnTop={true}
                closeOnClick={false}
                draggable={false}
                rtl={false}
            />
        </>
    );
}

export default AddLoyaltyPoints