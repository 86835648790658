import React, { useState, useEffect, useRef } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ConfigurationReportTab from './configurationTab';
import Sorting from '../../components/company_panel/common/sorting';
import { capitalizeAllFirstLetters, capitalizeFirstLetter, formatToTwoDecimals, getCompany, showPrice } from '../../helpers/commonFunctions';
import CustomPagination from '../../components/company_panel/common/custompagination';
import Loader from '../../components/company_panel/common/loader';
import notFound from '../../../src/assets/company/img/not-found-img.png'
import CustomRangepicker from '../../components/company_panel/common/rangepicker';
import StatusFilter from '../../components/company_panel/common/statusFilter';
import { LOADER_TIME, showFilterlist } from '../../utils/Constants';
import { ListItemCostCalculationReport } from '../../services/company_panel/report.services';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';


const ItemCostList = () => {

    const breadcrumbs = [{ title: "Dashboard", url: "/company/dashboard" }, { title: "Pages", url: "" },]
    const [list, setList] = useState([])
    const [dataLength, setDataLength] = useState()
    const [itemPerPage, setItemPerPage] = useState(10)
    const [sorting, setSorting] = useState({});
    const [defaultSorting, setDefaultSorting] = useState(true);
    const [page, setPage] = useState(1);
    const [loader, setLoader] = useState(true)
    const [search, setSearch] = useState({})
    const [globalSearch, setGlobalSearch] = useState("")
    const [resetdate, setResetDate] = useState(false);

    useEffect(() => {
        if (search) {
            setLoader(true)
            const formData = new FormData()
            formData.append("page", page)
            formData.append("per_page", itemPerPage)
            formData.append("sort", JSON.stringify(sorting))
            formData.append("search", JSON.stringify(search))
            formData.append("global_search", globalSearch)
            formData.append('company_id', getCompany().id);
            ListItemCostCalculationReport(formData).then(data => {
                setDataLength(data.data.response.count);
                setList(data && data.data && data.data.response && data?.data?.response?.rows ? data?.data?.response?.rows : [])
                setTimeout(() => {
                    setLoader(false)
                }, LOADER_TIME);
            }).catch(error => {
                setTimeout(() => {
                    setLoader(false)
                }, LOADER_TIME);
                console.log("error ====> ", error);
            })
        }

    }, [sorting, search, globalSearch, page])

    const handleSort = (e, column) => {
        setDefaultSorting(false);
        let sort = { order: 0, column: column };
        if (e.target.classList.contains("assc")) {
            sort.order = -1;
        } else {
            sort.order = 1;
        }
        setSorting(sort);
    }

    const prepareSearch = (key, value) => {
        let sr = { ...search };
        if (String(value).length > 0) {
            sr[key] = value;
        } else {
            delete sr[key];
        }
        setSearch(sr);
    }

    const resetFilter = (e) => {
        e.preventDefault()
        setGlobalSearch("")
        prepareSearch()
        setSearch({})
        setResetDate(!resetdate)
        // $("#defaultstatus")[0].selectedIndex = 0
    }

    const headerMapping = {
        "item_id": "Item ID",
        "item_name": "Item name",
        "item_category": "Category",
        "item_quantity": "Quantity sold",
        "item_cost_price": "Cost price",
        "item_selling_price": "Selling price",
        "item_total_cost": "Total cost",
        "item_total_revenue": "Total sales",
        "item_total_profit": "Profit",
        "item_total_profit_margin": "Profit Margin",
    };

    const handleExport = () => {
        const formData = new FormData()
        formData.append("page", 1)
        formData.append("per_page", dataLength)
        formData.append("sort", JSON.stringify(sorting))
        formData.append("search", JSON.stringify(search))
        formData.append("global_search", globalSearch)
        formData.append('company_id', getCompany().company_id);
        ListItemCostCalculationReport(formData).then(data => {
            setDataLength(data.data.response.count);
            const listData = data && data.data && data.data.response ? data.data.response.rows : [];
            const printData = listData?.map((item) => {
                const transformedItem = {};
                // Extract fields from nested objects
                const itemID = item?.menu_item_id || 'N/A';
                const itemName = item?.item_name || 'N/A';
                const category = item?.category_name || 'N/A';
                const quantitySold = item?.item_quantity || 'N/A';
                const costPrice = item?.item_cost  || 'N/A';
                const sellingPrice = item?.item_default_selling_price || 'N/A';
                const totalCost = item?.item_total_cost || 'N/A';
                const totalSales = item?.item_total_revenue || 'N/A';
                const profit = item?.item_total_profit || 'N/A';
                const profitMargin = item?.item_total_profit_margin || 'N/A';

                // Map fields according to headerMapping
                for (const key in headerMapping) {
                    if (headerMapping.hasOwnProperty(key)) {
                        const newKey = headerMapping[key];
                        switch (key) {
                            case "item_id":
                                transformedItem[newKey] = '#' + (itemID);
                                break;
                            case "item_name":
                                transformedItem[newKey] = capitalizeFirstLetter(itemName);
                                break;
                            case "item_category":
                                transformedItem[newKey] = capitalizeFirstLetter(category);
                                break;
                            case "item_quantity":
                                transformedItem[newKey] = (quantitySold);
                                break;
                            case "item_cost_price":
                                transformedItem[newKey] = `${showPrice(costPrice)} SAR`;
                                break;
                            case "item_selling_price":
                                transformedItem[newKey] = `${showPrice(sellingPrice)} SAR`;
                                break;
                            case "item_total_cost":
                                transformedItem[newKey] = `${showPrice(totalCost)} SAR`;
                                break;
                            case "item_total_revenue":
                                transformedItem[newKey] = `${showPrice(totalSales)} SAR`;
                                break;
                            case "item_total_profit":
                                transformedItem[newKey] = `${showPrice(profit)} SAR`;
                                break;
                            case "item_total_profit_margin":
                                transformedItem[newKey] = `${formatToTwoDecimals(profitMargin)} %`;
                                break;
                            default:
                                // Handle any unexpected keys
                                console.warn(`Unexpected key in headerMapping: ${key}`);
                                transformedItem[newKey] = 'N/A';
                                break;
                        }
                    }
                }
                return transformedItem;
            });

            const ws = XLSX.utils.json_to_sheet(printData, { header: Object.values(headerMapping) });
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
            saveAs(blob, 'Item-Cost-Report.xlsx');
            setTimeout(() => {
                setLoader(false);
            }, LOADER_TIME);
        }).catch(error => {
            setTimeout(() => {
                setLoader(false);
            }, LOADER_TIME);
            console.log("error ====> ", error);
        })
    };

    return (
        <>
            <div className="animation_fade">
                <div className='page-header d-flex align-items-center'>
                    <div className='page-header-left'>
                        <h4>Report</h4>
                    </div>
                    <div className='page-header-right ms-auto'>
                        <button onClick={handleExport} disabled={dataLength === 0} className="btn btn-primary ms-2">Export Report</button>
                    </div>
                </div>
                <div className="card custom-card overflow-hidden">
                    <div className='gap-20 border-bottom'>
                        <div className='col-12'>
                            <div className='d-flex justify-content-between align-items-center'>
                                <ConfigurationReportTab tab='cost' />
                            </div>
                        </div>
                    </div>
                    {/* <div className='gap-20 ps-0 pb-0 pe-0'>
                        <ul className='d-flex setting submenu-list border-bottom mb-0 p-0'>
                            <li className={taxType === 'Taxes' ? 'active' : ''} onClick={() => { taxConfiguration('Taxes') }}>Taxes</li>
                            <li className={taxType === 'Tax Groups' ? 'active' : ''} onClick={() => { taxConfiguration('Tax Groups') }}>Tax Groups</li>
                        </ul>
                    </div> */}
                    <div className="gap-20">
                        <div className='page-header d-flex align-items-center mb-0'>
                            {/* <div className='page-header-left'></div> */}
                            <div className='d-flex gap-3 align-items-center'>
                                <div className="form-group mb-0 rangepicker_container">
                                    <input type="search" className="form-control" value={globalSearch} placeholder="Search" onChange={(e) => { setGlobalSearch(e.target.value) }} />
                                </div>
                            </div>
                            <div className='page-header-right ms-auto'>
                                <div className='d-flex gap-3 align-items-center'>
                                    {/* <div className="date-input">
                                    <CustomRangepicker GetDateRange={(e) => { prepareSearch("created", e) }} resetdate={resetdate} />
                                </div> */}
                                    <button type="reset" value='Reset' onClick={resetFilter} className="btn btn-primary mr-2">Reset Filter</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tabler-wrap">
                        <div className="table-responsive">
                            <table className="table table-custom">
                                <thead>
                                    <tr>
                                        <th width="10%">
                                            <div className='sorting_column'>
                                                <span>Item ID</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="id" />
                                            </div>
                                        </th>
                                        <th width="%"><span>Item name</span></th>
                                        <th width="%"><span>Category</span></th>
                                        <th className='text-end' width="8%"><span>Quantity sold</span></th>
                                        <th className='text-end' width="%"><span>Cost price</span></th>
                                        <th className='text-end' width="%"><span>Selling price</span></th>
                                        <th className='text-end' width="%"><span>Total cost</span></th>
                                        <th className='text-end' width="%"><span>Total sales</span></th>
                                        <th className='text-end' width="%"><span>Profit</span></th>
                                        <th className='text-end' width="10%"><span>Profit Margin (%)</span></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {loader ?
                                        <tr>
                                            <td colSpan={11}><Loader /></td>
                                        </tr> : <>
                                            {list?.length ? list.map((row, i) => (
                                                <tr>
                                                    <td>{row?.menu_item_id}</td>
                                                    <td>{row?.item_name ? capitalizeFirstLetter(row?.item_name) : "-"}</td>
                                                    <td>{row?.category_name ? capitalizeFirstLetter(row?.category_name) : "-"}</td>
                                                    <td className='text-end'>{row?.item_quantity ? row?.item_quantity : "-"}</td>
                                                    <td className='text-end'>{row?.item_cost ? showPrice(row?.item_cost) + " SAR" : "-"}</td>
                                                    <td className='text-end'>{row?.item_default_selling_price ? showPrice(row?.item_default_selling_price) + " SAR" : "-"}</td>
                                                    <td className='text-end'>{row?.item_total_cost ? showPrice(row?.item_total_cost) + " SAR" : "-"}</td>
                                                    <td className='text-end'>{row?.item_total_revenue ? showPrice(row?.item_total_revenue) + " SAR" : "-"}</td>
                                                    <td className='text-end'>{row?.item_total_profit ? showPrice(row?.item_total_profit) + " SAR" : "-"}</td>
                                                    <td className='text-end'>{row?.item_total_profit_margin ? `${showPrice(row?.item_total_profit_margin)} %` : "-"}</td>
                                                </tr>
                                            )) : <tr>
                                                <td colSpan={11} className="text-center">
                                                    <img src={notFound} style={{ width: "5%" }} alt="Not Found" />
                                                    <p>No record found</p>
                                                </td>
                                            </tr>
                                            }</>}
                                </tbody>
                            </table>
                        </div>
                        <div className='tabler-footer'>
                            <div className='d-flex align-items-center'>
                                <div id="example1_info" role="status" className="table-result" aria-live="polite"><b>Total Records : {dataLength ? dataLength : "0"}</b></div>
                                {dataLength && dataLength > 0 ?
                                    <CustomPagination datalength={dataLength} itemperpage={itemPerPage} setPage={setPage} currentPage={page} pageRoute={[{ name: "item-cost-calculation", path: "/company/report/item_cost/list" }]} /> : ""}
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer
                    position="top-right"
                    hideProgressBar={false}
                    autoClose={true | 1000}
                    newestOnTop={true}
                    closeOnClick={false}
                    draggable={false}
                    rtl={false}
                />

            </div>
        </>
    );
}

export default ItemCostList