import React, { useEffect, useState } from 'react'
import { Formik } from 'formik'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { GetEditBrand, GetBrandDetail, CreateBrand } from '../../services/company_panel/brandService'
import Swal from "sweetalert2"
import Breadcrums from '../../components/company_panel/common/breadcrumbs'
import Loader from '../../components/company_panel/common/loader'
import $ from 'jquery';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { globalLoader } from '../../helpers/global'
import { getCompany } from '../../helpers/commonFunctions'

const BrandEdit = () => {
    const navigate = useNavigate()
    const [previewImage, setPreviewImage] = useState("");
    const params = useParams();
    const [searchParams] = useSearchParams();
    const [showDefault, setShowDefault] = useState({})
    const [submitted, setSubmitted] = useState(false)
    const breadcrumbs = [
        {
            title: "Brands",
            url: "/company/business/brand/list/1"
        },
        {
            title: searchParams.get("copy") ? "Create Brand" : "Edit Brand",
            url: ""
        },
    ]

    useEffect(() => {
        GetBrandDetail(params.id).then(data => {
            setShowDefault(data && data.data && data.data.response ? data.data.response : [])
        }).catch(error => {
            console.log("error=====>", error)
        })
    }, [])

    return (
        <>
            <div className="page-header d-flex align-items-center">
                <div className="page-header-left">
                    <Breadcrums data={breadcrumbs} />
                    <h4>Brands</h4>
                </div>
                <div className="page-header-right ms-auto">
                </div>
            </div>
            <Formik
                enableReinitialize
                initialValues={{
                    name: showDefault && showDefault.name ? showDefault.name : "",
                    name_ar: showDefault && showDefault.name_ar ? showDefault.name_ar : "",
                    logo: showDefault && showDefault.logo ? showDefault.logo : "",
                    short_description: showDefault && showDefault.short_description ? showDefault.short_description : "",
                    long_description: showDefault && showDefault.long_description ? showDefault.long_description : "",
                    short_description_ar: showDefault && showDefault.short_description_ar ? showDefault.short_description_ar : "",
                    long_description_ar: showDefault && showDefault.long_description_ar ? showDefault.long_description_ar : "",
                }}
                validate={values => {
                    const error = {};
                    const MAX_SIZE = 5 * 1024 * 1024;
                    if (values.logo == undefined || values.logo.type || !values.logo) {
                        if (values.logo == undefined || !values.logo) {
                            error.logo = 'Image is required';
                            $("#errortext").text("Image is required");
                        } else if (!values?.logo?.name.match(/\.(jpg|jpeg|png|webp|avif|gif)$/)) {
                            error.logo = 'Invalid Image Format';
                            $("#errortext").text("Invalid Image Format");
                        } else if (values.logo.size > MAX_SIZE) {
                            error.logo = 'Image size exceeds the maximum limit of 5MB';
                            $("#errortext").text("Image size exceeds the maximum limit of 5MB");
                        } else {
                            $("#errortext").text("");
                        }
                    }
                    if (!values.name.trim()) {
                        error.name = "Brand name is required";
                    } else if (values.name.length > 30) {
                        error.name = "Brand name should not exceed 30 characters";
                    }

                    if (!values.name_ar.trim()) {
                        error.name_ar = "Brand name (Arabic) is required";
                    } else if (values.name_ar.length > 30) {
                        error.name_ar = "Brand name (Arabic) should not exceed 30 characters";
                    }
                    if (!values.short_description.trim()) {
                        error.short_description = "Short description is required";
                    } else if (values.short_description.trim().length > 200) {
                        error.short_description = "Short description must be 200 characters or less";
                    }

                    if (!values.long_description.trim()) {
                        error.long_description = "Long description is required";
                    } else if (values.long_description.trim().length > 500) {
                        error.long_description = "Long description must be 500 characters or less";
                    }

                    if (!values.short_description_ar.trim()) {
                        error.short_description_ar = "Short description (Arabic) is required";
                    } else if (values.short_description_ar.trim().length > 200) {
                        error.short_description_ar = "Short description (Arabic) must be 200 characters or less";
                    }

                    if (!values.long_description_ar.trim()) {
                        error.long_description_ar = "Long description (Arabic) is required";
                    } else if (values.long_description_ar.trim().length > 500) {
                        error.long_description_ar = "Long description (Arabic) must be 500 characters or less";
                    }
                    return error;
                }}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitted(true)
                    globalLoader(true);
                    let formData = new FormData();
                    formData.append("id", params.id)
                    formData.append('logo', values.logo);
                    formData.append('name', values.name.trim());
                    formData.append('name_ar', values.name_ar.trim());
                    formData.append('short_description', values.short_description);
                    formData.append('long_description', values.long_description);
                    formData.append('short_description_ar', values.short_description_ar);
                    formData.append('long_description_ar', values.long_description_ar);
                    formData.append('company_id', getCompany().company_id);
                    if (!searchParams.get('copy')) {
                        GetEditBrand(formData).then(response => {
                            setSubmitting(false);
                            setSubmitted(false);
                            globalLoader(false);
                            if (response.data.status === 1 || response.data.status === "1") {
                                Swal.fire({
                                    customClass: 'swal-wide',
                                    icon: 'success',
                                    title: response.data.message,
                                    showConfirmButton: false,
                                    timer: 1500,
                                    toast: true,
                                    position: 'top-right',
                                });
                                setTimeout(() => {
                                    navigate(`/company/business/brand/list/${params.pgno}`)
                                }, 1000);
                            } else {
                                Swal.fire({
                                    customClass: 'swal-wide',
                                    icon: 'error',
                                    title: response.data.message,
                                    showConfirmButton: false,
                                    timer: 5000,
                                    toast: true,
                                    position: 'top-right',
                                })
                                setSubmitted(false)
                            }
                        }).catch(error => {
                            globalLoader(false)
                            console.log("error ====> ", error);
                        })
                    } else {
                        CreateBrand(formData).then(response => {
                            setSubmitting(false);
                            setSubmitted(false);
                            globalLoader(false);
                            if (response.data.status === 1 || response.data.status === "1") {
                                Swal.fire({
                                    customClass: 'swal-wide',
                                    icon: 'success',
                                    title: response.data.message,
                                    showConfirmButton: false,
                                    timer: 1500,
                                    toast: true,
                                    position: 'top-right',
                                });
                                setTimeout(() => {
                                    navigate(`/company/business/brand/list/${1}`)
                                }, 500);
                            } else {
                                Swal.fire({
                                    customClass: 'swal-wide',
                                    icon: 'error',
                                    title: response.data.message,
                                    showConfirmButton: false,
                                    timer: 5000,
                                    toast: true,
                                    position: 'top-right',
                                })
                                setSubmitted(false);
                            }
                        }).catch(error => {
                            globalLoader(false);
                            console.log("error ====> ", error);
                        })
                    }
                }}
            >{({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,

            }) => (
                <form onSubmit={handleSubmit}>
                    <div className="row row-sm">
                        <div className="card custom-card">
                            <div className="card-body">
                                {showDefault && Object.keys(showDefault).length > 0 ?
                                    <div className="row row-sm">

                                        <div className='col-md-12'>
                                            <div className='upload-logo d-flex align-items-center gap-3 mb-3'>
                                                <div className="avatar avatar-xl">

                                                    {previewImage ?
                                                        <img alt="avatar" src={previewImage} className={previewImage ? "" : "d-none"} />
                                                        :
                                                        <img src={values.logo} alt="profileImage" />
                                                    }

                                                </div>
                                                <div className='btn btn-black btn-file-type'>Upload logo <input id="logo" name="logo" accept="image/*" type="file" onChange={(event) => {
                                                    setFieldValue("logo", event.currentTarget.files[0] || "");
                                                    { event.currentTarget.files.length == 1 ? (setPreviewImage(URL.createObjectURL(event.currentTarget.files[0]))) : (setPreviewImage("")) }
                                                }}
                                                /></div>
                                            </div>
                                            <span className='text-danger d-flex text-left' id='errortext'>
                                                {errors.logo && touched.logo && errors.logo}
                                            </span>
                                        </div>
                                        <div className='col-lg-6 text-center form-group mb-3'>
                                            <label htmlFor='name' className='text-left d-flex'>Brand Name<span className="requirestar">*</span></label>
                                            <input name='name' id="name" placeholder='Enter brand name' type='text' onChange={handleChange} onBlur={handleBlur} value={values.name} className='form-control' />
                                            <span className='text-danger d-flex text-left'>{errors.name && touched.name && errors.name}</span>
                                        </div>
                                        <div className='col-lg-6 text-center form-group mb-3'>
                                            <label htmlFor='name_ar' className='text-left d-flex'>Brand Name (Arabic)<span className="requirestar">*</span></label>
                                            <input name='name_ar' id="name_ar" placeholder='Enter brand name (Arabic)' type='text' onChange={handleChange} onBlur={handleBlur} value={values.name_ar} className='form-control' />
                                            <span className='text-danger d-flex text-left'>{errors.name_ar && touched.name_ar && errors.name_ar}</span>
                                        </div>
                                        <div className='col-lg-6 text-center form-group required mb-3'>
                                            <label htmlFor='short_description' className='text-left d-flex'>Short description  <span className="requirestar">*</span> </label>
                                            <textarea name='short_description' placeholder='Enter short description (Arabic)' id="short_description" rows="2" type='text' onChange={handleChange} onBlur={handleBlur} value={values.short_description} className='form-control' style={{ height: "100px" }} />
                                            <span className='text-danger d-flex text-left'>{errors.short_description && touched.short_description && errors.short_description}</span>
                                        </div>

                                        <div className='col-lg-6 text-center form-group required mb-3'>
                                            <label htmlFor='short_description_ar' className='text-left d-flex'>Short description (Arabic)  <span className="requirestar">*</span> </label>
                                            <textarea name='short_description_ar' placeholder='Enter short description (Arabic)' id="short_description_ar" rows="2" type='text' onChange={handleChange} onBlur={handleBlur} value={values.short_description_ar} className='form-control' style={{ height: "100px" }} />
                                            <span className='text-danger d-flex text-left'>{errors.short_description_ar && touched.short_description_ar && errors.short_description_ar}</span>
                                        </div>
                                        <div className='col-lg-6 text-center form-group required mb-3'>
                                            <label htmlFor='long_description' className='text-left d-flex'>Long description  <span className="requirestar">*</span> </label>
                                            <textarea name='long_description' placeholder='Enter long description' id="long_description" rows="2" type='text' onChange={handleChange} onBlur={handleBlur} value={values.long_description} className='form-control' style={{ height: "100px" }} />
                                            <span className='text-danger d-flex text-left'>{errors.long_description && touched.long_description && errors.long_description}</span>
                                        </div>
                                        <div className='col-lg-6 text-center form-group required mb-3'>
                                            <label htmlFor='long_description_ar' className='text-left d-flex'>Long description (Arabic) <span className="requirestar">*</span> </label>
                                            <textarea name='long_description_ar' placeholder='Enter long description (Arabic)' id="long_description_ar" rows="2" type='text' onChange={handleChange} onBlur={handleBlur} value={values.long_description_ar} className='form-control' style={{ height: "100px" }} />
                                            <span className='text-danger d-flex text-left'>{errors.long_description_ar && touched.long_description_ar && errors.long_description_ar}</span>
                                        </div>
                                        <div className="mt-2">
                                            <button className="btn btn-primary mr-2" type="submit" disabled={submitted ? true : null}>
                                                Save
                                            </button>
                                        </div>
                                    </div> : <Loader />}
                            </div>
                        </div>
                    </div>
                </form>
            )}
            </Formik>
            <ToastContainer
                position="top-right"
                hideProgressBar={false}
                autoClose={true | 1000}
                newestOnTop={true}
                closeOnClick={false}
                draggable={false}
                rtl={false}
            />
        </>

    )
}

export default BrandEdit