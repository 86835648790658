// export const API_BASE = "http://localhost:4000/webservice/admin/";   // lakshya sir
// export const API_BASE = "http://192.168.1.83:5000/webservice/admin/";   //naveen sir
// export const API_BASE = "http://samasport.devtechnosys.tech:17345/webservice/admin/"

import noneImage from '../assets/company/img/none.png'
import fishImage from '../assets/company/img/fish.png'
import butterImage from '../assets/company/img/butter.png'
import eggImage from '../assets/company/img/egg.png'
import celeryImage from '../assets/company/img/celery.png'
import cheeseImage from '../assets/company/img/cheese.png'
import glutenImage from '../assets/company/img/gluten-free.png'
import milkImage from '../assets/company/img/milk.png'
import mustardImage from '../assets/company/img/mustard.png'
import sesameImage from '../assets/company/img/sesame.png'
import shrimpImage from '../assets/company/img/shrimp.png'
import soyImage from '../assets/company/img/soy.png'
export const EDITOR_TOOLBAR = ['sourceEditing'];

export const CKEDITOR_CONFIG = {
  filebrowserUploadUrl: `${process.env.REACT_APP_API_URL}/admin/gallery/upload-ck-image`,
  allowedContent: true,
  fileTools_requestHeaders: {
    Authorization: `Bearer ${localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")).token : ""}`,
  },
  extraAllowedContent: '*[*]',
  toolbar: [
    { name: 'source', items: ['Source'] },
    { name: 'functions', items: ['NewPage', 'ExportPdf', 'Print', 'Preview',] },
    { name: 'clipboard', items: ["Cut", "Copy", "Paste", "CopyFormatting"] },
    { name: 'miscellaneous', items: ["Undo", "Redo"] },
    { name: 'text-corrections', items: ['Find', 'Replace', 'SelectAll', 'Scayt'] },
    { name: 'basic-styles', items: ['Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript'] },
    { name: 'paragraph', items: ['NumberedList', 'BulletedList', 'Outdent', 'Indent', 'Blockquote'] },
    { name: 'alignment', items: ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', 'BidiLtr', 'BidiRtl'] },
    { name: 'links', items: ['Link', 'Unlink', 'Anchor', 'Image'] },
    { name: 'table', items: ['Table', 'TableToolbar'] },
    { name: 'styles', items: ['Font', 'FontSize', 'Format', 'TextColor', 'BGColor', 'PageBreak'] },
    { name: 'forms', items: ['Form', "Radio", "Checkbox", "TextField", "Textarea", "Select", 'MediaButton'] },
    { name: 'configs', items: ['Smiley', 'SpecialChar', 'Iframe', 'Maximize'] }
  ]
};

export const VIDEO_UPLOAD = {
    CHUNK_SIZE: 1048576, /** 1 MB */
}
export const MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const MATCH_TYPES = ['Live', 'Recorded'];

export const MEDIA_TYPES = ['Audio', 'Video'];

export const COMPETITION_TYPES = ['Championship', 'Season'];

export const DEVMODE = { USERNAME: "DEV@ADMIN", PASSWORD: "Dev@1234", SESSION_TIME: 300000 /** 5 MINUTES */ };

export const ORDER_STATUS = {
    PENDING: 0,/**Product is ordered successfully but not delivered */
    CANCELED: 1,/**Order is cancel */
    DELIVERED: 2,/**Product is successfully delivered */
}

export const productcategorylist = [{ value: "Product" }, { value: "Coupon" }]
export const Transaction_Status = [{ name: "Status", status__id: "" }, { name: "Failed", status__id: 0 }, { name: "Success", status__id: 1 }]
export const showOrder_Status = [{ name: "Status", status__id: "" }, { name: "Pending", status__id: 0 }, { name: "Cancelled", status__id: 1 }, { name: "Delivered", status__id: 2 }];
// export const showFilterlist = [
//   { name: "list_heading_status", status__id: "" },
//   { name: "btn_active", status__id: "1" },
//   { name: "btn_inactive", status__id: "0" },
// ];
export const showFilterlist = [{ name: "Status", status__id: "" }, { name: "Active", status__id: "1" }, { name: "Inactive", status__id: "0" }]
export const showFilterlistBoolean = [{ name: "Status", status__id: "" }, { name: "Active", status__id: true }, { name: "Inactive", status__id: false }]
export const UserGraphOption = [{ name: "This Week", value: "this_week" }, { name: "Last Month", value: "last_month" }, { name: "Last Year", value: "last_year" }]
export const DURATION = [{ key: "1", label: "1 Month" }, { key: "2", label: "2 Month" }, { key: "3", label: "3 Month" }, { key: "4", label: "4 Month" }]
export const COUPON_TYPE = [{ key: "flat", label: "Flat" }, { key: "percentage", label: "Percentage" }]
export const CHOOSE_BUY_ITEMS = [{ key: "burger", label: "Burger" }, { key: "pizza", label: "Pizza" }, { key: "pasta", label: "Pasta" }, { key: "sandwich", label: "Sandwich" }, { key: "momos", label: "Momos" }]
export const CHOOSE_GET_ITEMS = [{ key: "burger", label: "Burger" }, { key: "pizza", label: "Pizza" }, { key: "pasta", label: "Pasta" }, { key: "sandwich", label: "Sandwich" }, { key: "momos", label: "Momos" }]
export const BUY = [
    { key: "1", label: "1" },
    { key: "2", label: "2" },
    { key: "3", label: "3" },
    { key: "4", label: "4" },
    { key: "5", label: "5" },
    { key: "6", label: "6" },
    { key: "7", label: "7" },
    { key: "8", label: "8" },
    { key: "9", label: "9" },
    { key: "10", label: "10" }
  ];
  
  export const GET = [
    { key: "1", label: "1" },
    { key: "2", label: "2" },
    { key: "3", label: "3" },
    { key: "4", label: "4" },
    { key: "5", label: "5" },
    { key: "6", label: "6" },
    { key: "7", label: "7" },
    { key: "8", label: "8" },
    { key: "9", label: "9" },
    { key: "10", label: "10" }
  ];
  
export const USER = [{ key: "user1", label: "User1" }, { key: "user2", label: "User2" }]


export const FONT_STYLE = ["Arial", "Verdana", "Helvetica", "Times New Roman", "Courier New", "Georgia", "Palatino", "Garamond", "Bookman", "Comic Sans MS", "Trebuchet MS", "Arial Black", "Impact", "Lucida Sans Unicode", "Tahoma", "Geneva", "Courier", "Lucida Console", "Monaco", "Bradley Hand", "Brush Script MT", "Century Gothic", "Franklin Gothic Medium", "Gill Sans", "Hobo Std", "Jazz LET", "Luminari", "Marker Felt", "Optima", "Papyrus", "Skia", "Zapfino"];

export const FONT_SIZE = ["8px", "10px", "12px", "14px", "16px", "18px", "20px", "24px", "28px", "32px", "36px", "40px", "48px", "56px", "64px", "72px"];

export const WEEK = [{ key: "sunday", label: "Sunday" }, { key: "monday", label: "Monday" }, { key: "tuesday", label: "Tuesday" }, { key: "wednesday", label: "Wednesday" }, { key: "thursday", label: "Thursday" }, { key: "friday", label: "Friday" }, { key: "saturday", label: "Saturday" }]
export const IMG_TYPE = ["jpg","jpeg","png","webp","avif","gif"];
export const MAX_FILE_SIZE = 5 * 1024 * 1024
export const FILE_TYPE_ERROR = "Invalid file type";
export const FILE_SIZE_ERROR = "File size exceeds the limit";
export const LOADER_TIME = 500; /** Values should be in ms */

export const PASSWORD_MIN_LENGTH = 8
export const PASSWORD_MAX_LENGTH = 20
export const ATLEAST_ONE_SMALL_REGEX = /[a-z]/g
export const ATLEAST_ONE_CAPITAL_REGEX = /[A-Z]/g
export const ATLEAST_ONE_NUMBER_REGEX = /[0-9]/g;
export const ATLEAST_ONE_SPECIAL_CHARACTER_REGEX = /[^\w\s]/;

export const CUSTOMER_TYPE = {
  All: "all",
  New: "new",
  Existing:'existing'
};

export const IMG_MAX_SIZE = { value: 5 * 1024 * 1024, display_name: "5 MB"} ;

export const SUPPORTED_FORMATS = ['jpg', 'jpeg', 'png', 'webp', 'avif', 'gif'];

// 30000

export const WEEKDAYS = [
  { value: "sunday", label: "Sunday" }, 
  { value: "monday", label: "Monday" }, 
  { value: "tuesday", label: "Tuesday" },
  { value: "wednesday", label: "Wednesday" }, 
  { value: "thursday", label: "Thursday" },
  { value: "friday", label: "Friday" }, 
  { value: "saturday", label: "Saturday" }
];

export const SUBSCRIPTION_CONTENT_LENGTH = 50;


export const SOCKET_EVENTS = {
  ORDER_CREATED : "order_created" , 
  ORDER_MARKED_READY : "order_marked_ready" , 
  STATION_UPDATED  : "station_updated" , 
  NEW_NOTIFICATION : "new_notification" , 
  NEW_REQUEST : "new_request",
  REQUEST_ANSWERED: "request_answered"
}

// export const allergiesData = [
//   { value: "gluten", label: <><img src={glutenImage} alt="Gluten" style={{ width: "20px", height: "20px", marginRight: "8px" }} />Gluten (الجلوتين)</> },
//   { value: "egg", label: <><img src={eggImage} alt="Egg" style={{ width: "20px", height: "20px", marginRight: "8px" }} />Egg (البيض)</> },
//   { value: "milk", label: <><img src={milkImage} alt="Milk" style={{ width: "20px", height: "20px", marginRight: "8px" }} />Milk (الحليب)</> },
//   { value: "cheese", label: <><img src={cheeseImage} alt="Cheese" style={{ width: "20px", height: "20px", marginRight: "8px" }} />Cheese (الجبن)</> },
//   { value: "celery", label: <><img src={celeryImage} alt="Celery" style={{ width: "20px", height: "20px", marginRight: "8px" }} />Celery (الكرفس)</> },
//   { value: "shrimp", label: <><img src={shrimpImage} alt="Shrimp" style={{ width: "20px", height: "20px", marginRight: "8px" }} />Shrimp (الروبيان)</> },
//   { value: "soy", label: <><img src={soyImage} alt="Soy" style={{ width: "20px", height: "20px", marginRight: "8px" }} />Soy (فول الصويا)</> },
//   { value: "fish", label: <><img src={fishImage} alt="Fish" style={{ width: "20px", height: "20px", marginRight: "8px" }} />Fish (الأسماك)</> },
//   { value: "butter", label: <><img src={butterImage} alt="Butter" style={{ width: "20px", height: "20px", marginRight: "8px" }} />Butter (الزبدة)</> },
//   { value: "mustard", label: <><img src={mustardImage} alt="Mustard" style={{ width: "20px", height: "20px", marginRight: "8px" }} />Mustard (الخردل)</> },
//   { value: "sesame", label: <><img src={sesameImage} alt="Sesame" style={{ width: "20px", height: "20px", marginRight: "8px" }} />Sesame (بذور السمسم)</> }        
// ]

export const allergiesData = [
  { name: "None (ا شيء)",image: noneImage},
  { name: "Gluten (الجلوتين)", image: glutenImage },
  { name: "Egg (البيض)", image: eggImage },
  { name: "Milk (الحليب)", image: milkImage },
  { name: "Cheese (الجبن)", image: cheeseImage },
  { name: "Celery (الكرفس)", image: celeryImage },
  { name: "Shrimp (الروبيان)", image: shrimpImage },
  { name: "Soy (فول الصويا)", image: soyImage },
  { name: "Fish (الأسماك)", image: fishImage },
  { name: "Butter (الزبدة)", image: butterImage },
  { name: "Mustard (الخردل)", image: mustardImage },
  { name: "Sesame (بذور السمسم)", image: sesameImage }
];

export const allergyImageMap = {
  "none" : noneImage,
  "gluten": glutenImage,
  "egg": eggImage,
  "milk": milkImage,
  "cheese": cheeseImage,
  "celery": celeryImage,
  "shrimp": shrimpImage,
  "soy": soyImage,
  "fish": fishImage,
  "butter": butterImage,
  "mustard": mustardImage,
  "sesame": sesameImage
};

export const countyPhoneNumberData = {
  "1": {"name": "United States/Canada", "code": "US/CA", "length": 10},
  "7": {"name": "Russia", "code": "RU", "length": 10},
  "20": {"name": "Egypt", "code": "EG", "length": 9},
  "30": {"name": "Greece", "code": "GR", "length": 10},
  "31": {"name": "Netherlands", "code": "NL", "length": 9},
  "32": {"name": "Belgium", "code": "BE", "length": 9},
  "33": {"name": "France", "code": "FR", "length": 9},
  "34": {"name": "Spain", "code": "ES", "length": 9},
  "36": {"name": "Hungary", "code": "HU", "length": 9},
  "39": {"name": "Italy", "code": "IT", "length": 10},
  "40": {"name": "Romania", "code": "RO", "length": 9},
  "41": {"name": "Switzerland", "code": "CH", "length": 9},
  "42": {"name": "Czech Republic", "code": "CZ", "length": 9},
  "43": {"name": "Austria", "code": "AT", "length": 9},
  "44": {"name": "United Kingdom", "code": "GB", "length": 10},
  "45": {"name": "Denmark", "code": "DK", "length": 8},
  "46": {"name": "Sweden", "code": "SE", "length": 9},
  "47": {"name": "Norway", "code": "NO", "length": 8},
  "48": {"name": "Poland", "code": "PL", "length": 9},
  "49": {"name": "Germany", "code": "DE", "length": 10},
  "51": {"name": "Peru", "code": "PE", "length": 9},
  "52": {"name": "Mexico", "code": "MX", "length": 10},
  "53": {"name": "Cuba", "code": "CU", "length": 8},
  "54": {"name": "Argentina", "code": "AR", "length": 10},
  "55": {"name": "Brazil", "code": "BR", "length": 11},
  "56": {"name": "Chile", "code": "CL", "length": 9},
  "57": {"name": "Colombia", "code": "CO", "length": 10},
  "58": {"name": "Venezuela", "code": "VE", "length": 10},
  "59": {"name": "Guyana", "code": "GY", "length": 10},
  "60": {"name": "Malaysia", "code": "MY", "length": 9},
  "61": {"name": "Australia", "code": "AU", "length": 9},
  "62": {"name": "Indonesia", "code": "ID", "length": 10},
  "63": {"name": "Philippines", "code": "PH", "length": 10},
  "64": {"name": "New Zealand", "code": "NZ", "length": 8},
  "65": {"name": "Singapore", "code": "SG", "length": 8},
  "66": {"name": "Thailand", "code": "TH", "length": 9},
  "7": {"name": "Kazakhstan", "code": "KZ", "length": 10},
  "82": {"name": "South Korea", "code": "KR", "length": 10},
  "84": {"name": "Vietnam", "code": "VN", "length": 10},
  "86": {"name": "China", "code": "CN", "length": 11},
  "90": {"name": "Turkey", "code": "TR", "length": 10},
  "91": {"name": "India", "code": "IN", "length": 10},
  "92": {"name": "Pakistan", "code": "PK", "length": 10},
  "93": {"name": "Afghanistan", "code": "AF", "length": 9},
  "94": {"name": "Sri Lanka", "code": "LK", "length": 9},
  "95": {"name": "Myanmar", "code": "MM", "length": 9},
  "98": {"name": "Iran", "code": "IR", "length": 10},
  "212": {"name": "Morocco", "code": "MA", "length": 9},
  "213": {"name": "Algeria", "code": "DZ", "length": 9},
  "216": {"name": "Tunisia", "code": "TN", "length": 8},
  "218": {"name": "Libya", "code": "LY", "length": 9},
  "220": {"name": "Gambia", "code": "GM", "length": 7},
  "221": {"name": "Senegal", "code": "SN", "length": 9},
  "222": {"name": "Mauritania", "code": "MR", "length": 8},
  "223": {"name": "Mali", "code": "ML", "length": 8},
  "224": {"name": "Guinea", "code": "GN", "length": 9},
  "225": {"name": "Ivory Coast", "code": "CI", "length": 10},
  "226": {"name": "Burkina Faso", "code": "BF", "length": 8},
  "227": {"name": "Niger", "code": "NE", "length": 8},
  "228": {"name": "Togo", "code": "TG", "length": 8},
  "229": {"name": "Benin", "code": "BJ", "length": 8},
  "230": {"name": "Mauritius", "code": "MU", "length": 8},
  "231": {"name": "Liberia", "code": "LR", "length": 8},
  "232": {"name": "Sierra Leone", "code": "SL", "length": 9},
  "233": {"name": "Ghana", "code": "GH", "length": 9},
  "234": {"name": "Nigeria", "code": "NG", "length": 10},
  "235": {"name": "Chad", "code": "TD", "length": 8},
  "236": {"name": "Central African Republic", "code": "CF", "length": 8},
  "237": {"name": "Cameroon", "code": "CM", "length": 8},
  "238": {"name": "Cape Verde", "code": "CV", "length": 8},
  "239": {"name": "São Tomé and Príncipe", "code": "ST", "length": 8},
  "240": {"name": "Equatorial Guinea", "code": "GQ", "length": 9},
  "241": {"name": "Gabon", "code": "GA", "length": 8},
  "242": {"name": "Congo", "code": "CG", "length": 8},
  "243": {"name": "Democratic Republic of the Congo", "code": "CD", "length": 9},
  "244": {"name": "Angola", "code": "AO", "length": 9},
  "245": {"name": "Guinea-Bissau", "code": "GW", "length": 8},
  "246": {"name": "British Indian Ocean Territory", "code": "IO", "length": 7},
  "247": {"name": "Ascension Island", "code": "AC", "length": 7},
  "248": {"name": "Seychelles", "code": "SC", "length": 7},
  "249": {"name": "Sudan", "code": "SD", "length": 9},
  "250": {"name": "Rwanda", "code": "RW", "length": 9},
  "251": {"name": "Ethiopia", "code": "ET", "length": 9}
};
