import React, { useEffect, useState } from 'react'
import { Formik, Field } from 'formik'
import { useNavigate, useParams } from 'react-router-dom'
import Swal from "sweetalert2"
import Layout from '../../layout'
import Breadcrums from '../../components/common/breadcrumbs'
import Loader from '../../components/common/loader'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GetSingleDevice, editDevice } from '../../services/devicesService'
import { Dropdown } from 'semantic-ui-react';
import { GetAllCompanies } from '../../services/companyService'

const EditDevice = () => {
    const navigate = useNavigate()
    const params = useParams()
    const [showDefault, setShowDefault] = useState({})
    const [submitted, setSubmitted] = useState(false)
    const [companies, setCompanies] = useState([]);

    const breadcrumbs = [
        {title: "Dashboard",url: "/admin/dashboard"},
        {title: "Devices Management",url: "/admin/devices-management/list/1"},
        {title: "Edit",url: ""}
    ]

    const statusOptions=[
        {key: 1, text: "Active", value: 1},
        {key: 0, text: "Inactive", value: 0}
    ]
    const hardwareOptions=[
        {key: "printer", text: "Printer", value: "printer"},
        {key: "swipe machine", text: "Swipe Machine", value: "swipe machine"},
        {key: "cash drawer", text: "Cash Drawer", value: "cash drawer"},
        {key: "pos", text: "POS", value: "pos"},
    ]
    // const printerOptions=[
    //     {key: "customer", text: "Customer", value: "customer"},
    //     {key: "kitchen", text: "Kitchen", value: "kitchen"},
    // ]
    
    useEffect(() => {
        // const statusOptions = arr.map((item) => ({
        //     key: item.id,
        //     text: item.name,
        //     value: item.id,
        // }))
        // setRoleList(roleDropdown)

        GetSingleDevice({id : params.id}).then(data => {
            setShowDefault(data && data.data && data.data.response ? data.data.response : [])
        }).catch(error => {
            console.log("error=====>", error)
        })
    }, [])

    useEffect(()=>{
        GetAllCompanies().then(data => {
            const records = data.data.response.companies;
            const companiesOptions = records.map((record)=>{
                return {
                    key : record.id,
                    text : record.name,
                    value : record.id
                }
            })
            setCompanies(companiesOptions);
        }).catch(error => {
            console.log("error=====>", error)
        })
    },[])

    return (
        <>
            <Layout>
                <Breadcrums data={breadcrumbs} />
                <Formik
                    enableReinitialize
                    initialValues={{
                        hardware_used: showDefault && showDefault?.hardware_used ? showDefault.hardware_used : "",
                        ref_id: showDefault && showDefault?.ref_id ? showDefault.ref_id : "",
                        serial_number : showDefault && showDefault?.serial_number ? showDefault.serial_number : "",
                        status: showDefault && showDefault?.status ? 1 : 0,
                        company_id : showDefault && showDefault?.company_id ? showDefault.company_id : "",
                    }}

                    validate={values => {
                        const error = {};
                        if (!values.hardware_used) error.hardware_used = "Hardware used is required";
                        if (!values.ref_id) {
                            error.ref_id = "Reference ID is required";
                        } else if (values.ref_id.length > 15) {
                            error.ref_id = "Reference ID cannot be greater than 15 digits";
                        }
                        
                        if (!values.serial_number) {
                            error.serial_number = "Serial number is required";
                        } else if (values.serial_number.length > 15) {
                            error.serial_number = "Serial number cannot be greater than 15 digits";
                        }
                        if (!values.company_id) error.name = "Company is required";
                        return error;
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                        setSubmitted(true)
                        let formData = new FormData();
                        formData.append("id", params.id)
                        formData.append('hardware_used', values.hardware_used);
                        formData.append('ref_id', values.ref_id.trim());
                        formData.append('serial_number', values.serial_number.trim());
                        formData.append('company_id', values.company_id);
                        formData.append('status', values.status==1 ? true : false);
                        editDevice(formData).then(response => {
                            setSubmitting(false);
                            setSubmitted(false);
                            if (response.data.status === 1 || response.data.status === "1") {
                                Swal.fire({
                                    customClass: 'swal-wide',
                                    icon: 'success',
                                    title: response.data.message,
                                    showConfirmButton: false,
                                    timer: 1500,
                                    toast: true,
                                    position: 'top-right',
                                });
                                setTimeout(() => {
                                    navigate('/admin/devices-management/list/1')
                                }, 1000);
                            } else {
                                Swal.fire({
                                    customClass: 'swal-wide',
                                    icon: 'error',
                                    title: response.data.message,
                                    showConfirmButton: false,
                                    timer: 5000,
                                    toast: true,
                                    position: 'top-right',
                                })
                                setSubmitted(false)
                            }
                        }).catch(error => {
                            console.log("error ====> ", error);
                        })
                    }}
                >{({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,

                }) => (
                    <form onSubmit={handleSubmit}>
                        <div className="row row-sm">
                            <div className="col-lg-12 col-md-12 animation_fade">
                                <div className="page-header d-flex align-items-center">
                                    <div className="page-header-left">
                                        <h4>Edit Subscription</h4>
                                    </div>
                                    <div className="page-header-right ms-auto">
                                        
                                    </div>
                                </div>
                                <div className="card custom-card">
                                    <div className="card-body">
                                        {showDefault && Object.keys(showDefault).length > 0 ?
                                        <div className="row row-sm">
                                            
                                            <div className='col-lg-6 text-center form-group mb-3'>
                                                <label htmlFor='hardware_used' className='text-left d-flex'>Hardware Used <span className="requirestar">*</span></label>
                                                <Dropdown
                                                    className='form-control'
                                                    name= "hardware_used"
                                                    id="hardware_used"
                                                    placeholder='Select hardware used'
                                                    search
                                                    fluid
                                                    selection
                                                    options={hardwareOptions}
                                                    value={values.hardware_used}
                                                    onChange={(event, data) => { setFieldValue("hardware_used", data.value) }}
                                                />
                                                <span className='text-danger d-flex text-left'>{errors.hardware_used && touched.hardware_used && errors.hardware_used}</span>
                                            </div>

                                            <div className='col-lg-6 text-center form-group required mb-3'>
                                                <label htmlFor='ref_id' className='text-left d-flex'>Reference ID  <span className="requirestar">*</span> </label>
                                                <input name='ref_id' placeholder='Enter reference ID' id="ref_id" type='text'   onChange={handleChange} onBlur={handleBlur} value={values.ref_id}  className='form-control' />
                                                <span className='text-danger d-flex text-left'>{errors.ref_id && touched.ref_id && errors.ref_id}</span>
                                            </div>
                                           
                                            <div className='col-lg-6 text-center form-group required mb-3'>
                                                <label htmlFor='serial_number' className='text-left d-flex'>Serial Number  <span className="requirestar">*</span> </label>
                                                <input name='serial_number' placeholder='Enter serial number' id="serial_number" type='text'   onChange={handleChange} onBlur={handleBlur} value={values.serial_number}  className='form-control' />
                                                <span className='text-danger d-flex text-left'>{errors.serial_number && touched.serial_number && errors.serial_number}</span>
                                            </div>

                                           
                                            {/* <div className='col-lg-6 text-center form-group mb-3'>
                                                <label htmlFor='printer_type' className='text-left d-flex'>Printer Type :<span className="requirestar">*</span></label>
                                                <Dropdown
                                                    className='form-control'
                                                    name= "printer_type"
                                                    id="printer_type"
                                                    placeholder='printer_type'
                                                    search
                                                    fluid
                                                    selection
                                                    options={printerOptions}
                                                    value={values.printer_type}
                                                    onChange={(event, data) => { setFieldValue("printer_type", data.value) }}
                                                />
                                                <span className='text-danger d-flex text-left'>{errors.printer_type && touched.printer_type && errors.printer_type}</span>
                                            </div>

                                            <div className='col-lg-6 text-center form-group required mb-3'>
                                                <label htmlFor='routing_groups' className='text-left d-flex'>Routing Groups  :<span className="requirestar">*</span> </label>
                                                <input name='routing_groups' id="routing_groups" type='text'   onChange={handleChange} onBlur={handleBlur} value={values.routing_groups}  className='form-control' />
                                                <span className='text-danger d-flex text-left'>{errors.routing_groups && touched.routing_groups && errors.routing_groups}</span>
                                            </div>
                                            
                                            <div className='col-lg-6 text-center form-group required mb-3'>
                                                <label htmlFor='name' className='text-left d-flex'>Name  :<span className="requirestar">*</span> </label>
                                                <input name='name' id="name" type='text'   onChange={handleChange} onBlur={handleBlur} value={values.name}  className='form-control' />
                                                <span className='text-danger d-flex text-left'>{errors.name && touched.name && errors.name}</span>
                                            </div>  */}

                                            <div className='col-lg-6 text-center form-group required mb-3'>
                                                <label htmlFor='status' className='text-left d-flex'>Status <span className="requirestar">*</span></label>
                                                <Dropdown
                                                    className='form-control'
                                                    name= "status"
                                                    id="status"
                                                    placeholder='Status'
                                                    search
                                                    fluid
                                                    selection
                                                    options={statusOptions}
                                                    value={values.status}
                                                    onChange={(event, data) => { setFieldValue("status", data.value) }}
                                                />
                                                 <span className='text-danger d-flex text-left'>{errors.status && touched.status && errors.status}</span>
                                            </div>

                                            <div className='col-lg-6 text-center form-group required mb-3'>
                                                <label htmlFor='company_id' className='text-left d-flex'>Company  <span className="requirestar">*</span> </label>
                                                {/* <input name='company_id' id="company_id" type='number'   onChange={handleChange} onBlur={handleBlur} value={values.company_id}  className='form-control' /> */}
                                                <Dropdown
                                                    className='form-control'
                                                    name= "company_id"
                                                    id="company_id"
                                                    placeholder='Select company'
                                                    search
                                                    fluid
                                                    selection
                                                    options={companies}
                                                    value={values.company_id}
                                                    onChange={(event, data) => { setFieldValue("company_id", data.value) }}
                                                />
                                                <span className='text-danger d-flex text-left'>{errors.company_id && touched.company_id && errors.company_id}</span>
                                            </div>
        
                                            <div className="mt-2">
                                                <button className="btn ripple btn-secondary" type="submit" disabled={submitted ? true : null}>
                                                    Save
                                                </button>
                                            </div>
                                            </div> : <Loader />}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                )}
                </Formik>
                <ToastContainer
                position="top-right"
                hideProgressBar={false}
                autoClose={true | 1000}
                newestOnTop={true}
                closeOnClick={false}
                draggable={false}
                rtl={false}
                />
            </Layout>
        </>

    )
}

export default EditDevice


