import React, { useState, useEffect, useRef, useMemo } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { ChangeInventoryStatus, DeleteInventory, GetInventoryList, ImportedInventory, ImportedInventorySample, ImportedInventoryValidate } from '../../services/company_panel/inventoryService'
import Swal from 'sweetalert2'
import CustomPagination from '../../components/company_panel/common/custompagination'
import { SpecialCharacter, capitalizeFirstLetter, formateDate, getBrandId, getOutletId, removeHyphens, removeSpecialCharacter } from '../../helpers/commonFunctions';
import Sorting from '../../components/company_panel/common/sorting';
import Loader from '../../components/company_panel/common/loader'
import StatusFilter from '../../components/company_panel/common/statusFilter'
import { LOADER_TIME, showFilterlist } from '../../utils/Constants'
import CustomRangepicker from '../../components/company_panel/common/rangepicker'
import $ from "jquery";
import { getCompany, checkPermission } from '../../helpers/commonFunctions';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import SampleFileCsv from '../../../src/assets/company/inventorySampleFiles/finalSampleFileAug27.csv';
import SampleFileXls from '../../../src/assets/company/inventorySampleFiles/finalSampleFileAug27.xls';
import SampleFileXlsx from '../../../src/assets/company/inventorySampleFiles/finalSampleFileAug27.xlsx';
import notFound from '../../assets/company/img/no-record-found.png'

const InventoryList = () => {

    const navigate = useNavigate()
    const params = useParams()
    const location = useLocation()
    const fileInputRef = useRef(null);
    const fileValidateInputRef = useRef(null);
    const [list, setList] = useState([])
    const [statsUpdate, setStatusUpdate] = useState("false")
    const [dataLength, setDataLength] = useState()
    const [itemPerPage, setItemPerPage] = useState(10)
    const [sorting, setSorting] = useState({});
    const [defaultSorting, setDefaultSorting] = useState(true);
    const [page, setPage] = useState(1);
    const [loader, setLoader] = useState(true)
    const [search, setSearch] = useState({})
    const [globalSearch, setGlobalSearch] = useState("")
    const [resetdate, setResetDate] = useState(false);
    const [validateFileError, setValidateFileError] = useState([]);
    const [isBrandChanged, setIsBrandChanged] = useState(false);
    const [isOutletChanged, setIsOutletChanged] = useState(false);

    useMemo(() => {
        document.addEventListener('brand-changed', function () {
            setIsBrandChanged(!isBrandChanged);
        });
        document.addEventListener('outlet-changed', function () {
            setIsOutletChanged(!isOutletChanged);
        });
    })

    useEffect(() => {
        if (search) {
            setLoader(true)
            setTimeout(() => {
                const formData = new FormData()
                formData.append("page", params.id)
                formData.append("per_page", itemPerPage)
                formData.append("sort", JSON.stringify(sorting))
                formData.append("search", JSON.stringify(search))
                formData.append("global_search", globalSearch)
                formData.append('brand_id', getBrandId());
                formData.append('outlet_id', getOutletId());
                GetInventoryList(formData).then(data => {
                    setDataLength(data.data.response.count);
                    setList(data && data.data && data.data.response ? data.data.response.rows : [])
                        setLoader(false);
                }).catch(error => {
                    setTimeout(() => {
                        setLoader(false);
                    }, LOADER_TIME);
                    console.log("error ====> ", error);
                })
            }, LOADER_TIME);
        }
    }, [location, statsUpdate, sorting, search, globalSearch, isBrandChanged, isOutletChanged])


    const resetFilter = (e) => {
        e.preventDefault()
        setGlobalSearch("")
        prepareSearch()
        setSearch({})
        setResetDate(!resetdate)
        $("#defaultstatus")[0].selectedIndex = 0
    }

    const closeModel = id => {
        $('#addCardModal').hide()
        $('.modal-backdrop').remove()
        setStatusUpdate(!statsUpdate);

    }

    const openModel = (id, type) => {
        $('#' + id)
            .show()
            .css('opacity', 1)
            .addClass('show')
        $('body').append('<div className="modal-backdrop fade show"></div>')
    }

    // Delete functionality
    function deleteFunction(row) {
        Swal.fire({
            customClass: 'swal-wide',
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#2f9bff',
            cancelButtonColor: '#dc3545',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {

                const formdata = new FormData()
                formdata.append("id", row);
                DeleteInventory(formdata).then(data => {
                    if (data.status === 200) {
                        Swal.fire({
                            customClass: 'swal-wide',
                            position: 'absolute',
                            icon: 'success',
                            title: data.data.message,
                            showConfirmButton: false,
                            timer: 2000,
                            toast: true,
                            position: 'top-right',
                        })
                        setStatusUpdate(!statsUpdate)
                    }
                }).catch(error => {
                    console.log("deleteError")
                })
            }
        })
    }

    // Change Status functionality
    const ChangeStatus = (row, Num) => {
        const formData = new FormData()
        formData.append("id", row)
        formData.append("status", Num)
        Swal.fire({
            customClass: 'swal-wide',
            title: 'Are you sure?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#2f9bff',
            cancelButtonColor: '#dc3545',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                ChangeInventoryStatus(formData).then(data => {
                    if (data.status === 200) {
                        Swal.fire({
                            customClass: 'swal-wide',
                            position: 'absolute',
                            icon: 'success',
                            title: data.data.message,
                            showConfirmButton: false,
                            timer: 2000,
                            toast: true,
                            position: 'top-right',
                        })
                        setStatusUpdate(!statsUpdate)
                    }
                }).catch(error => {
                    console.log("error===>",error)
                })
            }
        })
    }

    // view details functionality
    const viewFunction = (row) => {
        navigate(`/company/raw_materials/inventory/view/${row}`)
    }

    // edit functionality
    const editFunction = (row) => {
        navigate(`/company/raw_materials/inventory/${params.id}/edit/${row.id}`)
    }

    const copyOutletFunction = (row) => {
        navigate(`/company/raw_materials/inventory/${params.id}/edit/${row.id}?copy=true`);
    }

    const handleSort = (e, column) => {
        setDefaultSorting(false);
        let sort = { order: 0, column: column };
        if (e.target.classList.contains("assc")) {
            sort.order = -1;
        } else {
            sort.order = 1;
        }
        setSorting(sort);
    }

    // search or filter function
    const prepareSearch = (key, value) => {

        let sr = { ...search };
        if (String(value).length > 0) {
            sr[key] = value;
        } else {
            delete sr[key];
        }
        setSearch(sr);
    }
    // search or filer end

    const headerMapping = {
        "item_name": "Item",
        "stock" : "Stock(quantity)",
        "category": "Category",
        "sub_category": "Subcategory",
        "item_type": "Item type",
        "primary_unit_of_measure": "Primary unit of measure",
        "created_at" : "created Date"
    };



    const formatValue = (value) => {
        if (value === null || value === undefined || value === "") {
            return 'N/A';
        }
        return value.toString().replace(/_/g, ' ');
    };
    const handleExport = () => {
        const formData = new FormData()
        formData.append("page", 1)
        formData.append("per_page", dataLength)
        formData.append("sort", JSON.stringify(sorting))
        formData.append("search", JSON.stringify(search))
        formData.append("global_search", globalSearch)
        formData.append('brand_id', getBrandId());
        formData.append('outlet_id', getOutletId());
        GetInventoryList(formData).then(data => {
            setDataLength(data.data.response.count);
            const listData = data && data.data && data.data.response ? data.data.response.rows : [];
            const printData = listData.map((item) => {
            const transformedItem = {};
            const itemName = item?.item_name || 'N/A'
            const stockQuantity = item?.inventory_stocks[0]?.quantity
            const categoryName = item.category && item.category.category ? item.category.category.name : 'N/A';
            const subCategoryName = item.category ? item.category.name : 'N/A';
            const itemType = item?.item_type || 'N/A'
            const uomName = item.uom ? capitalizeFirstLetter(item.uom.uom_code) : 'N/A';
            const createdAt = item?.created_at
            for (const key in headerMapping) {
                if (headerMapping.hasOwnProperty(key)) {
                    const newKey = headerMapping[key];
                    if (key === "item_name") {
                        transformedItem[newKey] = capitalizeFirstLetter(itemName);
                    }
                    else if (key === "stock") {
                        transformedItem[newKey] = (stockQuantity);
                    }
                    else if (key === "category") {
                        transformedItem[newKey] = capitalizeFirstLetter(categoryName);
                    }
                    else if (key === "sub_category") {
                        transformedItem[newKey] = capitalizeFirstLetter(subCategoryName);
                    }
                    else if (key === "item_type") {
                        transformedItem[newKey] = formatValue(removeHyphens(capitalizeFirstLetter(itemType)));
                    } 
                    else if(key === "primary_unit_of_measure") {
                        transformedItem[newKey] = uomName;
                    }
                    else if(key === "created_at") {
                        transformedItem[newKey] = formateDate(createdAt);
                    }
                }
            }
            return transformedItem;
        });
        const ws = XLSX.utils.json_to_sheet(printData, { header: Object.values(headerMapping) });
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
        saveAs(blob, 'inventory.xlsx');
        setTimeout(() => {
            setLoader(false);
        }, LOADER_TIME);
    }).catch(error => {
        setTimeout(() => {
            setLoader(false);
        }, LOADER_TIME);
        console.log("error ====> ", error);
    })
    };



    const triggerFileInput = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleFileChange = async (e) => {
        const file = e.target.files[0];
        let company_detail = JSON.parse(localStorage.getItem("company_detail"));
        if (!file) return;
        const formData = new FormData();
        formData.append("file", file);
        formData.append("brand_id", localStorage.getItem("brand_id"))
        formData.append("company_id", company_detail.id)
        ImportedInventory(formData).then((response) => {
            setStatusUpdate(!statsUpdate);
            e.target.value = null;
            if (response.data.status === 1 || response.data.status === '1') {
                Swal.fire({
                    customClass: 'swal-wide',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 2000,
                    toast: true,
                    position: 'top-right',
                })
            } else if (response.data.status === 0 || response.data.status === '0') {
                if (Array.isArray(response.data.error)) {
                    setValidateFileError(response.data.error)
                    openModel('addCardModal', 'add');
                } else {
                    Swal.fire({
                        customClass: 'swal-wide',
                        icon: 'error',
                        title: response.data.message,
                        showConfirmButton: false,
                        timer: 5000,
                        toast: true,
                        position: 'top-right',
                    })
                }
            }
        }).catch((error) => {
            console.log("Error---->", error);
        })
    }

    return (
        <>
            <div className="animation_fade">
                <div className='filter-header d-flex justify-content-between align-items-center mb-4'>
                    <div className='page-header-left'>
                        <h4>Inventory List</h4>
                    </div>
                    <div className='d-flex gap-3 align-items-center'>
                    <button className="btn btn-primary mr-2"  onClick={() => navigate(`/company/raw_materials/inventory/end_of_day/daily/list/1`)}>Inventory End Of Day</button>
                        <div className="btn-group" role="group" aria-label="Button group with nested dropdown">
                            <div className="btn-group" role="group">
                                <div className="dropdown">
                                    <button className="btn btn-primary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        Sample Files
                                    </button>
                                    <ul className="dropdown-menu">
                                        <li><a className="dropdown-item" href={SampleFileCsv} download>Download csv file</a></li>
                                        <li><a className="dropdown-item" href={SampleFileXls} download>Download xls file</a></li>
                                        <li><a className="dropdown-item" href={SampleFileXlsx} download>Download xlsx file</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div>
                            <input type="file" accept=".xls,.xlsx,.csv" onChange={handleFileChange} ref={fileInputRef} style={{ display: 'none' }}
                            />
                            <button className="btn btn-primary mr-2" onClick={triggerFileInput}>Import inventory</button>
                        </div>
                        <button onClick={handleExport} className="btn btn-primary mr-2"> Export inventory</button>
                        <div className='page-header-right ms-auto'>
                            {checkPermission('store_inventories', 'add') ?
                                <button className="btn btn-primary ms-2" onClick={() => navigate(`/company/raw_materials/inventory/add`)}>Create Inventory</button>
                                : ''
                            }
                        </div>
                    </div>
                </div>
                <div className="card custom-card overflow-hidden">
                    <div className='gap-20'>
                        <div className='filter-header d-flex justify-content-between align-items-center'>
                            <div className='d-flex gap-3 align-items-center'>
                                <div className="form-group mb-0 rangepicker_container">
                                    <input type="search" className="form-control" value={globalSearch} placeholder="Search" onChange={(e) => { setGlobalSearch(e.target.value) }} />
                                </div>
                            </div>
                            <div className='d-flex gap-3 align-items-center'>
                                <div className="date-input">
                                    <CustomRangepicker GetDateRange={(e) => { prepareSearch("created", e) }} resetdate={resetdate} />
                                </div>
                                <StatusFilter data={showFilterlist} prepareSearch={prepareSearch} />
                                <button type="reset" value='Reset' onClick={resetFilter} className="btn btn-primary mr-2">Reset Filter</button>
                            </div>
                        </div>
                    </div>
                    <div className="tabler-wrap">
                        <div className="table-responsive">
                            <table className="table table-custom">
                                <thead>
                                    <tr>
                                        <th width="10.5%">
                                            <div className='sorting_column'>
                                                <span>Item</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="item_name" />
                                            </div>
                                        </th>
                                        <th width="12.5%">
                                            <div className='sorting_column'>
                                                <span>Stock (quantity)</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="stock" />
                                            </div>
                                        </th>
                                        <th width="12.5%">
                                            <div className='sorting_column'>
                                                <span>Category</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="category_id" />
                                            </div>
                                        </th>
                                        <th width="12.5%">
                                            <div className='sorting_column'>
                                                <span>Sub category</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="sub_category_id" />
                                            </div>
                                        </th>
                                        <th width="12.5%">
                                            <div className='sorting_column'>
                                                <span>Item type</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="item_type" />
                                            </div>
                                        </th>
                                        <th width="12.5%">
                                            <div className='sorting_column'>
                                                <span>Primary unit of measure</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="primary_unit_of_measure" />
                                            </div>
                                        </th>

                                        <th className="created_head" width="13.5%">
                                            <div className='sorting_column'>
                                                <span>Created Date</span>
                                                <Sorting sort={sorting} handleSort={handleSort} defaultSorting={defaultSorting} column="created_at" />
                                            </div>
                                        </th>
                                        <th className='status_head' width="12.5%">Status</th>
                                        <th className='action_head text-end' width="12.5%">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {loader ?
                                        <tr>
                                            <td colSpan={12}><Loader /></td>
                                        </tr> : <>
                                            {list.length ? list.map((row, i) => (
                                                <tr key={i}>
                                                    <td>{capitalizeFirstLetter(row?.item_name)}</td>
                                                    <td>{row?.inventory_stocks[0]?.quantity}</td>
                                                    <td>{row?.category?.category?.name ? capitalizeFirstLetter(row?.category?.category?.name) : 'N/A'}</td>
                                                    <td>{capitalizeFirstLetter(row?.category?.name)}</td>
                                                    <td>{capitalizeFirstLetter(removeHyphens(row?.item_type))}</td>
                                                    <td>{row?.uom?.uom}</td>
                                                    <td>{row?.created_at ? formateDate(row.created_at) : "N/A"}</td>

                                                    <td>
                                                        <div className="form-check form-switch e_none cp" onClick={() => { ChangeStatus(row.id, row.status == 1 ? 0 : 1) }}>
                                                            <input className="form-check-input" type="checkbox" id="status-change" name="status" defaultChecked={row.status} />
                                                            <label className="form-check-label" htmlFor="status-change"></label>
                                                        </div>
                                                    </td>
                                                    <td className='text-end'>
                                                        <div className="dropdown  d-inline-block">
                                                            <button className="btn btn-icon btn-secondary" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                <i className='ti ti-dots-vertical'></i>
                                                            </button>
                                                            <ul className="dropdown-menu">
                                                            {checkPermission('store_inventories', 'view') ?
                                                                <li><div className='dropdown-item' onClick={() => { viewFunction(row?.id) }}><i className="ti ti-eye" aria-hidden="true"></i> View details</div></li>
                                                                : '' }
                                                                {checkPermission('store_inventories', 'edit') ?
                                                                    <li><div className='dropdown-item' onClick={() => { editFunction(row) }}><i className="ti ti-edit" aria-hidden="true"></i> Edit</div></li>
                                                                    : ''}
                                                                {checkPermission('store_inventories', 'delete') ?
                                                                    <li> <div className='dropdown-item' onClick={() => { deleteFunction(row.id) }}><i className="ti ti-trash" aria-hidden="true"></i> Delete</div></li> :
                                                                    ''}
                                                                {checkPermission('store_inventories', 'add') ?
                                                                <li> <div className='dropdown-item' onClick={() => { copyOutletFunction(row) }}><i className="ti ti-copy" aria-hidden="true"></i> Copy inventory</div></li>
                                                                : '' }

                                                            </ul>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )) : <tr><td colSpan={11} className="text-center">
                                                <img src={notFound} style={{ width: "5%" }} alt="Not Found" />
                                                <p>No records</p>
                                            </td></tr>}</>}
                                </tbody>
                            </table>
                        </div>
                        <div className='tabler-footer'>
                            <div className='d-flex align-items-center'>
                                <div id="example1_info" role="status" className="table-result" aria-live="polite"><b>Total Records : {dataLength ? dataLength : "0"}</b></div>
                                {dataLength && dataLength > 0 ?
                                    <CustomPagination datalength={dataLength} itemperpage={itemPerPage} setPage={setPage} currentPage={page} pageRoute={[{ name: "outlet-management", path: "/company/raw_materials/inventory/list" }]} /> : ""}
                            </div>
                        </div>
                    </div>
                    <div>
                    </div>
                </div>
            </div>
            {/* Modal */}
            <div
                className="modal fade"
                id="addCardModal"
                aria-labelledby="addCardModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addCardModalLabel">
                                Some error are found, Please correct the field according to sample files.
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                onClick={e => closeModel('addCardModal')}
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body">
                            <table className="table table-bordered table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col">Sr. No. </th>
                                        <th scope="col">Sheet Row No.</th>
                                        <th scope="col">Errors</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {validateFileError.map((err, i) => (
                                        <tr key={i}>
                                            <th scope="row">{i + 1}</th>
                                            <th scope="row">Row No. {err.index + 1}</th>
                                            <td className='text-danger'>{err.error}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <ToastContainer
                position="top-right"
                hideProgressBar={false}
                autoClose={true | 1000}
                newestOnTop={true}
                closeOnClick={false}
                draggable={false}
                rtl={false}
            />
        </>
    );
}

export default InventoryList;