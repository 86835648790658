import React, { useEffect, useMemo, useState } from 'react'
import PageHeaders from '../components/common/pageHeader'
import chart1 from '../assets/company/img/chart1.jpg'
import chart2 from '../assets/company/img/chart2.jpg'
import chart3 from '../assets/company/img/chart3.jpg'
import chart4 from '../assets/company/img/chart4.jpg'
import ReactApexChart from 'react-apexcharts';
import Chart from 'react-apexcharts';
import { getDailyData } from '../services/company_panel/dashboardServices'
import { generateDateRange, generateLastNDaysDate, howPrice, showPrice } from '../helpers/commonFunctions'
import CustomRangepicker from '../components/company_panel/common/rangepicker'
import DashboardList from './dashboard/list'
import { GetAllOrders } from '../services/company_panel/orderService'
import { saveOrders } from '../helpers/indexedDB'

const Dashboard = () => {
	const [activeTab, setActiveTab] = useState('Weekly');
	const [activeDay, setActiveDay] = useState(7);
	const [date, setDate] = useState({});
	const [apiData, setApiData] = useState({});
	const [resetdate, setResetDate] = useState(false);
	const [seriesData, setSeriesData] = useState([{
		name: "Brands",
		data: []
	}]);
	const [outletData, setOutletData] = useState([{
		name: "Outlets",
		data: []
	}]);
	const [orderData, setOrderData] = useState([{
		name: "Orders",
		data: []
	}]);
	const [paymentData, setPaymentData] = useState([{
		name: "Payments",
		data: []
	}]);
	const [options, setOptions] = useState({});


	useEffect(() => {
		//fetching orders to store in indexedDB
		GetAllOrders().then(data => {
			//IndexedDB service
			saveOrders(data.data.response)
				.then(() => {
					console.log('All orders are saved.');
				})
				.catch((error) => {
					console.error('Error saving orders data:', error);
				});
		}).catch(error => {
			console.log("error ====> ", error);
		})
	}, [])

	const graphDateRangeLabel = () => {
		if (activeTab === 'Date') {
			const startDate = new Date(date?.created?.start);
			const endDate = new Date(date?.created?.end);
			startDate.setUTCDate(startDate.getUTCDate() + 1)
			endDate.setUTCDate(endDate.getUTCDate() + 1)
			return generateDateRange(startDate, endDate)
		} else {
			return generateLastNDaysDate(activeDay)
		}
	}

	// const updateOptions = (dayCount) => {
	// 	setOptions({
	// 		chart: {
	// 			type: 'line',
	// 			height: 175,
	// 			zoom: {
	// 				enabled: false
	// 			},
	// 			toolbar: {
	// 				show: false,
	// 			},
	// 		},
	// 		dataLabels: {
	// 			enabled: false
	// 		},
	// 		stroke: {
	// 			curve: 'straight',
	// 			width: 2,
	// 		},
	// 		colors: ['#2F9BFF'],
	// 		// title: {
	// 		//   text: 'Fundamental Analysis of Stocks',
	// 		//   align: 'left'
	// 		// },
	// 		// subtitle: {
	// 		//   text: 'Price Movements',
	// 		//   align: 'left'
	// 		// },
	// 		labels: graphDateRangeLabel(),
	// 		xaxis: {
	// 			type: 'datetime',
	// 			labels: {
	// 				formatter: function (value) {
	// 					const date = new Date(value);
	// 					return date.getDate() + ' ' + date.toLocaleString('default', { month: 'short' });
	// 				},
	// 				style: {
	// 					fontSize: '10px',
	// 					fontWeight: 'normal',
	// 					margin: '2px'
	// 				},
	// 				rotate: 0, // Keep the labels horizontal
	// 			},
	// 			tickAmount: Math.min(dayCount, 5), // Limits the number of ticks (evenly space them)
	// 		},
	// 		yaxis: {
	// 			opposite: false
	// 		},
	// 		markers: {
	// 			size: 4,
	// 		},
	// 		legend: {
	// 			horizontalAlign: 'left'
	// 		},
	// 		grid: {
	// 			borderColor: '#e7e7e7',
	// 			xaxis: {
	// 				lines: {
	// 					show: true,
	// 				}
	// 			},
	// 			yaxis: {
	// 				lines: {
	// 					show: true,
	// 				}
	// 			}
	// 		},
	// 		// tooltip: {
	// 		// 	shared: true,
	// 		// 	intersect: false,
	// 		// },
	// 	});
	// }

	const updateOptions = (dayCount) => {
		setOptions({
			chart: {
				type: 'line',
				height: 175,
				zoom: {
					enabled: false
				},
				toolbar: {
					show: false,
				},
			},
			dataLabels: {
				enabled: false
			},
			stroke: {
				curve: 'straight',
				width: 2,
			},
			colors: ['#2F9BFF'],
			labels: graphDateRangeLabel(),
			xaxis: {
				type: 'datetime',
				labels: {
					formatter: function (value) {
						const date = new Date(value);
						return `${date.getDate()} ${date.toLocaleString('default', { month: 'short' })}`;
					},
					style: {
						fontSize: '10px',
						fontWeight: 'normal',
						margin: '2px'
					},
					rotate: 0,
				},
				tickAmount: Math.min(dayCount, 5),
			},
			yaxis: {
				labels: {
					formatter: function (value) {
						// Show whole numbers, and up to 2 decimal places if needed
						return value % 1 === 0 ? value : value.toFixed(2);
					},
					style: {
						fontSize: '10px',
					},
				},
				opposite: false
			},
			markers: {
				size: 4,
			},
			legend: {
				horizontalAlign: 'left'
			},
			grid: {
				borderColor: '#e7e7e7',
				xaxis: {
					lines: {
						show: true,
					}
				},
				yaxis: {
					lines: {
						show: true,
					}
				}
			},
			tooltip: {
				y: {
					formatter: function (value) {
						return value % 1 === 0 ? value : value.toFixed(2);
					},
				},
			},
		});
	};


	function handleTabClick(tab) {
		let days = 30;
		if (tab === 'Daily') {
			days = 1;
		} else if (tab === 'Weekly') {
			days = 7;
		} else if (tab === 'Monthly') {
			days = 30;
		}
		setActiveDay(days);
		setActiveTab(tab);
	};

	useEffect(() => {
		updateOptions(activeDay)
		const formData = new FormData();
		if (activeTab === 'Date') {
			formData.append('date', JSON.stringify(date))
		} else {
			formData.append("days", activeDay);
		}

		getDailyData(formData)
			.then((data) => {
				const apiResponse = data?.data?.response;
				setApiData(data && data?.data && data?.data?.response ? data?.data?.response : {})
				const brandValues = Object.values(apiResponse?.brandCountByDate);
				const outletValues = Object.values(apiResponse?.outletCountByDate);
				const orderValues = Object.values(apiResponse?.orderCountByDate);
				const paymentValues = Object.values(apiResponse?.paymentAmountByDate);

				setSeriesData([{ name: "Brands", data: brandValues }]);
				setOutletData([{ name: "Outlets", data: outletValues }]);
				setOrderData([{ name: "Order", data: orderValues }]);
				setPaymentData([{ name: "Payments", data: paymentValues }]);
			})
			.catch((error) => {
				console.log("error===>", error);
			});
	}, [activeTab, activeDay, date]);

	const prepareSearch = (key, value) => {
		let sr = { ...date };
		if (String(value).length > 0) {
			sr[key] = value;
		} else {
			delete sr[key];
		}
		setDate(sr);
	}

	const resetFilter = (e) => {
		e.preventDefault()
		prepareSearch()
		setDate('')
		setResetDate(!resetdate)
	}

	return (
		<>
			<div className='card'>
				<div className='card-header d-flex justify-content-between'>
					<div className="card-header-left">
						<ul className="nav nav-pills" id="pills-tab" role="tablist">
							{['Weekly', 'Monthly'].map((tab) => (
								<li className="nav-item" role="presentation" key={tab}>
									<button
										className={`nav-link ${activeTab === tab ? 'active' : ''}`}
										id={`pills-${tab}-tab`}
										data-bs-toggle="pill"
										type="button"
										role="tab"
										aria-controls={`pills-${tab}`}
										aria-selected={activeTab === tab}
										onClick={(e) => { handleTabClick(tab); activeTab !== 'Date' && resetFilter(e) }}
									>
										{/* {tab} {tab === 'Date' && <i className="ti ti-calendar-event"></i>} */}
										{tab !== 'Date' && tab}
										{/* {tab === 'Date' && <CustomRangepicker GetDateRange={(e) => { prepareSearch("created", e) }} resetdate={resetdate} />} */}
									</button>
								</li>
							))}
						</ul>
						{/* <ul className="nav nav-pills" id="pills-tab" role="tablist">
							<li className="nav-item" role="presentation">
								<button className="nav-link active" id="pills-Daily-tab" data-bs-toggle="pill" data-bs-target="#pills-Daily" type="button" role="tab" aria-controls="pills-Daily" aria-selected="true">Daily</button>
							</li>
							<li className="nav-item" role="presentation">
								<button className="nav-link" id="pills-Weekly-tab" data-bs-toggle="pill" data-bs-target="#pills-Weekly" type="button" role="tab" aria-controls="pills-Weekly" aria-selected="false">Weekly</button>
							</li>
							<li className="nav-item" role="presentation">
								<button className="nav-link" id="pills-Monthly-tab" data-bs-toggle="pill" data-bs-target="#pills-Monthly" type="button" role="tab" aria-controls="pills-Monthly" aria-selected="false">Monthly</button>
							</li>
							<li className="nav-item" role="presentation">
								<button className="nav-link" id="pills-Date-tab" data-bs-toggle="pill" data-bs-target="#pills-Date" type="button" role="tab" aria-controls="pills-Date" aria-selected="false">Date <i className="ti ti-calendar-event"></i></button>
							</li>
						</ul> */}
					</div>
					<div className="card-header-right">
						<div className="date-input" onClick={() => setActiveTab("Date")}>
							<CustomRangepicker GetDateRange={(e) => { prepareSearch("created", e) }} resetdate={resetdate} />
						</div>
						{/* <div className='d-flex gap-2'>
							<div className='form-group header-drop'>
								<select className='form-control'>
									<option>Brand</option>
									<option>Brand</option>
									<option>Brand</option>
									<option>Brand</option>
									<option>Brand</option>
								</select>
							</div>
							<div className='form-group header-drop'>
								<select className='form-control'>
									<option>Order Type</option>
									<option>Order Type</option>
									<option>Order Type</option>
									<option>Order Type</option>
									<option>Order Type</option>
								</select>
							</div>
						</div> */}
					</div>
				</div>
				<div className='card-body'>
					<div className="tab-content" id="pills-tabContent">
						<div className="tab-pane fade show active" id="pills-Daily" role="tabpanel" aria-labelledby="pills-Daily-tab" tabIndex="0">
							<div className='row'>
								<div className='col-md-3'>
									<div className='card border'>
										<div className='card-body'>
											<h3 style={{ fontSize: '30px' }}>{apiData?.totalBrandCount}</h3>
											<div>Total Brands</div>
											<ReactApexChart options={options} series={seriesData} type="line" height={175} />
										</div>
									</div>
								</div>
								<div className='col-md-3'>
									<div className='card border'>
										<div className='card-body'>
											<h3 style={{ fontSize: '30px' }}>{apiData?.totalOutletCount}</h3>
											<div>Total Outlets</div>
											<ReactApexChart options={options} series={outletData} type="line" height={175} />
										</div>
									</div>
								</div>
								<div className='col-md-3'>
									<div className='card border'>
										<div className='card-body'>
											<h3 style={{ fontSize: '30px' }}>{apiData?.totalOrderCount}</h3>
											<div>Total Orders</div>
											<ReactApexChart options={options} series={orderData} type="line" height={175} />
										</div>
									</div>
								</div>
								<div className='col-md-3'>
									<div className='card border'>
										<div className='card-body'>
											<h3 style={{ fontSize: '30px' }}>{showPrice(apiData?.totalPaymentAmount)} SAR</h3>
											<div>Total Payments Received</div>
											<ReactApexChart options={options} series={paymentData} type="line" height={175} />
										</div>
									</div>
								</div>
								{/* <div className='col-md-3'>
									<img src={chart4} style={{ width: "100%" }} />
								</div> */}
							</div>
						</div>
						<div className="tab-pane fade" id="pills-Weekly" role="tabpanel" aria-labelledby="pills-Weekly-tab" tabIndex="0">...</div>
						<div className="tab-pane fade" id="pills-Monthly" role="tabpanel" aria-labelledby="pills-Monthly-tab" tabIndex="0">...</div>
						<div className="tab-pane fade" id="pills-Date" role="tabpanel" aria-labelledby="pills-Date-tab" tabIndex="0">...</div>
					</div>

				</div>
			</div>
			{/* <div className='card mt-4'>
				<div className='card-header d-flex align-items-center justify-content-between'>
					<div className="card-header-left">
						<h4>Recent Activities</h4>
					</div>
					<div className="card-header-right">
						<ul className="nav nav-pills" id="pills-tab2" role="tablist">
							<li className="nav-item" role="presentation">
								<button className="nav-link active" id="pills-Logged-Users-tab" data-bs-toggle="pill" data-bs-target="#pills-Logged-Users" type="button" role="tab" aria-controls="pills-Logged-Users" aria-selected="true">Logged Users</button>
							</li>
							<li className="nav-item" role="presentation">
								<button className="nav-link" id="pills-Today-orders-tab" data-bs-toggle="pill" data-bs-target="#pills-Today-orders" type="button" role="tab" aria-controls="pills-Today-orders" aria-selected="false">Today's orders</button>
							</li>
							<li className="nav-item" role="presentation">
								<button className="nav-link" id="pills-Today-payment-tab" data-bs-toggle="pill" data-bs-target="#pills-Today-payment" type="button" role="tab" aria-controls="pills-Today-payment" aria-selected="false">Today's payment</button>
							</li>
						</ul>
					</div>
				</div>
			</div> */}
			<DashboardList />
		</>
	)
}

export default Dashboard
