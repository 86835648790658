import React, { useState, useEffect } from 'react'
import Layout from '../../layout'
import { useLocation, useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import { GetTemplateList, GetChangeStatus, DeleteTemplate } from '../../services/emailtemplatesservice'
import Sorting from '../../components/common/sorting'
import { DEVMODE } from '../../utils/Constants';
import { getSessionKey, removeSessionKey, setSessionKey, formateDate, TrimText, capitalizeFirstLetter, formateDateWithMonth, getCompany, formateDateWithTime } from '../../helpers/commonFunctions';
import Loader from '../../components/common/loader'
import Breadcrums from '../../components/common/breadcrumbs'
import StatusFilter from '../../components/common/statusFilter'
import CustomRangepicker from '../../components/common/rangepicker'
import { showFilterlist } from '../../utils/Constants'
import $ from 'jquery'
import toHtml from 'html-react-parser'
import { CancelSubscription, GetSubscriptionsHistory, GetSubscriptionsList, RenewSubscription, UpgradeSubscription } from '../../services/subscriptionService'
import { Formik, useFormik } from 'formik'
import { Button, Modal } from 'rsuite'
import * as Yup from "yup";
import CustomPagination from '../../components/common/customPaginationAdmin'
import ViewAllDetails from '../company/viewAllDetails'



const SubscriptionHistory = () => {
    const params = useParams()
    const location = useLocation()
    const navigate = useNavigate()
    const [list, setList] = useState([])
    const [statsupdate, setStatusUpdate] = useState("false")
    const [serialno, setSerialNo] = useState("")
    const [datalength, setDataLength] = useState()
    const [itemperpage, setItemPerPage] = useState(10)
    const [sorting, setSorting] = useState({});
    const [defaultSorting, setDefaultSorting] = useState(true);
    const [devmode, setDevmode] = useState(false);
    const [page, setPage] = useState(1);
    const [loader, setLoader] = useState(true)
    const [search, setSearch] = useState({})
    const [globalsearch, setGlobalSearch] = useState("")
    const breadcrumbs = [{ title: "Dashboard", url: "/admin/dashboard" },{title: "Companies",url: "/admin/company/list/1"}, { title: "Subscription History", url: "" }]
    const [resetdate, setResetDate] = useState(false)
    const [show, setShow] = useState(false);
    const [listData, setListData] = useState([])
    const [subscriptionId, setSubscriptionID] = useState();
    const [historyId, setHistoryID] = useState();

    useEffect(() => {
        if (search) {
            setLoader(true)
            const formData = new FormData()
            formData.append("page", params.pgno)
            formData.append("per_page", itemperpage)
            formData.append("sort", JSON.stringify(sorting))
            formData.append("search", JSON.stringify(search))
            formData.append("global_search", globalsearch)
            formData.append("company_id", params?.id)
            GetSubscriptionsHistory(formData).then(data => {
                setDataLength(data.data.response.count);
                setList(data && data.data && data.data.response ? data.data.response.rows : [])
                setLoader(false)
            }).catch(error => {
                console.log("error ====> ", error);
            })
        }
    }, [globalsearch, statsupdate, page,params.pgno])


    useEffect(() => {
            const formData = new FormData()
            formData.append("page", 1)
            // formData.append("per_page", itemPerPage)
            // formData.append("sort", JSON.stringify(sorting))
            // formData.append("search", JSON.stringify(search))
            // formData.append("global_search", globalSearch)
            GetSubscriptionsList({}).then(data => {
                setDataLength(data.data.response.count);
                setListData(data && data.data && data.data.response ? data.data.response.rows : [])
            }).catch(error => {
                console.log("error ====> ", error);
            })
    }, [])

    function cancelSubscription(row) {
        Swal.fire({
            customClass: 'swal-wide',
            title: 'Are you sure?',
            text: "You want to cancel this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#403fad',
            cancelButtonColor: '#f1388b',
            confirmButtonText: 'Yes, cancel it!'
        }).then((result) => {
            if (result.isConfirmed) {
                const formData = new FormData()
                formData.append("company_id", params?.id);
                formData.append("history_id", row);
                CancelSubscription(formData).then(response => {
                    if (response.data.status === 1 || response.data.status === "1") {
                        Swal.fire({
                            customClass: 'swal-wide',
                            icon: 'success',
                            title: response?.data?.message,
                            showConfirmButton: false,
                            timer: 1500,
                            toast: true,
                            position: 'top-right',
                        });
                        setStatusUpdate(!statsupdate);
                    } else {
                        Swal.fire({
                            customClass: 'swal-wide',
                            icon: 'success',
                            title: response.data.error[0].msg,
                            showConfirmButton: false,
                            timer: 5000,
                            toast: true,
                            position: 'top-right',
                        })
                    }
                }).catch(error => {
                    console.log("deleteError")
                });
            }
        })
    }


    function renewSubscription(row) {
        Swal.fire({
            customClass: 'swal-wide',
            title: 'Are you sure?',
            text: "You want to renew this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#403fad',
            cancelButtonColor: '#f1388b',
            confirmButtonText: 'Yes, renew it!'
        }).then((result) => {
            if (result.isConfirmed) {
                const formData = new FormData()
                formData.append("company_id", params?.id);
                formData.append("subscription_id", row?.subscription_id);
                formData.append("history_id", row?.id);
                RenewSubscription(formData).then(response => {
                    if (response.data.status === 1 || response.data.status === "1") {
                        Swal.fire({
                            customClass: 'swal-wide',
                            icon: 'success',
                            title: 'Subscription renew successfully',
                            showConfirmButton: false,
                            timer: 1500,
                            toast: true,
                            position: 'top-right',
                        });
                        setStatusUpdate(!statsupdate);
                    } else {
                        Swal.fire({
                            customClass: 'swal-wide',
                            icon: 'success',
                            title: response.data.error[0].msg,
                            showConfirmButton: false,
                            timer: 5000,
                            toast: true,
                            position: 'top-right',
                        })
                    }
                }).catch(error => {
                    console.log("deleteError")
                });
            }
        })
    }

    function upgradeSubscription(row) {
        Swal.fire({
            customClass: 'swal-wide',
            title: 'Are you sure?',
            text: "You want to upgrade this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#403fad',
            cancelButtonColor: '#f1388b',
            confirmButtonText: 'Yes, upgrade it!'
        }).then((result) => {
            if (result.isConfirmed) {
                const formData = new FormData()
                formData.append("company_id", params?.id);
                formData.append("subscription_id", row);
                RenewSubscription(formData).then(response => {
                    if (response.data.status === 1 || response.data.status === "1") {
                        Swal.fire({
                            customClass: 'swal-wide',
                            icon: 'success',
                            title: 'Subscription upgrade successfully',
                            showConfirmButton: false,
                            timer: 1500,
                            toast: true,
                            position: 'top-right',
                        });
                    } else {
                        Swal.fire({
                            customClass: 'swal-wide',
                            icon: 'success',
                            title: response.data.error[0].msg,
                            showConfirmButton: false,
                            timer: 5000,
                            toast: true,
                            position: 'top-right',
                        })
                    }
                }).catch(error => {
                    console.log("deleteError")
                });
            }
        })
    }

    return (
        <Layout>
            <Breadcrums data={breadcrumbs} />
            <div className="animation_fade">
                <div className="card custom-card">
                    <div className="card-body">
                    <ViewAllDetails tab='company-details'/>
                        <div className='d-flex justify-content-between align-items-center mb-4'>
                            <h6 className="main-content-label">Subscription History</h6>
                            {/* <div className='d-flex align-items-center'>
                                <div className='form-group mb-0 me-3'>
                                    <div className="form-group mb-0 rangepicker_container">
                                        <i className="fa fa-search calender_icon"></i>
                                        <input type="search" className="form-control" value={globalsearch} placeholder="Search" onChange={(e) => { setGlobalSearch(e.target.value) }} />
                                    </div>
                                </div>
                                <div className="me-3">
                                    <CustomRangepicker GetDateRange={(e) => { prepareSearch("created", e) }} resetdate={resetdate}/>
                                </div>
                                <div className="me-3">
                                    <StatusFilter data={showFilterlist} prepareSearch={prepareSearch} />
                                </div>
                                <button type="reset" value='Reset' onClick={resetFilter} className="btn btn-warning float-right mr-2">Reset Filter</button>
                            </div> */}
                        </div>
                        <div className="table-responsive">
                            <table className="table table-bordered border-t0 key-buttons text-nowrap w-100" style={{ tableLayout: 'fixed', overflow: 'hidden' }}>
                                <thead>
                                    <tr>
                                        <th width="10%">
                                            <div className='sorting_column'>
                                                <span>Company Name</span>
                                                {/* <Sorting sort={sorting} handleSort={handleSort} column="title" /> */}
                                            </div>
                                        </th>
                                        <th width="20%">
                                            <div className='sorting_column'>
                                                <span>Company Email</span>
                                                {/* <Sorting sort={sorting} handleSort={handleSort} column="title" /> */}
                                            </div>
                                        </th>
                                        <th width="10%">
                                            <div className='sorting_column'>
                                                <span>Subscription Name</span>
                                                {/* <Sorting sort={sorting} handleSort={handleSort} column="title" /> */}
                                            </div>
                                        </th>
                                        <th width="10%">
                                            <div className='sorting_column'>
                                                <span>Amount</span>
                                                {/* <Sorting sort={sorting} handleSort={handleSort} column="slug" /> */}
                                            </div>
                                        </th>
                                        <th width="10%">
                                            <div className='sorting_column'>
                                                <span>Month</span>
                                                {/* <Sorting sort={sorting} handleSort={handleSort} column="type" /> */}
                                            </div>
                                        </th>
                                        <th width="10%">
                                            <div className='sorting_column'>
                                                <span>Purchase Date</span>
                                                {/* <Sorting sort={sorting} handleSort={handleSort} column="type" /> */}
                                            </div>
                                        </th>
                                        <th width="10%">
                                            <div className='sorting_column'>
                                                <span>Expiry Date</span>
                                            </div>
                                        </th>
                                        <th width="5%">
                                            <div className='sorting_column'>
                                                <span>Status</span>
                                            </div>
                                        </th>
                                        {/* <th width="10%">
                                            <div className='sorting_column'>
                                                <span>Status</span>
                                            </div>
                                        </th> */}
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {loader ?
                                        <tr>
                                            <td colSpan={9}><Loader /></td>
                                        </tr> : <>
                                            {list.length ? list.map((row, i) => {
                                                return <tr key={i}>
                                                    <td>{row?.company?.name ? capitalizeFirstLetter(row?.company?.name) : "N/A"}</td>
                                                    <td>{row?.company?.official_email ? (row?.company?.official_email) : "N/A"}</td>
                                                    <td>{row?.subscription?.name ? capitalizeFirstLetter(row.subscription.name) : "N/A"}</td>
                                                    <td>{row?.subscription?.amount ? (row.subscription.amount + " " + "SAR") : "N/A"}</td>
                                                    <td>{row?.subscription?.month ? (row.subscription.month) : "N/A"}</td>
                                                    <td>{row?.createdAt ? formateDateWithTime(row.createdAt) : "N/A"}</td>
                                                    <td>{row?.createdAt ? formateDateWithMonth(row.createdAt, row?.subscription?.month) : "N/A"}</td>
                                                    <td>{row?.subscription_status && i == 0 ? 'Active' : 'Cancelled'}</td>
                                                    {/* <td>{row?.company_subscription?.subscription_id == null  ? 'Cancelled' : 'Active'}</td> */}
                                                    <td>
                                                        <div className="d-flex">
                                                            {i == 0 || row?.subscription_status === true ?
                                                            <button disabled = {i!==0 || row?.subscription_status === false} className="btn ripple btn-main-primary signbtn" onClick={() => { cancelSubscription(row?.id) }}>Cancel</button>
                                                            :<button ></button>}
                                                             {i == 0 || row?.subscription_status === true ?
                                                            <button  className="btn ripple btn-secondary ms-2" onClick={() => { renewSubscription(row) }}>Renew</button>
                                                            :<button ></button>}
                                                             {i == 0 || row?.subscription_status === true ?
                                                            <button  className="btn ripple btn-success ms-2" onClick={() => { setShow(true);setHistoryID(row?.id) }}>Upgrade</button>
                                                            :<button ></button>}
                                                        </div>
                                                    </td>
                                                </tr>
                                            }) : <tr><td colSpan={9} className="text-center">No records</td></tr>}
                                        </>}

                                </tbody>
                            </table>
                        </div>
                        <div className="" id="example1_info" role="status" aria-live="polite"><b>Total Records : {datalength ? datalength : "0"}</b></div>
                        {datalength && datalength > 0 ?
                            <CustomPagination datalength={datalength} itemperpage={itemperpage} currentPage={page} setPage={setPage} pageRoute={[{ name: "subscription-history", path: `/admin/company/view/subscription-history/list/${params?.id}` }]} /> : ""}
                    </div>
                </div>
                <Modal
                    size={"lg"}
                    className="invitation-modal modal-height-auto"
                    open={show}
                    onClose={() => {
                        setShow(false);
                    }}
                    backdrop={'static'}
                >
                    <Modal.Header className="mb-3">
                        <Modal.Title>Select Subscription</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <Formik
                                initialValues={{
                                    subscription_id: '',
                                }}
                                //  validationSchema={inviteValidationSchema}
                                onSubmit={(values) => {
                                    const formData = new FormData()
                                    formData.append("company_id", params?.id);
                                    formData.append("subscription_id", subscriptionId);
                                    formData.append("history_id", historyId);
                                    UpgradeSubscription(formData).then(response => {
                                        if (response.data.status === 1 || response.data.status === "1") {
                                            Swal.fire({
                                                customClass: 'swal-wide',
                                                icon: 'success',
                                                title: 'Subscription upgrade successfully',
                                                showConfirmButton: false,
                                                timer: 1500,
                                                toast: true,
                                                position: 'top-right',
                                            });
                                            setStatusUpdate(!statsupdate);
                                            setShow(false);
                                        } else {
                                            Swal.fire({
                                                customClass: 'swal-wide',
                                                icon: 'success',
                                                title: response.data.error[0].msg,
                                                showConfirmButton: false,
                                                timer: 5000,
                                                toast: true,
                                                position: 'top-right',
                                            })
                                            setShow(false);
                                        }
                                    }).catch(error => {
                                        console.log("deleteError")
                                    });
                                }}
                            >
                                {({
                                    values,
                                    errors,
                                    handleChange,
                                    handleBlur,
                                    touched,
                                    handleSubmit,
                                    setFieldValue,
                                }) => (
                                    <form onSubmit={handleSubmit}>
                                        <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                                            <div className="row">
                                                <div className="col-lg-6 text-center form-group mt-4">
                                                    <label className="text-left d-flex">Select Subscription Plan:</label>
                                                    <div className="form-group">
                                                        <div className="select-down-arrow">
                                                            <select
                                                                className="form-control select2"
                                                                value={values.subscription_id}
                                                                onChange={(e) => {
                                                                    setFieldValue('subscription_id', e.target.value);
                                                                    setSubscriptionID(e.target.value);
                                                                }}
                                                            >
                                                                <option value="" label="Select">
                                                                    Select
                                                                </option>
                                                                {listData && listData.length > 0 && listData.map((option, i) => (
                                                                    <option key={i} value={option.id}>
                                                                        {option?.name}
                                                                    </option>
                                                                ))}
                                                            </select>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <Modal.Footer className="mt-4">
                                                <Button
                                                    onClick={() => {
                                                        setShow(false);
                                                    }}
                                                    appearance="subtle"
                                                >
                                                    Close
                                                </Button>
                                                <Button
                                                    type="submit"
                                                    appearance="primary"
                                                >
                                                    Submit
                                                </Button>
                                            </Modal.Footer>
                                        </div>
                                    </form>
                                )}
                            </Formik>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        </Layout>
    )
}

export default SubscriptionHistory