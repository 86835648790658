
import React, { useEffect, useMemo, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { GetSingleInventory } from '../../services/company_panel/inventoryService'
import toHtml from 'html-react-parser'
import { capitalizeAllFirstLetters, capitalizeFirstLetter, formateDate, getBrandId, getOutletId, removeHyphens, removeSpecialCharacter, TrimText } from '../../helpers/commonFunctions';
import Breadcrums from '../../components/company_panel/common/breadcrumbs';
import Loader from '../../components/company_panel/common/loader'
import { allergyImageMap } from '../../utils/Constants';


const InventoryView = () => {
    const navigate = useNavigate()
    const params = useParams()
    const [showDefault, setShowDefault] = useState({})
    const breadcrumbs = [{ title: "Inventory", url: "/company/raw_materials/inventory/list/1" }, { title: "View", url: "" }]
    const [isOutletChanged, setIsOutletChanged] = useState(false);
    const [isBrandChanged, setIsBrandChanged] = useState(false);
    const [currentBrand] = useState(getBrandId());

    useMemo(() => {
        document.addEventListener('brand-changed', function () {
            setIsBrandChanged(!isBrandChanged);
        });
        document.addEventListener('outlet-changed', function () {
            setIsOutletChanged(!isOutletChanged);
        });
        setTimeout(() => {
            if (currentBrand != getBrandId()) {
                navigate(`/company/raw_materials/inventory/list/${1}`);
            }
        }, 100);
    });

    useEffect(() => {
        setTimeout(() => {
            GetSingleInventory({ id: params.id, outlet_id: getOutletId() }).then(data => {
                setShowDefault(data && data.data && data.data.response ? data.data.response : [])
            }).catch(error => {
                console.log("error=====>", error)
            })
        }, 200);
    }, [isOutletChanged]);

    useMemo(() => {
        document.addEventListener('outlet-changed', function () {
            setIsOutletChanged(!isOutletChanged);
        });
    });

    return (
        <>
            <div className="page-header d-flex align-items-center">
                <div className="page-header-left">
                    <Breadcrums data={breadcrumbs} />
                    <h4>Inventory</h4>
                </div>
            </div>
            <div className="card custom-card">
                {showDefault && Object.keys(showDefault).length > 0 ?
                    <div className="card-body">
                        <div className="card custom-card">
                            <div className="card-body">
                                <h3 className="main-content-label mb-3">Inventory Info</h3>

                                <div className="col-lg-12 form-group">
                                    <table id="simple-table" className="table ">
                                        <tbody>
                                            <tr>
                                                <td className='text-start'>Category</td>
                                                <td className='text-end'>{showDefault && showDefault?.category_id ? capitalizeAllFirstLetters(showDefault?.category?.category?.name) : "N/A"}</td>
                                            </tr>
                                            <tr>
                                                <td className='text-start'>Sub category</td>
                                                <td className='text-end'>{showDefault && showDefault?.sub_category_id ? capitalizeAllFirstLetters(showDefault?.category?.name) : "N/A"}</td>
                                            </tr>
                                            <tr>
                                                <td className='text-start'>Item type </td>
                                                <td className='text-end' >{showDefault && showDefault?.item_type ? removeHyphens(capitalizeAllFirstLetters(showDefault?.item_type)) : "N/A"}</td>
                                            </tr>
                                            {showDefault?.item_type === 'production' &&
                                                <tr>
                                                    <td className='text-start'>Production item </td>
                                                    <td className='text-end' >{showDefault && showDefault?.item_type ? removeHyphens(capitalizeAllFirstLetters(showDefault?.production_item)) : "N/A"}</td>
                                                </tr>
                                            }
                                            <tr>
                                                <td className='text-start'>Item name </td>
                                                <td className='text-end' >{showDefault && showDefault?.item_type ? removeHyphens(capitalizeAllFirstLetters(showDefault?.item_name)) : "N/A"}</td>
                                            </tr>

                                            <tr>
                                                <td className='text-start'>Primary unit of measure</td>
                                                <td className='text-end'>{showDefault && showDefault?.uom ? showDefault?.uom?.uom : "N/A"}</td>
                                            </tr>
                                            <tr>
                                                <td className='text-start'>Self life </td>
                                                <td className='text-end' >{showDefault && showDefault?.self_life ? showDefault?.self_life : "N/A"}</td>
                                            </tr>
                                            <tr>
                                                <td className='text-start'>Calories as per primary unit</td>
                                                <td className='text-end' >{showDefault && showDefault?.calories_as_per_primary_unit ? showDefault?.calories_as_per_primary_unit : "N/A"}</td>
                                            </tr>
                                            <tr>
                                                <td className='text-start'>Price of raw material</td>
                                                <td className='text-end' >{showDefault && showDefault?.raw_material_price ? showDefault?.raw_material_price : "N/A"}</td>
                                            </tr>
                                            <tr>
                                                <td className='text-start'>Stock (quantity)</td>
                                                <td className='text-end' >{showDefault && showDefault?.inventory_stocks[0] ? showDefault?.inventory_stocks[0]?.quantity : "N/A"}</td>
                                            </tr>
                                            <tr>
                                                <td className='text-start'>Can change later</td>
                                                <td className='text-end' >{showDefault && showDefault?.can_change_later ? "YES" : "NO"}</td>
                                            </tr>
                                            <tr>
                                                <td className='text-start'>Transferable</td>
                                                <td className='text-end' >{showDefault && (showDefault?.transferable === true || showDefault?.transferable === 'true') ? "YES" : "NO"}</td>
                                            </tr>
                                            <tr>
                                                <td className='text-start'>Duration</td>
                                                <td className='text-end' >{showDefault && showDefault?.duration ? capitalizeFirstLetter(showDefault?.duration) : "N/A"}</td>
                                            </tr>
                                            <tr>
                                                <td className='text-start'>Company</td>
                                                <td className='text-end' >{showDefault && showDefault?.company_id ? capitalizeAllFirstLetters(showDefault?.company.name) : "N/A"}</td>
                                            </tr>
                                            <tr>
                                                <td className='text-start'>Allergies</td>
                                                <td className='text-end' >
                                                    {showDefault && showDefault?.allergies.length > 0
                                                        ? showDefault.allergies.map((allergy, index) => {
                                                            const capitalizedAllergy = capitalizeAllFirstLetters(allergy);
                                                            const allergyImage = allergyImageMap[allergy.toLowerCase()];
                                                            return (
                                                                <span key={index}>
                                                                    {allergyImage && (
                                                                        <img
                                                                            src={allergyImage}
                                                                            alt={capitalizedAllergy}
                                                                            style={{ width: '20px', height: '20px', marginRight: '8px' }}
                                                                        />
                                                                    )}
                                                                    {capitalizedAllergy}
                                                                    {index < showDefault.allergies.length - 1 && ', '}
                                                                </span>
                                                            );
                                                        })
                                                        : "N/A"
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className='text-start'>Status </td>
                                                <td className='text-end' >{showDefault && showDefault?.status ? "Active" : "Inactive"}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div className="card custom-card  ">
                            <div className="card-body p-0">
                                <h1 className='p-3'>Unit of conversion</h1>
                                <table className="table table-custom p-0 w-100 table-sm w-100" style={{ padding: '0!important' }}>

                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>UOM</th>
                                            <th>Conversion</th>
                                            <th>Price</th>
                                            <th>Calories</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Primary Unit of Measurement</td>
                                            <td className='col-2'> {showDefault?.uom?.uom_code} </td>
                                            <td className='col-2'>{showDefault?.inventory_unit_converisons[0]?.conversion} </td>
                                            <td className='col-2'>{showDefault?.inventory_unit_converisons[0]?.price} </td>
                                            <td className='col-2'>{showDefault?.inventory_unit_converisons[0]?.calories} </td>
                                        </tr>
                                        <tr>
                                            <td className='col-1'>Transfer Unit of Measure</td>
                                            <td className='col-2'> {showDefault?.inventory_unit_converisons[1]?.uomDetail?.uom_code} </td>
                                            <td className='col-2'>{showDefault?.inventory_unit_converisons[1]?.conversion} </td>
                                            <td className='col-2'>{showDefault?.inventory_unit_converisons[1]?.price} </td>
                                            <td className='col-2'>{showDefault?.inventory_unit_converisons[1]?.calories} </td>
                                        </tr>
                                        <tr>
                                            <td className='col-1'>Usage Unit of Measure</td>
                                            <td className='col-2'> {showDefault?.inventory_unit_converisons[2]?.uomDetail?.uom_code} </td>
                                            <td className='col-2'>{showDefault?.inventory_unit_converisons[2]?.conversion} </td>
                                            <td className='col-2'>{showDefault?.inventory_unit_converisons[2]?.price} </td>
                                            <td className='col-2'>{showDefault?.inventory_unit_converisons[2]?.calories} </td>
                                        </tr>
                                        <tr>
                                            <td className='col-1'>Count Unit of Measurement</td>
                                            <td className='col-2'> {showDefault?.inventory_unit_converisons[3]?.uomDetail?.uom_code} </td>
                                            <td className='col-2'>{showDefault?.inventory_unit_converisons[3]?.conversion} </td>
                                            <td className='col-2'>{showDefault?.inventory_unit_converisons[3]?.price} </td>
                                            <td className='col-2'>{showDefault?.inventory_unit_converisons[3]?.calories} </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    : <Loader />}

                <div className='my-4 mx-2'>
                    <Link to={`/company/raw_materials/inventory/1/edit/${params.id}`} className="btn btn-primary ms-2 align-content-center">Edit Inventory</Link>
                    <Link to={`/company/store_manager/transfer_stock/transfer_out/list/1`} className="btn btn-outline-primary ms-2 align-content-center">Transfer Stock</Link>
                </div>
            </div>
        </>
    )
}

export default InventoryView