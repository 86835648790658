import React, { useState, useEffect, useMemo } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import CustomPagination from '../../../components/company_panel/common/custompagination'
import $ from "jquery";
import { capitalizeAllFirstLetters, checkPermission, getBrandId } from '../../../helpers/commonFunctions';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ConfigurationTab from '../configurationTab';
import { DeleteMenuItems, ListMenuItems } from '../../../services/company_panel/categoryService';
import notFound from '../../../assets/company/img/no-record-found.png'
import GlobalLoader from '../../../components/company_panel/common/globalLoader';

const MenuList = () => {
    const navigate = useNavigate()
    const params = useParams()
    const location = useLocation()
    const [list, setList] = useState([])
    const [statsUpdate, setStatusUpdate] = useState("false")
    const [dataLength, setDataLength] = useState()
    const [itemPerPage] = useState(9)
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState({})
    const [globalSearch, setGlobalSearch] = useState("")
    const [resetdate, setResetDate] = useState(false);
    const [isBrandChanged, setIsBrandChanged] = useState(false);
    const [loadingData, setLoadingData] = useState(false);

    useMemo(() => {
        document.addEventListener('brand-changed', function () {
            setIsBrandChanged(!isBrandChanged);
        });
    });


    useEffect(() => {
        if (search) {
            setLoadingData(true);
            const formData = new FormData()
            formData.append("page", params.id)
            formData.append("per_page", itemPerPage)
            formData.append("search", JSON.stringify(search))
            formData.append("global_search", globalSearch)
            formData.append('brand_id', getBrandId());
            ListMenuItems(formData).then(data => {
                setDataLength(data.data.response.count);
                setList(data && data.data && data.data.response ? data.data.response.rows : []);
                setLoadingData(false);
            }).catch(error => {
                setLoadingData(false);
                console.log("error ====> ", error);
            })
        }
    }, [location, statsUpdate, search, globalSearch, isBrandChanged])


    const resetFilter = (e) => {
        e.preventDefault()
        setGlobalSearch("")
        setSearch({})
        prepareSearch()
        setResetDate(!resetdate)
    }

    function deleteFunction(row) {
        Swal.fire({
            customClass: 'swal-wide',
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#2f9bff',
            cancelButtonColor: '#dc3545',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                const formdata = new FormData()
                formdata.append("id", row)
                DeleteMenuItems(formdata).then(data => {
                    if (data.status === 200) {
                        Swal.fire({
                            customClass: 'swal-wide',
                            position: 'absolute',
                            icon: 'success',
                            title: 'Menu deleted successfully',
                            showConfirmButton: false,
                            timer: 2000,
                            toast: true,
                            position: 'top-right',
                        })
                        setStatusUpdate(!statsUpdate)
                    }
                }).catch(error => {
                    console.log("deleteError")
                })
            }
        })
    }

    const prepareSearch = (key, value) => {
        let sr = { ...search };
        if (String(value).length > 0) {
            sr[key] = value;
        } else {
            delete sr[key];
        }
        setSearch(sr);
    }

    const editFunction = (id) => {
        navigate(`/company/menu/menu/menu_card/edit/${id}`);
    }

    return (
        <>
            {loadingData && <GlobalLoader />}
            <div className="animation_fade">
                <div className='page-header d-flex align-items-center'>
                    <div className='page-header-left'>
                        <h4>Menu</h4>
                    </div>
                    <div className='page-header-right ms-auto'>

                    </div>
                </div>
                <div className="card custom-card overflow-hidden">
                    <div className='gap-20 border-bottom'>
                        <div className='col-12'>
                            <div className='d-flex justify-content-between align-items-center'>
                                <ConfigurationTab tab='menu' />
                            </div>
                        </div>
                    </div>

                    <div className='gap-20 ps-0 pb-0 pe-0'>
                    </div>
                    <div className="gap-20">
                        <div className='page-header d-flex align-items-center mb-0'>
                            <div className='page-header-left'>

                                <div className='gap-2'>
                                    <div className='filter-header d-flex justify-content-between align-items-center'>
                                        <div className='d-flex gap-3 align-items-center'>
                                            <div className="form-group mb-0 rangepicker_container">
                                                <input
                                                    type="search"
                                                    className="form-control"
                                                    value={globalSearch}
                                                    placeholder="Search"
                                                    onChange={(e) => { setGlobalSearch(e.target.value) }}
                                                />
                                            </div>
                                        </div>
                                        <div className='d-flex gap-4 align-items-center' style={{ marginLeft: '10px' }} >
                                            <button type="reset" value='Reset' onClick={resetFilter} className="btn btn-primary mr-2">Reset Filter</button>
                                        </div>
                                    </div>
                                </div>


                            </div>
                            <div className='page-header-right ms-auto'>
                            {checkPermission('store_menus', 'add') ?
                                <button className="btn btn-primary" type="button" onClick={(e) => navigate(`/company/menu/menu/menu_card/addMenu`)}>Add Menu</button>
                                : '' }
                            </div>
                        </div>
                    </div>
                    <div className="tabler-wrap">

                        <div className="table-responsive">

                            <div className='card gap-20'>
                                <div className='row plan-row'>
                                    <div className="row">
                                        {
                                            list.length ? (
                                                list.map((row, i) => (
                                                    <div className="col-md-4 mb-4" key={i}>
                                                        <div className="plan-box">
                                                            <div className="plan-head d-flex justify-content-between">
                                                                <div className="plan-left">
                                                                    <h4>{capitalizeAllFirstLetters(row?.name)} </h4>

                                                                </div>

                                                            </div>
                                                            <div className="plan-body">
                                                            </div>
                                                            <div className="plan-footer d-flex gap-3">
                                                            {checkPermission('store_menus', 'edit') ?
                                                                <button className="btn btn-primary" onClick={() => { editFunction(row.id) }}>Edit</button>
                                                                : '' }
                                                                 {checkPermission('store_menus', 'delete') ?
                                                                <button className="btn btn-danger" onClick={() => { deleteFunction(row.id) }}>Delete</button>
                                                                : '' }
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            ) : (
                                                <div className='text-center'>
                                                    <img src={notFound} style={{ width: "5%" }} alt="Not Found" />
                                                    <p>No menu available.</p>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className='tabler-footer'>
                            <div className='d-flex align-items-center'>
                                <div id="example1_info" role="status" className="table-result" aria-live="polite"><b>Total Records : {dataLength ? dataLength : "0"}</b></div>
                                {dataLength && dataLength > 0 ?
                                    <CustomPagination datalength={dataLength} itemperpage={itemPerPage} setPage={setPage} currentPage={page} pageRoute={[{ name: "menu", path: "/company/menu/menu/menu_card/list" }]} /> : ""}
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer
                    position="top-right"
                    hideProgressBar={false}
                    autoClose={true | 1000}
                    newestOnTop={true}
                    closeOnClick={false}
                    draggable={false}
                    rtl={false}
                />

            </div>
        </>
    );
}

export default MenuList