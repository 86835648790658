import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { capitalizeAllFirstLetters, capitalizeFirstLetter, getOutletId, removeHyphens } from '../../helpers/commonFunctions';
import Breadcrums from '../../components/company_panel/common/breadcrumbs';
import Loader from '../../components/company_panel/common/loader'
import { WasteDetails } from '../../services/company_panel/wasteManagement.services';


const WasteManagementView = () => {
    const navigate = useNavigate();
    const params = useParams();
    const [showDefault, setShowDefault] = useState({});
    const breadcrumbs = [{ title: "Waste Management", url: "/company/store_manager/waste_management/list/1" }, { title: "View Waste", url: "" }];
    const [isBrandChanged, setIsBrandChanged] = useState(false);
    const [currentOutlet] = useState(getOutletId());
    const [isOutletChanged, setIsOutletChanged] = useState(false);

    useMemo(() => {
        document.addEventListener('brand-changed', function () {
            setIsBrandChanged(!isBrandChanged);
        });
        document.addEventListener('outlet-changed', function () {
            setIsOutletChanged(!isOutletChanged);
        });
        setTimeout(() => {
            if (currentOutlet != getOutletId()) {
                navigate(`/company/store_manager/waste_management/list/${1}`);
            }
        }, 200);
    });

    useEffect(() => {
        WasteDetails({ id: params.id }).then(data => {
            setShowDefault(data && data.data && data.data.response ? data.data.response : [])
        }).catch(error => {
            console.log("error=====>", error)
        })
    }, [])

    return (
        <>
            <div className="page-header d-flex align-items-center">
                <div className="page-header-left">
                    <Breadcrums data={breadcrumbs} />
                    <h4>View Waste</h4>
                </div>
            </div>
            <div className="card custom-card">
                {showDefault && Object.keys(showDefault).length > 0 ?
                    <div className="card-body p-0">
                        <div className="col-lg-12 form-group">
                            <table id="simple-table" className="table table-custom">
                                <tbody>
                                    <tr >
                                        <td>Item Name</td>
                                        <td className='text-end'>{showDefault && showDefault.item_type == 'raw-material' ? capitalizeAllFirstLetters(showDefault.inventory.item_name) : capitalizeAllFirstLetters(showDefault.menu_item.item_name)}</td>
                                    </tr>
                                    {/* <tr>
                                        <td>Category</td>
                                        <td className='text-end'>{showDefault && showDefault.category.category.name ? capitalizeAllFirstLetters(showDefault.category.category.name) : "N/A"}</td>
                                    </tr>
                                    <tr>
                                        <td>Sub Category</td>
                                        <td className='text-end'>{showDefault && showDefault.category.name ? capitalizeAllFirstLetters(showDefault.category.name) : "N/A"}</td>
                                    </tr> */}
                                    <tr>
                                        <td>Item Type</td>
                                        <td className='text-end'>{showDefault && showDefault.item_type ? removeHyphens(capitalizeAllFirstLetters(showDefault.item_type)) : "N/A"}</td>
                                    </tr>
                                    <tr>
                                        <td>Quantity</td>
                                        <td className='text-end'>{showDefault && showDefault.quantity ? showDefault.quantity : "N/A"}</td>
                                    </tr>
                                    <tr>
                                        <td>Reason For Waste</td>
                                        <td className='text-end'>{showDefault && showDefault.waste_management_reason.reason ? capitalizeFirstLetter(showDefault.waste_management_reason.reason) : "N/A"}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <button className="btn btn-primary m-4" onClick={() => navigate(`/company/store_manager/waste_management/${params.id}/edit/${params.id}`)}>Update Waste</button>
                        </div>
                    </div> : <Loader />}
            </div>
        </>
    )
}

export default WasteManagementView