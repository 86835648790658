import React, { useEffect, useMemo, useState } from 'react';
import { Card, Container, Row, Col, Form, Button, Table, Modal, InputGroup, FormControl } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Breadcrums from '../../components/company_panel/common/breadcrumbs';
import { getBrandId, getCompany } from '../../helpers/commonFunctions';
import { GetAllSuppliers, getInventoriesFromSupplier } from '../../services/company_panel/supplierService';
import { GetBrandOutletsList } from '../../services/company_panel/outletService';
import { CreatePurchaseOrder } from '../../services/company_panel/purchaseOrderService';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { Link, useHistory } from 'react-router-dom';
import { Loader } from 'semantic-ui-react';

const OrderAdd = () => {
  const [supplier, setSupplier] = useState();
  const [suppliers, setSuppliers] = useState([]);
  const [outlets, setOutlets] = useState([]);
  const [inventories, setInventories] = useState([]);
  const [selectedOutlet, setSelectedOutlet] = useState('');
  const [deliveryDate, setDeliveryDate] = useState('');
  const [arrayData, setArrayData] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]); // New state for selected items
  const [tableItems, setTableItems] = useState([]); 
  const [isItem, setIsItem] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [loader, setLoader] = useState(true);
  const [isBrandChanged, setIsBrandChanged] = useState(false);

  const navigate = useNavigate();
  const breadcrumbs = [
    { title: "Purchase Order", url: "/company/store_manager/purchase_order/list/1" },
    { title: "Create Order", url: "" },
  ];

  useMemo(() => {
    document.addEventListener('brand-changed', function () {
      setIsBrandChanged(!isBrandChanged);
    });
  });

  useEffect(() => {
    GetAllSuppliers({ brand_id: getBrandId() }).then(data => {
      setSuppliers(data?.data?.response || []);
      setLoader(false);
    }).catch(error => {
      console.log("error ====> ", error);
    });

    GetBrandOutletsList({ brand_id: getBrandId() }).then(data => {
      setOutlets(data?.data?.response || []);
      setLoader(false);
    }).catch(error => {
      console.log("error ====> ", error);
    });
    setArrayData([]);
    setTableItems([]);
  }, [isBrandChanged]);

  const getInventories = () => {
    if (supplier) {
      getInventoriesFromSupplier({ id: supplier }).then(data => {
        const supplierInventoriesRelations = data?.data?.response?.rows || [];
        setInventories(supplierInventoriesRelations);
      }).catch(error => {
        console.log("error=====>", error);
      });
    }
  };
  const getTodayDate = () => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    const year = tomorrow.getFullYear();
    let month = tomorrow.getMonth() + 1;
    let day = tomorrow.getDate();

    if (month < 10) {
      month = ` 0${month}`;
    }
    if (day < 10) {
      day = `0${day}`;
    }

    return ` ${year} -${month} -${day}`;
  };

  const validationSchema = Yup.object().shape({
    brand_id: Yup.string().required('Supplier is required'),
    outlet: Yup.string().required('Outlet is required'),
    deliveryDate: Yup.date()
      .required('Delivery Date is required')
  });

  const formik = useFormik({
    initialValues: {
      deliveryDate: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      const formData = new FormData();
      formData.append("supplier_id", supplier);
      formData.append("outlet_id", selectedOutlet);
      formData.append("delivery_date", values.deliveryDate);
      formData.append("inventories", JSON.stringify(tableItems));
      formData.append("company_id", getCompany().company_id);
      formData.append("final_price", final_price);
      CreatePurchaseOrder(formData).then(response => {
        setSubmitting(false);
        setSubmitted(false);
        if (response.data.status === 1 || response.data.status === "1") {
          Swal.fire({
            customClass: 'swal-wide',
            icon: 'success',
            title: response.data.message,
            showConfirmButton: false,
            timer: 1500,
            toast: true,
            position: 'top-right',
          });
          setTimeout(() => {
            navigate(`/company/store_manager/purchase_order/list/${1}`);
          }, 1000);
        } else {
          Swal.fire({
            customClass: 'swal-wide',
            icon: 'error',
            title: response.data.message,
            showConfirmButton: false,
            timer: 5000,
            toast: true,
            position: 'top-right',
          });
          setSubmitted(false);
        }
      }).catch(error => {
        console.log("error ====> ", error);
      });
    },
  });

  const handleDecrement = (index) => {
    const updatedArrayData = [...tableItems];
    if (updatedArrayData[index].quantity > 1) {
      updatedArrayData[index].quantity -= 1;
      updatedArrayData[index].total_price = updatedArrayData[index].quantity * updatedArrayData[index].price;
      setTableItems(updatedArrayData);
    }
  };

  const handleIncrement = (index) => {
    const updatedArrayData = [...tableItems];
    updatedArrayData[index].quantity = Number(updatedArrayData[index].quantity) + 1;
    updatedArrayData[index].total_price = updatedArrayData[index].quantity * updatedArrayData[index].price;
    setTableItems(updatedArrayData);
  };

  const handleRemoveItem = (index) => {
    const updatedArrayData = tableItems.filter((_, i) => i !== index);
    setTableItems(updatedArrayData);
  };

  const handlePriceChange = (price, index) => {
    if (price < 0 || price != Math.floor(price)) {
      return;
    }
    const updatedArrayData = [...tableItems];
    updatedArrayData[index].price = price;
    updatedArrayData[index].total_price = updatedArrayData[index].quantity * updatedArrayData[index].price;
    setTableItems(updatedArrayData);
  }

  const handleAddHandler = (val) => {
    const existingItemIndex = arrayData.findIndex(item => item.inventory_id === val.inventory.id);

    if (existingItemIndex !== -1) {
      // If the item is already selected, remove it
      const updatedArrayData = arrayData.filter(item => item.inventory_id !== val.inventory.id);
      setArrayData(updatedArrayData);
    } else {
      // If the item is not selected, add it
      setArrayData([...arrayData, {
        inventory_id: val.inventory.id,
        name: val.inventory?.item_name,
        price: val.inventory.raw_material_price,
        quantity: 1,
        total_price: val.inventory.raw_material_price,
        can_change_later: val.inventory.can_change_later,
        uom: val.inventory.uom.uom
      }]);
    }
  };

  // const handleQuantityChange = (e, index) => {
  //   const newArrayData = [...arrayData];
  //   const newQuantity = parseInt(e.target.value, 10);

  //   if (newQuantity >= 1 && newQuantity <= (newArrayData[index].old_quantity - newArrayData[index].minimum_stock_limit)) {
  //     newArrayData[index].quantity = newQuantity;
  //     setArrayData(newArrayData);
  //   }
  // };
  const handleQuantityChange = (e, index) => {
    // Convert input value to a number
    const newValue = e.target.value;

    // Check if the new value is valid (positive number and up to 6 digits)
    if (!/^\d{1,6}$/.test(newValue)) {
      return; // Ignore invalid inputs
    }

    const numericValue = Number(newValue);

    if (numericValue <= 0) {
      return; // Ignore non-positive values
    }

    // Create a new array to update state
    const updatedArrayData = [...tableItems];
    updatedArrayData[index].quantity = numericValue;
    updatedArrayData[index].total_price = numericValue * updatedArrayData[index].price;

    // Update the state with the new array
    setTableItems(updatedArrayData);
  };


  const handleAddItem = () => {
    const updatedArrayData = [...arrayData, ...selectedItems];
    setArrayData(updatedArrayData);
    setTableItems(updatedArrayData);
    setSelectedItems([]);
    setShowModal(false);
    setIsItem(true);
  };

  const getTomorrowDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    let day = today.getDate();
    if (month < 10) {
      month = `0${month}`;
    }
    if (day < 10) {
      day = `0${day}`;
    }
    return `${year}-${month}-${day}`;
  };




  let final_price = 0;
  return (
    <div>
      <div className="page-header d-flex align-items-center">
        <div className="page-header-left">
          <Breadcrums data={breadcrumbs} />
          <h4>Purchase order</h4>
        </div>
      </div>

      <div className="row row-sm">
        <div className="col-lg-12">
          <Card className="custom-card p-0">
            <Card.Body>
              <Form onSubmit={formik.handleSubmit}>
                <Form.Group id="outletSelect" className="row">
                  <div className="col-lg-6 form-group mt-3">
                    <label htmlFor="brand_id" className="text-left">
                      {suppliers.length === 0 && (loader == false || loader == 'false') ?
                        <div className="alert alert-warning">
                          There is no supplier created yet. Please{' '}
                          <Link to="/company/raw_materials/supplier_manage/add">
                            create a supplier
                          </Link>{' '}
                          to assign to the purchase order.
                        </div>
                        : null}
                      Supplier: <span className="requirestar">*</span>
                    </label>


                    <InputGroup style={{ width: '100%', marginBottom: '20px' }}>
                      <Form.Control
                        as="select"
                        id="brand_id"
                        name="brand_id"
                        value={formik.values.brand_id}
                        onChange={(event) => {
                          setSupplier(event.target.value);
                          formik.handleChange(event);
                        }}
                        onBlur={formik.handleBlur}
                        isInvalid={formik.touched.brand_id && formik.errors.brand_id}
                      >

                        <option value="">Select Supplier</option>
                        {suppliers.length ? suppliers.map((supplier) => (
                          <option key={supplier.id} value={supplier.id}>{supplier.name}</option>
                        )) : ''}
                      </Form.Control>
                      {formik.touched.brand_id && formik.errors.brand_id && (
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.brand_id}
                        </Form.Control.Feedback>
                      )}
                    </InputGroup>
                  </div>


                  <div className="col-lg-6 form-group mt-3">
                    {/* Outlet Warning Message */}
                    {outlets.length === 0 && (loader == false || loader == 'false') ?
                      <div className="alert alert-warning">
                        There is no outlet created yet. Please{' '}
                        <Link to="/company/business/outlet/add">
                          create an outlet
                        </Link>{' '}
                        to assign to the purchase order.
                      </div>
                      : null}

                    {/* Outlet Field */}
                    <label htmlFor="outlet" className="text-left">
                      Outlet: <span className="requirestar">*</span>
                    </label>
                    <InputGroup style={{ width: '100%', marginBottom: '20px' }}>
                      <Form.Control
                        as="select"
                        id="outlet"
                        name="outlet"
                        value={formik.values.outlet}
                        onChange={(event) => {
                          setSelectedOutlet(event.target.value);
                          formik.handleChange(event);
                        }}
                        onBlur={formik.handleBlur}
                        isInvalid={formik.touched.outlet && formik.errors.outlet}
                      >
                        <option value="">Select Outlet</option>
                        {outlets.length ? outlets.map((outlet) => (
                          <option key={outlet.id} value={outlet.id}>{outlet?.outlet_name} { outlet?.short_code ? `(${outlet?.short_code})` : "" }</option>
                        )) : ''}
                      </Form.Control>
                      {formik.touched.outlet && formik.errors.outlet && (
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.outlet}
                        </Form.Control.Feedback>
                      )}
                    </InputGroup>
                  </div>


                  {/* Delivery Date Input */}
                  <div className="col-lg-6 form-group mt-3">
                    <label htmlFor="deliveryDate" className="text-left">
                      Delivery Date: <span className="requirestar">*</span>
                    </label>
                    <InputGroup style={{ width: '100%', marginBottom: '20px' }}>
                      <Form.Control
                        style={{ cursor: 'pointer' }}
                        type="date"
                        id="deliveryDate"
                        name="deliveryDate"
                        value={formik.values.deliveryDate}
                        onClick={(e) => e.target.showPicker()}
                        onChange={(event) => {
                          setDeliveryDate(event.target.value);
                          formik.handleChange(event);
                        }}
                        min={getTomorrowDate()}
                        onBlur={formik.handleBlur}
                        isInvalid={formik.touched.deliveryDate && formik.errors.deliveryDate}
                      />
                      {formik.touched.deliveryDate && formik.errors.deliveryDate && (
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.deliveryDate}
                        </Form.Control.Feedback>
                      )}
                    </InputGroup>
                  </div>
                </Form.Group>

                {
                  supplier ?
                    <div className="d-flex justify-content-between align-items-center mt-4 mb-4">
                      <h4 style={{ fontSize: "18px" }}>Items List</h4>

                      <b onClick={() => { setShowModal(true); getInventories(); setArrayData(tableItems) }} style={{ cursor: 'pointer' }}>
                        <FontAwesomeIcon icon={faPlus} style={{ marginRight: '10px' }} /> Browse Items
                      </b>
                    </div> : null
                }

                {tableItems.length > 0 && <table className='table table-custom m-0'>
                  <thead>
                    <tr>
                      <th>Item Name</th>
                      <th>Quantity</th>
                      <th>Unit</th>
                      <th>Price</th>
                      <th className='text-end'>Total Price</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      isItem && tableItems.map((item, index) => {
                        final_price += Number(item.total_price);
                        return <tr key={index}>
                          <td>{item.name}</td>
                          <td className="d-flex align-items-center">
                            <Button variant="outline-secondary" size="sm" onClick={() => handleDecrement(index)}>-</Button>
                            <span className="mx-2">
                              <input
                                type="number"
                                value={item.quantity}
                                onChange={(e) => handleQuantityChange(e, index)}
                                className="form-control mx-2"
                                style={{ width: '60px' }}
                                min="1"
                                max={item.old_quantity - item.minimum_stock_limit}
                              />

                            </span>
                            <Button variant="outline-secondary" size="sm" onClick={() => handleIncrement(index)}>+</Button>
                          </td>
                          <td>{item.uom}</td>
                          <td>
                            <InputGroup>
                              <InputGroup.Text style={{ borderLeft: '1px solid #e8e8e8' }}>SAR</InputGroup.Text>
                              <Form.Control
                                type="number"
                                value={item.price}
                                disabled={item.can_change_later == false}
                                onChange={(event) => { handlePriceChange(event.target.value, index) }}

                              />
                            </InputGroup>
                          </td>
                          <td className='text-end'>{item.total_price} SAR</td>
                          <td>
                            <Button variant='danger' size='sm' onClick={() => handleRemoveItem(index)}>
                              <FontAwesomeIcon icon={faTimes} />
                            </Button>
                          </td>
                        </tr>
                      })
                    }
                    <tr>
                      <td colSpan="4" className="text-end" style={{borderBottom: 'none', textDecoration: 'none'}}><strong>Final Price:</strong></td>
                      <td colSpan="1" className="text-end" style={{borderBottom: 'none', textDecoration: 'none', height: "63px", padding: "15px"}}>{final_price}  SAR</td>
                    </tr>
                  </tbody>

                </table>}

                {/* Modal for Item Selection */}
                <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
                  <Modal.Header closeButton>
                    <Modal.Title style={{ fontSize: '20px' }}>Browse Items</Modal.Title>
                  </Modal.Header>

                  <Modal.Body>
                    {inventories.length === 0 && (loader == false || loader == 'false') ?
                      <div className="alert alert-warning">
                        There is no inventories assigned to this supplier yet. Please{' '}
                        <Link to={`/company/raw_materials/supplier_manage/1/edit/${supplier}`}>
                          assign inventories to this supplier
                        </Link>{' '}
                      </div>
                      : <>
                        <FormControl type="text"
                          placeholder="Search items"
                          value={searchTerm}
                          onChange={(e) => setSearchTerm(e.target.value)}
                          className="mb-3"
                        />

                        <table className='table table-custom'>
                          <thead>
                            <tr>
                              <th>Select</th>
                              <th>Item Name</th>
                              <th>Price</th>
                            </tr>
                          </thead>
                          <tbody>
                            {inventories.filter(val =>
                              val.inventory?.item_name.toLowerCase().includes(searchTerm.toLowerCase())
                            ).map((val, index) => (
                              <tr key={index}>
                                <td>
                                  <Form.Check
                                    type="checkbox"
                                    onChange={() => handleAddHandler(val)}
                                    checked={arrayData.some(item => item.inventory_id === val.inventory.id)}
                                  />
                                </td>
                                <td>{val.inventory?.item_name}</td>
                                <td>{val.inventory.raw_material_price}</td>
                              </tr>
                            ))}
                            {inventories.filter(val =>
                              val.inventory?.item_name.toLowerCase().includes(searchTerm.toLowerCase())
                            ).length === 0 && (
                                <tr>
                                  <td colSpan="3" className="text-center">Data not found</td>
                                </tr>
                              )}
                          </tbody>

                        </table>
                      </>
                    }
                  </Modal.Body>
                  <Modal.Footer>
                    <button className='btn btn-outline-primary' onClick={() => setShowModal(false)}>
                      Cancel
                    </button>
                    <Button variant="primary" onClick={handleAddItem}>
                      Add Item
                    </Button>
                  </Modal.Footer>
                </Modal>

                <Button
                  variant="primary"
                  type="submit"
                  className="mt-3"
                  disabled={tableItems.length === 0 || inventories.length === 0} // Disable if no items or inventories
                >
                  Create Order
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default OrderAdd;
