import React, { useState, useEffect, useRef, useMemo } from 'react'
import { Formik } from 'formik';
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { GetTaxList, GetTaxDetail } from '../../../services/company_panel/setting/taxConfigurationService';
import Swal from 'sweetalert2'
import CustomPagination from '../../../components/company_panel/common/custompagination'
import $ from "jquery";
import { capitalizeAllFirstLetters, checkPermission, getBrandId, getCompany, removeModalBackdrops, showLimitedElements } from '../../../helpers/commonFunctions';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ConfigurationTab from '../configurationTab';
import StatusFilter from '../../../components/company_panel/common/statusFilter';
import CustomRangepicker from '../../../components/company_panel/common/rangepicker';
import notFound from '../../../assets/company/img/no-record-found.png'
import { CreateGroups, DeleteGroups, DetailsGroups, ListGroups, UpdateGroups } from '../../../services/company_panel/routingStationService';
import { LOADER_TIME } from '../../../utils/Constants';


const RoutingGroups = () => {

    const breadcrumbs = [{ title: "Dashboard", url: "/company/dashboard" }, { title: "Pages", url: "" },]
    const navigate = useNavigate()
    const [submitted, setSubmitted] = useState(false)
    const formikRef = useRef();
    const params = useParams()
    const location = useLocation()
    const [list, setList] = useState([])
    const [statsUpdate, setStatusUpdate] = useState("false")
    const [dataLength, setDataLength] = useState()
    const [itemPerPage, setItemPerPage] = useState(9)
    const [sorting, setSorting] = useState({});
    const [defaultSorting, setDefaultSorting] = useState(true);
    const [page, setPage] = useState(1);
    const [loader, setLoader] = useState(true)
    const [search, setSearch] = useState({})
    const [globalSearch, setGlobalSearch] = useState("")
    const [resetdate, setResetDate] = useState(false);
    const [taxType, setTaxType] = useState('Primary category')
    const [name, setName] = useState('Primary category')
    const [showDefault, setShowDefault] = useState({})
    const [actionType, setActionType] = useState('add');
    const [isBrandChanged, setIsBrandChanged] = useState(false);


    // const [orderMode, setOrderMode] = useState([]);
    const [primaryTaxList, setPrimaryTaxList] = useState([]);

    // useEffect(() => {
    //     GetActiveOrderModes().then(data => {
    //         setOrderModeList(data && data.data && data.data.response ? data.data.response : [])
    //     }).catch(error => {
    //         console.log("error ====> ", error);
    //     })
    // }, [])

    useMemo(() => {
        document.addEventListener('brand-changed', function () {
            setIsBrandChanged(!isBrandChanged);
        });
    });

    useEffect(() => {
        return () => {
            removeModalBackdrops();
        }
    }, []);

    useEffect(() => {
        if (search) {
            setLoader(true)
            const formData = new FormData()
            formData.append("page", params.id)
            formData.append("per_page", itemPerPage)
            formData.append("sort", JSON.stringify(sorting))
            formData.append("search", JSON.stringify(search))
            formData.append("global_search", globalSearch)
            // formData.append('company_id', getCompany().company_id);
            formData.append('brand_id', getBrandId());

            ListGroups(formData).then(data => {
                setDataLength(data.data.response.count);
                // setList(data && data.data && data?.data?.response ? data.data.response : [])
                setList(data && data.data && data?.data?.response ? data.data.response.rows.map((item) => {
                    return {
                        ...item,
                        assigned_items: item?.assigned_items.map((elem) => {
                            return {
                                ...elem?.menu_item_details
                            }
                        })
                    }
                }) : []);

                setTimeout(() => {
                    setLoader(false)
                }, LOADER_TIME);

            }).catch(error => {
                setTimeout(() => {
                    setLoader(false)
                }, LOADER_TIME);
                console.log("error ====> ", error);
            })
        }

    }, [location, statsUpdate, sorting, search, globalSearch, isBrandChanged]);

    // Delete functionality
    function deleteFunction(row) {
        Swal.fire({
            customClass: 'swal-wide',
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#2f9bff',
            cancelButtonColor: '#dc3545',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                const formdata = new FormData()
                formdata.append("id", row)
                DeleteGroups(row).then(data => {
                    if (data.status === 200) {
                        Swal.fire({
                            customClass: 'swal-wide',
                            position: 'absolute',
                            icon: 'success',
                            title: data.data.message,
                            showConfirmButton: false,
                            timer: 2000,
                            toast: true,
                            position: 'top-right',
                        })
                        setStatusUpdate(!statsUpdate)
                    }
                }).catch(error => {
                    console.log("deleteError", error);
                })
            }
        })
    }


    // edit functionality

    const editFunction = (row) => {
        DetailsGroups(row).then(data => {
            setShowDefault(data && data?.data && data?.data?.response ? data?.data?.response : []);
            formikRef.current.setFieldValue("name", data?.data?.response?.name)
        }).catch(error => {
            console.log("error=====>", error)
        })
        setActionType('edit');
        // $('#addCardModal').show().css('opacity', 1).addClass('show');
        // $('body').append('<div class="modal-backdrop fade show"></div>')
    }


    const closeModel = (id) => {
        $('#addCardModal').hide();
        $('.modal-backdrop').remove();
        setShowDefault({});
        setStatusUpdate(!statsUpdate)
    }

    const openModel = (id, type) => {
        setActionType(type);
        $('#' + id).show().css('opacity', 1).addClass('show');
        $('body').append('<div class="modal-backdrop fade show"></div>')
        // setOrderMode([]);
    }



    return (
        <>
            {/* <Breadcrums data={breadcrumbs} /> */}
            <div className="animation_fade">
                <div className='page-header d-flex align-items-center'>
                    <div className='page-header-left'>
                        <h4>Routing Station</h4>
                    </div>
                    <div className='page-header-right ms-auto'>

                    </div>
                </div>
                <div className="card custom-card overflow-hidden">
                    <div className='gap-20 border-bottom'>
                        <div className='col-12'>
                            <div className='d-flex justify-content-between align-items-center'>
                                <ConfigurationTab tab='groups' />
                            </div>
                        </div>
                    </div>
                    <div className="gap-20">
                        <div className='page-header d-flex align-items-center mb-0'>
                            <div className='page-header-left'>
                                <div className='gap-2'>
                                    <div className='filter-header d-flex justify-content-between align-items-center'>
                                        {/* <p>Showing 10 entries</p> */}
                                    </div>
                                </div>


                            </div>
                            <div className='page-header-right ms-auto'>
                                {/* <button className="btn btn-primary" type="button" onClick={(e) => openModel('addCardModal', 'add')}>Add Group</button> */}
                                {checkPermission('store_routing_groups', 'add') ?
                                    <button className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#addCardModal" href='#addCardModal' onClick={() => { formikRef?.current?.resetForm(); setActionType('add'); }}>Add Group</button>
                                    : ''}
                            </div>
                        </div>
                    </div>
                    <div className="tabler-wrap">

                        <div className="table-responsive">
                            <div className='card gap-20'>
                                <div className='row plan-row'>
                                    {list?.length ? list.map((row, i) => (
                                        <div className='col-md-4 mb-4' key={i}>
                                            <div className='plan-box'>
                                                <div className='plan-head d-flex justify-content-between'>
                                                    <div className='plan-left' style={{ height: "3.5rem" }}>
                                                        <h4>{capitalizeAllFirstLetters(row?.name)}</h4>
                                                        <p>{showLimitedElements(row?.assigned_items.map((group) => group?.item_name), 1)}</p>
                                                    </div>
                                                </div>
                                                <div className='plan-footer d-flex gap-3'>
                                                    <button className='btn btn-primary' onClick={() => navigate(`/company/menu/routing-station/groups/assign-item/${row.id}`)}>Assign Items</button>
                                                    {checkPermission('store_routing_groups', 'edit') ?
                                                        <button className='btn btn-primary' data-bs-toggle="modal" data-bs-target="#addCardModal" href='#addCardModal' onClick={() => editFunction(row.id)}>Edit</button>
                                                        : ''}
                                                    {checkPermission('store_routing_groups', 'delete') ?
                                                        <button className='btn btn-danger' onClick={() => deleteFunction(row)}>Delete</button>
                                                        : ''}
                                                </div>
                                            </div>
                                        </div>
                                    )) : (
                                        <div className='text-center'>
                                            <img src={notFound} style={{ width: "5%" }} alt="Not Found" />
                                            <p>No Record.</p>
                                        </div>
                                    )}
                                </div>
                            </div>

                        </div>
                        <div className='tabler-footer'>
                            <div className='d-flex align-items-center'>
                                <div id="example1_info" role="status" className="table-result" aria-live="polite"><b>Total Records : {dataLength ? dataLength : "0"}</b></div>
                                {dataLength && dataLength > 0 ?
                                    <CustomPagination datalength={dataLength} itemperpage={itemPerPage} setPage={setPage} currentPage={page} pageRoute={[{ name: "outlet-management", path: "/company/menu/routing-station/groups/list" }]} /> : ""}
                            </div>
                        </div>
                    </div>
                </div>
                {/*  Modal*/}

                <Formik
                    enableReinitialize
                    innerRef={formikRef}
                    initialValues={{
                        name: "",
                    }}
                    validate={values => {
                        const error = {};
                        if (!values.name.trim()) {
                            error.name = `Name is required`;
                        } else if (values.name.trim().length < 3 || values.name.trim().length > 30) {
                            error.name = `Name must be between 3 and 30 characters`;
                        }

                        return error;
                    }}

                    onSubmit={(values, { setSubmitting, resetForm }) => {

                        setSubmitted(true)
                        let formData = new FormData();
                        formData.append('name', values.name);
                        formData.append('brand_id', getBrandId());

                        if (actionType === 'edit') {
                            formData.append("id", showDefault.id)
                            UpdateGroups(formData).then(response => {
                                setSubmitting(false);
                                if (response.data.status === 1 || response.data.status === "1") {
                                    Swal.fire({
                                        customClass: 'swal-wide',
                                        icon: 'success',
                                        title: response.data.message,
                                        showConfirmButton: false,
                                        timer: 1500,
                                        toast: true,
                                        position: 'top-right',
                                    });
                                    setTimeout(() => {
                                        $('#addCardModal').hide();
                                        $('.modal-backdrop').remove();
                                        setStatusUpdate(!statsUpdate)
                                        resetForm();
                                        setShowDefault({});
                                    }, 1000);
                                } else {
                                    Swal.fire({
                                        customClass: 'swal-wide',
                                        icon: 'error',
                                        title: response.data.message,
                                        showConfirmButton: false,
                                        timer: 5000,
                                        toast: true,
                                        position: 'top-right',
                                    })
                                    setSubmitted(false)
                                }
                            }).catch(error => {
                                console.log("error ====> ", error);
                            })

                        } else {
                            CreateGroups(formData).then(response => {

                                setSubmitting(false);
                                if (response.data.status === 1 || response.data.status === "1") {
                                    Swal.fire({
                                        customClass: 'swal-wide',
                                        icon: 'success',
                                        title: response.data.message,
                                        showConfirmButton: false,
                                        timer: 1500,
                                        toast: true,
                                        position: 'top-right',
                                    });
                                    setTimeout(() => {
                                        $('#addCardModal').hide();
                                        $('.modal-backdrop').remove();
                                        setStatusUpdate(!statsUpdate)
                                        resetForm();
                                        setShowDefault({});
                                    }, 1000);
                                } else {
                                    Swal.fire({
                                        customClass: 'swal-wide',
                                        icon: 'error',
                                        title: response.data.message,
                                        showConfirmButton: false,
                                        timer: 5000,
                                        toast: true,
                                        position: 'top-right',
                                    })
                                    setSubmitted(false)
                                }
                            }).catch(error => {
                                console.log("error ====> ", error);
                            })

                        }

                    }}

                >{({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                }) => (

                    <div className="modal fade" id="addCardModal" aria-labelledby="addCardModalLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title  fs-4" id="addCardModalLabel" >{actionType === 'edit' ? 'Edit' : 'Add'} Group</h5>
                                    {/* <button type="button" className="btn-close" onClick={(e) => closeModel('addCardModal')} data-bs-dismiss="modal" aria-label="Close"></button> */}
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={formikRef?.current?.resetForm} ></button>
                                </div>
                                <form onSubmit={handleSubmit}>
                                    <div className="modal-body">
                                        <div className="mb-3">
                                            <label htmlFor="name" className="col-form-label">Name<span className=' requirestar'>*</span></label>
                                            <input type="text" className="form-control" id="name" name="name" placeholder='Enter Name' onChange={handleChange} onBlur={handleBlur} value={values.name} autoComplete='off' />
                                            <span className='text-danger d-flex text-left'>{errors.name && touched.name && errors.name}</span>
                                        </div>
                                        <button type="button" className="btn btn-outline-primary ml-2" style={{ width: '48%', marginRight: '10px' }} data-bs-dismiss="modal" aria-label="Close" onClick={formikRef?.current?.resetForm}>Cancel</button>
                                        <button type="submit" className="btn btn-primary" style={{ width: '48%' }} data-bs-dismiss="modal" aria-label="Close" >{actionType === 'edit' ? 'Update' : 'Add'}</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                )}
                </Formik>

                <ToastContainer
                    position="top-right"
                    hideProgressBar={false}
                    autoClose={true | 1000}
                    newestOnTop={true}
                    closeOnClick={false}
                    draggable={false}
                    rtl={false}
                />

            </div>
        </>
    );
}

export default RoutingGroups;